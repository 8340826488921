'use strict';

angular.module('app')

.factory('fei', function (ThemeConfig)
{
    var theme = new ThemeConfig({
        Blue: '#002B59',
        Seance: '#701E7D',
        Affair: '#875295',
        'Affair dark': '#7D3F8B',
        // Default Colours
        Black: '#000000',
        White: '#ffffff',
        Grey: '#DCDDDF',
        None: ' '
    });

    theme.appConfig = function (options)
    {
        options.presentation.marginals.appendDateEnabled = false;
        options.presentation.marginals.chapterNumberEnabled = false;
    };

    var colourObject = theme.customColoursObjectInverted([
        'White',
        'Blue',
        'Grey',
        'Seance',
        'Affair',
        'Affair dark'
    ]);

    var colourList = theme.customColoursList([
        'White',
        'Blue',
        'Grey',
        'Seance',
        'Affair',
        'Affair dark'
    ]);


    /**
     * Modify the editor options to suit this theme
     *
     */
    theme.editorOptions = function (options)
    {
        delete options.nestableComponents.column.nested.tabs;

        options.componentOptions.textblock.tags = {
            h1: 'H1',
            h2: 'H2',
            h3: 'H3',
            h4: 'H4',
            h5: 'H5',
            h6: 'H6',
            p: 'P'
        };

        options.componentOptions['shape-line'].defaultColor = theme.getColour('Affair');
        options.componentOptions['shape-line'].allowedTags = {};

        options.componentOptions.column.allowedTags = {};
        options.componentOptions.column.config.push({
            name: '1/2/1 Column',
            value: [3,6,3]
        });

        options.componentOptions.grid.allowedTags = {};
        options.componentOptions.chart.allowedTags = {};
        options.componentOptions.image.allowedTags = {};
        options.componentOptions['chapter-header'].allowedTags = {};
        options.componentOptions['high-map'].allowedTags = {};
        options.componentOptions['bg-image'].allowedTags = {};
        options.componentOptions['video-player'].allowedTags = {};

        options.componentOptions['comp-panel'].allowedTags = {};

        options.componentOptions['comp-panel'].background = {
            default: theme.getColour('White'),
            colors: colourObject
        };

        options.textColours = theme.customColoursObject([
            'Black',
            'White',
            'Seance'
        ]);

        options.backgroundColors = colourObject;

        options.componentClasses.bullets.classes.dash = 'Dash';

        options.componentClasses['bg-targeted-image'] = {
            default: ' ',
            classes: {
                ' ': 'None',
                'bkg-1': 'Triangle purple',
                'bkg-11': 'Triangle grey',
                'bkg-2': 'Triangle grey purple',
                'bkg-3': 'Triangle purple grey',
                'bkg-4': 'Triangle purple purple',
                'bkg-5': 'Triangle white grey',
                'bkg-6': 'Triangle white purple',
                'bkg-7': 'Overlay 01',
                'bkg-8': 'Overlay 02',
                'bkg-9': 'Overlay 03',
                'bkg-10': 'Overlay 04'
            }
        };

        options.nestableComponents.editor.components.timeline = 'Timeline';

    };

    /**
    * Modify the map config to suit this theme
    *
    */
    theme.mapConfig = function (options)
    {
        options.themes.purple = {
            name: 'Purple',
            color: theme.getColour('Seance'),
            nullColor: theme.getColour('Affair', 0.5),
            borderWidth: 0,
            borderColor: '#000000',
        };
    };

    /**
     * Modify the chart colours to suit this theme
    */
    theme.chartThemeConfig = function (options)
    {
        options.themes.light.colors.highlight = theme.getColour('Seance');
        options.themes.light.colors.arrows = theme.getColour('Seance');
        options.themes.light.colors.series = colourList;

        options.themes.dark.colors.highlight = theme.getColour('Seance');
        options.themes.dark.colors.arrows = theme.getColour('Seance');
        options.themes.dark.colors.series = colourList;
    };

    theme.swatchColorsConfig = function (options)
    {
        options.default = theme.getColour('Seance');
        options.colors = colourObject;
    };

    /**
     * Modify the presentation config to suit this theme
     *
    */
    theme.presentationConfig = function (options)
    {
        options.marginals = 'header';
    };

    return theme;
});
