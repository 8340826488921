'use strict';

angular.module('presentation.theme')

.directive('themeTextStylePanel', function ()
{
    return {
        restrict: "E",
        templateUrl: 'app/presentation/theme/theme.builder/typography/text-style-panel/text-style-panel.html',
        scope: {
            textStyle: "=",
            model: "=",
            index: "=",
            marginOption: '=?',
        },
        bindToController: true,
        controllerAs: '$ctrl',
        controller: function ()
        {
            this.$onInit = () =>
            {
                this.defaultFont = this.model.config.typography.defaultFont;
                this.refreshWeights();

                if (angular.isUndefined(this.marginOption)) {
                    this.marginOption = true;
                }
            };

            this.refreshWeights = (setDefault) =>
            {
                this.weights = this.model.getFontWeights(this.textStyle.typeface);

                if (setDefault) {
                    this.setWeight(Object.keys(this.weights)[0]);
                }
            };

            this.setTypeface = (fontFamily) =>
            {
                this.textStyle.typeface = fontFamily ? fontFamily.name : null;
                this.refreshWeights(true);
            };

            this.setWeight = (weight) =>
            {
                this.textStyle.weight = weight;
            };
        }
    };
});