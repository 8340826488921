'use strict';

angular.module('presentation.theme')

.directive('themeStyleToggle', function ()
{
    return {
        restrict: "E",
        templateUrl: 'app/presentation/theme/components/theme.style-toggle/theme.style-toggle.html',
        scope: {
            toggleState: "="
        },
        controller: function ($scope)
        {
            $scope.styleToggle = true;
        }
    };
});
