'use strict';

angular.module('presentation.frontend')

.factory('PreviousState', function ($window, $state, localStorageValid)
{
    var localStorage = localStorageValid() ? $window.localStorage : {};
    var skip = false;

    var service = {

        saveState: function ()
        {
            if (!$state.current.name) {
                return;
            }

            if (skip) {
                skip = false;
                return;
            }

            var state = {
                name: $state.current.name,
                params: $state.params,
                url: $state.href($state.current.name, $state.params)
            };

            localStorage.previousState = angular.toJson(state);
        },
        getState: function ()
        {
            return angular.fromJson(localStorage.previousState);
        },
        skip: function ()
        {
            skip = true;
        }
    };

    return service;
});
