'use strict';

angular.module('presentation.theme')

.directive('themeNavigation', function ()
{
    return {
        restrict: "E",
        templateUrl: 'app/presentation/theme/components/theme.navigation/theme.navigation.html',
        scope: false,
        controller: function ($scope, ThemeValidator)
        {
            $scope.steps = [
                {
                    title: "Introduction",
                    link: "admin.theme-build.introduction"
                },
                {
                    title: "Colours",
                    link: "admin.theme-build.colours",
                    subSteps: [
                        {
                            label: "Brand colours*",
                        },
                        {
                            label: "Presentation sidebar",
                        },
                    ],
                },
                {
                    title: "Typefaces",
                    link: "admin.theme-build.typefaces",
                    subSteps: [
                        {
                            label: "Font upload",
                        },
                        {
                            label: "Font setup",
                        },
                    ],
                },
                {
                    title: "Typography",
                    link: "admin.theme-build.typography",
                    subSteps: [
                        {
                            label: "Default text",
                        },
                        {
                            label: "Text styles",
                        },
                        {
                            label: "List styles",
                        },
                    ],
                },
                {
                    title: "Presentation Intro",
                    link: "admin.theme-build.presentation-intro",
                    subSteps: [
                        {
                            label: "Image Upload",
                        },
                        {
                            label: "Additional Options",
                        }
                    ],
                },
                {
                    title: "Slide setup",
                    link: "admin.theme-build.slide-setup",
                    subSteps: [
                        {
                            label: "Header / Footer config",
                        },
                        {
                            label: "Margin typography",
                        },
                        {
                            label: "Margin size",
                        },
                        {
                            label: "Gradient overlay",
                        },
                    ],
                },
                {
                    title: "Tables",
                    link: "admin.theme-build.tables",
                    subSteps: [
                        {
                            label: "Border styles*",
                        },
                        {
                            label: "Table styles",
                        },
                    ],
                },
                {
                    title: "Shapes",
                    link: "admin.theme-build.shapes",
                    subSteps: [
                        {
                            label: "Shape styles",
                        },
                    ],
                },
                {
                    title: "Images",
                    link: "admin.theme-build.images",
                    subSteps: [
                        {
                            label: "Image marker",
                        },
                        {
                            label: "Background overlays",
                        },
                    ],
                },
                {
                    title: "Charts",
                    link: "admin.theme-build.charts",
                    subSteps: [
                        {
                            label: "Light theme",
                        },
                        {
                            label: "Dark theme",
                        },
                    ],
                },
                // {
                //     title: "Maps",
                //     link: "admin.theme-build.maps",
                //     subSteps: [
                //         {
                //             label: "Light theme",
                //         },
                //         {
                //             label: "Dark theme",
                //         },
                //     ],
                // },
                {
                    title: "Tabs",
                    link: "admin.theme-build.tabs",
                    subSteps: [
                        {
                            label: "Title",
                        },
                        {
                            label: "Tabs",
                        },
                        {
                            label: "Divider",
                        },
                        {
                            label: "Slider",
                        },
                    ],
                },
                {
                    title: "Popups",
                    link: "admin.theme-build.popups",
                    subSteps: [
                        {
                            label: "Colour Profiles",
                        },
                    ],
                },
                {
                    title: "IFrames",
                    link: "admin.theme-build.iframes",
                    subSteps: [],
                },
                {
                    title: "Brand color",
                    link: "admin.theme-build.brand-color",
                    subSteps: [],
                },
                {
                    title: "Custom styling",
                    link: "admin.theme-build.custom",
                    subSteps: [
                        {
                            label: "CSS Editor",
                        },
                    ],
                }
            ];

            $scope.hasErrors = (title) => {
                return ThemeValidator.sectionHasError(title);
            };
        }
    };
});
