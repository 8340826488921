'use strict';

angular.module('app')

.factory('clientViewOptions', function (
    $state,
    $q,
    $uibModal,
    extendDeep,
    Slide,
    resourceViewOptions,
    recoverAction,
    toggleTrashAction,
    gridNameTemplate,
    listNameTemplate,
    gridThumbTemplate,
    createAction,
    editSingleItemAction,
    deleteSingleItemAction,
    moveSingleItemAction,
    deselectAction,
    multiDeleteAction,
    multiMoveAction,
    createFolderAction,
    kdGate,
    moment
) {
    var options = angular.copy(resourceViewOptions);
    var gatedCreateAction = angular.copy(createAction);
    var gatedCreateFolderAction = angular.copy(createFolderAction);

    var showCreateButton = function ()
    {
        return kdGate.allows('clients.create');
    };

    gatedCreateAction.show = showCreateButton;
    gatedCreateAction.template = '<i class="material-icon material-icon-add"></i> New client';
    gatedCreateFolderAction.show = showCreateButton;

    options.moment = moment;


    options.toolbar = [gatedCreateFolderAction, gatedCreateAction];

    options.actions = [
        deselectAction,
        multiDeleteAction,
        multiMoveAction,
    ];

    options.inlineActions = [
        editSingleItemAction,
    ];

    options.inlineDropdown = [
        moveSingleItemAction,
        deleteSingleItemAction
    ];

    options.gridActions = [
        ...options.inlineActions,
        ...options.inlineDropdown,
    ];



    extendDeep(options, {
        tagging: true,
        taggingAdvanced: true,
        onItemClick: function ($event, model)
        {
            $event.stopPropagation();

            if (kdGate.allows('clients.create') &&
                kdGate.allows('clients.edit-colleagues')) {
                    model.goInto();
            }
        },
        openPresentations: function(modelID) {
            $uibModal.open({
                windowClass: 'share-action-form',
                templateUrl: 'app/client/clientPresentations/clientPresentations.html',
                controllerAs: '$ctrl',
                controller: 'ClientPresentationsCtrl',
                size: 'lg',
                resolve: {
                    model: ['Presentation', (Presentation) => {
                        return Presentation.findAll({
                            where: {client_id: modelID },
                            with: 'breadcrumbs'
                        });
                    }]
                }
            });
        },
        list: {
            columns: [
                {
                    label: 'Name',
                    name: 'name',
                    template: listNameTemplate('name', 'asset.links.thumb', 'type'),
                    sortable: true
                },
                {
                    label: 'Type',
                    name: 'type',
                    template: '<span class="resource-ui-type glyphicon glyphicon-komondor-{{ model.type }}"' +
                    '                ng-class="{deleted: model.deleted_at}"></span>',
                    sortable: false
                },
                {
                    label: 'Editable',
                    name: 'editable',
                    template: '<i ng-show="model.editable" class="material-icon material-icon-check"></i>',
                    sortable: false
                },
                {
                    label: 'Presentations',
                    name: 'shares_count',
                    template: `<span ng-hide="model.type == \'folder\' || model.presentations_count < 1">
                                    <a href ng-click="options.openPresentations(model.id)">
                                        <u>{{ model.presentations_count }}</u>
                                    </a>
                                </span>`,
                    sortable: false
                },
                {
                    label: 'Last modified',
                    name: 'updated_at',
                    template: `<span class="no-wrap" uib-tooltip="{{ model.updated_at | date }}">
                                    {{ options.moment(model.updated_at).fromNow() }}
                                </span>`,
                    sortable: true
                },
                {
                    template: `<contextual-options actions="options.inlineActions"
                                                   dropdown-actions="options.inlineDropdown"
                                                   models="options.resourceList.selected"
                                                   model="model"
                                                   class="row-actions pull-right"></contextual-options>`
                }
            ],
        },
        grid: {
            fields: [
                {
                    template: gridThumbTemplate('asset.links.thumb', 'type'),
                },
                {
                    template: gridNameTemplate('name', 'type'),
                },
                {
                    template: `<contextual-options dropdown-actions="options.gridActions"
                                                   models="options.resourceList.selected"
                                                   model="model"
                                                   class="grid-actions pull-right"></contextual-options>`
                }
            ]
        }
    });

    return options;
});
