'use strict';

angular.module('presentation.theme')

.component('themeBrandColor', {
    templateUrl: 'app/presentation/theme/theme.builder/brand-color/brand-color/theme.builder.brand-color.html',
    bindings: {
        config: '=',
        palette: '='
    }
});
