'use strict';

angular.module('app')

.service('prepareEditorRtlOptions', function ()
{
    this.components = [
        'textblock',
        'bullets',
        'table',
    ];

    this.update = function (editorOptions, value) {
        let componentOptions = editorOptions.componentOptions;

        this.components.forEach((component) => {
            if (componentOptions[component]) {
                componentOptions[component].rtl = value;
            }
        });
    };
});
