'use strict';

angular.module('app')

.controller('ClientEditCtrl', function ($scope, $stateParams, Asset, model, $http, Notification, targetedImageCache)
{
    $scope.model = model;

    $scope.model.data = $scope.model.data || [{key:'',value:''}];
    $scope.model.type = $scope.model.type || 'client';

    // Create clients in the current folder
    if ($scope.model.folder_id === undefined) {
        $scope.model.folder_id = $stateParams.folder_id || 0;
    }

    $scope.$watch('model.asset_id', function (newVal, oldVal) {

        let model = null;

        if (angular.isNumber(newVal)) {
            model = Asset.get(newVal);
        }

        if (model && model.isSvg()) {
            Notification.error({
                title: 'This image format is not allowed!',
                message: 'Client logo cannot be SVG, please use PNG or JPG.'
            });

            $scope.model.asset_id = oldVal;
        }

        $scope.model.asset_id = $scope.model.asset_id || 0;
    });

    $scope.$watch('model.secondary_asset_id', function (newVal, oldVal) {

        let model = null;

        if (angular.isNumber(newVal)) {
            model = Asset.get(newVal);
        }

        if (model && model.isSvg()) {
            Notification.error({
                title: 'This image format is not allowed!',
                message: 'Client logo cannot be SVG, please use PNG or JPG.'
            });

            $scope.model.secondary_asset_id = oldVal;
        }

        $scope.model.secondary_asset_id = $scope.model.secondary_asset_id || 0;

    });

    $scope.saveModel = function ()
    {
        $scope.model.DSCreate().then(function ()
        {
            targetedImageCache.clear();
            $scope.$close($scope.model);
        });
    };

    $scope.whiteOverlay = function ()
    {
        const showError = () => {
            Notification.error({
                title: 'Something went wrong!',
                message: 'Secondary logo was not generated.'
            });
        };

        $http.post('/api/clients/white-overlay/' + $scope.model.asset_id).then((response) => {

            if (response.data && response.data.id) {
                $scope.model.secondary_asset_id = response.data.id;
            } else {
                showError();
            }
        }).catch(showError);
    };
});
