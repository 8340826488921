/* eslint-env serviceworker, es6 */
/* globals self, caches, fetch */
"use strict";

const CACHE_NAME = "media-cache-v1";
let cumulativeSize = 0;

// Helper function to check if the request is for an image or video
function isMediaRequest(request) {
    return request.url.match(/\.(?:png|jpg|jpeg|gif|webp|mp4)$/);
}

// Install event: Skip waiting and immediately activate
self.addEventListener("install", () => {
    self.skipWaiting(); // Skip the waiting phase and activate immediately
});

// Activate event: Take control of the clients immediately
self.addEventListener("activate", (event) => {
    event.waitUntil(self.clients.claim()); // Take control of all pages immediately
});

self.addEventListener("fetch", (event) => {
    if (isMediaRequest(event.request)) {
        const cachedResponsePromise = caches
            .match(event.request)
            .then((cachedResponse) => {
                if (cachedResponse) {
                    return cachedResponse;
                }

                return fetch(event.request).then((networkResponse) => {
                    return caches.open(CACHE_NAME).then((cache) => {
                        cache.put(event.request, networkResponse.clone());
                        return networkResponse;
                    });
                });
            });

        event.respondWith(cachedResponsePromise);
    }
});

self.addEventListener("message", (event) => {
    if (event.data && event.data.type === "CLEAR_CACHE") {
        caches
            .keys()
            .then((cacheNames) => {
                return Promise.all(
                    cacheNames.map((cacheName) => {
                        return caches.delete(cacheName);
                    })
                );
            })
            .then(() => {
                cumulativeSize = 0; // Reset cumulative size
            });
    }
});
