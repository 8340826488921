'use strict';

angular.module('app')

.config(function (editorGatesProvider) {
   editorGatesProvider.setGates('shape-line');
})

.directive('shapeLine', function (defaultEditorOptions, presentationState, currentTheme)
{
    return {
        restrict: 'A',
        require: ['^component', '^editor'],
        templateUrl: 'scripts/editorComponents/shapeLine/shapeLine.html',
        controller: controller
    };

    function controller ($scope, $element, $filter)
    {
        const presentation = presentationState.presentation;
        const theme = currentTheme();

        const editorOptions = defaultEditorOptions.get();
        const componentCtrl = $element.controller('component');
        const options = editorOptions.componentOptions['shape-line'];

        $scope.refreshStyles = refreshStyles;
        $scope.getTrackables = getTrackables;
        $scope.setStyle = setStyle;

        $scope.lineStyles = {
          'solid': 'Solid',
          'dotted': 'Dotted'
        };

        $scope.lineStylesIcons = {
          'solid': 'images/sidebar/line/solid.svg',
          'dotted': 'images/sidebar/line/dashed.svg'
        };

        $scope.backgroundColors = $filter('coloursFilter')(editorOptions.backgroundColors, 'transparent');

        $scope.model.height = $scope.model.height || options.defaultHeight;
        $scope.model.color = $scope.model.color || options.defaultColor;
        $scope.model.lineStyle = $scope.model.lineStyle || 'solid';

        $scope.getColor = function(value) {

            if (value === 'client_brad_color') {

                let color = null;

                if (theme) {
                    color = theme.getDefaultClientBrandColor();
                }

                if (presentation && !presentationState.editMode) {
                    color = presentation.getClientsBrandColor();
                }

                if (color) {
                    return color;
                }
            }

            return value;
        };

        /**
         * Used to make a class by color name.
         */
        $scope.colorClassName = function (value) {
            return value
                .toLowerCase()
                .replace(/ /g,'-')
                .replace(/[^\w-]+/g,'');
        };

        function refreshStyles ()
        {
            if ($scope.model.lineStyle === 'solid') {
                setStyle('backgroundColor', $scope.getColor($scope.model.color));
                setStyle('height', $scope.model.height + 'px');
                componentCtrl.removeStyle('borderBottomColor');
                componentCtrl.removeStyle('borderBottomWidth');
                componentCtrl.removeStyle('borderBottomStyle');
            }

            if ($scope.model.lineStyle === 'dotted') {
                setStyle('borderBottomColor', $scope.getColor($scope.model.color));
                setStyle('borderBottomWidth', $scope.model.height + 'px');
                setStyle('borderBottomStyle', 'dotted');
                componentCtrl.removeStyle('backgroundColor');
                componentCtrl.removeStyle('height');
            }
        }

        function setStyle (name, value)
        {
            const style = {};
            style[name] = value;

            componentCtrl.addStyle(style);
        }

        function getTrackables ()
        {
            return {
                'color': {
                    deepWatch: false,
                    onChange: refreshStyles
                },
                'height': {
                    deepWatch: false,
                    onChange: refreshStyles
                },
                'lineStyle': {
                    deepWatch: false,
                    onChange: refreshStyles
                },
            };
        }

        $scope.$watchGroup([
            'model.color',
            () => presentationState.editMode,
            () => presentationState.client,
        ], refreshStyles, true);
    }
});
