'use strict';

angular.module('presentation.frontend')

.directive('slidePrint', function (
    gradientConfigurator,
    presentationState,
    defaultEditorOptions,
    prepareEditorRtlOptions,
    clientTokens,
    slideModal
) {
    return {
        restrict: 'E',
        controllerAs: '$ctrl',
        bindToController: true,
        templateUrl: 'views/editor/slide.html',
        scope: {
            slide: '=',
            popup: '='
        },
        controller: function($scope)
        {
            // Copy needed as some options may vary from slide to slide
            const editorOptions = angular.copy(defaultEditorOptions.get());

            this.$onInit = function ()
            {
                const config = this.slide.config;
                this.onGradientChange(config.gradientConfig);

                $scope.slide = this.slide;
                $scope.print = true;
                $scope.presentationState = presentationState;
                $scope.clientTokens = clientTokens;
                $scope.currEditingLayer = 'FrontLayer';

                prepareEditorRtlOptions.update(editorOptions, config.rtl);

                // Slide controller properties
                $scope.editorOptions = editorOptions;
                $scope.backLayerOptions = angular.copy(editorOptions);

                let presentation = presentationState.presentation;

                $scope.slideBackground = function (value)
                {
                    if (value === 'client_brad_color') {

                        if (presentation) {
                            let color =  presentation.getClientsBrandColor();

                            if (color) {
                                return color;
                            }
                        }
                    }

                    return value;
                };

                if (this.popup) {
                    const modalParent = '#popup-' + this.popup.id + ' .slide-container';
                    const size = this.popup.config.size || 'md';
                    const colour = this.popup.config.colour || 'md';

                    slideModal.open(this.popup.id, size, colour, modalParent, false);
                }
            };


            this.onGradientChange = function (config)
            {
                gradientConfigurator.applyChanges(config);
            };

        }
    };
});
