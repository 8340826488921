'use strict';

angular.module('presentation.theme')

.component('themeListStyles', {
    templateUrl: 'app/presentation/theme/theme.builder/typography/list-styles/list-styles.html',
    bindings: {
        model: '<'
    },
    controller: function ()
    {
        this.$onInit = () =>
        {
            if (this.model.config.typography.listStyles < 1) {
                this.addListStyle();
            }
        };

        this.addListStyle = () =>
        {
            const defaultListStyle = {
                assetId: 0,
                name: 'New list style',
                className: `list-style-${Date.now()}`,
                type: 'circles',
                color: 'transparent',
                textStyle: this.model.config.typography.textStyles[0],
                width: 4,
                margin: '',
                paddingRight: 5
            };

            this.model.config.typography.listStyles.push(defaultListStyle);
        };

        this.deleteListStyle = (index) =>
        {
            this.model.config.typography.listStyles.splice(index, 1);
        };
    }
});
