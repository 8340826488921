'use strict';

angular.module('helpCentre')

.controller('HelpCentreArticleCtrl', function ($scope, $stateParams, categories, articleContent)
{
    $scope.articleContent = articleContent;

    // Check wether description is single string or array or paragraphs
    $scope.articleDescription = angular.isString($scope.articleContent.description) ? [$scope.articleContent.description] : $scope.articleContent.description;

    // Retrieve the appropriate section template
    $scope.getSectionTemplateUrl = function (type)
    {
        return 'help-centre/templates/' + type + '.html';
    };
});
