'use strict';

angular.module('presentation.theme')

.directive('themeMapStyles', function ()
{
    return {
        restrict: "E",
        templateUrl: 'app/presentation/theme/theme.builder/maps/map-styles/map-styles.html',
        scope: {
            mapsConfig: "=",
            palette: "="
        }
    };
});
