'use strict';

angular.module('app')

.factory('fiba', function (ThemeConfig)
{
    var theme = new ThemeConfig();

    /**
     * Panel component name changes per theme
     *
     */
    theme.getPanelName = function ()
    {
        return 'Block';
    };

    theme.appConfig = function (options)
    {
        options.presentation.marginals.appendDateEnabled = false;
        options.presentation.marginals.chapterNumberEnabled = false;
    };

    /**
     * Modify the editor options to suit this theme
     *
     */
    theme.editorOptions = function (options)
    {
        delete options.nestableComponents.column.nested.tabs;

        options.componentClasses['comp-panel'] = {
            default: ' ',
            classes: {
                ' ': 'None',
                'panel-circle': 'Circle',
                'panel-linear-fade': 'Linear fade panel',
                'panel-horizontal-gradient-one': 'Horizontal gradient light',
                'panel-horizontal-gradient-two': 'Horizontal gradient dark'
            }
        };

        options.componentClasses['bg-image'] = {
            default: ' ',
            classes: {
                ' ': 'None',
                'panel-curved': 'Left gradient panel',
                'panel-curved-transparent': 'Left transparent panel',
            }
        };

        options.componentClasses['bg-targeted-image'] = {
            default: ' ',
            classes: {
                ' ': 'None',
                'panel-curved': 'Left gradient panel',
                'panel-curved-transparent': 'Left transparent panel',
            }
        };

        options.componentClasses['video-player'] = {
            default: ' ',
            classes: {
                ' ': 'None',
                'panel-curved': 'Left gradient panel',
                'panel-curved-transparent': 'Left transparent panel',
            }
        };

        options.componentClasses.table = {
            default: 'styled',
            classes: {
                styled: 'Styled',
                unstyled: 'Unstyled',
            }
        };

        options.componentClasses.image = {
            default: 'left',
            classes: {
                left: 'Left',
                right: 'Right',
                middle: 'Middle',
                'image-circle': 'Circle',
            }
        };

        options.componentClasses.bullets = {
            default: 'chevron-lg',
            classes: {
                'chevron-lg': 'Chevron Large',
                'chevron-md': 'Chevron Medium',
                'chevron-sm': 'Chevron Small',
            }
        };

        options.nestableComponents.editor.back_layer = {
            'video-player': 'Video',
            'bg-targeted-image': 'Image',
        };

        options.componentOptions['comp-panel'].background = {
            default: 'transparent',
            colors: {
                transparent: 'None',
                '#000000': 'Black',
                '#a4945d': 'Gold',
                '#35b5ff': 'Blue one',
                '#1a81c6': 'Blue two',
                '#1354ad': 'Blue three',
            }
        };

        options.componentOptions.textblock.tags = {
            h1: 'Chapter intro',
            h2: 'Headline',
            h3: 'Title large',
            h4: 'Title small',
            h5: 'Big number',
            h6: 'Caption',
            p: 'Paragraph',
            cite: 'Cite',
            // blockquote : 'Quote',
        };

        options.componentOptions['shape-line'].defaultColor = '#a4945d';

        options.componentOptions.textblock.allowedTags = {
            b: 'no attributes',
            u: 'no attributes',
            sup: 'no attributes',
            sub: 'no attributes',
            font: true,
            a: true
        };

        options.textColours = {
            Black: '#4B4B4D',
            White: '#FFFFFF',
            blue: '#1354AD',
            gold: '#A4945D',
        };

        options.backgroundColors = {
            transparent: 'None',
            '#000000': 'Black',
            '#A4945D': 'Gold',
            '#35B5FF': 'Blue one',
            '#1A81C6': 'Blue two',
            '#1354AD': 'Blue three',
        };

        options.componentOptions['shape-line'].defaultColor = '#a4945d';
        options.componentOptions.column.allowedTags = {};
        options.componentOptions.grid.allowedTags = {};
        options.componentOptions.chart.allowedTags = {};
        options.componentOptions.image.allowedTags = {};
        options.componentOptions['comp-panel'].allowedTags = {};
        options.componentOptions['chapter-header'].allowedTags = {};
        options.componentOptions['high-map'].allowedTags = {};
        options.componentOptions['bg-image'].allowedTags = {};
        options.componentOptions['shape-line'].allowedTags = {};
        options.componentOptions['video-player'].allowedTags = {};
        options.componentOptions['slide-info'].allowedTags = {};

        options.componentOptions['shape-line'].margin = true;
        options.componentOptions.chart.margin = true;
        options.componentOptions.table.margin = true;
        options.componentOptions.bullets.margin = true;

        options.componentOptions['comp-panel'].height = true;

        options.componentOptions.column.config.push({
            name: '1/4 Column',
            value: [3,9]
        },{
            name: '3/4 Column',
            value: [9,3]
        });

        options.nestableComponents.column = {
            nested: {
                column: 'Column',
                textblock: 'Textblock',
                bullets: 'Bullets',
                'targeted-image': 'Image',
                'client-logo': 'Client Logo',
                'comp-panel': 'Panel',
                grid: 'Grid',
                chart: 'Chart',
                'shape-line': 'Line',
                table: 'Table',
                'slide-info': 'Slide information'
            }
        };
    };

    /**
 * Modify the map config to suit this theme
 *
 */
    theme.mapConfig = function (options)
    {
        options.themes.gold = {
            name: 'Gold',
            color: '#a4945d',
            nullColor: 'rgba(164, 148, 93, 0.3)',
            pinStyle: {
                backgroundColor: '#ffffff',
                border: '1px solid #a4945d',
            },
            labelStyle: {
                backgroundColor: 'rgba(0, 0, 0, 0)',
                color: '#ffffff',
                border: '0',
                fontFamily: 'Fiba',
            },
            borderWidth: 1,
            borderColor: '#000000',
        };
    };

    /**
 * Modify the preloader to suit this theme
*/
    theme.preloader = function (options)
    {
        options.themes.active = 'default'; // default, circle or mask
    };

    /**
     * Modify the chart colours to suit this theme
    */
    theme.chartThemeConfig = function (options)
    {
        options.themes.light.colors.highlight = '#a4945d';
        options.themes.light.colors.arrows = '#a4945d';
        options.themes.light.colors.series = [
            '#A4945D',
            '#35B5FF',
            '#0069B0',
            '#D1B64D',
            '#75D4FD',
            '#4080E4',
            '#FFE965'
        ];

        options.themes.dark.colors.highlight = '#a4945d';
        options.themes.dark.colors.arrows = '#a4945d';
        options.themes.dark.colors.series = [
            '#A4945D',
            '#35B5FF',
            '#0069B0',
            '#D1B64D',
            '#75D4FD',
            '#4080E4',
            '#FFE965'
        ];

    };

    return theme;
});
