'use strict';

angular.module('presentation.frontend')

/**
 * Responsible for converting presentation and slide object to json
 * User to data to post to api, or compare slides and presentations
 */
.service('toJson', function ()
{
    var self = this;

    /**
     *
     * Converts slide object to json
     *
     * @param slide
     */
    this.slide = function (slide)
    {
        var slideCopy = angular.copy(slide);

        return {
            id: slideCopy.id,
            name: slideCopy.name,
            config: slideCopy.config,
            components: slideCopy.components,
            back_layer: slideCopy.back_layer,
            type: slideCopy.type,
            template_id: slideCopy.template_id
        };
    };

    /**
     *
     * Converts chapter object to json
     *
     * @param chapter
     */
    this.chapter = function (chapter)
    {
        const chapterCopy = angular.copy(chapter);

        return {
            display_order: 1,
            id: chapterCopy.id,
            presentation_id: chapterCopy.presentation_id,
            name: chapterCopy.name,
            slides: chapterCopy.chapter_slides.map((chapter_slide) => {
                return chapter_slide.slide;
            })
        };
    };
    /**
     *
     * Converts presentation object to json
     *
     * @param presentation - Presentation object
     * @param markModifiedSlides - to add "modified" flag to a slide is slide was modified
     * @returns {{id: *, name: *, chapters: Array}}
     */
    this.presentation = function (presentation, markModifiedSlides)
    {
        var data = {
            id: presentation.id,
            name: presentation.name,
            chapters: []
        };

        angular.forEach(presentation.chapters, function (chapter)
        {
            var chapterData = {
                id : chapter.id,
                display_order : chapter.display_order,
                name : chapter.name,
                chapter_slides: []
            };

            angular.forEach(chapter.chapter_slides, function (chapter_slide)
            {
                var slide = self.slide(chapter_slide.slide);

                if (markModifiedSlides) {
                    if (!angular.isNumber(chapter_slide.slide.id) || chapter_slide.slide.hasContentChanged()) {
                        slide.modified = true;
                    } else {
                        slide = {
                            id: chapter_slide.slide.id,
                            modified: false
                        };
                    }
                }

                chapterData.chapter_slides.push({
                    id : chapter_slide.id,
                    display_order : chapter_slide.display_order,
                    slide: slide
                });
            });

            data.chapters.push(chapterData);
        });

        return data;
    };

    this.offlineJson = function (presentation)
    {
        var data = modelToJson(presentation);
        data.chapters = [];

        angular.forEach(presentation.chapters, function (chapter)
        {
            var chapterData = modelToJson(chapter);
            chapterData.chapter_slides = [];

            angular.forEach(chapter.chapter_slides, function (chapter_slide)
            {
                var chapterSlideData = modelToJson(chapter_slide);
                chapterSlideData.slide = modelToJson(chapter_slide.slide);

                chapterData.chapter_slides.push(chapterSlideData);
            });

            data.chapters.push(chapterData);
        });

        return data;
    };

    function modelToJson (model)
    {
        return angular.fromJson(angular.toJson(model));
    }
});
