'use strict';

/**
 * drawer for comparison arrow shapes
 */
angular.module('app')

.factory('ChartComparisonArrows', function (ChartConfigFactory)
{

    return function (chart)
    {
        return {
            chart: chart,
            shapes: [],
            shapesMap: {
                bar: {
                    increased: 'up',
                    decreased: 'down',
                    equal: 'equalH'
                },
                column: {
                    increased: 'up',
                    decreased: 'down',
                    equal: "equalH"
                }
            },

            clean: function ()
            {
                // remove previous drawn comparison arrow shapes
                angular.forEach(this.shapes, function (item) {
                    item.element.remove();
                });

                this.shapes = [];
            },

            /**
             * draw comparison arrow shapes inbwtween the bars/columns in chart
             *
             * @param  {Array} rects                Rects of each bar/column to draw
             * @param  {String|Object} color        Color of shapes.
             * @param  {String} chartType           Type of chart. (only bar/column need to draw comparison arrow)
             * @return {Void}
             *
             * Color defines fill of 'incresed', 'decreased' and 'equal' shapes
             */
            draw: function (rects, color, chartType, comparisonTextDisabled)
            {
                this.clean();

                var self = this;
                var arrowColour = angular.copy(color);

                angular.forEach(rects, function (rect, idx) {

                    // ignore last bar if it's single series
                    // as there is nothing to compare against
                    if (rects[0].isSingleSerie && idx === rects.length - 1) {
                        return;
                    }

                    var path,
                        arrowMetrics = ChartConfigFactory.calculateComparisonItemCenter(chart, rects, idx, comparisonTextDisabled);

                    if (rect.nextComparisonValue && self.shapesMap[chartType] && self.shapesMap[chartType][rect.nextComparisonValue]) {

                        var shapeName = self.shapesMap[chartType][rect.nextComparisonValue];
                        var shapeWidth = arrowMetrics.height;
                        var shapeHeight = arrowMetrics.height;
                        path = ChartConfigFactory.getArrowPath(shapeName, arrowMetrics, shapeWidth, shapeHeight);

                    // Skip if comparisonValue or chartType is not mapped
                    } else {
                        return;
                    }

                    if (angular.isObject(color)) {
                        arrowColour = color[rect.nextComparisonValue] ? color[rect.nextComparisonValue] : 'black';
                    }

                    self.shapes.push(chart.renderer
                        .path(path)
                        .attr({
                            opacity: 1,
                            fill: arrowColour,
                            zIndex: 1
                        })
                        .add()
                    );

                });
            }
        };
    };
});
