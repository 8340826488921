'use strict';

angular.module('datasheet.frontend')

.directive('datasheetRepository', function () {

    return {
        restrict: 'A',
        controller: function ($scope)
        {
            this.repo = $scope.datasheetRepository;
        },
        scope: {
            datasheetRepository: '='
        }
    };
});
