'use strict';

angular.module('presentation.font')

.factory('fontViewOptions', function (resourceViewOptions, extendDeep)
{
    return extendDeep({}, resourceViewOptions, {
        list: {
            columns: [
                {
                    label: 'Name',
                    name: 'name',
                    sortable: true
                }
            ]
        }
    });
});
