'use strict';

angular.module('app')

.controller('SlideOptionsCtrl', function (
    $rootScope,
    $state,
    $scope,
    $timeout,
    editorState,
    currentTheme,
    presentationState,
    $uibModal,
    ChapterSlide,
    Slide,
    defaultEditorOptions,
    extendDeep,
    gradientConfigurator,
    Notification,
    editorSidebarCollapseState,
    prepareEditorRtlOptions
) {
    const editorOptions = presentationState.getEditorOptions();
    let theme = currentTheme();

    this.$onInit = function () {

        if ($state.params.presentationMode === 'slideshow') {
            editorOptions.controls = editorState.editMode = false;
        }
    };

    this.setEditorTextDirection = function (rtl) {
        prepareEditorRtlOptions.update($scope.editorOptions, rtl);
        presentationState.editor.changeTextDirection(rtl);
    };

    ///////////////////
    // scope members //
    ///////////////////
    $scope.frontLayerHidden = true;
    $scope.backgroundColors = editorOptions.backgroundColors;
    $scope.editorOptions = editorOptions;

    $scope.frontLayer = {
        active : true
    };

    $scope.collapseState = editorSidebarCollapseState.state;
    $scope.slide = presentationState.slide;
    $scope.editorState = editorState;
    $scope.sidebarOptionsOn = presentationState.isSidebarOptions();

    // Slide text colour options
    $scope.textColors = {
        'txt-dark': 'Dark',
        'txt-light': 'Light'
    };

    // Flow components from
    $scope.slidePosition = {
        top: 'Top',
        middle: 'Middle',
        bottom: 'Bottom'
    };

    $scope.slidePositionIcon = {
        top: 'images/sidebar/image/top.svg',
        middle: 'images/sidebar/image/centre.svg',
        bottom: 'images/sidebar/image/bottom.svg'
    };

    $scope.isSingleSlide = !presentationState.chapter;

    /////////////////////
    // scope functions //
    /////////////////////
    $scope.onTextColorChange = onTextColorChange;
    $scope.onBreadcrumbsChange = onBreadcrumbsChange;
    $scope.toggleEditingLayer = toggleEditingLayer;
    $scope.gradient = gradientConfigurator;
    $scope.toggleGradient = toggleGradient;

    /**
     * Used to make a class by color name.
     */
    $scope.colorClassName = (value) => {
        return value
            .toLowerCase()
            .replace(/ /g,'-')
            .replace(/[^\w-]+/g,'');
    };

    $scope.selectedBgColorPreview = (value) =>
    {
        if (value === 'client_brad_color') {

            if (!theme) {
                return null;
            }

            let color =  theme.getDefaultClientBrandColor();

            if (color) {
                return color;
            }
        }

        return value;
    };

    $scope.applyRtl = () => {
        this.setEditorTextDirection($scope.slide.config.rtl);
    };

    //////////
    // init //
    //////////

    if ($scope.slide) {
        //Waiting for slide to be updated on slideCtrl.js
        $timeout(function ()
        {
            onTextColorChange();
            gradientConfigurator.applyChanges($scope.slide.config.gradientConfig);
        });
    }

    toggleEditingLayer(presentationState.currentLayer);

    ///////////////////////
    // private functions //
    ///////////////////////
    function toggleEditingLayer (layerName)
    {
        if (layerName) {
            $scope.editingLayer = layerName;
            $scope.frontLayer.active = layerName === 'FrontLayer';
        } else {
            $scope.editingLayer = $scope.frontLayer.active ? 'FrontLayer' : 'BackLayer';
        }

        $timeout(function () {
            $rootScope.$broadcast('toggle-editing-layer', $scope.editingLayer);
        });
    }

    function onTextColorChange ()
    {
        $rootScope.slideTextColor = $scope.slide.config.text.color;
    }

    function onBreadcrumbsChange ()
    {
        $scope.editorOptions.showBreadcrumbs = $scope.slide.config.showBreadcrumbs;
    }

    function toggleGradient ()
    {
        if ($scope.slide) {
            $scope.slide.config.gradientConfig.visible = !$scope.slide.config.gradientConfig.visible;
            $scope.gradient.applyChanges($scope.slide.config.gradientConfig);
        }
    }

});
