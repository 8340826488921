'use strict';

angular.module('presentation.theme')

.component('themePopupSizePanel',  {
    templateUrl: 'app/presentation/theme/theme.builder/popups/popup-size-panel/popup-size-panel.html',
    bindings: {
        config: '=',
        label: '=',
        suite: '='
    },
    controller: function ($scope)
    {
        this.$onInit = () =>
        {
            this.config.align = this.config.align || 'center';
            this.config.top = this.config.top || 45;

            this.sizeUnits = ['px', '%'];

            this.alignments = ['left', 'center', 'right'];

            this.range = {
                min: 0,
                max: 0
            };

            $scope.$watch('$ctrl.config.unit', () =>
            {
                this.range.min = this.config.unit === '%' ? 20 : 300;
                this.range.max = this.config.unit === '%' ? 100 : 1440;

                this.onBlur();
            });
        };

        this.onBlur = () =>
        {
            this.config.size = Math.min(parseInt(this.config.size), this.range.max);
            this.config.size = Math.max(parseInt(this.config.size), this.range.min);
        };
    }
});
