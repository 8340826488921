'use strict';

angular.module('komondor.multitenancy')

.factory('companyViewOptions', function (
    resourceViewOptions,
    extendDeep,
    createAction,
    listView,
    deselectAction,
    multiDeleteAction,
    editSingleItemAction,
    deleteSingleItemAction,
    moment
) {
    var newCreateAction = angular.copy(createAction);
    newCreateAction.template = '<i class="material-icon material-icon-add"></i> New company';

    return extendDeep({}, resourceViewOptions, {
        moment: moment,
        views: [listView],
        actionsInside: [
            editSingleItemAction,
            deleteSingleItemAction
        ],
        actions: [
            deselectAction,
            multiDeleteAction,
        ],
        toolbar: [
            newCreateAction
        ],
        list: {
            columns: [
                {
                    label: 'Abbreviation',
                    name: 'abbreviation',
                    sortable: true
                },
                {
                    label: 'Name',
                    name: 'name',
                    sortable: true
                },
                {
                    label: 'Created At',
                    name: 'created_at',
                    template: `<span class="no-wrap" uib-tooltip="{{ model.created_at | date }}">
                                    {{ options.moment(model.created_at).fromNow() }}
                                </span>`,
                    sortable: true
                },
                {
                    label: 'Last modified',
                    name: 'updated_at',
                    template: `<span class="no-wrap" uib-tooltip="{{ model.updated_at | date }}">
                                    {{ options.moment(model.updated_at).fromNow() }}
                                </span>`,
                    sortable: true
                },
                {
                    template: `<contextual-options actions="options.actionsInside"
                                                   models="options.resourceList.selected"
                                                   model="model"
                                                   class="row-actions pull-right"></contextual-options>`
                }
            ]
        }
    });
});
