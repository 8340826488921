'use strict';

angular.module('app')

.run(function (IconToken) {}) // jshint ignore:line

.factory('IconToken', function (DS)
{
    return DS.defineResource({
        basePath: '/api/',
        name: 'IconToken',
        endpoint: 'iconTokens',
        upsert: true,
        relations: {
            belongsTo: {
                Icon: {
                    localField: 'icon',
                    localKey: 'icon_id'
                }
            }
        }
    });
});
