'use strict';

angular.module('editorComponents.timeline')

.directive('timelineCell', function ()
{
    return {
        restrict: 'A',
        require: ['timelineViewport', 'component'],
        templateUrl: 'scripts/editorComponents/timeline/views/timeline.cell.html',
        controller: 'timelineCellCtrl',
        controllerAs: '$ctrl',
        bindToController: true,
        scope: {
            model: '=timelineCell'
        }
    };
});
