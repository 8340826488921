'use strict';

angular.module('app')

.factory('downloadAction', function ($window)
{
    return {
        name: 'download',
        tooltip: 'Download',
        buttonClass: 'btn btn-default',
        template: '<i class="material-icon material-icon-file-download"></i> Download',
        act: function (models)
        {
            $window.open(models[0].links.download, '_blank');
        },
        show: function (model)
        {
            return model.type !== 'folder';
        }
    };
});
