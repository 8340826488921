'use strict';

angular.module('editorComponents.timeline')

.controller('timelineEventOptionsCtrl', function ($scope, editorState)
{
    var options = editorState.componentListCtrl.options;

    if (!options) {
        return;
    }

    $scope.minWidth = 150;
    $scope.width = Number(options.model.style.width.replace(/\D/g,'')) || $scope.minWidth;
    $scope.backgroundColors = options.backgroundColors;

    $scope.eventChange = function (styles)
    {
        $scope.$emit('event:styles', styles);
    };

});
