'use strict';

angular.module('app')

.factory('compPanelBackwardsCompatibility', function ()
{
    return function ($scope)
    {
        if ($scope.model.bgrColour) {
            $scope.data.backgroundColor = $scope.model.bgrColour;
            delete $scope.model.bgrColour;
        }

        if ($scope.model.style.background) {
            $scope.data.backgroundColor = $scope.model.style.background;
            delete $scope.model.style.background;
        }

        if ($scope.model.style.backgroundColor) {
            $scope.data.backgroundColor = $scope.model.style.backgroundColor;
            delete $scope.model.style.backgroundColor;
        }

        if ($scope.model.class && $scope.model.class.startsWith('cirle-')) {
            $scope.model.class = $scope.model.class.replace('cirle-', 'circle-');
        }
    };
});
