'use strict';

angular.module('editorComponents.timeline')

.factory('TimelineItem', function (moment)
{
    function getDate (date, format)
    {
        return moment(Date.parse(date)).format(format);
    }

    function defaultFormatter (date, format)
    {
        return getDate(date, format);
    }

    return function (type, date, format)
    {
        this.type = type;
        this.date = date || new Date();
        this.format = format || 'DD-MM-YYYY';
        this.formatter = defaultFormatter;
        this.label = this.formatter(this.date, this.format);
        this.data = null;

        // Used for grouping events
        this.year = getDate(this.date, 'YYYY');
        this.month = getDate(this.date, 'MMM');
    };
});
