'use strict';

angular.module('presentation.theme')

.directive('themeEditorConfig', function ()
{
    return {
        restrict: "E",
        templateUrl: 'app/presentation/theme/components/theme.editor-config/theme.editor-config.html'
    };
});
