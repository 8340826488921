'use strict';

class themeBackgroundOverlayController {

    constructor () {
        this.classPrefix = 'overlay';
        this.defaultItemLabel = 'New overlay';

        this.itemOpen = {};
        this.itemEditing = {};
    }

    isOpen(index)
    {
        if (angular.isUndefined(this.itemOpen[index])) {
            this.itemOpen[index] = true;
        }

        return this.itemOpen[index];
    }

    toggleOpen(index)
    {
        this.itemOpen[index] = !this.itemOpen[index];
    }

    editing(item)
    {
        item.name = this.classPrefix + '-' + item.label
            .trim()
            .toLowerCase()
            .replace(new RegExp("[!@#$%&*[\\]{}().,<>/`~;:'\"|?^\\\\+=]", "g"), "")
            .replace(new RegExp(" ", "g"), "-");
    }

    isEditing(index)
    {
        if (angular.isUndefined(this.itemEditing[index])) {
            this.itemEditing[index] = false;
        }

        return this.itemEditing[index];
    }

    toggleEditing(index)
    {
        this.itemEditing[index] = !this.itemEditing[index];
    }

    addItem()
    {
        const newItem = {
            label: this.defaultItemLabel,
            name: '',
            uuid: '',
            order: '',
            assetId: 0,
            width: 100,
            position: 'left',
        };

        this.editing(newItem);
        this.items.push(newItem);
    }

    removeItem(index)
    {
        this.items.splice(index, 1);
    }
}

angular.module('presentation.theme')

.component('themeBackgroundOverlay',  {
    templateUrl: 'app/presentation/theme/theme.builder/images/background/background-overlay/background-overlay.html',
        bindings: {
        items: '<',
    },
    controller: themeBackgroundOverlayController,
});
