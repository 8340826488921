'use strict';

angular.module('presentation.theme')

.component('themeListStylePanel',  {
    templateUrl: 'app/presentation/theme/theme.builder/typography/list-style-panel/list-style-panel.html',
    bindings: {
        listStyle: '<',
        model: '<',
        index: '<'
    },
    controller: function ()
    {
        this.listTypes = [
            {
                value: 'circles',
                name: 'Bullets'
            },
            {
                value: 'numeric',
                name: 'Numeric'
            },
            {
                value: 'custom',
                name: 'Custom'
            }
        ];

        this.$onInit = () =>
        {
            this.palette = this.model.config.colors.palette;

            this.refreshMaps();
        };

        this.changeTextStyle = (textStyle) =>
        {
            this.listStyle.textStyle = textStyle;
            this.minWidth = textStyle.size;
        };

        this.changeType = (type) =>
        {
            this.listStyle.type = type;
        };

        this.refreshMaps = () =>
        {
            this.listTypeMap = createObjectMap(this.listTypes, 'value', 'name');
        };

        function createObjectMap (styleArray, key, value)
        {
            return styleArray.reduce(function (map, style) {
                map[style[key]] = style[value];
                return map;
            }, {});
        }
    }
});
