'use strict';

angular.module('presentation.theme')

.factory('themeConfigTables', function ()
{
    return {
        enabled: true,
        tableStyles: {
            headerTextstyle: 'p',
            headerCellPadding: 10,
            headerBorderStyle: 'solid-1',
            bodyTextstyle: 'p',
            bodyCellPadding: 10,
            bodyBorderStyle: 'solid-1',
            cellHighlightColor: 'color-1',
        },
        borderStyles: [{
            width: 2,
            style: 'solid',
            name: 'solid-1',
            color: 'dark',
        }],
    };
});
