'use strict';

angular.module('presentation.theme')

.component('themeImageMakers',  {
    templateUrl: 'app/presentation/theme/theme.builder/images/image-markers/image-markers.html',
    bindings: {
        config: '<',
        palette: '<',
    },
});
