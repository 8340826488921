'use strict';

angular.module('presentation.theme')

.config(function (ThemeValidatorProvider)
{
    ThemeValidatorProvider.add(function(config) {
        const maps = config.maps;

        const colourFields = [
            {key: 'themes.dark.borderColor'},
            {key: 'themes.dark.nullColor'},
            {key: 'themes.dark.selectionColor'},
            {key: 'themes.light.borderColor'},
            {key: 'themes.light.nullColor'},
            {key: 'themes.light.selectionColor'},
        ];

        return [{
            section: 'maps',
            fields: colourFields,
            validKeys: 'colour',
            config: maps
        }];
    });
});
