'use strict';

angular.module('datasheet.admin')

.factory('datumViewOptions', function (
    $state,
    $q,
    extendDeep,
    Slide,
    multiDeleteAction,
    multiMoveAction,
    resourceViewOptions,
    listView,
    listNameTemplate,
    createAction,
    deselectAction,
    editSingleItemAction,
    deleteSingleItemAction,
    moveSingleItemAction,
    createFolderAction,
    kdGate,
    resourceIcons,
    moment
){
    var options = angular.copy(resourceViewOptions);
    var gatedCreateAction = angular.copy(createAction);
    var gatedCreateFolderAction = angular.copy(createFolderAction);

    var showCreateButton = function () {
        return kdGate.allows('data.create');
    };

    gatedCreateAction.show = showCreateButton;
    gatedCreateAction.template = '<i class="material-icon material-icon-add"></i> New datasheet';
    gatedCreateFolderAction.show = showCreateButton;

    resourceIcons.datasheet = 'material-icon material-icon-data';

    options.moment = moment;

    options.toolbar = [gatedCreateFolderAction, gatedCreateAction];

    options.sorts = [
        {
            label: "Type",
            sort: "type asc,name asc"
        }
    ];

    options.actions = [
        deselectAction,
        multiDeleteAction,
        multiMoveAction,
    ];

    options.inlineActions = [
        editSingleItemAction,
    ];

    options.inlineDropdown = [
        moveSingleItemAction,
        deleteSingleItemAction
    ];

    options.gridActions = [
        ...options.inlineActions,
        ...options.inlineDropdown,
    ];

    extendDeep(options, {
        tagging: true,
        taggingAdvanced: true,
        queryDefaults: {
            where: {
                folder_id: 0
            }
        },
        onItemClick: function ($event, model)
        {
            $event.stopPropagation();

            model.goInto();
        },
        list: {
            columns: [
                {
                    label: 'Name',
                    name: 'name',
                    template: listNameTemplate('name', true, 'type'),
                    sortable: true
                },
                {
                    label: 'Rows',
                    name: 'rows',
                    template: '<span>{{ model.rows }}</span>',
                    sortable: true
                },
                {
                    label: 'Size',
                    name: 'size',
                    template: '<span>{{ model.size | bytes }}</span>',
                    sortable: true
                },
                {
                    label: 'Editable',
                    name: 'editable',
                    template: '<i ng-show="model.editable" class="material-icon material-icon-check"></i>',
                    sortable: false
                },
                {
                    label: 'Last modified',
                    name: 'updated_at',
                    template: `<span class="no-wrap" uib-tooltip="{{ model.updated_at | date }}">
                                    {{ options.moment(model.updated_at).fromNow() }}
                                </span>`,
                    sortable: true
                },
                {
                    template: `<contextual-options actions="options.inlineActions"
                                                   dropdown-actions="options.inlineDropdown"
                                                   models="options.resourceList.selected"
                                                   model="model"
                                                   class="row-actions pull-right"></contextual-options>`
                }
            ],
        },
        views: [listView],
    });

    return options;
});
