'use strict';

angular.module('color-picker-swatches', [
    'color.picker',
    'ui.bootstrap'
])

.factory('tinycolor', function ($window)
{
    return $window.tinycolor;
})

.component('colorPickerSwatches', {
    restrict: 'A',
    templateUrl: 'app/colorpicker/colorpicker.html',
    replace: true,
    controller: 'ColorpickerCtrl',
    bindings: {
        color: '=colorPickerSwatchesColor',
        brandColorEnabled: '=colorPickerBrandColorEnabled',
        useBrandColor: '=colorPickerSwatchesUseBrandColor',
        swatches: '=colorPickerSwatchesSwatches',
        defaultColor: '=colorPickerSwatchesDefaultColor',
        reinit: '<colorPickerSwatchesReinit',
    }
})

.controller('ColorpickerCtrl', function (tinycolor)
{
    this.$onChanges = function () {
        this.setDefaults();
    };

    this.setDefaults = function ()
    {
        this.setSeriesRGB();

        if (!this.brandColorEnabled) {
            this.useBrandColor = false;
        }
    };

    this.colorpickerTemplateUrl = 'app/colorpicker/colorpickerPopover.html';
    this.currentSeriesRGB = {
        R: 0,
        G: 0,
        B: 0
    };

    this.setSeriesRGB = function ()
    {
        var color = tinycolor(this.color);
        this.currentSeriesRGB.R = color._r;
        this.currentSeriesRGB.G = color._g;
        this.currentSeriesRGB.B = color._b;
    };

    this.setSeriesHex = function (label)
    {
        this.currentSeriesRGB[label] = Math.min(255, Number(this.currentSeriesRGB[label]));
        var colorRGBStr = 'rgb(' + this.currentSeriesRGB.R + ', ' + this.currentSeriesRGB.G + ', ' + this.currentSeriesRGB.B + ')';
        this.color = tinycolor(colorRGBStr).toHexString().toUpperCase();
    };

    this.checkSeriesColor = function ()
    {
        var color = this.color;
        var colorObj = tinycolor(color);
        if (color && !colorObj._ok) {
            this.color = this.defaultColor;
        }
    };
});
