'use strict';

angular.module('app')

.directive("sideMenu", function (
    $window,
    $document,
    $timeout,
    $q,
    getRelativeEventCoords,
    Notification
) {
    const document = $document[0];

    return {
        restrict: "A",
        link: link
    };

    function link (scope, elem)
    {
        ///////////////////
        // local members //
        ///////////////////
        var rectChapterCol = {};        // rect of chapters column
        var rectSlidesCol = {};         // rect of slides column
        var chaptersRects = [];         // a list of rects storing chapter row dimension
        var slidesRects = [];           // a list of rects storing slide row dimension
        var trailingDiv;                // a div following the movement of cursor when moving slides/chapters
        var enableTrailingDiv = true;   // enable/disable the trailing div (clone of moving row) to follow cursor

        ///////////////////
        // scope members //
        ///////////////////
        scope.picked = null;

        /////////////////////
        // scope functions //
        /////////////////////
        scope.pickup = pickup;

        ////////////
        // events //
        ////////////
        elem.on('mousemove', move);

        ///////////////////////
        // private functions //
        ///////////////////////
        function pickup ($event, item, itemParent)
        {
            $event.stopPropagation();
            $event.preventDefault();

            // Dont't pick up if it's not a left click
            if (scope.picked || $event.button !== 0) {
                return;
            }

            // $log.log('--------------------------------------------------------------- pickup');

            getColRects();
            getRowRects();

            scope.picked = {
                item: item,             // slide | chapter
                parent: itemParent,     // to decide if picked is a slide or chapter
                displayOrder: item.display_order
            };

            scope.movingSlideToChapterIdx = null;

            prepareTrailingElem($event, item, itemParent);

            // only listen to mouseup event ONCE
            angular.element($window).one('mouseup', release);
        }

        function release ($event)
        {
            $event.stopPropagation();
            $event.preventDefault();

            if (!scope.picked) {
                reset();
                return;
            }

            // $log.log('--------------------------------------------------------- release');

            var i;
            var pickedItem = scope.picked.item;

            // dropped chapter
            if (!scope.picked.parent) {

                for (i = 0; i < scope.chapters.length; i++) {
                    scope.chapters[i].display_order = i + 1;
                }

                scope.movedRow(pickedItem);
            }
            // dropped slide
            else {
                if (scope.movingSlideToChapterIdx !== null && scope.chapters.indexOf(scope.selected.chapter) !== scope.movingSlideToChapterIdx) {
                    // $log.log('**********************************************');
                    // $log.log('*** move to another chapter');
                    // $log.log('**********************************************');

                    var movingToChapter = scope.chapters[scope.movingSlideToChapterIdx];

                    if (!movingToChapter.canAddSlide(pickedItem.slide)) {
                        Notification.warning('It is not possible to add a Master slide multiple times into the same chapter.');
                        reset();
                        return;
                    }

                    pickedItem.chapter_id = movingToChapter.id;
                    pickedItem.display_order = movingToChapter.chapter_slides.length;

                    scope.selected.chapter.forceSlidesDisplayOrder();

                    // update the moved slide's chapter_id and display_order
                    scope.movedRow(pickedItem);
                } else {
                    // $log.log('**********************************************');
                    // $log.log('*** move within same chapter, or dropped at same chapter row');
                    // $log.log('**********************************************');

                    scope.movedRow(pickedItem);
                }
            }

            reset();
        }

        /**
         * reset everything
         */
        function reset()
        {
            scope.movingSlideToChapterIdx = null;

            scope.picked = null;

            resetTrailingElem();
        }

        function move ($event)
        {
            $event.stopPropagation();
            $event.preventDefault();

            if (!scope.picked) {
                return;
            }

            // $log.log('----------------------------------- move');

            // min height of touch-area to trigger 'movement' of row
            var minRowH = 60;
            var coords = getRelativeEventCoords($event, elem[0]);
            var i;

            moveTrailingElem(coords);

            // moving within chapter list
            if (coords.x > rectChapterCol.left && coords.x < rectChapterCol.right) {

                for (i = 0; i < chaptersRects.length; i++) {

                    // the moving row might be small in H and mouse is over a row large in H,
                    if (coords.y > chaptersRects[i].top && coords.y < chaptersRects[i].top + minRowH) {
                        movingToChapter(i);
                        break;
                    }
                }
            }
            // moving within slide list
            else if (coords.x > rectSlidesCol.left && coords.x < rectSlidesCol.right) {

                for (i = 0; i < slidesRects.length; i++) {

                    if (coords.y > slidesRects[i].top && coords.y < slidesRects[i].top + minRowH) {
                        movingToSlide(i);
                        break;
                    }
                }
            }
        }

        /**
         * temporarily moving slide/chapter row by changing its display_order
         * NO update to back-end.
         */
        function movingToChapter (idx)
        {
            // $log.log('*** movingToChapter', ' to idx ', idx);

            // scope.picked is a chapter row
            if (!scope.picked.parent) {
                // $log.log('move CHAPTER to chapter at idx : ', idx);

                var currIdx = scope.chapters.indexOf(scope.picked.item);
                if (currIdx === idx) {
                    // $log.log('moving from ', currIdx, ' to ', idx, ' - same position! IGNORE!!!');
                    return;
                }

                // $log.log('moving from index ', currIdx, ' to ', idx);

                scope.chapters.splice(currIdx, 1);
                scope.chapters.splice(idx, 0, scope.picked.item);

                for (var i = 0; i < scope.chapters.length; i++) {
                    scope.chapters[i].display_order = i + 1;
                }
            }
            // scope.picked is a slide row
            else {
                // $log.log('move SLIDE to chapter at idx : ', idx);

                trailingElemOverChapter();

                scope.movingSlideToChapterIdx = idx;
            }

            scope.$apply();

            getRowRects();
        }

        function movingToSlide (idx)
        {
            // $log.log('*** movingToSlide', ' to idx ', idx);

            // moving CHAPTER to slide row
            if (!scope.picked.parent) {
                return;
            }

            // reset trailing elem to default
            trailingElemOverSlide();

            // reset movingSlideToChapterIdx
            scope.movingSlideToChapterIdx = null;

            var currIdx = scope.chapterSlides.indexOf(scope.picked.item);
            if (currIdx === idx) {
                // $log.log('moving from ', currIdx, ' to ', idx, ' - same position! IGNORE!!!');
                return;
            }

            // $log.log('moving from index', currIdx, ' to ', idx);

            var o;
            for (var i = 0; i < scope.chapterSlides.length; i++) {
                o = scope.chapterSlides[i];

                // move slide downwards
                if (currIdx < idx) {
                    if (i === currIdx) {
                        o.display_order = idx + 1;
                    } else if (i > currIdx && i <= idx) {
                        o.display_order = i;
                    } else {
                        o.display_order = i + 1;
                    }
                }
                // move slide upwards
                else {
                    if (i >= idx && i < currIdx) {
                        o.display_order = i + 2;
                    } else if (i === currIdx) {
                        o.display_order = idx + 1;
                    } else {
                        o.display_order = i + 1;
                    }
                }
            }

            scope.$apply();

            getRowRects();
        }

        /**
         * trailing element related functions
         */
        function prepareTrailingElem ($event, item, itemParent)
        {
            if (!enableTrailingDiv) {
                return;
            }
            // prepare trailing elem
            trailingDiv = document.getElementById('temp-dragging');
            if (!trailingDiv) {
                trailingDiv = document.createElement('div');
                trailingDiv.setAttribute('id', 'temp-dragging');
                document.body.appendChild(trailingDiv);
            }

            trailingDiv.setAttribute('class', '');

            var trailingInnerHTML = "";

            // is Chapter being moved
            if (!itemParent) {
                trailingInnerHTML = item.display_order + '. ' + item.name;
            } else {
                // is Slide being moved
                trailingInnerHTML = item.display_order + '. ' + item.slide.name;
            }

            trailingDiv.innerHTML = trailingInnerHTML;

            var coords = getRelativeEventCoords($event, elem[0]);
            moveTrailingElem(coords);
        }

        function resetTrailingElem ()
        {
            if (!enableTrailingDiv) {
                return;
            }
            trailingDiv.setAttribute('class', 'hidden');
        }

        function moveTrailingElem (coords)
        {
            if (!enableTrailingDiv) {
                return;
            }

            trailingDiv.style.left = coords.x + 5 + 'px';
            trailingDiv.style.top = coords.y + 5 + 'px';
        }

        function trailingElemOverChapter ()
        {
            if (!enableTrailingDiv) {
                return;
            }
            trailingDiv.setAttribute('class', 'shrink');
        }

        function trailingElemOverSlide ()
        {
            if (!enableTrailingDiv) {
                return;
            }
            trailingDiv.setAttribute('class', '');
        }

        /**
         * rect related functions
         */
        function getColRects ()
        {
            if (!rectChapterCol.top || !rectSlidesCol.top) {
                rectChapterCol = elem.children()[1].getBoundingClientRect();
                rectSlidesCol = elem.children()[2].getBoundingClientRect();
            }
        }

        function getRowRects ()
        {
            chaptersRects = [];

            angular.forEach(angular.element(elem[0].querySelector('.sidemenu-chapter-column ul')).children(), function (child) {
                chaptersRects.push(child.getBoundingClientRect());
            });

            slidesRects = [];

            angular.forEach(angular.element(elem[0].querySelector('.sidemenu-slide-column ul')).children(), function (child) {
                slidesRects.push(child.getBoundingClientRect());
            });
        }

    }
});
