'use strict';

angular.module('app')

.constant('notificationMessages', {
    create: 'Successfully created',
    update: 'Successfully edited',
    destroy: 'Successfully deleted'
})

.decorator('DS', function ($delegate, DSUtils)
{
    $delegate.defaults.afterCreate = function (resource, model, cb)
    {
        cb(null, model);

        if (resource.quietMode) {
            return;
        }

        $delegate.emit('create');
    };

    $delegate.defaults.afterUpdate = function (resource, model, cb)
    {
        cb(null, model);

        if (resource.quietMode) {
            return;
        }

        $delegate.emit('update');
    };

    $delegate.defaults.afterDestroy = function (resource, model, cb)
    {
        cb(null, model);

        if (resource.quietMode) {
            return;
        }

        $delegate.emit('destroy');
    };

    $delegate.defaults.errorHandler = function (response, action, modelName)
    {
        // Set server error message or not authorized message
        if (response.status >= 500 || response.status === 403) {
            var errorMessage = response.status >= 500 ? 'something went wrong' : response.data;

            $delegate.emit('error', action, modelName, errorMessage);
        }

        // Set validation error message
        if (response.status === 422) {
            $delegate.emit('validation-error', response.data);
        }

        return DSUtils.Promise.reject(response);
    };

    return $delegate;
})

.run(function (DS, Notification, notificationMessages, validationNotification, quietMode)
{
    if (quietMode) {
        return;
    }

    DS.on('create', function ()
    {
        Notification.success(notificationMessages.create);
    });

    DS.on('update', function ()
    {
        Notification.success(notificationMessages.update);
    });

    DS.on('destroy', function ()
    {
        Notification.success(notificationMessages.destroy);
    });

    DS.on('restore', function (status)
    {
        Notification.success(status);
    });

    DS.on('error', function (action, model, reason)
    {
        var message = 'Failed to ' + action;

        // Set item name
        if (model) {
            message += ' ' + model;
        }

        // Set reason (error)
        if (reason) {
            message += ' due to: ' + reason;
        }

        Notification.error(message);
    });

    DS.on('validation-error', validationNotification.show);
});
