'use strict';

angular.module('app')

.controller('IconEditCtrl', function ($scope, $stateParams, model, IconToken)
{
    $scope.model = model;
    $scope.model.type = $scope.model.type || 'icon';
    $scope.tokens = [];

    angular.forEach($scope.model.tokens, function (value)
    {
        $scope.tokens.push({
            token: value.token
        });
    });

    if ($scope.tokens.length === 0) {
        $scope.tokens.push({
            token: ''
        });
    }

    // Create icon in the current folder
    if ($scope.model.folder_id === undefined) {
        $scope.model.folder_id = $stateParams.folder_id || 0;
    }

    $scope.addToken = function ()
    {
        $scope.tokens.push({
            token: ''
        });
    };

    $scope.keyPress = function ($event)
    {
        if ($event.keyCode === 13)
        {
            $scope.addToken();
        }
    };

    $scope.removeToken = function (token)
    {
        var index = $scope.tokens.indexOf(token);
        $scope.tokens.splice(index, 1);
    };

    $scope.saveModel = function ()
    {
        $scope.model.tokens_sync = $scope.tokens.map(function (item)
        {
            return item.token;
        });

        //Removing deleted tokens.
        IconToken.ejectAll({
            where: {
                icon_id: $scope.model.id,
                token: {
                    notIn: $scope.model.tokens_sync
                }
            }
        });

        $scope.model.DSCreate().then(function (iconModel)
        {
            $scope.$close(iconModel);
        });
    };
});
