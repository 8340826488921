'use strict';

angular.module('app')

.service('analytics', function ($window, $http, appUuid, $rootScope, $q, IsMobile) {

    this.session = null;
    this.slideview = null;
    this.log = null;

    this.init = () => {

        $rootScope.$on('component-action', (event, data, $scope) =>
        {
            if (!this.slideview) {
                return $q.resolve();
            }

            //TODO: Add more compoentns...
            if (data.component === 'video-player') {

                if (data.action === 'pause' || data.action === 'ended') {
                    data.action = 'play';
                    this.endAction(data, $scope);
                } else {
                    this.startAction(data, $scope);
                }

            } else {
                this.startAction(data, $scope);
            }
        });

        $window.addEventListener('beforeunload',  () => {
            this.endSession();
        });
    };

    this.startAction = (data, $scope, endImmediately = false) => {

        if (!this.slideview) {
            return;
        }

        let request = {
            slideview_id: this.slideview.id,
            component: data.component,
            action: data.action,
            subject_id: data.subject_id,
            subject_name: data.subject_name,
            scope: $scope ? $scope.$id : null,
            end_immediately: endImmediately,
        };

        return $http.post('/api/a/slideview/start-action', request).then((response) =>
        {
            this.log = response.data;

            return this.log;
        });
    };

    this.endAction = (data, $scope) => {

        if (!this.slideview) {
            return;
        }

        let request = {
            slideview_id: this.slideview.id,
            component: data.component,
            action: data.action,
            subject_id: data.subject_id,
            subject_name: data.subject_name,
            scope: $scope.$id,
        };

        return $http.post('/api/a/slideview/end-action', request).then((response) =>
        {
            this.log = response.data;

            return this.log;
        });
    };

    this.startSession = () => {

        if (!$rootScope.link_id) {
            return $q.resolve();
        }

        let data = {
            app_uuid: appUuid(),
            link_id: $rootScope.link_id,
            mobile: IsMobile ? true : false,
        };

        return $http.post('/api/a/session/start', data).then((response) =>
        {
            this.session = response.data;

            return this.session;
        });
    };

    this.notifyNonConsent = () => {

        if (!$rootScope.link_id) {
            return $q.resolve();
        }

        let data = {
            app_uuid: appUuid(),
            link_id: $rootScope.link_id,
            mobile: IsMobile ? true : false,
        };

        return $http.post('/api/a/notify-non-consent', data).then((response) =>
        {
            return response.data;
        });
    };

    this.endSession = () => {

        if (!this.session) {
            return;
        }

        let url = '/api/a/session/' + this.session.id + '/end';

        try {
            $window.navigator.sendBeacon(url, {});
        }
        catch(err) {
            $http.post(url, {});
        }

        this.session = null;
    };

    this.startSlideView = (chapter_slide_id) =>
    {
        if (!$rootScope.link_id || !this.session) {
            return $q.resolve();
        }

        if (!this.session || this.session.end) {
            throw new Error("Session is not initialized!");
        }

        let data = {
            session_id: this.session.id,
            chapter_slide_id: chapter_slide_id,
        };

        return  $http.post('/api/a/slideview/start', data).then((response) =>
        {
            this.slideview = response.data;

            return this.slideview;
        });
    };
})

.run(function (analytics) {
    analytics.init();
});
