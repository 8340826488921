'use strict';

angular.module('app')

.controller('2FaAuthCtrl', function ($rootScope, $uibModal, $http, $uibModalInstance, force, kdAuth) {

    this.data = {
        active: kdAuth.user.two_fa_enabled,
        secret: kdAuth.user.two_fa_secret
    };



    this.config = $rootScope.config.two_fa;
    this.force = force;

    this.onChange = () =>
    {
        if (this.data.active) {

            $uibModal.open({
                templateUrl: 'app/admin/2fa-auth/2FaAuthSetup.html',
                controller: '2FaAuthSetupCtrl',
                controllerAs: '$ctrl',
                bindToController: true,
                backdrop: this.force ? 'static' : true,
                keyboard : !force,
                resolve: {
                    force: this.force,
                    data: () => {
                        return $http.get('api/two-fa/generate').then((response) => {
                            return response.data;
                        });
                    }
                }
            });

        } else {

            $uibModal.open({
                templateUrl: 'app/admin/2fa-auth/2FaAuthDisable.html',
                controller: '2FaAuthDisableCtrl',
                controllerAs: '$ctrl',
                bindToController: true,
            });
        }

        $uibModalInstance.close();
    };

});
