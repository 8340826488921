'use strict';

angular.module('presentation.theme')

.component('themePopupColourPanel',  {
    templateUrl: 'app/presentation/theme/theme.builder/popups/popup-colour-panel/popup-colour-panel.html',
    bindings: {
        colourProfile: '<',
        model: '<',
        key: '<'
    },
    controller: function ()
    {
        this.$onInit = () =>
        {
            this.palette = this.model.config.colors.palette;
        };
    }
});
