'use strict';

angular.module('app')

.factory('city-football-group', function (ThemeConfig)
{
    var theme = new ThemeConfig({
        'Flourescent Sky Blue': '#00b9e4',
        Merino: '#F7F0EB',
        'Obsidian Blue': '#00285e',
        'Spun Pearl': '#A9A9AC',
        'Sky Blue': '#6caddf',
        'Dove Gray': '#727272',
        'Periwinkle Gray': '#B3D1E2',
        'San Juan': '#324E66',
        'Bondi Blue': '#0096AF',
        'Venice Blue': '#06578D',

        'Vibrant Purple': '#952d98',
        'Acid Yellow':' #ffe700',
        'Bright Orange': '#f99728',
        'Lime Green': '#bed600',
        'Pure Putty': '#dad7cb',
        'Amends Red': '#ff0000',
        // Arrow colours
        Green: '#00b143',
        Red: '#cf0c0c',
        'Grey Light': '#a9a9ab',
        // Default Colours
        Black: '#000000',
        White: '#ffffff',
        'Cool Grey': '#999999',
        None: 'transparent'
    });

    var colourList = theme.customColoursList([
        'Flourescent Sky Blue',
        'Merino',
        'Obsidian Blue',
        'Spun Pearl',
        'Sky Blue',
        'Dove Gray',
        'Periwinkle Gray',
        'San Juan',
        'Bondi Blue',
        'Venice Blue'
    ]);

    var colourObject = theme.customColoursObjectInverted([
        'Flourescent Sky Blue',
        'Merino',
        'Obsidian Blue',
        'Spun Pearl',
        'Sky Blue',
        'Dove Gray',
        'Periwinkle Gray',
        'San Juan',
        'Bondi Blue',
        'Venice Blue'
    ]);

    /**
     * Modify the editor options to suit this theme
     *
     */
    theme.editorOptions = function (options)
    {
        options.textColours = theme.customColoursObject([
            'Flourescent Sky Blue',
            'Sky Blue',
            'Obsidian Blue',
            'Cool Grey',
            'White',
            'Black',
            'Vibrant Purple',
            'Acid Yellow',
            'Bright Orange',
            'Lime Green',
            'Pure Putty',
            'Amends Red',
        ]);

        options.backgroundColors = theme.customColoursObjectInverted([
            'Flourescent Sky Blue',
            'Sky Blue',
            'Obsidian Blue',
            'Cool Grey',
            'White',
            'Black'
        ]);

        options.componentOptions['comp-panel'].background = {
            default: ' ',
            colors: options.backgroundColors
        };

        options.componentClasses['comp-panel'] = {
            default: 'block-blue',
            classes: {
                'block-blue': 'Block - Blue',
                'circle-blue': 'Circle - Blue'
            }
        };

        options.componentClasses.grid = {
            default: ' ',
            classes: {
                ' ': 'Align Grid Centre',
                'left-align': 'Align Grid Left'
            }
        };

        options.nestableComponents.editor.components = {
            column: 'Columns',
            grid: 'Grid',
            chart: 'Chart',
            image: 'Image',
            table: 'Table',
            'comp-panel': theme.getPanelName(),
            'client-logo': 'Client Logo',
            'video-player': 'Video',
            //'high-map': 'Map'
        };

        // Remove high-map until city start using it fully
        delete options.nestableComponents.column.nested['high-map'];
        delete options.nestableComponents.column.nested.tabs;

        options.nestableComponents.editor.back_layer = {
            'video-player': 'Video',
            'bg-targeted-image': 'Image',
            // 'high-map': 'Map'
        };

        options.componentOptions.textblock.tags = {
            h1: 'Heading',
            h2: 'Sub-heading',
            h5: 'Stat',
            h6: 'Large copy',
            p: 'Paragraph'
        };

        options.componentOptions['shape-line'].defaultColor = theme.getColour('Flourescent Sky Blue');
        options.componentOptions.column.allowedTags = {};
        options.componentOptions.grid.allowedTags = {};
        options.componentOptions.chart.allowedTags = {};
        options.componentOptions.image.allowedTags = {};
        options.componentOptions['comp-panel'].allowedTags = {};
        options.componentOptions['chapter-header'].allowedTags = {};
        // options.componentOptions['high-map'].allowedTags = {};
        options.componentOptions['bg-image'].allowedTags = {};
        options.componentOptions['shape-line'].allowedTags = {};
        options.componentOptions['video-player'].allowedTags = {};

        options.componentOptions.table.margin = true;
    };

    /**
    * Modify the map config to suit this theme
    *
    */
    theme.mapConfig = function (options)
    {
        options.themes.blue = {
            name: 'Blue',
            color: theme.getColour('Flourescent Sky Blue'),
            nullColor: 'rgba(0,184,228,0.3)',
            borderWidth: 0,
            borderColor: '#000000',
        };
    };

    /**
     * Modify the chart colours to suit this theme
    */
    theme.chartThemeConfig = function (options)
    {
        var arrowsConfig = {
            increased: theme.getColour('Green'),
            decreased: theme.getColour('Red'),
            equal: theme.getColour('Flourescent Sky Blue')
        };

        options.themes.light.colors.highlight = theme.getColour('Flourescent Sky Blue');
        options.themes.light.colors.arrows = arrowsConfig;
        theme.getColour('Flourescent Sky Blue');
        options.themes.light.colors.series = colourList;

        options.themes.dark.colors.highlight = theme.getColour('Flourescent Sky Blue');
        options.themes.dark.colors.arrows = arrowsConfig;
        options.themes.dark.colors.series = colourList;
    };

    theme.swatchColorsConfig = function (options)
    {
        options.default = theme.getColour('Obsidian Blue');
        options.colors = colourObject;
    };

    /**
     * Modify the preloader to suit this theme
     *
    */
    theme.preloader = function (options)
    {
        var activeTheme = 'circle';  // default, circle or mask
        options.themes.active = activeTheme;
    };

    /**
     * Modify the slide config to suit this theme
     *
    */
    theme.slideConfig = function (options)
    {
        options.text.color = 'txt-light';
    };

    /**
     * Modify the presentation config to suit this theme
     *
    */
    theme.presentationConfig = function (options)
    {
        options.marginals = 'footer';
    };

    return theme;
});
