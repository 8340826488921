'use strict';

angular.module('presentation.theme')

.component('themeShapeOptions', {
    templateUrl: 'app/presentation/theme/theme.builder/shapes/shape-options/theme.builder.shape-options.html',
    bindings: {
        config: '=',
    }
});
