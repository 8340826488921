'use strict';

angular.module('app')

.controller('ForceChangePassword', function (DS, $http, $uibModalInstance, kdAuth, forceChangePassword)
{
    this.data = {};
    this.changeEndpoint = DS.defaults.basePath + 'auth/change-password';

    this.submit = () =>
    {
        $http.post(this.changeEndpoint, this.data)
        .then((resp) => {
            kdAuth.setUser(resp.data);
        }).then($uibModalInstance.close);
    };

    this.later = () =>
    {
        forceChangePassword.postpone();
        $uibModalInstance.close();
    };
});
