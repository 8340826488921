'use strict';

angular.module('app')

.service('presenterService', function (
    $window,
    $http,
    $rootScope,
    presenterOverlay,
    $stateParams,
    objectDefaults,
    Pusher,
    $state,
    $cookies,
    $timeout,
    handleSlideVideosService
) {

    this.link = null;
    this.state = {};

    this.channel = null;
    this.pusher = null;

    this.init = (link) => {

        this.link = link;
        this.state = link.presenter_state || {};
        this.state.popup = this.state.popup || {};

        this.listen();

        if (this.state.viewers) {
            presenterOverlay.getViewers(this.state.viewers);
        }

        $rootScope.$on('component-action', (event, data) =>
        {
            if(data.action === 'play') {

                return this.changeState({
                    component: data,
                })
                .then(() => {
                    this.changeState({
                        component: {
                            action: "updateVolume",
                            component: data.component,
                            subject_id: data.subject_id,
                            subject_name: data.subject_name,
                            uuid: data.uuid,
                            value: 1
                        },
                    });
                });
            }

            if(data.action === 'updateVolume') {
                return this.changeState({
                    component: data,
                });
            }

            this.changeState({
                component: data,
            });
        });

        $rootScope.$on('$stateChangeSuccess', ($event, toState, toParams) =>
        {
            if (/^admin.editor.presentation.chapter.slide/.test(toState.name) && toParams.id) {
                this.changeState({
                    chapter: toParams.chapterOrder,
                    slide: toParams.slideOrder
                });
            }
        });

        $timeout(() => {
            handleSlideVideosService.preventAutoPlay(this.link.presenter_state.slide);
        });

        $window.addEventListener('beforeunload',  () => {
            this.endSession(true);
        });

        $timeout(() => {
            $http.post('/api/presenter/' + this.link.id + '/ping', {presenter: true});
        }, 3000);

        this.addOverlay();
    };

    this.changeState = (state) => {

        if (!this.link) {
            return;
        }

        this.state = objectDefaults(state, this.state);

        return $http.post('/api/presenter/' + this.link.id + '/update', {
            state: this.state
        }).then((response) => {
            this.state = response.data;
        });
    };

    this.startSession = () => {

        if (!this.link) {
            throw new Error("Link is not initialized!");
        }

        let data = {
            chapter: $stateParams.chapterOrder || 1,
            slide: $stateParams.slideOrder || 1,
        };

        return $http.post('/api/presenter/' + this.link.id + '/start', data).then((response) =>
        {
            this.state = response.data;
            this.removeOverlay();

            handleSlideVideosService.reEnableAutoPlay(this.link.presenter_state.slide);

            return this.state;
        });
    };

    this.endSession = (beacon = false) => {

        this.state = {};

        if (!this.link) {
            return;
        }

        let url = '/api/presenter/' + this.link.id + '/stop';

        if (beacon) {

            try {
                $window.navigator.sendBeacon(url, {});
            }
            catch(err) {
                $http.post(url, {});
            }

        } else {
            return $http.post(url, {'btn_stopped': true}).then((response) => {
                this.addOverlay();
                handleSlideVideosService.endVideoPlay(this.link.presenter_state.slide);

                return response;
            });
        }
    };

    this.addOverlay = () => {
        presenterOverlay.create(this.link.presentation, () => {
            this.startSession();
        });
    };

    this.removeOverlay = () => {
        presenterOverlay.removeElement();
    };

    this.listen = () => {

        if (this.channel && this.channel.subscribed) {
            this.channel.unsubscribe();
        }

        this.pusher = this.pusher || new Pusher($rootScope.config.pusher.key, {
            cluster: $rootScope.config.pusher.cluster,
            auth: {
                headers: {
                    'X-XSRF-TOKEN': $cookies.get('XSRF-TOKEN'),
                }
            },
            encrypted: false,
            disableStats: true
        });

        this.channel = this.pusher.subscribe(this.link.channel);

        this.channel.bind('present.updated', (data) =>
        {
            if (this.state && this.state.viewers) {
                this.state.viewers = data.viewers;
            }

            presenterOverlay.getViewers(data.viewers);
            presenterOverlay.disableStart();
        });

        this.channel.bind('present.ping', (newViewer) =>
        {
            if (!newViewer || !newViewer.id) {
                return;
            }
            presenterOverlay.getViewers({[newViewer.id]: newViewer.status});
        });
    };

});
