'use strict';

angular.module('presentation.theme')

.config(function (ThemeValidatorProvider)
{
    ThemeValidatorProvider.add(function(config) {
        const listStyles = config.typography.listStyles;

        const colourFields = [
            {key: 'color'},
        ];

        const textFields = [
            {key: 'textStyle.tag'},
        ];

        return [{
            section: 'typography',
            fields: colourFields,
            validKeys: 'colour',
            config: listStyles
        }, {
            section: 'typography',
            fields: textFields,
            validKeys: 'text',
            config: listStyles
        }];
    });
});
