'use strict';

angular.module('komondor-editor')

.directive('sidebarPanelBackground', function ()
{

    return {
        replace: false,
        restrict: 'A',
        require: '^editor',
        templateUrl: 'scripts/editorComponents/compPanel/panel-background/sidebar-panel-background.html',
        controllerAs: '$comPanelBgCtrl',
        controller: function ($scope, $element, editorState)
        {
            this.comScope = editorState.componentScope;
        }
    };
});
