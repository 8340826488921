'use strict';

angular.module('presentation.theme')

.config(function (ThemeValidatorProvider)
{
    ThemeValidatorProvider.add(function(config) {
        const typography = config.typography;

        const textStyleFields = [
            {
                typeface: 'defaultFont.typeface',
                weight: 'defaultFont.weight',
            },
        ];

        return [{
            section: 'typography',
            fields: textStyleFields,
            validKeys: 'typeface',
            config: typography
        }];
    });
});
