"use strict";

angular
    .module("komondor.multitenancy")

    .controller(
        "DomainEditCtrl",
        function ($scope, model, Company, Action, extendDeep, $rootScope) {
            if (!model.config || angular.isArray(model.config)) {
                model.config = {};
            }

            model.config = extendDeep(
                {
                    services: {
                        analytics: {
                            view_id: "",
                        },
                    },
                    sidebarOptions: {
                        default: false,
                    },
                    analytics_session_enabled:
                        $rootScope.config.analytics_session_enabled,
                    serverless: {
                        pdf_generation: false,
                    },
                    conversion: { video: { third_party: false } },
                },
                model.config
            );

            if (angular.isArray(model.config.services.analytics)) {
                model.config.services.analytics = {};
            }

            $scope.model = model;
            $scope.companies = Company.filter();
            $scope.actions = Action.filter();

            $scope.saveModel = function () {
                $scope.model.DSCreate().then($scope.$close);
            };

            $scope.maxStorageGetterSetter = function (value) {
                if (angular.isDefined(value)) {
                    $scope.model.config.max_storage_gb = gigabyteToByte(value);
                }

                model.config = extendDeep(
                    {
                        services: {
                            analytics: {
                                view_id: "",
                            },
                        },
                        sidebarOptions: {
                            default: false,
                        },
                        analytics_session_enabled:
                            $rootScope.config.analytics_session_enabled,
                        serverless: {
                            pdf_generation: false,
                        },
                        conversion: { video: { third_party: false } },
                    },
                    model.config
                );

                if (angular.isArray(model.config.services.analytics)) {
                    model.config.services.analytics = {};
                }

                $scope.model = model;
                $scope.companies = Company.filter();
                $scope.actions = Action.filter();

                $scope.saveModel = function () {
                    $scope.model.DSCreate().then($scope.$close);
                };

                $scope.maxStorageGetterSetter = function (value) {
                    if (angular.isDefined(value)) {
                        $scope.model.config.max_storage_gb =
                            gigabyteToByte(value);
                    }

                    return byteToGigabyte($scope.model.config.max_storage_gb);
                };

                function gigabyteToByte(value) {
                    return value * 1073741824;
                }

                function byteToGigabyte(value) {
                    return value / 1073741824;
                }
            };
        }
    );
