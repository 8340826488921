'use strict';

angular.module('presentation.theme')

.component('themeGradientOverlay', {
    templateUrl: 'app/presentation/theme/theme.builder/slide-setup/gradient-overlay/gradient-overlay.html',
    bindings: {
        gradient: '=',
        palette: '='
    }
});