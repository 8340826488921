'use strict';

angular.module('app')

.provider('isPhantom', function ()
{
    this.isPrerender = false;

    this.$get = function ($window)
    {
        if (this.isPrerender) {
            return true;
        }

        if (/\bHeadlessChrome\//.test($window.navigator.userAgent)) {
            return true;
        }

        return false;
    };

    this.setPrerender = function (value)
    {
        this.isPrerender = value;
    };
});