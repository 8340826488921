'use strict';

angular.module('app')

.filter('time', function ()
{
    return function (d)
    {
        if (!d) {
            return 0;
        }

        d = Number(d);
        const h = Math.floor(d / 3600);
        const m = Math.floor(d % 3600 / 60);
        const s = Math.floor(d % 3600 % 60);

        const hDisplay = h > 0 ? h + "h " : "";
        const mDisplay = m > 0 ? m + "m " : "";
        const sDisplay = s > 0 ? s + "s"  : "0s";

        return hDisplay + mDisplay + sDisplay;
    };
});
