'use strict';

angular.module('app')

.factory('newSlideAction', function (
    $state,
    kdGate,
    Slide
) {

    return {
        name: 'Insert blank slide',
        tooltip: 'Insert blank slide',
        buttonClass: 'btn btn-primary',
        template: '<i class="material-icon material-icon-add"></i> Insert blank slide',
        show: () => {
            return kdGate.allows('slide.edit-blank') &&
                   kdGate.allows('slide.edit-limited');
        },
        act: (empty, options) =>
        {
            const newSlide = Slide.inject({
                type: 'slide',
                editable: true,
                name: 'New blank slide',
                back_layer: [],
                components: [],
                config: [],
                folder_id: 0,
            });

            options.onItemClick(empty, newSlide, true);
        },
    };
});
