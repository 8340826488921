'use strict';

angular.module('app')

.factory('baseApiUrl', function () {
    return '/api/';
})

.factory('ngKomondorAccessBaseApiUrl', function () {
    return '/api/';
})

.factory('ngKomondorMediaLibraryBaseApiUrl', function () {
    return '/api/';
});
