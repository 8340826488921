'use strict';

angular.module('presentation.theme')

.component('themeCustomCss', {
    templateUrl: 'app/presentation/theme/theme.builder/custom/theme.custom-css/theme.custom-css.html',
    bindings: {
        customCss: '='
    },
    controller: function ()
    {

    }
});
