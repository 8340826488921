'use strict';

angular.module('presentation.theme')

.factory('themeConfigPopupsDefaultColour', function () {
    return  {
        name: '',
        bgColor: '',
        bgColorWhite: true,
        closeColor: '',
        closeColorDefault: true,
        bgOpacity: 100,
        closeButtonAssetId: 0,
        closeButtonWidth: 20,
        closeButtonHeight: 20,
        closeButtonMargin: 0,
    };
})

.factory('themeConfigPopups', function () {

    return {
        colourProfiles: {},
        modalSizes: {
            sm: {
                size: 300,
                unit: 'px',
                align: 'center',
                top: 45,
            },
            md: {
                size: 600,
                unit: 'px',
                align: 'center',
                top: 45
            },
            lg: {
                size: 986,
                unit: 'px',
                align: 'center',
                top: 45
            },
            xl: {
                size: 1350,
                unit: 'px',
                align: 'center',
                top: 45
            },
            suite: {
                size: 800,
                unit: 'px',
                align: 'left',
                top: 45
            },
        }
    };
});
