'use strict';

angular.module('app')

.controller('UnsavedPresentationConfirmationCtrl', function (
    $scope,
    presentation,
    slide,
    $uibModalInstance,
    notify,
    $rootScope,
    Notification
){
    $scope.presentation = presentation;
    $scope.slide = slide;

    $scope.save = function ()
    {
        if ($scope.presentation) {
            $scope.presentation.saveSlides().then(function ()
            {
                if ($scope.presentation && $scope.presentation.checksum) {
                    delete $scope.presentation.checksum;
                }

                Notification.success('Successfully edited');

                $uibModalInstance.close($scope.presentation);
            });
        } else {
            $scope.slide.DSSave().then(function ()
            {
                $rootScope.$broadcast('slide:saved');

                if (slide && slide.contentChecksum) {
                    delete slide.contentChecksum;
                }

                $uibModalInstance.close($scope.slide);
            });
        }
    };

    $scope.discard = function ()
    {
        if ($scope.presentation) {

            if ($scope.presentation.checksum) {

                // Clear Data store
                $scope.presentation.ejectDataStore();
                
                delete $scope.presentation.checksum;
            }

            $uibModalInstance.close($scope.presentation);

        } else {

            if ($scope.slide.contentChecksum) {
                delete $scope.slide.contentChecksum;
            }

            $uibModalInstance.close($scope.slide);
        }
    };
});
