'use strict';

angular.module('presentation.theme')

.directive('themePresentationSidebar', function ()
{
    return {
        restrict: "E",
        templateUrl: 'app/presentation/theme/theme.builder/colours/presentation-sidebar/presentation-sidebar.html',
        scope: false
    };
});
