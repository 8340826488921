'use strict';

angular.module('presentation.theme')

.config(function (ThemeValidatorProvider)
{
    ThemeValidatorProvider.add(function(config) {
        const tabs = config.tabs;

        const colourFields = [
            {key: 'title.color'},
            {key: 'title.bg'},
            {key: 'color'},
            {key: 'bg'},
            {key: 'activeBg'},
            {key: 'activeColor'},
            {key: 'divider.color'},
            {key: 'slider.color'},
            {key: 'slider.bg'},
        ];

        const textStylesFields = [
            {key: 'info.fontStyle'},
            {key: 'title.fontStyle'}
        ];

        return [{
            section: 'tabs',
            fields: colourFields,
            validKeys: 'colour',
            config: tabs
        }, {
            section: 'tabs',
            fields: textStylesFields,
            validKeys: 'text',
            config: tabs
        }];
    });
});
