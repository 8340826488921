'use strict';

angular.module('presentation.theme')

.factory('themeConfigIframes', function ()
{
    return {
        enabled: false,
    };
});
