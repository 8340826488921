'use strict';

angular.module('slide-popup')

.controller('slideModalCtrl', function (
    $scope,
    $rootScope,
    $uibModalInstance,
    $document,
    $timeout,
    defaultEditorOptions,
    presentationState,
    slide,
    slideSize,
    slideColour,
    defaultModalThemeConfig,
    Notification,
    editorState,
    clientTokens,
    checkEditorHeight,
    linkModal,
    arrayGet,
    analytics,
    presenterService,
    currentTheme
) {
    // Ensure modal is always closed before changing state
    // Fixes issue when background fade disappears
    const stateChange = $rootScope.$on('$stateChangeStart', $scope.cancel);

    this.$onInit = function ()
    {
        if (!presentationState.editMode) {
            analytics.startAction({
                component: 'popup',
                action: 'open',
                subject_id: slide.id,
                subject_name: slide.name
            }, $scope);
        }

        $scope.slide = slide;

        $scope.theme = currentTheme();
        $scope.clientTokens = clientTokens;

        $scope.presentationState = presentationState;
        $scope.editorOptions = defaultEditorOptions.get();

        $scope.editorOptions.onCreateLink = linkModal.open;
        $scope.editorOptions.onClickLink = linkModal.click;

        $scope.editorOptions.showBreadcrumbs = false;


        if (presentationState.isSidebarOptions() && presentationState.slide) {
            $scope.editorOptions.showBreadcrumbs = presentationState.slide.config.showBreadcrumbs;
        }

        // Hide extra options at bottom of modal
        const options = {
            'enablePopupsCreation': false
        };

        linkModal.setOptions(options);

        if ($rootScope.offline && $rootScope.offline_edit) {
            presentationState.setEditMode(false);
        }

        $timeout(function ()
        {
            $scope.initEditor = true;
        });

        //180px leeway for modal top and bottom of the window
        editorState.maxHeight = $document[0].querySelector(".slide").clientHeight - 180;

        checkEditorHeight.setCallback(function () {
            Notification.warning('Content overflows print area');
        });

        $scope.colourProfiles = arrayGet($scope.theme, 'config.popups.colourProfiles', []);
        $scope.slideColour = null;

        let optionsCount = Object.keys($scope.colourProfiles).length;
        $scope.showColourOption = optionsCount > 1;

        if (optionsCount > 0) {
            slideColour = slideColour || Object.keys($scope.colourProfiles)[0];
        }

        $timeout(function ()
        {
            $scope.changeModalColour(slideColour);
        });

        presenterService.changeState({
            popup: {
                id: slide.id,
                slideSize: slideSize,
                slideColour: slideColour,
            }
        });
    };

    $scope.modalConfig = defaultModalThemeConfig.get();

    $scope.slideSize = slideSize || $scope.modalConfig.default;
    $scope.offline = $rootScope.offline;

    // Remove on cancel click
    $scope.cancel = function ()
    {
        $uibModalInstance.dismiss();

        if ($rootScope.offline && $rootScope.offline_edit) {
            presentationState.setEditMode(true);
        }
    };

    // Save event
    $scope.ok = function ()
    {
        return $scope.slide.DSCreate().then(function (slide)
        {
            $uibModalInstance.close({
                target: null,
                href: '#',
                'data-slide-popup-id': slide.id,
                'data-slide-popup-size': $scope.slideSize,
                'data-slide-popup-colour': $scope.slideColour,
                class: 'slide-popup-link'
            });
        });
    };

    $scope.togglePreviewMode = function ()
    {
        presentationState.toggleEditMode();
    };

    $scope.changeModalSize = function (size)
    {
        const popup = angular.element($document[0].querySelector('.modal.slide-popup'));

        popup.removeClass($scope.modalConfig.sizes[$scope.slideSize].class);
        popup.addClass($scope.modalConfig.sizes[size].class);

        $scope.slideSize = size;
    };

    $scope.changeModalColour = function (colour)
    {
        const popup = angular.element($document[0].querySelector('.modal.slide-popup'));

        popup.removeClass($scope.slideColour);
        popup.addClass(colour);

        $scope.slideColour = colour;
    };

    $scope.$on('$destroy', function ()
    {
        if (!presentationState.editMode && $scope.slide) {
            analytics.endAction({
                component: 'popup',
                action: 'open',
                subject_id: $scope.slide.id,
                subject_name: $scope.slide.name
            }, $scope);
        }

        presenterService.changeState({
            popup: {}
        });

        // Reset popup options to default
        const options = {
            'enablePopupsCreation': true
        };

        linkModal.setOptions(options);

        stateChange();
    });
});
