'use strict';

angular.module('app')

.directive('slidePresenterOptions', function() {
    return {
        restrict: 'EA',
        templateUrl: 'app/presenter/slide-presenter-options/slidePresenterOptions.html',
        controller: 'SlidePresenterCtrl',
    };
});
