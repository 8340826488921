'use strict';

angular.module('app')

.factory('slideViewOptions', function (
    extendDeep,
    resourceViewOptions,

    deselectAction,
    multiDeleteAction,
    multiMoveAction,
    multiSlideDeleteAction,

    editSingleItemAction,
    moveSingleItemAction,
    singleDeleteSlideAction,
    masterUsageSingleItemAction,

    templateAction,
    masterAction,

    gridNameTemplate,
    listNameTemplate,
    gridThumbnailableTemplate,
    listThumbnailableTemplate,
    gridThumbTemplate,
    createAction,
    createFolderAction,
    kdGate,
    moment
) {
    const options = angular.copy(resourceViewOptions);

    const gatedCreateAction = angular.copy(createAction);
    gatedCreateAction.show = showCreateButton;
    gatedCreateAction.template = '<i class="material-icon material-icon-add"></i> New slide';

    const gatedCreateFolderAction = angular.copy(createFolderAction);
    gatedCreateFolderAction.show = showCreateButton;

    function showCreateButton ()
    {
        return kdGate.allows('slide.create') &&
               kdGate.allows('slide.edit-template') ||
               kdGate.allows('slide.edit-master');
    }

    options.moment = moment;

    options.toolbar = [gatedCreateFolderAction, gatedCreateAction];

    options.sorts = [
        {
            label: "Type",
            sort: "type asc,name asc"
        }
    ];

    options.inlineActions = [
        editSingleItemAction,
    ];

    options.inlineDropdown = [
        templateAction,
        masterAction,
        masterUsageSingleItemAction,
        moveSingleItemAction,
        singleDeleteSlideAction
    ];

    options.gridActions = [
        ...options.inlineActions,
        ...options.inlineDropdown,
    ];

    // Build the options
    extendDeep(options, {
        tagging: true,
        taggingAdvanced: true,
        viewIndex: 1,
        queryDefaults: {
            where: {
                type: { in: ['template', 'master', 'folder'] },
                folder_id: 0
            }
        },
        actions: [
            deselectAction,
            multiSlideDeleteAction,
            multiMoveAction,
        ],
        rowClassifier: function (row, classes) {
            classes['status-template'] = row.type === 'template';
            classes['status-master'] = row.type === 'master';
            classes['status-locked'] = !!row.locked_by;
            return classes;
        },
        onItemClick: function ($event, model)
        {
            $event.stopPropagation();
            model.goInto();
        },
        list: {
            columns: [
                {
                    label: 'Name',
                    name: 'name',
                    template: listThumbnailableTemplate(),
                    sortable: true
                },
                {
                    label: 'Type',
                    name: 'type',
                    template: `<div class="resource-status"
                                    ng-class="{hidden: model.type == \'folder\'}">
                                        <i class="glyphicon icon-status-{{ model.type }}"></i>
                                </div>`,
                    sortable: false
                },
                {
                    label: 'Used',
                    name: 'num_used',
                    template: `<span ng-if="model.type === \'master\' && model.chapters_count > 0">
                                    <a href ui-sref=".usage({id: model.id})">
                                        <u>{{ model.chapters_count }}</u>
                                    </a>
                                </span>`,
                    sortable: false
                },
                {
                    label: 'Editable',
                    name: 'editable',
                    template: `<i title="{{ model.locked_by ? 'Locked By: ' + model.locked_by.name : null }}"
                                    ng-show="model.editable || model.locked_by"
                                    ng-class="{'material-icon-lock': model.locked_by , 'material-icon-check': model.editable}"
                                    class="material-icon "></i>`,
                    sortable: false
                },
                {
                    label: 'Last modified',
                    name: 'updated_at',
                    template: `<span class="no-wrap" uib-tooltip="{{ model.updated_at | date }}">
                                    {{ options.moment(model.updated_at).fromNow() }}
                                </span>`,
                    sortable: true
                },
                {
                    template: `<contextual-options actions="options.inlineActions"
                                                   dropdown-actions="options.inlineDropdown"
                                                   models="options.resourceList.selected"
                                                   model="model"
                                                   class="row-actions pull-right"></contextual-options>`
                }
            ],
        },
        grid: {
            fields: [
                {
                    template: `<div class="resource-lock-icon">
                                    <i title="{{ model.locked_by ? 'Locked By: ' + model.locked_by.name : null }}"
                                        ng-show="model.locked_by"
                                        class="material-icon material-icon-lock"></i>
                                </div>`,
                },
                {
                    template: `<div class="resource-status">
                                    <i class="glyphicon icon-status-{{model.type}}"></i>
                                </div>`,
                },
                {
                    template: gridThumbnailableTemplate(),
                },
                {
                    template: `<div class="field-hidden">
                                    {{ model.format.join(", ") }}
                                </span>`,
                },
                {
                    template: gridNameTemplate('name', null),
                },
                {
                    template: `<contextual-options dropdown-actions="options.gridActions"
                                                   models="options.resourceList.selected"
                                                   model="model"
                                                   class="grid-actions pull-right"></contextual-options>`
                }
            ]
        }
    });

    return options;
});
