'use strict';

angular.module('app')

/*
* A factory to simply pass a set of JSON defaults to
* the Highcharts-ng directive.
* https://github.com/pablojim/highcharts-ng
*/
.factory('chartStylePresets', function (highcharts)
{
    var chartStylePresets = {
        options: {
            legend: {
                enabled: true,
                itemStyle: {
                    color: '#FFFFFF',
                    fontSize: '1rem'
                },
                align: 'left',
                verticalAlign: 'bottom'
            },
            // This is the Main Highcharts chart config. Any Highchart options are valid here.
            // will be overriden by values specified below.
            chart: {
                type: 'bar',
                backgroundColor: 'transparent',
                animation: true,
                style: {
                    fontFamily: 'inherit'
                },
                events: {
                    // click: function(e)
                    // {

                    // }
                }
            },
            tooltip: {
                enabled: false
                //     style: {
                //         padding: 10,
                //         fontWeight: 'bold',
                //         followPointer: false,
                //         followTouchMove: false
                //     }
            },

            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        allowOverlap: false,
                        inside: false,
                        style: {
                            fontSize: '1.2rem',
                            textShadow: 'none'
                        }
                    }
                },
                bar: {
                    borderColor: 'transparent'
                },
                column: {
                    borderColor: 'transparent'
                },
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                        shadow: false,
                        style: {
                            textShadow: 'none'
                        }
                    },
                    borderWidth: 0
                }
            }

        },

        // disable credits e.g. highchart.com
        credits: {
            enabled: false
        },

        // The below properties are watched separately for changes.
        // Series object (optional) - a list of series using normal highcharts series options.
        // series: [{
        //     name: 'Example',
        //     data: [10, 15, 12, 8, 7]
        // }],

        // Title configuration (optional)
        title: {
            text: null,
        },

        // Boolean to control showng loading status on chart (optional)
        // Could be a string if you want to show specific loading text.
        loading: false,

        // Configuration for the xAxis (optional). Currently only one x axis can be dynamically controlled.
        // properties currentMin and currentMax provied 2-way binding to the chart's maximimum and minimum
        // xAxis: {
        //     title: { text: 'values' }
        // },
        xAxis: {
            gridLineColor: 'transparent',
            lineWidth: 2,
            tickLength: 0,
            labels: {
                style: {
                    fontSize: '1rem',
                    opacity: 0.8
                }
            }
        },
        yAxis: {
            gridLineColor: 'transparent',
            lineWidth: 0.5,
            tickLength: 0,
            labels: {
                style: {
                    fontSize: '1rem',
                    opacity: 0.8
                }
            },
            title: {
                enabled: false
            }
        },

        // Whether to use HighStocks instead of HighCharts (optional). Defaults to false.
        // useHighStocks: false,

        // Size (optional) if left out the chart will default to size of the div or something sensible.
        // size: {
        //     height: 400
        // },

    };

    /**
     * Fill pie chart transparent gaps if borderWidth is zero
     * Taken from & bit polished:
     * https://github.com/highcharts/highcharts/issues/1828#issuecomment-46528961
     */
    highcharts.wrap(highcharts.seriesTypes.pie.prototype, 'drawPoints', function (proceed)
    {
        if (this.options.borderWidth === 0) {
            highcharts.each(this.points, function (point)
            {
                var options = ['', 'hover', 'select'],
                    currentOption;

                for (var index in options) {
                    currentOption = options[index];
                    point.pointAttr[currentOption]['stroke-width'] = 1;
                    point.pointAttr[currentOption].stroke = point.pointAttr[currentOption].fill;
                }
            });
        }
        proceed.apply(this);
    });

    return chartStylePresets;
});
