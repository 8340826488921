'use strict';

angular.module('presentation.theme')

.directive('themeMapColours', function ()
{
    return {
        restrict: "E",
        templateUrl: 'app/presentation/theme/theme.builder/maps/map-colours/map-colours.html',
        scope: {
            model: '=',
            defaultColors: '=',
        }
    };
});
