'use strict';

angular.module('app')

.factory('clientTokens', function (editorState, presentationState, currentTheme)
{
    function replaceBrandColor(value) {

        let press = presentationState.presentation;
        let theme = currentTheme();

        let client_brand_color = ''; //Default One.

        if (press && press.client) {

            if (theme && theme.isBrandColorEnabled()) {
                client_brand_color = press.client.brand_color;
            }
        }

        return value.replace(new RegExp("#client_brand_color", 'g'), client_brand_color);
    }

    function replaceOwnerFields(value) {

        //user’s name, job title, email address and phone number.

        let press = presentationState.presentation;

        if (!press || !press.user) {
            return value;
        }

        let ownerTokens = {
            'owner_name' : press.user.name,
            'owner_email' : press.user.email,
            'owner_job_title' : press.user.job_title || '',
            'owner_dep' : press.user.job_dep || '',
            'owner_phone' : press.user.phone || '',
        };

        angular.forEach(ownerTokens, function (tokenVal, tokenKey) {
            value = value.replace(new RegExp(`{${tokenKey}}`, 'g'), tokenVal);
        });

        return value;
    }

    return function (value)
    {
        if (!value || !angular.isString(value)) {
            return value;
        }

        if (editorState.editMode) {
            return value;
        }

        let press = presentationState.presentation;

        value = replaceBrandColor(value);
        value = replaceOwnerFields(value);

        if (!press || !press.client) {
            return value;
        }

        let data = press.client.getData();

        angular.forEach(data, function (tokenVal, tokenKey) {
            value = value.replace(new RegExp(`{${tokenKey}}`, 'g'), tokenVal);
        });

        return value;
    };
});

