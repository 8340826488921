'use strict';

angular.module('app')

.config(function (editorGatesProvider) {
   editorGatesProvider.setGates('grid');
})

.directive('grid', function (
    $timeout,
    $parse,
    stripPopups,
    editorControls,
    objectDefaults,
    editorState
) {
    return {
        restrict: 'A',
        require: '^component',
        templateUrl: 'scripts/editorComponents/grid/grid.html',
        controller: function ($scope, $element) {

            const component = $element.controller('component');
            const minCells = 1;
            const maxCells = 24;
            const classPrefix = 'col-xs-';
            const defaultSpacing = {
                size: 15,
                unit: 'px'
            };

            this.$onInit = function () {
                updateWidth();
                generateSpacing();
            };

            $scope.computed = {};

            $scope.gridListOptions = {
                templateUrl: 'scripts/editorComponents/grid/gridComponentListOptions.html',
                controller: 'gridComponentListOptionsCtrl',
                sidebar: {
                    gridPosition : {
                        title: 'GRID POSITION',
                        template: '<div sidebar-grid-position></div>',
                        show: function () {
                            return true;
                        }
                    },
                }
            };

            $scope.columnOptions = [
                {
                    name: '1 per row',
                    cells: 1,
                    value: 12,
                    icon: 'images/sidebar/grid/1.svg',
                    push: [] // bootstrap grid offset generation,
                },
                {
                    name: '2 per row',
                    cells: 2,
                    value: 6,
                    icon: 'images/sidebar/grid/2.svg',
                    push: ['3']
                },
                {
                    name: '3 per row',
                    cells: 3,
                    value: 4,
                    icon: 'images/sidebar/grid/3.svg',
                    push: ['4', '2']
                },
                {
                    name: '4 per row',
                    cells: 4,
                    value: 3,
                    icon: 'images/sidebar/grid/4.svg',
                    push: ['4-5', '3', '1-5']
                },
                {
                    name: '6 per row',
                    cells: 6,
                    value: 2,
                    icon: 'images/sidebar/grid/6.svg',
                    push: ['5', '4', '3', '2', '1']
                },
                {
                    name: '12 per row',
                    cells: 12,
                    value: 1,
                    icon: 'images/sidebar/grid/12.svg',
                    push: ['5-5', '5', '4-5', '4', '3-5', '3', '2-5', '2', '1-5', '1', '0-5']
                }
            ];

            objectDefaults($scope.model.data, {
                spacingConf: angular.copy(defaultSpacing),
                columnConfig: $scope.columnOptions[3],
                numCells: $scope.columnOptions[3].cells,
                list: createDefaultColumns($scope.columnOptions[3].cells),
            });

            $scope.generateSpacing = generateSpacing;
            $scope.getTrackables = getTrackables;
            $scope.gridMove = gridMove;

            $scope.$on('grid:move', gridMove);

            $scope.changeNumColumns = function (options)
            {
                $scope.data.columnConfig = options;
                updateWidth();
            };

            $scope.reachedMaxCells = function ()
            {
                return $scope.data.numCells === maxCells;
            };

            $scope.changeSpacingConf = function ()
            {
                $scope.data.spacingConf.unit = $scope.data.spacingConf.unit === 'px' ? '%' : 'px';
                generateSpacing();
            };

            $scope.reachedMinCells = function ()
            {
                return $scope.data.numCells === minCells;
            };

            $scope.addCell = function ()
            {
                $scope.data.numCells++;

                if ($scope.data.list.length < $scope.data.numCells) {
                    var copyItem = angular.copy($scope.data.list[0]);
                    copyItem.nested = [];
                    $scope.data.list.push(copyItem);
                }

                updateWidth();

                editorState.history.commit({
                    undo: () => {
                        $scope.removeCell();
                        $scope.data.list.splice(-1, 1);
                    },
                    redo: () => {
                        $scope.addCell();
                    }
                });
            };

            $scope.removeCell = function ()
            {
                $scope.model.data.numCells--;
                updateWidth();

                editorState.history.commit({
                    undo: () => $scope.addCell(),
                    redo: () => $scope.removeCell()
                });
            };

            $scope.clone = function ()
            {
                const oldList = $scope.data.list;
                const newList = angular.copy($scope.data.list);

                angular.forEach(newList, function (list, idx) {
                    // Skip first cell
                    if (idx) {
                        list.nested = angular.copy($scope.data.list[0].nested);
                        list.nested = stripPopups(list.nested);
                    }
                });

                $scope.data.list = newList;

                historyCommit(oldList, newList);
                updateWidth();
            };

            $scope.clear = function ()
            {
                const oldList = $scope.data.list;
                const newList = angular.copy($scope.data.list);

                angular.forEach(newList, function (list) {
                    list.nested = [];
                });

                $scope.data.list = newList;

                historyCommit(oldList, newList);
                updateWidth();
            };

            $scope.isStartOfLastRow = function (index)
            {
                const remainder = $scope.data.numCells % $scope.data.columnConfig.cells;
                const firstCellOfLastRow = $scope.data.numCells - remainder;

                return index === firstCellOfLastRow ? `col-xs-push-${$scope.model.data.columnConfig.push[remainder-1]}` : '';
            };

            function gridMove ($event, child, distance)
            {
                $event.stopPropagation();

                const length = $scope.data.list.length;
                const oldList = [];
                const newList = [];

                let modelMoved = false;

                for (let i = 0; i < length; i++) {
                    oldList.push({
                        nested: $scope.data.list[i].nested
                    });
                }

                for (let i = 0; i < length; i++) {
                    if (!modelMoved && $scope.data.list[i].nested.indexOf(child) >= 0) {
                        let newPosition = i + distance;
                        if (newPosition >= 0 && newPosition < length) {
                            editorControls.removeControls();
                            moveCell(i, distance, child);
                            modelMoved = true;
                        }
                    }
                }

                for (let i = 0; i < length; i++) {
                    newList.push({
                        nested: $scope.data.list[i].nested
                    });
                }

                historyCommit(oldList, newList);
            }

            function historyCommit (oldList, newList)
            {
                const setter = $parse('model.data.list').assign;

                editorState.history.commit({
                    undo: () => {
                        const scope = $scope;
                        setter(scope, oldList);
                        updateWidth();
                    },
                    redo: () => {
                        const scope = $scope;
                        setter(scope, newList);
                        updateWidth();
                    }
                });
            }

            function moveCell (idx, distance, focusModel)
            {
                const moveTo = idx + distance;

                if ($scope.data.list[moveTo] === undefined) {
                    return;
                }

                $scope.data.list.splice(moveTo, 0, $scope.data.list.splice(idx, 1)[0]);
                updateWidth();

                if (focusModel) {
                    $timeout(function () {
                        $scope.$broadcast('component:request-focus', focusModel);
                    });
                }
            }

            function generateSpacing ()
            {
                const spacing = $scope.model.data.spacingConf.size + $scope.model.data.spacingConf.unit;

                $scope.model.data.spacing = {
                    row: {
                        marginLeft: '-' + spacing,
                        marginRight: '-' + spacing,
                        marginBottom: 0,
                        marginTop: 0
                    },
                    cell: {
                        paddingLeft: spacing,
                        paddingRight: spacing,
                        paddingTop: spacing,
                        paddingBottom: spacing
                    }
                };
            }

            function updateWidth ()
            {
                $scope.computed.width = `${classPrefix}${$scope.model.data.columnConfig.value}`;
                $scope.computed.list = $scope.model.data.list.slice(0, $scope.model.data.numCells);
            }

            function createDefaultColumns (num)
            {
                const arr = [];
                for (let i = 0; i < num; i++) {
                    arr.push({nested: []});
                }
                return arr;
            }

            function getTrackables ()
            {
                return {
                    'data.spacingConf.size': {
                        deepWatch: false,
                        callOnChangeAfter: true,
                        onChange: generateSpacing
                    },
                    'data.spacingConf.unit': {
                        deepWatch: false,
                        callOnChangeAfter: true,
                        onChange: generateSpacing
                    },
                    'data.columnConfig': {
                        deepWatch: false,
                        callOnChangeAfter: true,
                        onChange: updateWidth
                    },
                    'class': {
                        deepWatch: false
                    }
                };
            }

            component.setFocusRequestHandler(function ()
            {
                $timeout(function () {
                    $scope.$broadcast('component:request-focus', $scope.model.nested);
                });
            });
        }
    };
});
