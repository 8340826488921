'use strict';

angular.module('slide-popup')

.factory('slideModalConfig', function ()
{
    var moduleFolder = 'scripts/editorComponents/link/slideModal/';

    return {
        bodyTemplateUrl: moduleFolder + 'slideBody.html',
        popupTemplateUrl: moduleFolder + 'slideModal.html',
        getSlideId: function (linkAttrs)
        {
            var slideId = linkAttrs['data-slide-popup-id'];

            return angular.isObject(slideId) ? parseInt(slideId.value) : parseInt(slideId);
        },
        getInnerId: function (linkAttrs)
        {
            var innerId = linkAttrs['data-inner'];

            return angular.isObject(innerId) ? innerId.value : innerId;
        },
        getModelDefaultItem: function ()
        {
            return {
                name: 'Popup slide',
                type: 'slide',
                popup: true,
                slide_id: 0,
                components: [],
                back_layer: [],
            };
        }
    };
});
