'use strict';

angular.module('app')

.controller('presentationConflictCtrl', function (
    $scope,
    presentation,
    status,
    $uibModalInstance,
    notify,
    $rootScope,
    $state,
    $stateParams
) {
    $scope.presentation = presentation;
    $scope.status = status;
    $scope.offline = $rootScope.offline;

    $scope.createNew = function ()
    {
        presentation.saveOnline(status === 403 ? 'duplicate' : 'create').then(function (presentation)
        {
            $uibModalInstance.close(presentation);

            $stateParams.chapterOrder = "1";
            $stateParams.slideOrder = "1";
            $stateParams.id = presentation.id;

            $state.go('admin.editor.presentation.chapter.slide', {
                id: presentation.id
            }, {
                reload: true,
                inherit: false,
                notify: true
            });
        });
    };

    $scope.pullChanges = function ()
    {
        notify.confirm('Are you sure? You will lose all your changes!').result.then(function ()
        {
            presentation.ejectDataStore();
            // We can guarantee all presentations have chapter 1 slide 1
            $stateParams.chapterOrder = 1;
            $stateParams.slideOrder = 1;

            $state.transitionTo($state.current, $stateParams, {
                reload: true,
                inherit: true,
                notify: true
            });

            $uibModalInstance.close(presentation);
        });
    };
});
