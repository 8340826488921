"use strict";

angular.module('app')

.factory('listThumbnailableTemplate', function () {

    return function () {
        return `<p class="resource-ui-name">
                        <span class="thumb-wrap" ng-click="admin.goToModel($event, model)">
                            <span class="thumb" ng-if="model.type !== 'folder'" thumbnailable="model"></span>
                            <i ng-if="model.type == 'folder'"
                               class="icon"
                               ng-class="options.getIcon(model)"></i>
                        </span>
                        <span class="asset-wrap">
                            <span class="asset-title"
                                  ng-click="admin.goToModel($event, model)">{{ model.name }}</span>
                            <span class="asset-breadcrumbs"
                                  ng-if="options.showBreadcrumbs && model.breadcrumbs.length">
                            in <span ng-repeat="breadcrumb in model.breadcrumbs">
                                <a ng-click="$event.stopPropagation(); admin.goToFolder(breadcrumb.id)">{{ breadcrumb.label }}</a> {{ $last ? "" : ">" }}
                                </span>
                            </span>
                        </span>
                    </p>`;
    };

});