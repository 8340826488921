'use strict';

angular.module('app')

.controller('CountryDetailModalCtrl', function (
    $document,
    $scope,
    $log,
    $q,
    $uibModalInstance,
    $timeout,
    defaultEditorOptions,
    DataToken,
    DatasetUtil,
    Datasheet,
    CountryPopupDataRepo,
    contentModel,
    fullDatasets,
    typeConfig,
    defaultHighMapThemes
) {
    const editorOptions = defaultEditorOptions.get();
    ///////////////////
    // scope members //
    ///////////////////
    // remove the 'leaflet-map' option in editor's nesteableComponents
    $scope.editorOptions = angular.copy(editorOptions);
    delete $scope.editorOptions.nestableComponents.editor.components['high-map'];

    /**
     * add in 'fullDatasets' to editorOptions, so it can be used by chart.js
     *     Chart component directive's link(function) can init with editorCtrl.options.fullDatasets
     *
     *     In future we may have other means to pass data directly to Chart component.
     */
    $scope.editorOptions.fullDatasets = fullDatasets;
    $scope.editorOptions.controls = typeConfig.isEditMode;

    $scope.contentModel = contentModel;
    $scope.fullDatasets = fullDatasets;
    $scope.currCountry = typeConfig.currCountry;
    $scope.isEditMode = typeConfig.isEditMode;

    $scope.selectedData = {};
    $scope.showEditor = false;

    // add property 'name' and 'idx' to display as options
    angular.forEach($scope.fullDatasets, function (dataset, idx)
    {
        dataset.name = 'data series ' + (idx + 1);
        dataset.idx = idx;
    });

    $scope.repo = new CountryPopupDataRepo($scope.fullDatasets, $scope.currCountry);

    // Get theme for modal styling
    const HighMapThemes = defaultHighMapThemes.get();
    $scope.modalClass = HighMapThemes.themeName;

    /////////////////////
    // scope functions //
    /////////////////////
    /**
     * Generate token and insert into current cursor position.
     *
     */
    $scope.generateToken = function generateToken ()
    {
        // return if no dataset is selected
        if (!$scope.selectedData.dataset) {
            return;
        }

        $document[0].execCommand('insertHtml', false, DataToken.generateToken($scope.selectedData.dataset.idx, $scope.selectedData.valueField));
    };

    $scope.close = function ()
    {
        $uibModalInstance.close($scope.contentModel);
    };

    $scope.editableFormatter = function (value)
    {
        if (!$scope.fullDatasets || !$scope.currCountry) {
            return value;
        }

        value = DataToken.parseTokensInString(value, $scope.fullDatasets, $scope.currCountry);

        return value;
    };

    $timeout(function ()
    {
        $scope.showEditor = true;
    });

});
