'use strict';

angular.module('app')

/**
 * Injects theme service
 */
.service('themeInjector', function ($injector, Theme, UserTheme)
{
    this.instances = {};

    this.has = function(themeName)
    {
        return this.instances[themeName] ? true : false;
    };

    this.userThemeExists = function (themeName)
    {
        return Theme.filter({ name: themeName, system: false }).length > 0;
    };

    this.get = function (themeName)
    {
        if (this.userThemeExists(themeName)) {

            if (!this.has(themeName)) {
                this.instances[themeName] = new UserTheme(themeName);
            }

            return this.instances[themeName];
        }

        if ($injector.has(themeName)) {
            return $injector.get(themeName);
        }

        throw new Error("Theme not found");
    };
});
