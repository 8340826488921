'use strict';

angular.module('app')

/**
 * Popup modal for country details. Triggered by clicking on the shape/marker of country in map-component.
 */
.factory('CountryDetailModal', function ($uibModal)
{
    return function CountryDetailModal (contentModel, fullDatasets, typeConfig)
    {
        return $uibModal.open({
            size: 'lg',
            // HAX - Done this way to make it late compile
            // Because if compiled early some editor only options show within the preview mode
            // and then are hidden a fraction of a second later. (should fix properly)
            template: '<ng-include src=" \'scripts/editorComponents/highMap/countryPopup/countryDetailModal.html\' "></ng-include>',
            controller: 'CountryDetailModalCtrl',
            windowClass: 'country-detail-config-modal',
            resolve: {
                /**
                 * all datasets including filtered-data, label/value, filterQuery
                 *
                 * @return {Object}
                 */
                fullDatasets: function () {
                    return fullDatasets;
                },

                /**
                 * model of the editor content
                 *
                 *      {
                 *          compoennts: [component1, component2, ...]
                 *      }
                 *
                 * @return {Object}
                 */
                contentModel: function () {
                    return contentModel || { components: [] };
                },

                /**
                 * type configuration of the country-detail-modal
                 *
                 * @return {Object}     {}.isEditMode   If is in edit mode or preview/presentation mode
                 *                      {}.currCountry  Name of the country of this country-detail-modal.
                 */
                typeConfig: function () {
                    return typeConfig;
                }
            }
        });
    };
});
