'use strict';

angular.module('presentation.theme')

.component('themeFontFamily', {
    templateUrl: 'app/presentation/theme/theme.builder/typefaces/font-family/font-family.html',
    bindings: {
      model: '<'
    },
    controller: 'themeFontFamilyCtrl'
})

.controller('themeFontFamilyCtrl', function ($scope, Notification, Theme, Font, $q)
{
    let currentTheme = null;

    this.fontGroups = [];
    this.weights = [100, 200, 300, 400, 500, 600, 700, 800, 900];

    this.$onInit = () =>
    {
        currentTheme = this.model;

        this.fontGroups = getFontGroups();

        $scope.$watch(
            () => JSON.stringify(currentTheme.fonts),
            (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    this.fontGroups = getFontGroups();
                }
            }
        );
    };

    this.saveFont = (font) =>
    {
        const update = {
            weight: font.weight,
            family: font.family
        };

        const promises = font.fonts.map((font) => {
            return font.DSUpdate(update, {quietMode: true});
        });

        $q.all(promises).then(() => {
            updateFonts();
        });
    };

    this.deleteFonts = (fonts) =>
    {
        const promises = fonts.map((font) => {
            return Font.destroy(font.id, {quietMode: true});
        });

        $q.all(promises).then(() => {
            updateFonts();
        });
    };

    function updateFonts ()
    {
        const fontFamilyMap = currentTheme.font_families.reduce((map, fontFamily) => {
            map[fontFamily.name] = fontFamily.weights;
            return map;
        }, {});

        checkConfig(currentTheme.config, fontFamilyMap);
        currentTheme.setDefaultFonts();

        currentTheme.DSSave({quietMode: true}).then((model) => {
            model.setDefaultFonts();
        });
    }

    function getFontGroups ()
    {
        return currentTheme.fonts.reduce((group, font) => {
            const diskname = font.diskname.replace(
                font.diskname.substr(
                    font.diskname.lastIndexOf('.')), '');
            const extension = font.diskname.replace(/^.*\./, '');

            const defaultGroup = {
                diskname,
                fonts: [],
                weight: 0,
                family: '',
                extensions: [],
            };

            let index = findItem(group, (font) => font.diskname === diskname);

            if (index < 0) {
                index = group.length;
            }

            group[index] = group[index] || defaultGroup;
            group[index].fonts.push(font);
            group[index].weight = font.weight;
            group[index].family = font.family;
            group[index].extensions.push(extension);

            return group;
        }, []);
    }

    function findItem(arr, cb)
    {
        let index = arr.length;

        while(index) {

            --index;

            if (cb(arr[index], index)) {
                return index;
            }
        }

        return -1;
    }
    
    function checkConfig (config, fontFamilyMap)
    {
        angular.forEach(config, function (value)
        {
            if (angular.isObject(value)) {

                if (value.typeface) {
                    const fontFamily = fontFamilyMap[value.typeface];

                    if (!fontFamily) {
                        value.typeface = null;
                        value.weight = 'Inherit';
                    } else if (!fontFamily[value.weight]) {
                        value.weight = Object.keys(currentTheme.getFontWeights(value.typeface))[0];
                    }
                }

                checkConfig(value, fontFamilyMap, currentTheme);
            }

            if (angular.isArray(value)) {
                for (let i = 0; i < value.length; ++i) {
                    if (angular.isObject(value[i])) {
                        checkConfig(value, fontFamilyMap, currentTheme);
                    }
                }
            }

        });
    }
});
