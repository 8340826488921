'use strict';

angular.module('presentation.theme')

.directive('themeColourSelect', function ()
{
    return {
        restrict: "E",
        replace: true,
        templateUrl: 'app/presentation/theme/theme.builder/colours/colour-select/colour-select.html',
        scope: {
            palette: "=",
            colorKeyBinding: "="
        },
        controller: function ($scope) {

            $scope.setColorKey = function (key) {
                $scope.colorKeyBinding = key;
            };

            $scope.isTransparent = function ()
            {
                return $scope.colorKeyBinding === 'transparent';
            };

            $scope.getStyle = function () {

                return {
                    backgroundColor: $scope.palette[$scope.colorKeyBinding]
                };
            };
        }
    };
});
