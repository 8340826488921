'use strict';

angular.module('link-component')

.controller('hyperlinkEditModalCtrl', function (
    $scope,
    $rootScope,
    $http,
    $uibModalInstance,
    notify,
    presentationState,
    slideModal,
    href,
    target,
    inner,
    objectDefaults,
    ChapterSlide,
    options,
    Asset,
    $window
) {
    var init = function ()
    {
        $scope.options = options || {};

        objectDefaults($scope.options, {
            enablePopupsCreation: true
        });

        $scope.offline = $rootScope.offline;

        let slides = [];
        let selectedInner = null;

        if (presentationState.presentation) {
            slides = presentationState.presentation.getChapterSlides();
            selectedInner = slides.find((chapterSlide) => {
                return chapterSlide.press_unique === inner;
            });
        }

        $scope.data = {
            linkType: inner ? 'inner' : 'default',
            innerlink: selectedInner || slides[0],
            hyperlink: {
                target: href ? target : '_blank',
                href: href || '',
            },
            slides: slides
        };

        $scope.linkTypes = {
            default: 'Hyperlink',
            assetFileLink: 'Asset Link'
        };

        if (!$rootScope.offline && $scope.options.enablePopupsCreation) {
            $scope.linkTypes.popup = 'Popup';
        }

        if (slides.length) {
            $scope.linkTypes.inner = 'Inner Link';
        }

        if (inner) {
            var assetFileMatch = inner.match(/asset-file-([0-9]+)/);

            if (assetFileMatch && assetFileMatch[1]) {
                $scope.data.assetLink = href;
                $scope.data.hyperlink.href = '#';
                $scope.data.linkType = 'assetFileLink';
                $scope.data.assetId = assetFileMatch[1];
            }
        }

        $scope.$watch('data.assetId', function () {
            var id = Number($scope.data.assetId);

            if (id) {
                Asset.find($scope.data.assetId).then(function(res) {
                    $scope.data.assetName = res.name;
                });
            } else {
                $scope.data.assetName = '';
            }
        });

    };

    $scope.ok = function ()
    {
        if ($scope.data.linkType === 'default') {
            if ($scope.data.hyperlink.href.trim() === '') {
                notify.alert('Please remember to add link');
                return;
            }
            $scope.data.hyperlink['data-inner'] = '';
            $scope.data.hyperlink['class'] = '';

            $uibModalInstance.close($scope.data.hyperlink);
        }

        if ($scope.data.linkType === 'inner') {
            const finish = () => {
                $uibModalInstance.close({
                    href: '#',
                    target: '_self',
                    'data-inner': $scope.data.innerlink.press_unique,
                    class: 'slide-inner-link'
                });
            };

            if ($scope.data.innerlink.press_unique) {
                finish();
            } else {
                const uri = '/api/chapterSlides/' + $scope.data.innerlink.id + '/ensurePressUnique';
                $http.post(uri).then((response) => {
                    ChapterSlide.inject(response.data);
                    finish();
                });
            }
        }

        if ($scope.data.linkType === 'assetFileLink') {
            removeMediaPickerClass();

            Asset.find($scope.data.assetId).then(function(res) {
                $scope.data.assetLink = res.links.download;
                $uibModalInstance.close({
                    href: res.links.download,
                    'target': '_blank',
                    'data-inner': 'asset-file-' + $scope.data.assetId,
                });
            });
        }
    };

    $scope.cancel = function ()
    {
        $uibModalInstance.dismiss();
    };

    $scope.close = () => {
        removeMediaPickerClass();
        $uibModalInstance.dismiss();
    };

    $scope.createSlideOverlay = function ()
    {
        slideModal.open()
            .result.then($uibModalInstance.close);
    };

    // Fix for disabled scroll after additional modal open
    function removeMediaPickerClass () {
        var body = $window.document.getElementsByTagName('body')[0];
        body.classList.remove('modal-open');
    }

    init();
});
