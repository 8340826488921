'use strict';

angular.module('presentation.theme')

.config(function (ThemeValidatorProvider)
{
    ThemeValidatorProvider.add(function(config) {
        const sidebar = config.colors.presentationSidebar;

        const colourFields = [
            {key: 'sidebar'},
            {key: 'sidebarSecond'},
        ];

        return [{
            section: 'colours',
            fields: colourFields,
            validKeys: 'colour',
            config: sidebar
        }];
    });
});
