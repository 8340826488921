'use strict';

angular.module('app')

.controller('PasswordsResetCtrl', function (
    $scope,
    $http,
    $uibModalInstance,
    Notification
) {
    $scope.resetPasswords = () =>
    {
        let data = {
            'last_password_reset_at': 'now'
        };

        $http.post('/api/app_settings/update', data).then((response) => {
            Notification.success('All users will be asked to reset password!');
            $uibModalInstance.close(response.data);
        });
    };
});
