'use strict';

angular.module('helpCentre')

.config(function ($stateProvider)
{
    $stateProvider
    .state('admin.help-centre', {
        abstract: true,
        url: '/help-centre',
        template: '<ui-view/>',
        controller: 'HelpCentreCtrl',
        resolve: {
            categories: function($http) {
                // var fileUrl = 'help-centre/config.json';
                var fileUrl = 'help-centre/config';
                // var fileUrl = 'api/help-centre-config';

                return $http.get(fileUrl).then(function (response) {
                   return response.data;
                });
            }
        },
        onEnter: function($window) {
            $window.scrollTo(0, 0);
        }
    });

    $stateProvider
    .state('admin.help-centre.home', {
        url: '/',
        templateUrl: 'app/help-centre/views/helpCentreHome.html',
    });

    $stateProvider
    .state('admin.help-centre.article', {
        url:  '/:category/:slug',
        templateUrl: 'app/help-centre/views/helpCentreArticle.html',
        controller: 'HelpCentreArticleCtrl',
        resolve: {
            articleContent: function ($stateParams, $http) {
                // var fileUrl = 'help-centre/content/' + $stateParams.category + '/' + $stateParams.slug + '/config.json';

                var fileUrl = 'help-centre/content/' + $stateParams.category + '/' + $stateParams.slug + '/config';

                return $http.get(fileUrl).then(function (response) {
                   return response.data;
                });
            }
        }
    });
});
