'use strict';

angular.module('presentation.theme')

.factory('themeConfigMarginals', function (themeConfigTypography)
{
    return {
        enabled: true,
        slide: [
            {top: '87px'},
            {right: '50px'},
            {bottom: '15%'},
            {left: '50px'},
        ],
        headerFooterStyle: {
            position: 'header',
            leftSide: 'chapterName',
            middle: 'presentationName',
            rightSide: 'pageNo',
        },
        logo: {
            lightAssetId: null,
            darkAssetId: null,
            position: {
                label: 'Top left',
                propertyX: 'left',
                propertyY: 'top',
                valueX: 0,
                valueY: 0
            },
            width: 40
        },
        textStyle: angular.copy(themeConfigTypography.textStyleItem),
    };
});
