'use strict';

angular.module('presentation.theme')

.directive('themeTextDefaults', function ()
{
    return {
        restrict: "E",
        templateUrl: 'app/presentation/theme/theme.builder/typography/text-defaults/text-defaults.html',
        scope: {
            model: "=",
        },
        bindToController: true,
        controllerAs: '$ctrl',
        controller: function ()
        {
            this.$onInit =  () =>
            {
                this.themeDefaultFont = this.model.config.typography.defaultFont;
                this.refreshWeights();
            };

            this.refreshWeights = () =>
            {
                this.weights = this.model.getFontWeights(this.themeDefaultFont.typeface);
            };

            this.setDefaultTypeface = (fontFamily) =>
            {
                this.themeDefaultFont.typeface = fontFamily.name;
                this.setDefaultWeight(Object.keys(fontFamily.weights)[0]);
            };

            this.setDefaultWeight = (weight) =>
            {
                this.themeDefaultFont.weight = weight;
            };
        }
    };
});