'use strict';

angular.module('app')

.controller('stateNameActiveCtrl', function ($scope, $element, $rootScope, $state)
{
    if (!angular.isArray($scope.stateNames)) {
        throw "state-name-active directive expects an array!";
    }

    function onInit ()
    {
        isStateActive($scope.stateNames);
    }

    function isStateActive (stateArray)
    {
        var action = includesSate(stateArray) ? 'add' : 'remove';

        $element[ action + 'Class']('active');
    }

    function includesSate (stateArray)
    {
        for (var idx = 0; idx < stateArray.length; idx++) {

            var stateName = stateArray[idx];

            if ($state.includes(stateName)) {
                return true;
            }
        }

        return false;
    }

    $rootScope.$on('$stateChangeSuccess', function ()
    {
        isStateActive($scope.stateNames);
    });

    onInit();
})

.directive('stateNameActive', function ()
{
    return {
        restrict: 'A',
        scope: {
            stateNames: '=stateNameActive'
        },
        controller: 'stateNameActiveCtrl',
    };
});
