'use strict';

angular.module('app')

/**
 * An interface for themes
 */
.factory('ThemeConfig', function (tinycolor)
{
    /**
     * Constructor
     *
     */
    function Theme (themeColourObj)
    {
        this.palette = keyToProperCase(themeColourObj);
    }

    /**
     * List all Hex values in theme
     * @return Array
     */
    Theme.prototype.listColours = function ()
    {
        var list = [];
        for (var key in this.palette) {
            list.push(this.palette[key]);
        }

        return list;
    };

    /**
     * List all colour names in theme
     * @return {Array}
     */
    Theme.prototype.listColourNames = function ()
    {
        return Object.keys(this.palette);
    };

    /**
     * Get single hex value from theme by name
     * @param {String}
     * @returns {String}
     */
    Theme.prototype.getColour = function (colourName, opacity, defaultValue)
    {
        var colour;

        colourName = colourName.toProperCase();

        if (!colourName && this.palette[colourName]) {
            return defaultValue;
        }

        if (opacity) {

            colour = tinycolor(this.palette[colourName]);
            colour.setAlpha(opacity);
            colour = colour.toRgbString();

        } else {

            colour = this.palette[colourName];
        }

        return colour;
    };

    /**
     * Get object containing specified colours
     * @param {Array}
     * @returns {Object}
     * {
     *     'Black' : '#000000'
     * }
     */
    Theme.prototype.customColoursObject = function (colourArr)
    {
        var colours = {};

        for (var i = 0; colourArr.length > i; i++) {
            var colour = colourArr[i].toProperCase();

            if (this.palette[colour]) {
                colours[colour] = this.palette[colour];
            }
        }

        return colours;
    };

    /**
     * Invert the key and value of an object
     * @param {Array}
     * @returns {Object}
     * {
     *     '#000000' : 'Black'
     * }
     */
    Theme.prototype.customColoursObjectInverted = function (colourArr)
    {
        var obj = this.customColoursObject(colourArr);

        var newObj = {};
        var keys = Object.keys(obj);

        for (var i = 0; keys.length > i; i++) {
            newObj[obj[keys[i]]] = keys[i];
        }

        return newObj;
    };

    /**
     * Get list containing specified colours
     * @param {Array}
     * @returns {Array}
     */
    Theme.prototype.customColoursList = function (colourNames)
    {
        var list = [];

        for (var i = 0; colourNames.length > i; i++) {
            var colour = colourNames[i];

            if (this.palette[colour]) {
                list.push(this.palette[colour]);
            }
        }

        return list;
    };

    /**
     * Panel component name changes per theme
     *
     */
    Theme.prototype.getPanelName = function ()
    {
        return 'Panel';
    };

    /**
     * Modify the editor options to suit this theme
     *
     */
    Theme.prototype.editorOptions = function (options)
    {
        // jshint unused:false
    };

    /**
     * Modify the slide config to suit this theme
     *
     */
    Theme.prototype.slideConfig = function (options)
    {
        // jshint unused:false
    };

    /**
     * Modify the presentation config to suit this theme
     *
     */
    Theme.prototype.presentationConfig = function (options)
    {
        // jshint unused:false
    };

    /**
     * Modify the map Config to suit this theme
     *
     */
    Theme.prototype.mapConfig = function (options)
    {
        // jshint unused:false
    };

    /**
     * Modify the map regions Config to suit this theme
     *
     */
    Theme.prototype.mapRegionsConfig = function (options)
    {
        // jshint unused:false
    };

    /**
     * Modify the general application config
     *
     */
    Theme.prototype.appConfig = function (options)
    {
        // jshint unused:false
    };

    /**
     * Modify the preloader to suit this theme
     *
     */
    Theme.prototype.preloader = function (options)
    {
        // jshint unused:false
    };

    /**
     * Modify the chart theme to suit this theme
     *
     */
    Theme.prototype.chartThemeConfig = function (options)
    {
        // jshint unused:false
    };

    /**
     * Modify the modal config to suit this theme
     *
     */
    Theme.prototype.modalThemeConfig = function (options)
    {
        // jshint unused:false
    };

    /**
     * Modify the swatch colors to suit this theme
     *
     */
    Theme.prototype.swatchColorsConfig = function (options)
    {
        // jshint unused:false
    };

    /**
     * Modify the region options to suit this theme
     *
     */
    Theme.prototype.regionOptions = function (options)
    {
        return options;
    };

    // jshint freeze:false
    String.prototype.toProperCase = function ()
    {
        return this.replace(/\w\S*/g, function (txt)
        {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    };

    /**
     * Private Functions
     *
     */
    function keyToProperCase (obj)
    {
        var newObject = {};

        for (var key in obj) {
            newObject[key.toProperCase()] = obj[key];
        }

        return newObject;
    }

    return Theme;
});
