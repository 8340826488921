'use strict';

angular.module('app')

.service('defaultEditorOptions', function (
    editorDefaultOptions,
    extendDeep,
    defaultConfigCache,
    $rootScope,
    $document,
    kdGate,
    IsMobile,
    stripPopups,
    Notification,
    presentationState,
    $q
){
    // Why doesn't this extend one level from editorDefaultOptions?
    const options = {
        trackHistoryOptions: {
            enabled: kdGate.allows('editor-undo-redo'),
            namespace: 'model',
            trackerLimit: 100,
            scroll: {
                enabled: false,
                offset: 0
            }
        },
        pastePlainText: false,
        softReturn: true,
        moveDelete: true,
        highlightText: true,
        textFormatting: 'dropdown',
        showBreadcrumbs: true,
        componentOptionsContainer: null,
        sidebarIcons: {
            delete: 'images/sidebar/delete.svg',
            deleteCorner: 'images/sidebar/delete-corner.svg',
            copy: 'images/sidebar/copy.svg',
            copyCorner: 'images/sidebar/copy-corner.svg',
        },
        backwardsCompatibility: extendDeep({}, editorDefaultOptions.backwardsCompatibility, {
            'bg-image': function (model) {
                model.type = 'bg-targeted-image';
            },
            image: function (model) {
                model.type = 'targeted-image';
            },
        }),
        onPasteComponent: function (component)
        {
            return $q.resolve(stripPopups(component));
        },
        onCopyComponent: function ()
        {
            Notification.success('Component has been copied to clipboard!');
        },
        nestableComponents: {
            /*
            The [image] component has been deprecated and
            replaced by the [targeted-image] component
            */
            editor: {
                components: {
                    // Structural components
                    column: 'Column',
                    grid: 'Grid',
                    table: 'Table',

                    // Content component
                    'targeted-image': 'Image',
                    'client-logo': 'Client logo',
                    'video-player': 'Video',

                    // Style components
                    'comp-panel': 'Panel',

                    // Special components
                    'high-map': 'Map',
                    chart: 'Chart',
                    'ae-animation': 'Animation',
                    'i-frame': 'Iframe',
                },
                back_layer: {
                    'bg-targeted-image': 'Image',
                    'video-player': 'Video',
                    'ae-animation': 'Animation',
                    'i-frame': 'Iframe',
                }
            },
            column: {
                nested: {
                    // Structural components
                    column: 'Column',
                    grid: 'Grid',
                    table: 'Table',

                    // Content component
                    textblock: 'Text',
                    bullets: 'Bullets',
                    'targeted-image': 'Image',
                    'client-logo': 'Client logo',
                    'video-player': 'Video',

                    // Style component
                    'comp-panel': 'Panel',
                    'shape-line': 'Line',

                    // Special components
                    chart: 'Chart',
                    'high-map': 'Map',
                    'ae-animation': 'Animation',
                    'i-frame': 'Iframe',
                    tabs: 'Tabs',
                }
            },
            'slide-info': {
                nested: {
                    // Content component
                    textblock: 'Text'
                }
            },
            grid: {
                nested: {
                    // Structural component
                    column: 'Column',
                    table: 'Table',

                    // Content component
                    textblock: 'Text',
                    bullets: 'Bullets',
                    'targeted-image': 'Image',
                    'client-logo': 'Client logo',

                    // Style component
                    'comp-panel': 'Panel',
                    'shape-line': 'Line'
                }
            },
            'comp-panel': {
                nested: {
                    // Structural components
                    column: 'Column',
                    grid: 'Grid',
                    table: 'Table',

                    // Content component
                    textblock: 'Text',
                    bullets: 'Bullets',
                    'targeted-image': 'Image',
                    'client-logo': 'Client logo'
                }
            },
            bullets: {
                nested: {
                    // Content component
                    textblock: 'Text',
                    bullets: 'Bullets',
                    'targeted-image': 'Image',
                    'client-logo': 'Client logo'
                }
            },
            'targeted-image': {
                overlay: {
                    // Content component
                    column: 'Column',
                    textblock: 'Text',
                    bullets: 'Bullets',
                    'targeted-image': 'Image',
                    'client-logo': 'Client logo',
                    'ae-animation': 'Animation',

                    // Style component
                    'comp-panel': 'Panel'
                },
                markers: {},
            },
            table: {
                nested: {
                    // Structural component
                    column: 'Column',

                    // Content component
                    textblock: 'Text',
                    bullets: 'Bullets',
                    'targeted-image': 'Image',
                    'client-logo': 'Client logo'
                }
            },
            'high-map': {
                nested: {
                    'floating-box': 'Floating box'
                }
            },
            'floating-box': {
                nested: {
                    textblock: 'Text',
                    bullets: 'Bullets'
                }
            },
            tabs: {
                nested: {
                    // Structural components
                    column: 'Column',
                    grid: 'Grid',
                    table: 'Table',

                    // Content component
                    'targeted-image': 'Image',
                    'client-logo': 'Client logo',
                    'video-player': 'Video',

                    // Style components
                    'comp-panel': 'Panel',

                    // Special components
                    'high-map': 'Map',
                    chart: 'Chart',
                    'ae-animation': 'Animation',
                }
            }
        },
        componentClasses: {
            textblock: {
                title: 'Alignment',
                default: ' ',
                classes: {
                    ' ' : 'Default align',
                    'align-left': 'Left align',
                    'align-center': 'Centre align',
                    'align-right': 'Right align',
                    'align-justify': 'Justify align',
                },
                icons: {
                    ' ' : 'material-icon material-icon-clear',
                    'align-left': 'material-icon material-icon-format-align-left',
                    'align-center': 'material-icon material-icon-format-align-center',
                    'align-right': 'material-icon material-icon-format-align-right',
                    'align-justify': 'material-icon material-icon-subject',
                }
            },
            'comp-panel': {
                title: 'Panel style',
                default: ' ',
                classes: {
                    ' ': 'Container',
                    'panel-circle': 'Circle'
                }
            },
            bullets: {
                title: 'Bullet style',
                default: 'circles',
                classes: {
                    'list-unstyled': 'None',
                    circles: 'Circles',
                    numeric: 'Numeric'
                }
            },
            grid: {
                default: ' ',
                classes: {
                    ' ': 'Centre align',
                    'left-align': 'Left align'
                },
                icons: {
                    ' ' : 'material-icon material-icon-format-align-center',
                    'left-align': 'material-icon material-icon-format-align-left'
                }
            },
            'targeted-image': {
                default: 'left',
                classes: {
                    left: 'Left align',
                    middle: 'Centre align',
                    right: 'Right align'
                },
                imgIcons: {
                    'left': 'images/sidebar/alignment/left.svg',
                    'middle': 'images/sidebar/alignment/centre.svg',
                    'right': 'images/sidebar/alignment/right.svg'
                }
            },
        },

        componentOptions: extendDeep({},
            editorDefaultOptions.componentOptions,
            {
                // Default editor components overrides
                bullets: {
                    margin: kdGate.allows('slide.edit-limited'),
                    componentControls: {
                        link: {
                            title: 'LINK',
                            template: '<div sidebar-link></div>',
                            show: () => {
                                return true;
                            }
                        },
                        margins: {
                            show: () => {
                                return kdGate.allows('slide.edit-limited');
                            }
                        }
                    }
                },
                textblock: {
                    margin: kdGate.allows('slide.edit-limited'),
                    componentControls: {
                        link: {
                            title: 'LINK',
                            template: '<div sidebar-link></div>',
                            show: () => {
                                return true;
                            }
                        },
                        margins: {
                            show: () => {
                                return kdGate.allows('slide.edit-limited');
                            }
                        },
                    }
                },
                table: {
                    margin: kdGate.allows('slide.edit-limited'),
                    icons: {
                        mergeRight: 'images/sidebar/table/merge-across.svg',
                        mergeDown: 'images/sidebar/table/merge-below.svg',
                        unmerge: 'images/sidebar/table/unmerge-horizontal.svg',
                    },
                    componentControls: {
                        link: {
                            title: 'LINK',
                            template: '<div sidebar-link></div>',
                            show: () => {
                                return true;
                            }
                        },
                        margins: {
                            show: () => {
                                return kdGate.allows('slide.edit-limited');
                            }
                        }
                    }
                },
                tabs: {
                    icons: {
                        left: 'images/sidebar/tabs/left.svg',
                        right: 'images/sidebar/tabs/right.svg',
                        centre: 'images/sidebar/tabs/centre.svg',
                    }
                },
                'video-player': {
                    placeholderImage: 'images/placeholder-video-player.svg',
                    fullscreenDisabled: true,
                    allowedTags: {},
                    forcePoster: IsMobile,
                    componentControls: {
                        overlay: {
                            show: function () {
                                return presentationState.currentLayer !== 'FrontLayer';
                            }
                        }
                    }
                },
                // Custom platform component
                column: {
                    niceName: 'COLUMN',
                    icon: 'material-icon material-icon-view-column',
                    templateUrl: 'scripts/editorComponents/column/columnOptions.html',
                    margin: kdGate.allows('slide.edit-limited'),
                    allowedTags: {},
                    useIcons: true,
                    config: [
                        {
                            name: 'One column',
                            value: [12],
                            icon: 'images/sidebar/column/1.svg',
                        },
                        {
                            name: 'Two column',
                            value: [6, 6],
                            icon: 'images/sidebar/column/2.svg',
                        },
                        {
                            name: 'Three column',
                            value: [4, 4, 4],
                            icon: 'images/sidebar/column/3.svg',
                        },
                        {
                            name: 'Four column',
                            value: [3, 3, 3, 3],
                            icon: 'images/sidebar/column/4.svg',
                        },
                        {
                            name: '1/3 column',
                            value: [4, 8],
                            icon: 'images/sidebar/column/one-third.svg',
                        },
                        {
                            name: '2/3 column',
                            value: [8, 4],
                            icon: 'images/sidebar/column/two-third.svg',
                        }
                    ],
                    componentControls: {
                        formatting : {
                            title: 'FORMATTING',
                            template: '<div sidebar-column-formatting></div>',
                            show: () => {
                                return kdGate.allows('slide.edit-limited');
                            }
                        },
                        margins : {
                            title: 'MARGINS',
                            template: '<div sidebar-margin-controls></div>',
                            show: () => {
                                return kdGate.allows('slide.edit-limited');
                            }
                        },
                        options : {
                            title: 'OPTIONS',
                            template: '<div sidebar-column-options></div>',
                            show: () => {
                                return true;
                            }
                        },
                    }
                },
                'comp-panel': {
                    niceName: 'PANEL',
                    icon: 'material-icon material-icon-comp-panel',
                    templateUrl: 'scripts/editorComponents/compPanel/compPanelOptions.html',
                    allowedTags: {},
                    margin: kdGate.allows('slide.edit-limited'),
                    link: true,
                    height: false,
                    background: {
                        default: '#FFFFFF',
                        colors: {
                            '#FFFFFF': 'White',
                            '#002B59': 'Blue',
                            '#EB2617': 'Red',
                            '#77BF23': 'Green',
                            '#00AEA7': 'Light green',
                            '#F7610B': 'Orange',
                            '#FF9F00': 'Light orange',
                            '#3EB6E0': 'Light blue',
                            '#853F97': 'Purple'
                        }
                    },
                    componentControls: {
                        panelStyle : {
                            title: 'PANEL STYLE',
                            template: '<div sidebar-class-list></div>',
                            show: function () {
                                return true;
                            }
                        },
                        panelFormatting : {
                            title: 'PANEL FORMATTING',
                            template: '<div sidebar-panel-formatting></div>',
                            show: function ($scope) {
                                return !!$scope.enableHeightOption || !!$scope.enableClientColorOptions;
                            }
                        },
                        panelBackground : {
                            title: 'PANEL BACKGROUND',
                            template: '<div sidebar-panel-background></div>',
                            show: function ($scope) {
                                return !!$scope.backgroundColors;
                            }
                        },
                        margins : {
                            title: 'MARGINS',
                            template: '<div sidebar-margin-controls></div>',
                            show: () => {
                                return kdGate.allows('slide.edit-limited');
                            }
                        },
                        link : {
                            title: 'LINK',
                            template: '<div sidebar-link></div>',
                            show: () => {
                                return true;
                            }
                        },
                    }
                },
                'chapter-header': {
                    margin: kdGate.allows('slide.edit-limited'),
                    allowedTags: {
                        font: true,
                        b: true
                    }
                },
                grid: {
                    niceName: 'GRID',
                    icon: 'material-icon material-icon-grid',
                    templateUrl: 'scripts/editorComponents/grid/gridOptions.html',
                    margin: kdGate.allows('slide.edit-limited'),
                    allowedTags: {},
                    componentControls: {
                        formatting : {
                            title: 'FORMATTING',
                            template: '<div sidebar-grid-formatting></div>',
                            show: function () {
                                return true;
                            }
                        },
                        panelStyle : {
                            title: 'ALIGNMENT',
                            template: '<div sidebar-class-list></div>',
                            show: function () {
                                return true;
                            }
                        },
                        margins : {
                            title: 'MARGINS',
                            template: '<div sidebar-margin-controls></div>',
                            show: () => {
                                return kdGate.allows('slide.edit-limited');
                            }
                        },
                        options : {
                            title: 'OPTIONS',
                            template: '<div sidebar-grid-options></div>',
                            show: () => {

                                return true;
                            }
                        }
                    }
                },
                chart: {
                    niceName: 'CHART',
                    icon: 'material-icon material-icon-chart',
                    templateUrl: 'scripts/editorComponents/chart/chartOptions.html',
                    margin: kdGate.allows('slide.edit-limited'),
                    allowedTags: {},
                    componentControls: {
                        configure : {
                            title: undefined,
                            template: '<div sidebar-chart-configure></div>',
                            show: () => {
                                return true;
                            }
                        },
                        format : {
                            title: 'FORMATTING',
                            template: '<div sidebar-chart-formatting></div>',
                            show: () => {
                                return true;
                            }
                        },
                        options : {
                            title: 'OPTIONS',
                            template: '<div sidebar-chart-options></div>',
                            show: () => {
                                return true;
                            }
                        },
                        margins : {
                            title: 'MARGINS',
                            template: '<div sidebar-margin-controls></div>',
                            show: () => {
                                return kdGate.allows('slide.edit-limited');
                            }
                        }
                    }
                },
                'high-map': {
                    niceName: 'MAP',
                    icon: 'material-icon material-icon-high-map',
                    templateUrl: 'scripts/editorComponents/highMap/highMapOptions.html',
                    margin: kdGate.allows('slide.edit-limited'),
                    allowedTags: {},

                    componentControls: {
                        configure : {
                            title: undefined,
                            template: '<div sidebar-map-configure></div>',
                            show: () => {
                                return true;
                            }
                        },
                        formatting : {
                            title: 'FORMATTING',
                            template: '<div sidebar-map-formatting></div>',
                            show: () => {
                                return true;
                            }
                        },
                        margins : {
                            title: 'MARGINS',
                            template: '<div sidebar-margin-controls></div>',
                            show: () => {
                                return kdGate.allows('slide.edit-limited');
                            }
                        },
                        options : {
                            title: 'OPTIONS',
                            template: '<div sidebar-map-options></div>',
                            show: () => {
                                return true;
                            }
                        },
                    }
                },
                'bg-image': {
                    templateUrl: 'scripts/editorComponents/bgImage/bgImageOptions.html',
                    margin: kdGate.allows('slide.edit-limited'),
                    placeholderImage: 'images/placeholder-image.svg',
                    allowedTags: {},
                },
                'bg-targeted-image': {
                    niceName: 'IMAGE',
                    icon: 'material-icon material-icon-image',
                    templateUrl: 'scripts/editorComponents/bgTargetedImage/bgTargetedImageOptions.html',
                    margin: kdGate.allows('slide.edit-limited'),
                    placeholderImage: 'images/placeholder-image.svg',
                    allowedTags: {},
                    componentControls: {
                        asset : {
                            title: undefined,
                            template: '<div sidebar-image-picker></div>',
                            show: () => {
                                return true;
                            }
                        },
                        overlay : {
                            title: 'OVERLAY',
                            template: '<div sidebar-class-list></div>',
                            show: function () {
                                return true;
                            }
                        },
                        margins : {
                            title: 'MARGINS',
                            template: '<div sidebar-margin-controls></div>',
                            show: () => {
                                return kdGate.allows('slide.edit-limited');
                            }
                        },
                        options : {
                            title: 'OPTIONS',
                            template: '<div sidebar-bg-targeted-image-options></div>',
                            show: () => {

                                return true;
                            }
                        },
                    }
                },
                'shape-line': {
                    niceName: 'LINE',
                    icon: 'material-icon material-icon-shape-line',
                    templateUrl: 'scripts/editorComponents/shapeLine/shapeLineOptions.html',
                    margin: kdGate.allows('slide.edit-limited'),
                    defaultColor: '#3EB6E0',
                    defaultHeight: 5,
                    allowedTags: {},
                    componentControls: {
                        formatting : {
                            title: 'MARGINS',
                            template: '<div sidebar-shape-line-formatting></div>',
                            show: () => {
                                return true;
                            }
                        },
                        style : {
                            title: 'LINE STYLE',
                            template: '<div sidebar-shape-line-style></div>',
                            show: () => {
                                return true;
                            }
                        },
                        colour : {
                            title: 'COLOUR',
                            template: '<div sidebar-shape-line-colour></div>',
                            show: () => {
                                return true;
                            }
                        },
                        margins : {
                            title: 'MARGINS',
                            template: '<div sidebar-margin-controls></div>',
                            show: () => {
                                return kdGate.allows('slide.edit-limited');
                            }
                        },
                    }
                },
                'targeted-image': {
                    niceName: 'IMAGE',
                    icon: 'material-icon material-icon-image',
                    templateUrl: 'scripts/editorComponents/targetedImage/targetedImageOptions.html',
                    link: true,
                    resizable: true,
                    overlay: true,
                    margin: kdGate.allows('slide.edit-limited'),
                    placeholderImage: 'images/placeholder-image.svg',
                    allowedTags: {},
                    markers: true,
                    componentControls: {
                        asset : {
                            title: undefined,
                            template: '<div sidebar-image-picker></div>',
                            show: () => {
                                return true;
                            }
                        },
                        formatting : {
                            title: 'FORMATTING',
                            template: '<div sidebar-targeted-image-formatting></div>',
                            show: function () {
                                return true;
                            }
                        },
                        alignment : {
                            title: 'ALIGNMENT',
                            template: '<div sidebar-class-list></div>',
                            show: function () {
                                return kdGate.allows(`editor:component-targeted-image.class-list`);
                            }
                        },
                        margins : {
                            title: 'MARGINS',
                            template: '<div sidebar-margin-controls></div>',
                            show: () => {
                                return kdGate.allows('slide.edit-limited');
                            }
                        },
                        link : {
                            title: 'LINK',
                            template: '<div sidebar-link></div>',
                            show: () => {
                                return true;
                            }
                        },
                        options : {
                            title: 'OPTIONS',
                            template: '<div sidebar-targeted-image-options></div>',
                            show: function () {
                                return true;
                            }
                        }
                    }
                },
                'image-marker': {
                    niceName: 'IMAGE MARKER',
                    disableCopy: true,
                    link: true,
                    allowedTags: {},
                    componentControls: {
                        link : {
                            title: 'LINK',
                            template: '<div sidebar-link></div>',
                            show: () => {
                                return true;
                            }
                        },
                    }
                },
                'client-logo': {
                    niceName: 'CLIENT LOGO',
                    icon: 'material-icon material-icon material-icon-client-logo',
                    templateUrl: 'scripts/editorComponents/clientLogo/clientLogoOptions.html',
                    placeholderImage: 'images/placeholder-client-logo.svg',
                    resizable: true,
                    overlay: true,
                    margin: kdGate.allows('slide.edit-limited'),
                    allowedTags: {},
                    componentControls: {
                        formatting : {
                            title: 'FORMATTING',
                            template: '<div sidebar-client-logo-formatting></div>',

                            show: function () {
                                return true;
                            }
                        },
                        margins : {
                            title: 'MARGINS',

                            template: '<div sidebar-margin-controls></div>',
                            show: () => {
                                return kdGate.allows('slide.edit-limited');
                            }
                        }
                    }
                },
                'slide-info': {
                    templateUrl: 'scripts/editorComponents/slideInfo/slideInfoOptions.html',
                    margin: kdGate.allows('slide.edit-limited'),
                },
                'floating-box': {
                    niceName: 'FLOATING BOX',
                    templateUrl: 'scripts/editorComponents/floatingBox/floatingBoxOptions.html',
                    allowedTags: {},
                    componentControls: {
                        options : {
                            title: 'OPTIONS',
                            template: '<div sidebar-floating-box-options></div>',
                            show: () => {
                                return true;
                            }
                        }
                    }
                },
                'ae-animation': {
                    niceName: 'ANIMATION',
                    icon: 'material-icon material-icon-ae-animation',
                    templateUrl: 'scripts/editorComponents/aeAnimation/aeAminationOptions.html',
                    placeholderImage: 'images/placeholder-ae-animation.svg',
                    margin: kdGate.allows('slide.edit-limited'),
                    allowedTags: {},
                    componentControls: {
                        asset : {
                            title: undefined,
                            template: '<div sidebar-image-picker types="document" button-label="document"></div>',
                            show: () => {
                                return true;
                            }
                        },
                        margins : {
                            title: 'MARGINS',
                            template: '<div sidebar-margin-controls></div>',
                            show: () => {
                                return kdGate.allows('slide.edit-limited');
                            }
                        },
                        options : {
                            title: 'OPTIONS',
                            template: '<div sidebar-ae-animation-options></div>',
                            show: () => {
                                return true;

                            }
                        }
                    }
                },
                'i-frame': {
                    niceName: 'Iframe',
                    icon: 'material-icon material-icon-i-frame',
                    templateUrl: 'scripts/editorComponents/iFrame/iFrameOptions.html',
                    placeholderImage: 'images/placeholder-i-frame.svg',
                    margin: kdGate.allows('slide.edit-limited'),
                    allowedTags: {},
                    componentControls: {
                        url : {
                            title: 'URL',
                            template: '<div sidebar-iframe-url></div>',
                            show: () => {
                                return true;
                            }
                        },
                        poster : {
                            title: 'POSTER',
                            template: '<div sidebar-iframe-poster></div>',
                            show: () => {
                                return true;

                            }
                        },
                        formatting : {
                            title: 'FORMATTING',
                            template: '<div sidebar-iframe-formatting></div>',
                            show: () => {
                                return true;
                            }
                        },
                        margins : {
                            title: 'MARGINS',
                            template: '<div sidebar-margin-controls></div>',
                            show: () => {
                                return kdGate.allows('slide.edit-limited');
                            }
                        },
                    }
                },
                timeline: {
                    templateUrl: 'scripts/editorComponents/timeline/views/timelineOptions.html',
                    margin: kdGate.allows('slide.edit-limited'),
                    allowedTags: {},
                    background: {
                        default: '#FFFFFF',
                        colors: {
                            '#FFFFFF': 'White',
                            '#002B59': 'Blue',
                            '#EB2617': 'Red',
                            '#77BF23': 'Green',
                            '#00AEA7': 'Light green',
                            '#F7610B': 'Orange',
                            '#FF9F00': 'Light orange',
                            '#3EB6E0': 'Light blue',
                            '#853F97': 'Purple'
                        }
                    }
                }
            }
        ),

        backgroundColors: {
            '#FFFFFF': 'White',
            '#002B59': 'Blue',
            '#EB2617': 'Red',
            '#77BF23': 'Green',
            '#00AEA7': 'Light green',
            '#F7610B': 'Orange',
            '#FF9F00': 'Light orange',
            '#3EB6E0': 'Light blue',
            '#853F97': 'Purple'
        },

        beforeMove: function (pickup, target) {
            if (pickup.editor.property !== target.editor.property) {
                return false;
            }
        },
    };

    if ($rootScope.config && $rootScope.config.presentation && $rootScope.config.presentation.editor) {
        options.pastePlainText = $rootScope.config.presentation.editor.paste_plain;
        options.copyComponent =$rootScope.config.presentation.editor.copy_component;
    }

    this.get = function () {
        return defaultConfigCache('editorOptions', options);
    };
});
