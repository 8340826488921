'use strict';

angular.module('app')

.component('inputToggle', {
    require: 'ngModel',
    bindings: {
        off: '@',
        on: '@',
        ngModel: '='
    },
    controller: function ($element)
    {
        this.ngModelCtrl = $element.controller('ngModel');

        this.$onInit = function ()
        {
            $element.addClass('input-toggle');
        };

        this.updateNgModel = function (value)
        {
            this.ngModelCtrl.$setViewValue(value);
        };
    },
    template:
        '<span class="labels" id="input-toggle-on" ng-class="{\'active\': $ctrl.ngModelCtrl.$viewValue}"' +
                'ng-click="$ctrl.updateNgModel(true)">{{ $ctrl.on }}</span>' +
        '<input ng-model="$ctrl.ngModel" ng-change="$ctrl.updateNgModel($ctrl.ngModel)"' +
                'id="toggle-{{$id}}" type="checkbox">' +
        '<label for="toggle-{{$id}}"></label>' +
        '<span class="labels" id="input-toggle-off" ng-class="{\'active\': !$ctrl.ngModelCtrl.$viewValue}"' +
                'ng-click="$ctrl.updateNgModel(false)">{{ $ctrl.off }}</span>'
});
