'use strict';

angular.module('presentation.theme')

.directive('themeFont', function ()
{
    return {
        restrict: "E",
        templateUrl: 'app/presentation/theme/components/theme.font/theme.font.html'
    };
});
