'use strict';

angular.module('presentation.admin')

.factory('presentationViewOptions', function (
    extendDeep,
    resourceViewOptions,
    multiMoveAction,
    multiDeleteAction,
    moveSingleItemAction,
    editSingleItemAction,
    deleteSingleItemAction,
    shareAction,
    downloadAction,
    //previewAction,
    presentAction,
    cloneAction,
    printAction,
    deselectAction,
    gridNameTemplate,
    gridThumbTemplate,
    listNameTemplate,
    createAction,
    createFolderAction,
    kdGate,
    moment
) {
    const options = angular.copy(resourceViewOptions);
    const gatedCreateAction = angular.copy(createAction);
    const gatedCreateFolderAction = angular.copy(createFolderAction);

    const showCreateButton = function () {
        return kdGate.allows('presentation.create');
    };

    gatedCreateAction.show = showCreateButton;
    gatedCreateAction.template = '<i class="material-icon material-icon-add"></i> New presentation';
    gatedCreateFolderAction.show = showCreateButton;

    options.moment = moment;

    options.toolbar = [gatedCreateFolderAction, gatedCreateAction];

    options.actions = [
        deselectAction,
        multiDeleteAction,
        multiMoveAction,
    ];

    options.inlineActions = [
        presentAction,
        editSingleItemAction,
        shareAction,
    ];

    options.inlineDropdown = [
        downloadAction,
        printAction,
        cloneAction,
        moveSingleItemAction,
        deleteSingleItemAction
    ];

    options.gridActions = [
        ...options.inlineActions,
        ...options.inlineDropdown,
    ];

    options.sorts = [
        {
            label: "Type",
            sort: "type asc,name asc"
        }
    ];

    extendDeep(options, {
        tagging: true,
        taggingAdvanced: true,
        modelNameField: 'action',
        queryDefaults: {
            where: {
                folder_id: 0
            }
        },
        rowClassifier: function (row, classes) {
            classes['status-template'] = row.template;

            return classes;
        },
        onItemClick: function ($event, model)
        {
            $event.stopPropagation();

            model.goInto();
        },
        list: {
            columns: [
                {
                    label: 'Name',
                    name: 'name',
                    template: listNameTemplate('name', 'links.thumb', 'type'),
                    sortable: true
                },
                {
                    template: `<div class="resource-status"
                                    ng-class="{hidden: model.type == \'folder\' || !model.template}">
                                        <i class="glyphicon icon-status-template"></i>
                                </div>`,
                    sortable: false
                },
                {
                    label: 'Theme',
                    name: 'theme_id',
                    template: '<span>{{ model.theme.label }}</span>',
                    sortable: true
                },
                {
                    label: 'Client',
                    name: 'client_id',
                    template: '<span>{{ model.client.name }}</span>',
                    sortable: true
                },
                {
                    label: 'Shares',
                    name: 'shares_count',
                    template: `<span ng-hide="model.type == \'folder\' || model.shares_count < 1">
                                    <a href ui-sref=".share({id: model.id})">
                                        <u>{{ model.shares_count }}</u>
                                    </a>
                                </span>`,
                    sortable: false
                },
                {
                    label: 'Editable',
                    name: 'editable',
                    template: `<i title="{{ model.locked_by ? 'Locked By: ' + model.locked_by.name : null }}"
                                    ng-show="model.editable || model.locked_by"
                                    ng-class="{'material-icon-lock': model.locked_by , 'material-icon-check': model.editable}"
                                    class="material-icon "></i>`,
                    sortable: false
                },
                {
                    label: 'Last modified',
                    name: 'updated_at',
                    template: `<span class="no-wrap" uib-tooltip="{{ model.updated_at | date }}">
                                    {{ options.moment(model.updated_at).fromNow() }}
                                </span>`,
                    sortable: true
                },
                {
                    template: `<contextual-options actions="options.inlineActions"
                                                   dropdown-actions="options.inlineDropdown"
                                                   models="options.resourceList.selected"
                                                   model="model"
                                                   class="row-actions pull-right"></contextual-options>`
                }
            ],
        },
        grid: {
            fields: [
                {
                    template: `<div class="resource-lock-icon">
                                    <i title="{{ model.locked_by ? 'Locked By: ' + model.locked_by.name : null }}"
                                        ng-show="model.locked_by"
                                        class="material-icon material-icon-lock"></i>
                                </div>`,
                },
                {
                    template: `<div class="resource-status">
                                    <i class="glyphicon icon-status-template" ng-if="model.template"></i>
                                </div>`
                },
                {
                    template: gridThumbTemplate('thumb', 'type'),
                },
                {
                    template: `<div class="field-hidden">
                                    {{ model.format.join(", ") }}
                                </div>`,
                },
                {
                    template: gridNameTemplate('name', null),
                },
                {
                    template: `<contextual-options dropdown-actions="options.gridActions"
                                                   models="options.resourceList.selected"
                                                   model="model"
                                                   class="grid-actions pull-right"></contextual-options>`
                }
            ]
        }
    });

    return options;
});
