'use strict';

angular.module('presentation.admin')

.controller('DeleteLinkModalCtrl', function (
    $scope,
    $uibModalInstance,
    Share,
    share_id
) {

    $scope.delete = () =>
    {
        Share.destroy(share_id).then(() => {
            $uibModalInstance.close();
        });
    };
});
