'use strict';

angular.module('app')

.controller('usagePreviewCtrl', function (
    $scope,
    usage,
    model
) {
    $scope.usage = usage;
    $scope.model = model;
});
