'use strict';

angular.module('presentation.theme')

.factory('defaultTheme', function (Theme) {

    return () => {

        let themes = Theme.filter({is_default: true});

        if (!themes.length) {
            themes = Theme.filter();
        }

        return themes.length ? themes[0] : null;
    };
});
