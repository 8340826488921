'use strict';

angular.module('presentation.theme')

.directive('themeColourPalette', function (Notification)
{
    return {
        restrict: "E",
        templateUrl: 'app/presentation/theme/theme.builder/colours/colour-palette/colour-palette.html',
        scope: true,
        controller: function ($scope) {

            const defaultColor = "#FFFFFF";
            const permanentColours = ['transparent', 'light', 'dark'];

            $scope.colorNames = {
                custom: '',
                original: ''
            };

            // Backend treats empty objects as array
            // check if that's the case and convert
            if (angular.isArray($scope.model.config.colors.palette)) {
                $scope.model.config.colors.palette = {};
            }

            const resetColorPicker = function ()
            {
                $scope.ColorPickerValue = defaultColor;
            };

            const resetSelectedColor = function ()
            {
                $scope.selectedColor = null;
            };

            const getNextIndex = function ()
            {
                return "color" + (Object.keys($scope.model.config.colors.palette).length + 1);
            };

            this.$onInit = function ()
            {
                resetSelectedColor();
                resetColorPicker();
            };

            $scope.$watchCollection('ColorPickerValue', function() {
                if (!$scope.selectedColor && !$scope.colorNames.custom) {
                    $scope.colorNames.custom = getNextIndex();
                }
            });

            $scope.existAlready = function () {
                if ($scope.selectedColor && $scope.colorNames.original !== $scope.colorNames.custom) {
                    return false;
                }
                return Object.keys($scope.model.config.colors.palette).reduce((sum, colorName) => {
                    const newColour = $scope.ColorPickerValue.toUpperCase();
                    const oldColour = $scope.model.config.colors.palette[colorName].toUpperCase();
                    return newColour === oldColour ? sum + 1 : sum;
                }, 0);
            };

            $scope.addColor = function ()
            {
                let selectedColorName = $scope.colorNames.custom ? $scope.colorNames.custom : $scope.selectedColor;

                if ($scope.selectedColor) {
                    if ($scope.selectedColor !==  $scope.colorNames.custom) {
                        delete $scope.model.config.colors.palette[$scope.selectedColor];
                    }

                    Notification.info('On updating colour name, colour will need to be relinked with setting');
                    $scope.model.config.colors.palette[selectedColorName] = $scope.ColorPickerValue;
                    $scope.deselectHandler();
                    return;
                }

                const nextIndex = $scope.colorNames.custom ? $scope.colorNames.custom : getNextIndex();
                $scope.model.config.colors.palette[nextIndex] = $scope.ColorPickerValue;
                $scope.colorNames.custom = getNextIndex();
                resetColorPicker();
            };

            $scope.removeColor = function ()
            {
                if (permanentColours.indexOf($scope.selectedColor) > -1) {
                    Notification.warning('This colour can\'t be removed');
                    return;
                }

                delete $scope.model.config.colors.palette[$scope.selectedColor];
                resetSelectedColor();
                resetColorPicker();
            };

            $scope.selectColor = function (event, key)
            {
                event.stopPropagation();
                $scope.selectedColor = key;
                $scope.ColorPickerValue = $scope.model.config.colors.palette[key];
                $scope.colorNames.custom = $scope.selectedColor;
                $scope.colorNames.original = $scope.selectedColor;
            };

            $scope.deselectHandler = function ()
            {
                $scope.selectedColor = null;
                $scope.colorNames.custom = getNextIndex();
                resetColorPicker();
            };
        }
    };
});
