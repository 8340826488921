'use strict';

angular.module('editorComponents.timeline')

.factory('createTable', function (lodash, TimelineItem, moment)
{
    return function createTable (events, showAllMonths)
    {
        var eventObjects = createEvents(events);

        return {
            // Prepare top row
            top: groupMonths(eventObjects, showAllMonths),
            // Prepare bottom Row
            bottom: addYears(eventObjects, showAllMonths)
        };
    };

    function createEvents (events)
    {
        var list = [];

        angular.forEach(events, function (event)
        {
            var eventInstance = new TimelineItem('event', event.date);
            // Link $scope.model.data to instance
            eventInstance.data = event.data;
            eventInstance.style = event.style;

            list.push(eventInstance);
        });

        return list;
    }

    function groupMonths (events, showAllMonths)
    {
        return loopYears(events, function (monthInYear, key, list)
        {
            // Add year placeholder
            var placeholder = {
                type: 'placeholder'
            };

            // Don't add first placeholder, as displayed on timeline label
            if (list.length > 0) {
                list.push(placeholder);
            }

            var monthsGrouped = lodash.groupBy(monthInYear, 'month');

            angular.forEach(monthsGrouped, function (group, monthName)
            {
                var date = new Date(monthName + ' 1, ' + key);
                var month = new TimelineItem('month', date, 'MMM');

                month.children = group;

                list.push(month);
            });

            return list;
        }, showAllMonths);
    }

    function addYears (events, showAllMonths)
    {
        return loopYears(events, function (value, key, list)
        {
            var date = new Date('Jan 1, ' + key);

            // Don't add first year, as displayed on timeline label
            if (list.length > 0) {
                list.push(new TimelineItem('year', date, 'YYYY'));
            }

            for (var i = 0; i < value.length;i++) {
                list.push(value[i]);
            }

            return list;
        }, showAllMonths);
    }

    function loopYears (events, callback, showAllMonths)
    {
        var list = [];
        var years = lodash.groupBy(events, 'year');

        angular.forEach(years, function (months, year)
        {
            if (showAllMonths) {
                months = addAllMonths(months, year);
            }

            list = callback(months, year, list);
        });

        return lodash.sortBy(list, function (value) {
            return new Date(value.date);
        });
    }

    function addAllMonths (months, year)
    {
        var allMonths = moment.monthsShort();
        var currentMonths = months.map(function (event) {
            return event.month;
        });
        var monthsToAdd = lodash.difference(allMonths, currentMonths);

        for (var i = 0; monthsToAdd.length > i; i++) {
            var date = new Date(monthsToAdd[i] + ' 1, ' + year);
            months.push(new TimelineItem('placeholder', date));
        }

        return months;
    }
});
