'use strict';

angular.module('app')

.controller('2FaAuthSetupCtrl', function (
    $scope,
    $http,
    $rootScope,
    $document,
    data,
    force,
    twoFaSetup,
    auth) {

    this.data = data;
    this.force = force;

    this.step = 'qr';

    this.verify = {
        secret: this.data.secret,
        code: '',
        error : ''
    };

    this.verifyCode = () =>
    {
        return $http.post('api/two-fa/verify', this.verify).then(() =>
        {
            this.step = 'backup';
            this.verify.error = '';
        }).catch((response) =>
        {
            this.verify.error = response.data;
        });
    };

    this.finish = () =>
    {
        return $http.post('api/two-fa/save', this.verify).then(() =>
        {
            return auth.getUser().then(() =>
            {
                this.step = 'done';
            });
        });
    };

    this.downloadBackup = () =>
    {
        var element = $document[0].createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.data.backup));
        element.setAttribute('download', this.data.app + '-backup-code.txt');
        element.style.display = 'none';
        $document[0].body.appendChild(element);
        element.click();
        $document[0].body.removeChild(element);

        this.finish();
    };

    this.backToFirst = () => {
        $scope.$close();
        twoFaSetup.showModal(this.force);
    };
});
