'use strict';

angular.module('app')

.factory('multiMoveAction', function (moveAction)
{
    return angular.extend({}, moveAction, {
        name: 'multiMove',
        show: function (models)
        {
            return models.length > 1 && models.every(function (element) {
                return !element.deleted_at && element.editable !== false;
            });
        }
    });
});
