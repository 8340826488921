'use strict';

angular.module('datasheet.admin')

.controller('DatasheetEditCtrl', function (
    $scope,
    $stateParams,
    model,
    FileUploader,
    $cookies,
    baseApiUrl,
    $controller,
    $uibModalInstance,
    modelNameField,
    ajaxHeaders,
    notify
) {
    model.type = model.type || 'datasheet';
    model.folder_id = model.folder_id || $stateParams.folder_id;
    model.editable = angular.isUndefined(model.editable) ? true : model.editable;

    angular.extend(this, $controller('EditCtrl', {
        $scope: $scope, model: model, modelNameField: modelNameField, notify: notify
    }));

    $scope.$watch('uploader.queue.length', function (newValue) {
        $scope.showControl = newValue !== 0;
    });

    $scope.uploader = new FileUploader({
        url: baseApiUrl + 'datasheets/import',
        headers: angular.extend({
            'X-XSRF-TOKEN': $cookies.get('XSRF-TOKEN')
        }, ajaxHeaders),
        queueLimit: 1,
        autoUpload: true,
        onBeforeUploadItem: function (item) {
            item.formData = [{id: $scope.model.id}];
        },
        onErrorItem: function (item, response) {
            item.returnedErrors = response.file.join('\n');
        }
    });

    $scope.remove = function (fileItem) {
        fileItem.remove();
    };

    $scope.save = function (closeBool) {
        var close = closeBool || false;

        return $scope.model.DSCreate().then(function (model) {
            $scope.model = model;
            if (close) {
                $scope.$close(model);
            }
        });
    };

    $scope.cancel = function () {
        if (!$scope.isNew || !$scope.model.id) {
            $uibModalInstance.close();
            return;
        }

        // while creating new asset, 'cancel' should remove all created asset(s)
        $scope.model.DSDestroy().then(function () {
            $uibModalInstance.close();
        });
    };
});
