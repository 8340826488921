'use strict';

angular.module('komondor.multitenancy')

.factory('domainViewOptions', function (
    extendDeep,
    resourceViewOptions,
    multiDeleteAction,
    editSingleItemAction,
    deleteSingleItemAction,
    createAction,
    listView,
    loginAsAdminAction,
    settingsAction,
    deselectAction
) {
    const options = angular.copy(resourceViewOptions);
    const newCreateAction = angular.copy(createAction);
    newCreateAction.template = '<i class="material-icon material-icon-add"></i> New domain';

    options.toolbar = [newCreateAction];

    options.actions = [
        deselectAction,
        multiDeleteAction,
    ];

    options.inlineActions = [
        editSingleItemAction,
        loginAsAdminAction,
    ];

    options.inlineDropdown = [
        deleteSingleItemAction,
        settingsAction,
    ];

    options.gridActions = [
        ...options.inlineActions,
        ...options.inlineDropdown,
    ];

    extendDeep(options, {
        views: [listView],
        list: {
            columns: [
                {
                    label: 'Title',
                    name: 'title',
                    sortable: true
                },
                {
                    label: 'Domain Name',
                    name: 'name',
                    sortable: true
                },
                {
                    label: 'Custom Domain Name',
                    name: 'alias',
                    sortable: true
                },
                {
                    label: 'Logo',
                    name: 'logo',
                    template: `<i ng-if="::model.config.logo_asset_id"
                                    class="material-icon material-icon-check">
                                </i>`,
                    sortable: false
                },
                {
                    label: 'Login',
                    name: 'login',
                    template: `<i ng-if="::model.config.login_asset_id"
                                    class="material-icon material-icon-check">
                                </i>`,
                    sortable: false
                },
                {
                    label: 'Favicon',
                    name: 'favicon',
                    template: `<i ng-if="::model.config.favicon_asset_id"
                                    class="material-icon material-icon-check">
                                </i>`,
                    sortable: false
                },
                {
                    label: 'Max Users',
                    template: `<div>{{ model.config.max_users }}</div>`,
                    sortable: false
                },
                // {
                //     label: 'Analytics',
                //     name: 'analytics',
                //     template: `<div>{{ model.analytics || 'Managed by megasite' }}</div>`,
                //     sortable: false
                // },
                {
                    template: `<contextual-options actions="options.inlineActions"
                                                   dropdown-actions="options.inlineDropdown"
                                                   models="options.resourceList.selected"
                                                   model="model"
                                                   class="row-actions pull-right"></contextual-options>`
                }
            ]
        }
    });

    return options;
});
