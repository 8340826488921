'use strict';

angular.module('app')

.service('IconSearch', function (IconToken)
{
    this.search = function (input)
    {
        return IconToken.filter({
            where: {
                token: {
                    likei: input
                }
            }
        });
    };
});
