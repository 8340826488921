'use strict';

angular.module('presentation.frontend')

.animation('.transition-slide-down', function (slideTransitionConfig, tweenMax, $window, $rootScope)
{
    var config = slideTransitionConfig;

    var enter = function (element, done)
    {
        var windowHeight = $window.innerHeight;

        var fromOptions = {
            y: config.direction ? windowHeight : -windowHeight,
            force3D: true,
            immediateRender: true,
            onComplete: function ()
            {
                $rootScope.$broadcast('slideAnimationComplete');
                done();
            }
        };

        var toOptions = {
            y: 0
        };

        $rootScope.$broadcast('slideAnimationStart');

        var enterTween = tweenMax.fromTo(element, config.duration, fromOptions, toOptions);

        return function (isCancelled) {
            if (isCancelled) {
                enterTween.pause();
            }
        };
    };

    var leave = function (element, done)
    {
        var windowHeight = $window.innerHeight;

        var slides = element.parent().children();

        // Disable animation if there are more than 4 slides for smooth transition
        if (slides.length > 4) {
            done();
            return;
        }

        var options = {
            y: config.direction ? -windowHeight : windowHeight,
            force3D: true,
            immediateRender: true,
            onComplete: function ()
            {
                $rootScope.$broadcast('slideAnimationComplete');
                done();
            }
        };

        $rootScope.$broadcast('slideAnimationStart');

        var enterTween = tweenMax.to(element, config.duration, options);

        return function (isCancelled) {
            if (isCancelled) {
                enterTween.pause();
            }
        };
    };

    return {
        enter: enter,
        leave: leave,
    };
});
