'use strict';

angular.module('app')

.service('presenteeService', function ($window,
                                       $state,
                                       slideModal,
                                       Pusher,
                                       $cookies,
                                       $q,
                                       $timeout,
                                       $http,
                                       $rootScope,
                                       presenteeOverlay,
                                       handleSlideVideosService
) {

    this.link = null;
    this.state = {};

    this.channel = null;
    this.pusher = null;
    this.popup = null;
    this.me = null;
    this.started = false;

    this.reenabledAutoPlay = false;

    this.init = (link) => {

        this.link = link;

        presenteeOverlay.create(this.link.presentation, this.joinToPresentation);

        this.listen();

        $timeout(() => {
            presenteeOverlay.showElement();
            this.updateState(this.link.presenter_state);

            this.signIn();
        });

        $timeout(() => {
            handleSlideVideosService.preventAutoPlay(this.link.presenter_state.slide);
        });

        $rootScope.$on('$stateChangeSuccess', () =>
        {
            if (!this.started) {
                $timeout(() => {
                    handleSlideVideosService.endVideoPlay(this.link.presenter_state.slide);
                });
            }
        });
    };

    this.joinToPresentation = () => {

       let data = {
           'viewer': $cookies.get('viewerId'),
           'ready': true,
       };

        this.me = data;

        $http.post('/api/presenter/' + this.link.id + '/update-viewer', data).then((response) => {
            presenteeOverlay.setViewer(true);
            this.state = response.data;
        });
    };

    this.updateState = (newState) => {

        if(newState.in_use) {
            presenteeOverlay.checkIsOpenedByPresenter(newState.in_use.id);
        }

        handleSlideVideosService.removePresenteeControls();

        this.started = newState.started;

        if (this.started && this.me && this.me.ready) {
            presenteeOverlay.hideElement();

            let component = newState.component && newState.component.component === 'video-player' ? newState.component : '';

            if (component.action !== 'ended' && component.action !== 'pause' && !this.reenabledAutoPlay) {
                handleSlideVideosService.reEnableAutoPlay(newState.slide);
                this.reenabledAutoPlay = true;
            }
        }

        if(!this.started) {
           presenteeOverlay.showElement();
        }

        let oldChapter = this.state ? this.state.chapter : 1;
        let oldSlide = this.state ? this.state.slide : 1;

        //Go to needed slide
        if (newState.chapter !== oldChapter || newState.slide !== oldSlide) {
            $state.go('admin.editor.presentation.chapter.slide', {
                chapterOrder: newState.chapter || 1,
                slideOrder: newState.slide || 1
            }, {reload: 'admin.editor.presentation.chapter'});
        }

        //Open popup first if needed
        $timeout(() => {

            let oldPopup = this.state ? this.state.popup : {};
            let newPopup = newState ? newState.popup : {};

            if (!angular.equals(oldPopup, newPopup)) {

                if (oldPopup && this.popup) {
                    this.popup.dismiss();
                }

                if (newPopup && newPopup.id) {
                    this.popup = slideModal.open(newPopup.id, newPopup.slideSize, newPopup.slideColour);
                }
            }

            return $q.resolve();

        }).then(() => {

            //Then run any action on component..
            return $timeout(() => {

                let oldComponent = this.state ? this.state.component : {};

                if (!angular.equals(newState.component || {}, oldComponent)) {
                    if (newState.component && newState.component.component === 'tabs') {
                        this.tabAction(newState);
                    }

                    if (newState.component && newState.component.component === 'video-player') {
                        this.videoAction(newState);
                    }
                }

                this.state = newState;
            });
        });
    };

    this.tabAction = (data) => {

        $rootScope.$broadcast('component:tabs:action', {
            action: 'change-tab',
            tab_index: data.component.tab_index,
        });
    };

    this.videoAction = (data) => {
        $rootScope.$broadcast('component:video-player:action', {
            action: 'updateVolume',
            value: 0,
        });

        $rootScope.$broadcast('component:video-player:action', {
            action: data.component.action,
            value: data.component.value,
            asset_id: data.component.subject_id
        });
    };

    this.listen = () => {
        if (this.channel && this.channel.subscribed) {
            this.channel.unsubscribe();
        }

        this.pusher = this.pusher || new Pusher($rootScope.config.pusher.key, {
            cluster: $rootScope.config.pusher.cluster,
            auth: {
                headers: {
                    'X-XSRF-TOKEN': $cookies.get('XSRF-TOKEN'),
                }
            },
            encrypted: false,
            disableStats: true
        });

        this.channel = this.pusher.subscribe(this.link.channel);

        this.channel.bind('present.updated', (data) =>
        {
            this.updateState(data);
        });

        this.channel.bind('present.ping', (response) =>
        {
            if(response.presenter) {
                this.sayHello();
                this.saidHello = true;
                presenteeOverlay.setViewer(false);
            }
        });
    };

    this.sayHello = () => {
        this.signIn();
        $http.post('/api/presenter/' + this.link.id + '/ping', {viewerId: $cookies.get('viewerId')});
    };

    this.end = () => {

        if (this.channel && this.channel.subscribed) {
            this.channel.unsubscribe();
        }

        this.channel = null;

        presenteeOverlay.removeElement();
    };

    this.uuid = () => {
        return  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    };

    this.signIn = () => {
        let data = {
            'ready': false,
        };

        this.me = data;

        $http.post('/api/presenter/' + this.link.id + '/update-viewer', data).then((response) =>
        {
            presenteeOverlay.setViewer(false);
            this.state = response.data;
            $cookies.put('viewerId', response.data.viewer);
        });
    };
});
