'use strict';

angular.module('app')

.filter('default', function ()
{
    return function (value, defaultValue)
    {
        if (!angular.isUndefined(defaultValue) && !value) {
            return defaultValue;
        }

        return value;
    };
});
