'use strict';

angular.module('app').filter('truncate', function ()
{
    return function (input, length, suffix, preserve)
    {
        length = angular.isUndefined(length) ? input.length : length;
        preserve = preserve || false;
        suffix = suffix || '';

        if (!angular.isString(input) || input.length <= length) {
            return input;
        }

        return input.substring(0, preserve ? input.indexOf(' ', length) === -1 ? input.length : input.indexOf(' ', length)
            : length) + suffix;
    };
});