'use strict';

angular.module('app')

.factory('loginState', function ($rootScope)
{
    return function loginState()
    {
        if ($rootScope.config && $rootScope.config.saml.enabled) {
            return 'guest.login-sso';
        }

        return 'guest.login';
    };
});
