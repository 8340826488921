'use strict';

angular.module('app')

.config(function (resourceStateProvider, modalStateProvider)
{
    modalStateProvider
    .state('admin.slides.usage', {
        url: '/usage/{id}',
        controllerAs: '$ctrl',
        controller: 'masterUsageCtrl',
        templateUrl: 'scripts/resourceActions/masterUsageSingleItem/masterUsage.html',
        resolve: {
            model: ['Slide', 'modalState', function (Slide, modalState) {
                return Slide.findAll({
                    with: ['dependencies'],
                    where: {
                        id: {
                            '==': modalState.stateParams.id,
                        }
                    }
                }).then(function (model) {
                    modalState.model = model[0];
                    return modalState.model;
                });
            }],
        },
    });

});
