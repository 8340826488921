'use strict';

angular.module('app').controller('SlidePresenterCtrl', function (
    $rootScope,
    $scope,
    presenterService,
    moment,
    $interval,
    presentationState
) {

    $scope.presentationState = presentationState;
    $scope.slide = presentationState.slide;
    $scope.nextSlideModel = presentationState.nextSlideModel;
    $scope.nextChapterModel = presentationState.nextChapterModel;

    $scope.time_on_press = '00:00:00';
    $scope.time_on_slide = '00:00:00';

    this.$onInit = () => {

        this.instance = $interval(() =>
        {
            let now = moment().utc();

            if (presenterService.state.started_at) {

                let start = moment(presenterService.state.started_at).utc();

                $scope.time_on_press =  moment.utc(now.diff(start)).format('HH:mm:ss');
            }

            if (presenterService.state.slide_started_at) {

                let slideStart = moment(presenterService.state.slide_started_at).utc();

                $scope.time_on_slide =  moment.utc(now.diff(slideStart)).format('HH:mm:ss');
            }

        }, 1000); //1 Seconds
    };

    $scope.next = () => {
        presentationState.changeSlide(1);
    };

    $scope.prev = () => {
        presentationState.changeSlide(-1);
    };

    $scope.hasNext = () => {
        return !presentationState.hasSlide(1);
    };

    $scope.hasPrev = () => {
        return !presentationState.hasSlide(-1);
    };

    $scope.$on('$destroy', () => {
        $interval.cancel(this.instance);
    });
});
