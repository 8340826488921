'use strict';

angular.module('presentation.frontend')

.factory('slideTransitionConfig', function ()
{
    return {
        delay: 0,
        duration: 0.7,
        direction: true,
        changeDirection: function (modifier)
        {
            this.direction = modifier > 0;
        },
        options: [
            {
                id: 0,
                label: 'Fade In',
                class: 'transition-fade-in'
            },
            {
                id: 1,
                label: 'Slide Down',
                class: 'transition-slide-down'
            }
        ]
    };
});
