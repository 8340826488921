'use strict';

angular.module('app')

.factory('ChartComparisonPercentage', function (ChartConfigFactory)
{
    return function (chart) {

        return {
            chart: chart,
            texts: [],
            fontSize: 15,
            clean: function ()
            {
                // remove previous percentage labels
                angular.forEach(this.texts, function (item) {
                    item.element.remove();
                });

                this.texts = [];
            },
            switch: function (rects, color, chartType)
            {
                this.clean();

                var self = this;

                angular.forEach(rects, function (rect, id)
                {

                    // ignore last bar if it's single serie
                    // as there is nothing to compare against
                    if (rects[0].isSingleSerie && id === rects.length - 1) {
                        return;
                    }

                    var comparisonItemMetrics = ChartConfigFactory.calculateComparisonItemCenter(chart, rects, id, false);
                    var top = comparisonItemMetrics.y,
                        left = comparisonItemMetrics.x;

                    if (chartType === 'bar') {
                        top -= comparisonItemMetrics.width - 20;
                        left += 20;
                    } else {
                        top -= comparisonItemMetrics.height + 5;
                        left -= comparisonItemMetrics.width - 10;
                    }

                    self.texts.push(chart.renderer
                        .label(rect.diff, left, top)
                        .css({
                            color: color,
                            fontSize: self.fontSize +'px'
                        })
                        .add()
                    );

                });

            }

        };
    };
});
