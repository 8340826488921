'use strict';

angular.module('app')

.config(function (editorGatesProvider) {
   editorGatesProvider.setGates('slide-info');
})

.directive('slideInfo', function ($timeout, presentationState)
{
    var slideInfo = {
        restrict: 'A',
        require: ['^component', '^editor'],
        templateUrl: 'scripts/editorComponents/slideInfo/slideInfo.html',
        link: link,
    };

    function link ($scope, element, attributes, controllers)
    {
        $scope.presentationState = presentationState;
        $scope.data.nested = $scope.data.nested || [];

        $scope.data.allowNesting = $scope.data.allowNesting !== undefined ? $scope.data.allowNesting : true;
        $scope.data.showChapterNum = $scope.data.showChapterNum !== undefined ? $scope.data.showChapterNum : true;

        $scope.getTrackables = getTrackables;

        // $scope.data.showChapterName = ($scope.data.showChapterName !== undefined) ? $scope.data.showChapterName : true;
        // $scope.data.showSlideNum = ($scope.data.showSlideNum !== undefined) ? $scope.data.showSlideNum : true;
        // $scope.data.showSlideName = ($scope.data.showSlideName !== undefined) ? $scope.data.showSlideName : true;
        // $scope.data.showPresentationName = ($scope.data.showPresentationName !== undefined) ? $scope.data.showPresentationName : true;

        var componentCtrl = controllers[0];
        var editorCtrl = controllers[1];

        $scope.limited = editorCtrl.options.limited;

        $scope.chapterNum = 1;
        if (presentationState.chapter) {
            $scope.chapterNum = presentationState.chapterNum;
        } else {
            if (editorCtrl.model.chapter_slides.length) {
                $scope.chapterNum = editorCtrl.model.chapter_slides[0].chapter.display_order;
            }
        }

        componentCtrl.setFocusRequestHandler(function ()
        {
            $timeout(function () {
                $scope.$broadcast('component:request-focus', $scope.data.nested);
            });
        });

        function getTrackables ()
        {
            return {
                'data.allowNesting': {
                    deepWatch: false
                },
                'data.showChapterNum': {
                    deepWatch: false
                }
            };
        }
    }

    return slideInfo;
});
