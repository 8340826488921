'use strict';

angular.module('app')

.controller('AnalyticsSlideModalCtrl', function ($scope, $http, data) {

    this.chapter_slide = data.chapter_slide;
    this.data = data;

    this.videoLogs = this.data.logs.filter(item => item.component === 'video-player');
    this.tabLogs = this.data.logs.filter(item => item.component === 'tabs');
    this.popupLogs = this.data.logs.filter(item => item.component === 'popup');
    this.linkLogs = this.data.logs.filter(item => item.component === 'link' && item.subject_name !== '#');

    this.collapseState = {
        video: false,
        popup: false,
        tab: false,
        link: false
    };

});
