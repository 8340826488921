'use strict';

angular.module('floating-box-component', [
    'droppable',
    'ngKomondorEditor'
])

.config(function (editorGatesProvider) {
   editorGatesProvider.setGates('floating-box');
})

.directive('floatingBox', function ($timeout, Droppable, editorControls)
{
    return {
        restrict: 'A',
        replace: true,
        controller: 'FloatingBoxCtrl',
        require: ['^component', '^editor'],
        templateUrl: 'scripts/editorComponents/floatingBox/floatingBox.html',
        link: function ($scope, element, attributes, controllers)
        {
            var componentCtrl = controllers[0];
            var editorCtrl = controllers[1];
            var setDefaultPosition = !$scope.model.componentStyle;

            $scope.getTrackables = getTrackables;

            // Drag & drop setup
            var moveHandler,
                floatingBox = element.parent(),
                container = floatingBox.parent().parent();

            var handlerOptions = {
                container: container,
                percentage: true,
                stop: function (event, left, top)
                {
                    // Set css to model
                    $scope.model.componentStyle.left = left;
                    $scope.model.componentStyle.top = top;

                    $timeout(function ()
                    {
                        // Re-focus on object and show options
                        componentCtrl.select();
                        editorControls.show();
                    });
                }
            };

            var init = function ()
            {
                if (setDefaultPosition) {
                    floatingBox.addClass('before-show');
                }

                $timeout(function ()
                {
                    moveHandler = new Droppable(floatingBox, handlerOptions);

                    // Enable move since it's disabled for all components in the back layer
                    editorCtrl.options.move = true;

                    if (setDefaultPosition) {
                        moveHandler.setToCenter();
                        floatingBox.removeClass('before-show');
                    }
                });
            };

            /**
             * Floating box default options
             */
            $scope.defaultWidth =  300;
            $scope.defaultWidthUnit =  'px';
            $scope.allowedUnits = ['px', '%'];

            // Set defaults if styles not set
            $scope.model.componentStyle = $scope.model.componentStyle || {};
            $scope.model.componentStyle.width = $scope.model.componentStyle.width ||
                                                $scope.defaultWidth + $scope.defaultWidthUnit;

            componentCtrl.setFocusRequestHandler(function ()
            {
                $timeout(function ()
                {
                    $scope.$broadcast('component:request-focus', $scope.model.nested);
                });
            });

            // Override default method
            $scope.pickup = function ($event)
            {
                moveHandler.startMove($event);
                editorControls.hide();
            };

            // Get container width floating box sticked to
            $scope.getContainerWidth = function ()
            {
                return container[0].clientWidth;
            };

            init();

            function getTrackables ()
            {
                return {
                    'componentStyle.left': {
                        deepWatch: false,
                    },
                    'componentStyle.top': {
                        deepWatch: false,
                    },
                    'componentStyle.width': {
                        deepWatch: false,
                    },
                };
            }
        }
    };
})

.controller('FloatingBoxCtrl', function ($scope, $timeout)
{
    // Set defaults here
    var init = function ()
    {
        $timeout(function ()
        {
            setDefaultWidth();
        });
    };

    // Set default width value and unit
    function setDefaultWidth ()
    {
        var width = $scope.model.componentStyle.width;
        var widthValue = parseInt(width);
        var widthUnit = width.replace(/[^\D\.]/g, '');

        $scope.width = {
            value: widthValue ? widthValue : $scope.defaultWidth,
            unit: isUnitAllowed(widthUnit) ? widthUnit : $scope.defaultWidthUnit
        };
    }

    // Check if unit exist
    function isUnitAllowed (unit)
    {
        return $scope.allowedUnits &&
               angular.isArray($scope.allowedUnits) &&
               $scope.allowedUnits.indexOf(unit) !== -1;
    }

    // Get the next unit
    function getDifferentUnit (unit)
    {
        var index = $scope.allowedUnits && angular.isArray($scope.allowedUnits) ?
            $scope.allowedUnits.indexOf(unit) : -1;

        if (index === -1) {
            return $scope.defaultWidthUnit;
        }

        return index + 1 >= $scope.allowedUnits.length ?
            $scope.allowedUnits[0] : $scope.allowedUnits[index + 1];
    }

    $scope.setWidth = function (currentUnit)
    {
        var containerWidth = $scope.getContainerWidth();
        $scope.width.unit = currentUnit ? getDifferentUnit(currentUnit) : $scope.width.unit;

        if (isNaN($scope.width.value)) {
            $scope.width.value = $scope.defaultWidth;
        }

        if ($scope.width.unit === '%') {
            $scope.width.value = Math.min($scope.width.value, 100);
        } else if ($scope.width.value > containerWidth) {
            $scope.width.value = containerWidth;
        }

        $scope.model.componentStyle.width = $scope.width.value + $scope.width.unit;
    };

    init();
});
