'use strict';

angular.module('app')

.factory('ChartConfigFactory', function (defaultChartThemeConfig, numAbbrevFilter, tinycolor, isPhantom)
{
    // custom chart config
    //      - will be updated upon chart-options change
    //      - will be merged into $scope.chartConfig in chartController
    var chartThemeColors;

    var CustomConfig = function ()
    {
        return {
            theme: null,
            /**
             * customizable configs for highChart
             *
             * @type {Object}
             */
            config: {
                options: {
                    prefix: '',
                    suffix: '',
                    labelAbbreviation: false,
                    labelAbbreviationDecimals: 0,
                    legend: {
                        align: 'center',
                        enabled: true,
                        reversed: false,
                        itemStyle: {
                            fontSize: '1.2em'
                        }
                    },
                    chart: {
                        style: {
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            overflow: 'visible'
                        },
                        spacingRight: 10,
                        spacingTop: 10,
                        marginRight: null,
                        animation: !isPhantom
                    },

                    plotOptions: {
                        column: {
                            grouping: true
                        },
                        bar: {
                            grouping: true
                        },
                        series: {
                            animation: !isPhantom,
                            dataLabels: {
                                crop: false,
                                overflow: 'none',
                                allowOverlap: true,
                                style: {
                                    fontSize: '1.2em'
                                }
                            },
                        },
                        pie: {
                            dataLabels: {
                                format: '{point.percentage:.1f} %'
                            },
                            showInLegend: true,
                            borderWidth: 0
                        }
                    },
                    tooltip: {}
                },
                xAxis: {
                    labels: {
                        style: {
                            opacity: 1
                        }
                    },
                },
                yAxis: {
                    reversedStacks: false,
                    labels: {
                        style: {
                            opacity: 1
                        }
                    },
                }
            },
            /**
             * Get type of Chart(ONLY)
             *
             * @return {String}
             */
            getChartType: function ()
            {
                return this.config.options.chart.type;
            },

            /**
             * Update theme of chart.
             *
             * @param  {String} theme
             * @return {Void}
             */
            updateTheme: function (theme)
            {
                // Object with all colors config for charts
                const chartDefaultConfig = defaultChartThemeConfig.get();
                chartThemeColors = chartDefaultConfig.themes[theme].colors;

                this.theme = theme;
                this.config.options.legend.itemStyle.color = chartThemeColors.legend;

                this.config.options.plotOptions.series.dataLabels.color = chartThemeColors.plotOptions.series.dataLabels;
                this.config.options.plotOptions.pie.dataLabels.color = chartThemeColors.plotOptions.pie.dataLabels;
                this.config.options.plotOptions.pie.colors = chartThemeColors.series;

                this.config.xAxis.labels.style.color = chartThemeColors.xAxis.label;
                this.config.xAxis.lineColor = chartThemeColors.xAxis.line;

                this.config.yAxis.labels.style.color = chartThemeColors.yAxis.label;
                this.config.yAxis.lineColor = chartThemeColors.yAxis.line;

                this.config.xAxis.lineWidth = chartThemeColors.yAxis.lineWidth;
                this.config.yAxis.lineWidth = chartThemeColors.yAxis.lineWidth;
            },

            /**
             * Theme colors will be applied to each series(bar/column/...) of data in Chart.
             *
             * @param  {String} theme
             * @return {Void}
             */
            getThemeColors: function ()
            {
                return chartThemeColors.series;
            },
            getHighlightColor: function ()
            {
                return chartThemeColors.highlight;
            },
            getComparisonArrowColor: function ()
            {
                return chartThemeColors.arrows;
            },

            getComparisonTextColor: function ()
            {
                return chartThemeColors.percentage;
            },

            /**
             * Update type of Chart(ONLY).
             *
             *      NOTE: series.stacking is 'normal' but not (Boolean)true
             *
             * @param  {String} type    Type of chart. bar|bar-stack|column|column-stack|line|pie
             * @return {Void}
             */
            updateType: function (type)
            {
                switch (type) {
                    case 'bar-stack':
                        this.config.options.chart.type = 'bar';
                        this.config.options.plotOptions.series.stacking = 'normal';
                        this.config.options.plotOptions.series.dataLabels.inside = true;

                        break;

                    case 'column-stack':
                        this.config.options.chart.type = 'column';
                        this.config.options.plotOptions.series.stacking = 'normal';
                        this.config.options.plotOptions.series.dataLabels.inside = true;
                        break;

                    default:
                        this.config.options.chart.type = type;
                        this.config.options.plotOptions.series.stacking = undefined;
                        this.config.options.plotOptions.series.dataLabels.inside = false;
                        break;
                }

                // enable tooltip for pie chart
                if (type === 'pie') {
                    this.config.options.tooltip.enabled = true;
                    this.config.options.tooltip.pointFormat = '{series.name}: <b>{point.percentage:.1f}%</b>';
                // disable tooltip for other charts
                } else {
                    this.config.options.tooltip.enabled = false;
                }
            },

            ghostDataSeries: function  (ghostSeries, series)
            {
                if (!ghostSeries || series.length < 1 || !this.config.options.plotOptions.series.stacking) {
                    return;
                }

                series[0].color = 'transparent';
                series[0].showInLegend = false;
                series[0].dataLabels = {
                    enabled: false
                };

            },

            setLabelAbbreviation: function (abbr, decimals)
            {
                this.config.options.labelAbbreviation = abbr;
                this.config.options.labelAbbreviationDecimals = decimals;
            },

            setPrefixSuffix: function (prefix, suffix)
            {
                this.config.options.prefix = prefix;
                this.config.options.suffix = suffix;
            },

            /**
             * Update the dataLabel format of Chart(ONLY).
             *
             * @return {Void}
             */
            updateDataLabelFormat: function ()
            {
                var p = this.config.options.prefix;
                var s = this.config.options.suffix;

                var abbr = this.config.options.labelAbbreviation;
                var abbrDecimals = this.config.options.labelAbbreviationDecimals;

                this.config.options.plotOptions.series.dataLabels.formatter = function ()
                {
                    if (this.y && !isNaN(this.y)) {

                        var label = abbr ? numAbbrevFilter(this.y, abbrDecimals) : this.y;
                        var prefix = p ? p + ' ' : '';
                        var suffix = s ? s : '';
                        var dataLabel = prefix + label + suffix;

                        if (this.series.options.stacking) {

                            var labelColour = tinycolor(this.color).isLight() ? 'black' : 'white';
                            dataLabel = '<span style="color:' + labelColour + '">' + prefix + label + suffix + '</span>';
                        }

                        return dataLabel;
                    }
                };
            },

            /**
             * Update the dataLabel formatter.
             *
             * @return {Void}
             */
            updateDataLabelFormatter: function (isIconLabel, formatterFun)
            {
                this.config.xAxis.labels.formatter = formatterFun;
                this.config.yAxis.labels.formatter = formatterFun;

                if (isIconLabel) {

                    this.config.xAxis.labels.useHTML = true;
                    this.config.xAxis.labels.rotation = 0;

                    this.config.yAxis.labels.useHTML = true;
                    this.config.yAxis.labels.rotation = 0;

                } else {

                    this.config.xAxis.labels.rotation = null;
                    this.config.xAxis.labels.useHTML = false;

                    this.config.yAxis.labels.rotation = null;
                    this.config.yAxis.labels.useHTML = false;

                }
            },
            /**
             * Update the LegendLabel formatter.
             *
             * @return {Void}
             */
            updateLegendLabelFormatter: function (formatterFun)
            {
                if (formatterFun) {
                    this.config.options.legend.labelFormatter = formatterFun;
                    this.config.options.legend.useHTML = true;
                } else {
                    delete this.config.options.legend.labelFormatter;
                    this.config.options.legend.useHTML = false;
                }
            },

            updateAxis: function (xAxisVisible, yAxisVisible, invertYAxis)
            {
                this.config.xAxis.visible = xAxisVisible;
                this.config.yAxis.visible = yAxisVisible;

                this.config.yAxis.reversed = this.config.options.chart.type === 'line' ? invertYAxis : false;
            },

            updateDataLabelEnabled: function (enabled)
            {
                this.config.options.plotOptions.series.dataLabels.enabled = enabled;
            },

            updateSeriesGrouping: function (enabled)
            {
                this.config.options.plotOptions.column.grouping = enabled;
                this.config.options.plotOptions.bar.grouping = enabled;
            },

            updateLegend: function (visible)
            {
                this.config.options.legend.enabled = visible;
            }
        };
    };

    /**
     * Loop through all series and make sure a value exists for each
     * selected category with the same index. If it doesn't, it will add
     * an empty object with 0 value of the selected "valueField"
     *
     */
    function fillMissingSeriesValues (arr, key, labelField, categories)
    {
        var cat = categories,
            data = arr,
            selectedRowLabel = key,
            selectedChartLabel = labelField,
            matchCatIndexesArray = [];

        // Loop through all categories (labels)
        for (var i = 0; i < cat.length; i++) {

            var catFound = false;

            for (var y = 0; y < data.length; y++) {

                // If category exists in this series,
                // add to array in exact category position (index)
                if (cat[i] === data[y][selectedChartLabel]) {
                    matchCatIndexesArray[i] = data[y];
                    catFound = true;
                }
            }

            // If category is not found in series,
            // add object with 0 value of "labelField" to
            // be mapped later in rendering
            if (!catFound) {

                var emptyObj = {};
                emptyObj[selectedChartLabel] = cat[i];
                emptyObj[selectedRowLabel] = 0;

                // Add object matching category index
                matchCatIndexesArray.splice(i, 0, emptyObj);
            }
        }

        return matchCatIndexesArray;
    }


    /**
     * retrieve the value corresponding to a specific key, of each object in array
     *     e.g.
     *         arr      : [{key1: 100, key2: 200, key3: 300}, {key1: 1000, key2: 2000, key3: 3000}]
     *         key      : key1
     *         return   : [100, 1000]
     *
     * @param  {Array}  arr         Array of objects
     * @param  {String}  key        Key of the object to retrieve
     * @param  {Boolean} isFloat    Make sure the value retrieved is parse to float when possible
     * @return {Array}              Array of values retrieved
     */
    function pickByKey (arr, key, labelField, categories, isFloat)
    {

        var dataArray = fillMissingSeriesValues(arr, key, labelField, categories);

        return dataArray.map(function (obj) {
            var value = !isFloat ? obj[key] : typeof obj[key] === 'number' ? obj[key] : parseFloat(obj[key].replace(/\,/g,''));
            return isNaN(value) || value === 0 ? null : value;
        });

    }

    return {
        getCustomConfig: function ()
        {
            return new CustomConfig();
        },

        /**
         * parse the data array to chart-understandable data format
         *
         * @param  {Array}  data                Array of data objects filtered from original datasheet, before passing into chart
         * @param  {Array}  data[0].data        Data objects(rows) from datasheet
         * @param  {Object} data[0].filterQuery {
         *                                         where: {
         *                                             property1: {
         *                                                 in: [key1, key2]
         *                                             },
         *                                             property2: {
         *                                                 in: [key11]
         *                                             },
         *                                             ...
         *                                         }
         *                                      }
         * @param  {String} data[0].labelField  Selected field to act as label (x-axis for column chart)
         * @param  {String} data[0].valueField  Selected field to act as value (y-axis for column chart)
         * @return {Object} Object.categories   Array of strings
         *                  Object.seriesData   Array of data to be feed into highchart
         *
         */
        renderData: function (data)
        {
            var categories = [];
            for (var i = 0; i < data.length; i++) {
                for (var j = 0; j < data[i].data.length; j++) {
                    if (categories.indexOf(data[i].data[j][data[i].labelField]) < 0) {
                        categories.push(data[i].data[j][data[i].labelField]);
                    }
                }
            }

            var seriesData = [];
            for (i = 0; i < data.length; i++) {
                seriesData.push({
                    id: i,
                    name: data[i].displayValueField || data[i].valueField,
                    data: pickByKey(data[i].data, data[i].valueField, data[i].labelField, categories, true)
                });
            }

            return {
                categories: categories,
                /**
                 * chart friendly data
                 *
                 * @type {Array}    seriesData
                 * @type {Object}   seriesData[0].name  Name/Title of each data object
                 * @type {Array}    seriesData[0].data  Array of number value of each data object
                 */
                seriesData: seriesData
            };
        },

        /**
         * retrieve the value corresponding to a specific key, of each object in array
         *     e.g.
         *         arr      : [{key1: 100, key2: 200, key3: 300}, {key1: 1000, key2: 2000, key3: 3000}]
         *         property : key1
         *         return   : [100, 1000]
         *
         * @param  {Array}  arr         array of objects
         * @param  {String}  property   key of the object to retrieve
         * @param  {Boolean} isFloat    make sure the value retrieved is parse to float when possible
         * @return {Array}              array of values retrieved
         */
        pickBy: function (arr, property, isFloat)
        {
            return arr.map(function (obj) {
                return !isFloat ? obj[property] : typeof obj[property] === 'number' ? obj[property] : parseFloat(obj[property].replace(/\,/g,''));
            });
        },

        /**
         * Get percentage difference between 2 numbers
         * @param {Integer} nextValue
         * @param {Integer} currentValue
         * @returns {String}
         */
        getPercentageLabel: function (currentValue, nextValue)
        {
            var labelNumber = (nextValue - currentValue) / currentValue * 100;

            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round
            var result = Number(Math.round(labelNumber + "e+1")  + "e-1");

            return (result > 0 ? '+' : '') + result + '%';
        },
        /**
         * Get comparison text for 2 points
         * @param {Object} point
         * @param {Object} lastPoint
         * @param {Boolean} isSingleSerie
         * @param {String} chartType
         * @returns {string}
         */
        getComparisonValue: function (point, lastPoint)
        {
            var nextComparisonValue = '';

            if (point.y < lastPoint.y) {

                nextComparisonValue = 'increased';
            } else if (point.y > lastPoint.y) {

                nextComparisonValue = 'decreased';
            } else if (point !== lastPoint && point.y === lastPoint.y) {

                nextComparisonValue = 'equal';
            }

            return nextComparisonValue;
        },

        /**
         * Get sum of heights and the highest point for series
         * @param {Array} series
         * @param {Integer} currentIndex
         * @returns {{sumH: {Integer}, maxHPoint: {Object} }}
         */
        getSeriesMetrics: function (series, currentIndex)
        {

            var maxH = 0;

            // Find the sum of height for stacked chart,
            // Find the max height of any col/bar
            var metrics = {
                sumH: 0,
                maxHPoint: null
            };

            for (var k = 0; k < series.length; k++) {
                if (!series[k].data[currentIndex]) {
                    continue;
                }
                if (series[k].data[currentIndex].shapeArgs.height >= maxH) {
                    maxH = series[k].data[currentIndex].shapeArgs.height;
                    metrics.maxHPoint = series[k].data[currentIndex];
                }
                metrics.sumH += series[k].data[currentIndex].shapeArgs.height;
            }

            return metrics;
        },

        /**
         * retrive the rect coordinates of chart bar/columns
         *
         * @param  {Object} chart       HighChart object
         * @param  {Array} labelIndexes Array of indexes of rect needed to be retrieve. e.g [0, 4, 5]
         * @param  {Object} params      Helper object that contains chart type, chart stacking value, prefix and suffix
         * @param  {Number} overlapH    Extended value of the result rect comparing to the actual bar/column
         * @param  {Number} overlapV    Extended value of the result rect comparing to the actual bar/column
         * @return {Array}              Array of rectangles. e.g. [rect, rect, rect, ...]
         */
        getChartUnitRect: function (chart, labelIndexes, params, overlapH, overlapV, outputType)
        {
            var rects = [],
                point,
                lastPoint,
                estimateLabelWidth = 40,
                estimateLabelHeight = 15,
                seriesMetrics,
                series = angular.copy(chart.series),
                isSingleSerie = chart.series.length === 1 ? true : false,
                currentVal,
                nextVal,
                negativeOffset,
                chartType = params.chartType,
                isStacking = params.isStacking,
                labelHasPrefixSuffix = params.prefix || params.suffix ? true : false; // {y} default format with NO suffix/prefix

            overlapV = labelHasPrefixSuffix && chartType === 'bar' ? overlapV + 10 : overlapV;

            // Reverse series if it's column with multiple series
            if (chartType === 'column' && !isSingleSerie) {
                series.reverse();
            }

            for (var i = 0; i < series[0].data.length; i++) {

                if (labelIndexes.indexOf(i) === -1 ) {
                    continue;
                }

                point = series[0].data[i];
                lastPoint = series[series.length - 1].data[i];

                // Single serie scenario and output is for arrows
                if (isSingleSerie && outputType === 'comparison') {

                    if (i < series[0].data.length - 1) {
                        lastPoint = series[0].data[i + 1];
                    } else {
                        // Point = lastPoint, when last bar/column is reached
                        lastPoint = series[0].data[series[0].data.length - 1];
                    }
                } else if (outputType === 'comparison' && !lastPoint) {

                    // Nothing to compare so skip it
                    continue;
                } else if (outputType !== 'comparison' && !lastPoint) {

                    lastPoint = point;
                }

                seriesMetrics = this.getSeriesMetrics(series, i);
                currentVal = !isSingleSerie ? lastPoint : point;
                nextVal = !isSingleSerie ? point : lastPoint;

                // Catch series which have zero height
                if (!seriesMetrics.sumH || !seriesMetrics.maxHPoint) {
                    continue;
                }

                if (chartType === 'bar') {

                    var calcOffsetLeft = chart.plotLeft + chart.plotWidth - (point.shapeArgs.y + point.shapeArgs.height);

                    // bar-stacking
                    if (isStacking) {
                        negativeOffset = point.negative ? overlapV / 2  : 0;

                        rects.push({
                            left: seriesMetrics.maxHPoint.negative ? seriesMetrics.maxHPoint.shapeArgs.y + overlapV / 4 - chart.plotLeft :
                                calcOffsetLeft - negativeOffset,
                            top: chart.renderer.height -
                                    (chart.renderer.height - chart.plotTop - chart.plotHeight) -
                                    (nextVal.shapeArgs.x + nextVal.shapeArgs.width) - overlapH / 2,
                            width: seriesMetrics.sumH + overlapV / 2,
                            height: nextVal.shapeArgs.x + nextVal.shapeArgs.width - currentVal.shapeArgs.x + overlapH,
                            radius: 5
                        });
                        // bar
                    } else {
                        negativeOffset = point.negative ? overlapV + estimateLabelWidth : 0;

                        rects.push({
                            left: calcOffsetLeft - negativeOffset,
                            top: chart.renderer.height -
                                    (chart.renderer.height - chart.plotTop - chart.plotHeight) -
                                    (lastPoint.shapeArgs.x + lastPoint.shapeArgs.width) - overlapH / 2,
                            width: seriesMetrics.maxHPoint.shapeArgs.height + overlapV + estimateLabelWidth,
                            height: lastPoint.shapeArgs.x + lastPoint.shapeArgs.width - point.shapeArgs.x + overlapH,
                            radius: 5,
                            isSingleSerie: isSingleSerie,
                            nextComparisonValue: this.getComparisonValue(currentVal, nextVal),
                            diff: this.getPercentageLabel(currentVal.y, nextVal.y)
                        });
                    }
                } else if (chartType === 'column') {
                    // column-stacking
                    if (isStacking) {
                        rects.push({
                            left: currentVal.shapeArgs.x + chart.plotLeft - overlapH / 2,
                            top: point.negative ? seriesMetrics.maxHPoint.yBottom - overlapH / 4 :
                                                  chart.plotTop + (chart.plotHeight - seriesMetrics.sumH) - overlapH / 2,
                            width: nextVal.shapeArgs.x + chart.plotLeft +
                                    nextVal.shapeArgs.width - (currentVal.shapeArgs.x + chart.plotLeft) + overlapH,
                            height: seriesMetrics.sumH + overlapV / 2,
                            radius: 5
                        });
                        // column
                    } else {
                        rects.push({
                            left: currentVal.shapeArgs.x + chart.plotLeft - overlapH / 2,
                            top: point.negative ? seriesMetrics.maxHPoint.shapeArgs.y + chart.plotTop :
                                    seriesMetrics.maxHPoint.shapeArgs.y + chart.plotTop - overlapV - estimateLabelHeight,
                            width: nextVal.shapeArgs.x + chart.plotLeft +
                                    nextVal.shapeArgs.width - (currentVal.shapeArgs.x + chart.plotLeft) + overlapH,
                            height: seriesMetrics.maxHPoint.shapeArgs.height + overlapV + estimateLabelHeight,
                            radius: 5,
                            isSingleSerie: isSingleSerie,
                            nextComparisonValue: this.getComparisonValue(currentVal, nextVal),
                            diff: this.getPercentageLabel(currentVal.y, nextVal.y)
                        });
                    }
                }
            }

            return rects;
        },

        /**
         * Return comparison arrow metrics with/without percentage text
         * @param {Object} chart
         * @param {Array} rects
         * @param {Integer} id
         * @param {Boolean} comparisonTextDisabled
         * @returns {Object}
         */
        calculateComparisonItemCenter: function (chart, rects, id, comparisonTextDisabled)
        {

            var arrowMetrics,
                arrowWidth,
                nextRectHeight,
                nextRectWidth,
                maxArrowWidth = 80,
                maxArrowHeight = 40,
                arrowWidthWithText = 30,
                arrowHeightWithText = 30,
                chartType = chart.options.chart.type;

            var rect = rects[id],
                nextRect = id === rects.length - 1 ? rects[id] : rects[id + 1];

            var getArrowWidth = function (width)
            {

                return comparisonTextDisabled ? Math.min(Math.abs(width), maxArrowWidth) : arrowWidthWithText;
            };

            var getArrowHeight = function (aWidth)
            {
                var aHeight = rect.nextComparisonValue === "equal" ? aWidth : maxArrowHeight;
                return comparisonTextDisabled ? aHeight : arrowHeightWithText;
            };

            if (chartType === 'bar') {
                nextRectHeight = nextRect.nextComparisonValue === "" ? -nextRect.height : nextRect.height;
                arrowWidth = getArrowWidth(rect.height / 2);
                arrowMetrics = {
                    x: chart.chartWidth - 80,
                    y: (rect.top * 2 + nextRectHeight) / 2,
                    width: arrowWidth,
                    height: getArrowHeight(arrowWidth)
                };
            } else {
                arrowWidth = getArrowWidth(rect.width / 2);
                nextRectWidth = nextRect.nextComparisonValue === "" ? rects[id].width : nextRect.width;
                arrowMetrics = {
                    x: (rect.left * 2 + nextRectWidth) / 2,
                    y: chart.plotTop - 20,
                    width: arrowWidth,
                    height: getArrowHeight(arrowWidth)
                };
            }

            return arrowMetrics;
        },
        /**
         * Generate the svg path array for drawing an arrow.
         *
         * @param  {String} arrowType   Type/direction of arrow. e,g, right|left|up|down|equalV|equalH
         * @param  {Object} center      Center of the arrow. e.g. {x: ... , y: ...}
         * @param  {Number} maxW        Max width
         * @param  {Number} maxH        Max height
         * @return {Array}              Path array of the arrow.
         */
        getArrowPath: function (arrowType, center, maxW, maxH)
        {
            var thickness = 4;
            var paths = {
                up: [
                    'M',
                    center.x + maxW / 4,    center.y + maxH / 2,
                    'L',
                    center.x + maxW / 4,    center.y,
                    center.x + maxW / 2,    center.y,
                    center.x,               center.y - maxH / 2,
                    center.x - maxW / 2,    center.y,
                    center.x - maxW / 4,    center.y,
                    center.x - maxW / 4,    center.y + maxH / 2
                ],
                down: [
                    'M',
                    center.x + maxW / 4,    center.y - maxH / 2,
                    'L',
                    center.x + maxW / 4,    center.y,
                    center.x + maxW / 2,    center.y,
                    center.x,               center.y + maxH / 2,
                    center.x - maxW / 2,    center.y,
                    center.x - maxW / 4,    center.y,
                    center.x - maxW / 4,    center.y - maxH / 2
                ],
                left: [
                    'M',
                    center.x + maxW / 2,    center.y - maxH / 4,
                    'L',
                    center.x,               center.y - maxH / 4,
                    center.x,               center.y - maxH / 2,
                    center.x - maxW / 2,    center.y,
                    center.x,               center.y + maxH / 2,
                    center.x,               center.y + maxH / 4,
                    center.x + maxW / 2,    center.y + maxH / 4
                ],
                right: [
                    'M',
                    center.x - maxW / 2,    center.y - maxH / 4,
                    'L',
                    center.x,               center.y - maxH / 4,
                    center.x,               center.y - maxH / 2,
                    center.x + maxW / 2,    center.y,
                    center.x,               center.y + maxH / 2,
                    center.x,               center.y + maxH / 4,
                    center.x - maxW / 2,    center.y + maxH / 4
                ],
                equalH: [
                    'M',
                    center.x + maxW / 2,        center.y + thickness / 2,
                    'L',
                    center.x + maxW / 2,        center.y - thickness / 2,
                    center.x - maxW / 2,        center.y - thickness / 2,
                    center.x - maxW / 2,        center.y + thickness / 2
                ],
                equalV: [
                    'M',
                    center.x + thickness / 2,   center.y + maxH / 2,
                    'L',
                    center.x + thickness / 2,   center.y - maxH / 2,
                    center.x - thickness / 2,   center.y - maxH / 2,
                    center.x - thickness / 2,   center.y + maxH / 2
                ]
            };

            return paths[arrowType];
        }
    };
});
