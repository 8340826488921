'use strict';

angular.module('app')

.factory('multiSlideDeleteAction', function ($state)
{
    return {
        name: 'delete',
        tooltip: 'Delete',
        buttonClass: 'btn btn-default',
        template: '<i class="material-icon material-icon-delete"></i> Delete',
        act: function (models)
        {
            $state.go('.slideDelete', {
                ids: models.map((model) => model.id)
            });
        },
        show: function (models)
        {
            return models.length > 1 && models.every(function (model) {
                return !model.deleted_at && model.editable !== false && !model.locked_by;
            });
        }
    };
})

.factory('singleDeleteSlideAction', function (multiSlideDeleteAction)
{
    return angular.extend({}, multiSlideDeleteAction, {
        name: 'deleteSingleItem',
        show: function (model) {
            return !model.deleted_at && model.editable !== false && !model.locked_by;
        }
    });
});
