'use strict';

angular.module('presentation.theme')

.directive('themeFontRow', function (Font)
{
    return {
        restrict: "E",
        templateUrl: 'app/presentation/theme/components/theme.font-row/theme.font-row.html',
        scope:{
            model: '=',
        },
        link: function (scope)
        {
            Font.bindAll({}, scope, 'fonts');

            scope.fontFamilies = [];
            scope.fontWeights = [];

            scope.$watch('model.fontId', function()
            {
                scope.fontFamilies = [];

                var font = Font.get(scope.model.fontId);

                if (font && font.data) {
                    scope.fontFamilies = Object.keys(font.data);
                }
            });

            scope.$watch('model.fontFamily', function()
            {
                scope.fontWeights = [];

                var font = Font.get(scope.model.fontId);
                if (font && font.data && scope.model.fontFamily) {
                    scope.fontWeights = Object.keys(font.data[scope.model.fontFamily]);
                }
            });
        }
    };
});
