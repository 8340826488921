'use strict';

angular.module('app')

.factory('contactViewOptions', function (
    $state,
    $q,
    $uibModal,
    extendDeep,
    Slide,
    resourceViewOptions,
    recoverAction,
    toggleTrashAction,
    gridNameTemplate,
    listNameTemplate,
    gridThumbTemplate,
    createAction,
    editSingleItemAction,
    deleteSingleItemAction,
    moveSingleItemAction,
    deselectAction,
    multiDeleteAction,
    multiMoveAction,
    createFolderAction,
    kdGate,
    moment
) {
    var options = angular.copy(resourceViewOptions);
    var gatedCreateAction = angular.copy(createAction);
    var gatedCreateFolderAction = angular.copy(createFolderAction);

    var showCreateButton = function () {
        return kdGate.allows('contacts.create');
    };

    gatedCreateAction.show = showCreateButton;
    gatedCreateAction.template = '<i class="material-icon material-icon-add"></i> New contact';
    gatedCreateFolderAction.show = showCreateButton;

    options.moment = moment;

    options.toolbar = [gatedCreateFolderAction, gatedCreateAction];

    options.actions = [
        deselectAction,
        multiDeleteAction,
        multiMoveAction,
    ];

    options.inlineActions = [
        editSingleItemAction,
    ];

    options.inlineDropdown = [
        moveSingleItemAction,
        deleteSingleItemAction
    ];

    options.gridActions = [
        ...options.inlineActions,
        ...options.inlineDropdown,
    ];

    extendDeep(options, {
        tagging: true,
        taggingAdvanced: true,

        openUsagePreview: function (modelID) {
            $uibModal.open({
                templateUrl: 'app/contact/usagePreview/usagePreview.html',
                controllerAs: '$ctrl',
                controller: 'usagePreviewCtrl',
                size: 'lg',
                resolve: {
                    model: ['Contact', (Contact) => {
                        return Contact.find(modelID);
                    }],
                    usage: ['$http', ($http) => {
                        return $http.get('/api/contacts/' + modelID + '/usage').then((res) => {
                            return res.data;
                        });
                    }]
                }
            });
        },
        openPresentations: function (modelID) {
            $uibModal.open({
                windowClass: 'share-action-form',
                templateUrl: 'app/contact/contactPresentations/contactPresentations.html',
                controllerAs: '$ctrl',
                controller: 'ContactPresentationsCtrl',
                size: 'lg',
                resolve: {
                    shares: ['Share', (Share) => {
                        return Share.findAll({
                            where: {contact_id: modelID, type: 'shared'},
                            with: 'presentation'
                        });
                    }]
                }
            });
        },
        list: {
            columns: [
                {
                    label: 'Name',
                    name: 'name',
                    template: listNameTemplate('name', 'asset.links.thumb', 'type'),
                    sortable: true
                },
                {
                    label: 'Client',
                    name: 'client.name',
                    sortable: false
                },
                {
                    label: 'Code',
                    name: 'code',
                    sortable: false
                },
                {
                    label: 'Presentations',
                    name: 'links_count',
                    template: `<span ng-hide="model.links_count < 1">
                                    <a href ng-click="options.openPresentations(model.id)">
                                        <u>{{ model.links_count }}</u>
                                    </a>
                                </span>`,
                    sortable: false
                },
                {
                    label: 'Usage',
                    name: 'usage_count',
                    template: `<a href ng-click="options.openUsagePreview(model.id)">
                                        <u>{{ model.usage_count }}</u>
                                    </a>`,
                    sortable: false
                },
                {
                    label: 'Last modified',
                    name: 'updated_at',
                    template: `<span class="no-wrap" uib-tooltip="{{ model.updated_at | date }}">
                                    {{ options.moment(model.updated_at).fromNow() }}
                                </span>`,
                    sortable: true
                },
                {
                    template: `<contextual-options actions="options.inlineActions"
                                                   dropdown-actions="options.inlineDropdown"
                                                   models="options.resourceList.selected"
                                                   model="model"
                                                   class="row-actions pull-right"></contextual-options>`
                }
            ],
        },
        grid: {
            fields: [
                {
                    template: gridThumbTemplate('asset.links.thumb', 'type'),
                },
                {
                    template: gridNameTemplate('name', 'type'),
                },
                {
                    template: `<contextual-options dropdown-actions="options.gridActions"
                                                   models="options.resourceList.selected"
                                                   model="model"
                                                   class="grid-actions pull-right"></contextual-options>`
                }
            ]
        }
    });

    return options;
});
