'use strict';

angular.module('app')

.service('validationNotification', function (Notification)
{
    this.show = function (data, replace)
    {
        var title = 'Validation error';
        var messageBody = '';

        if (angular.isString(data)) {
            messageBody = data;
        } else if (angular.isObject(data)) {
            for (var field in data) {
                if (angular.isArray(data[field])) {
                    messageBody += data[field].join('<br/>');
                }

                messageBody += '<br/>';
            }
        }

        var message = {
            title: title,
            message: messageBody,

            // Should be enough to read a message
            delay: 10000,
            replaceMessage: replace
        };

        Notification.error(message);
    };
});
