'use strict';

angular.module('task', [
    'js-data'
])

.run(function (Pusher, Task, kdAuth, auth, $rootScope, $cookies, $timeout)
{
    let channel = null;
    let pusher = null;

    const listen = () =>
    {
        if (channel && channel.subscribed) {
            channel.unsubscribe();
        }

        if (auth.isGuest() || !kdAuth.user || !$rootScope.config || !$rootScope.config.pusher || !$rootScope.config.pusher.key) {

            if (pusher) {
                pusher.disconnect();
                pusher = null;
            }

            return;
        }

        pusher = pusher || new Pusher($rootScope.config.pusher.key, {
            cluster: $rootScope.config.pusher.cluster,
            auth: {
                headers: {
                    'X-XSRF-TOKEN': $cookies.get('XSRF-TOKEN'),
                }
            },
            encrypted: false,
            disableStats: true
        });

        channel = pusher.subscribe(kdAuth.user.channel);

        channel.bind('task.updated', (data) =>
        {
            const task = Task.get(data.task.id) || Task.inject(data.task);
            task.updateValues(data.task);
        });
    };

    $rootScope.$on('kdAuth:userChanged', listen);
    $timeout(listen);
});
