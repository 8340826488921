'use strict';

angular.module('presentation.theme')

.provider('ThemeValidator', function ()
{
    const validators = [];
    const labels = {};
    const validKeys = {
        colour: [],
        text: [],
        border: [],
        typeface: [],
    };

    let sectionErrors = [];
    let propertyErrors = [];
    let currentTheme = null;

    this.add = (validator) => {
        validators.push(validator);
    };

    this.addLabel = (label, func) => {
        labels[label] = {
            label: label,
            func: func
        };

        func(this.propertyHasError(label));
    };

    this.removeLabel = (label) => {
        delete labels[label];
    };

    this.sectionHasError = (title) => {
        return sectionErrors.indexOf(title.toLowerCase()) > -1;
    };

    this.propertyHasError = (ref) => {
        return propertyErrors.indexOf(ref) > -1;
    };

    this.run = (theme) => {

        currentTheme = theme;

        updateKeys(theme.config);

        let allValidatorConfigs = flatMap(validators.map((func) => func(theme.config)));
        allValidatorConfigs = allValidatorConfigs.map(validateConfig);

        sectionErrors = allValidatorConfigs.reduce(getSectionWithErrors, []);
        propertyErrors = allValidatorConfigs.reduce(getPropertyWithErrors, []);

        angular.forEach(labels, (item) => {
            item.func(this.propertyHasError(item.label));
        });

        return !!sectionErrors.length;
    };

    this.$get = () => {
        return {
            run: this.run,
            addLabel: this.addLabel,
            removeLabel: this.removeLabel,
            sectionHasError: this.sectionHasError,
            propertyHasError: this.propertyHasError,
        };
    };

    function updateKeys(config) {
        validKeys.colour = Object.keys(config.colors.palette);
        validKeys.text = config.typography.textStyles.map((textStyle) => textStyle.tag);
        validKeys.border = config.tables.borderStyles.map((borderStyle) => borderStyle.name);
        validKeys.typeface = currentTheme.fonts.map((font) => font.family + '-' + font.weight);
    }

    function getSectionWithErrors(acc, config) {
        return config.labelIds.length ? acc.concat(config.section.toLowerCase()) : acc;
    }

    function getPropertyWithErrors(acc, config) {
        return acc.concat(...config.labelIds);
    }

    function validateConfig(vc) {
        let labelIds = [];

        if (Array.isArray(vc.config)) {
            labelIds = validateArray(vc);
        } else {
            labelIds = validateObject(vc);
        }

        return {section: vc.section, labelIds: clean(labelIds)};
    }

    function validateArray(vc) {
        return clean(vc.config.map((config, key) => {
            return validateObject({
                fields: vc.fields,
                validKeys: vc.validKeys,
                section: vc.section,
                config,
                key
            });
        }));
    }

    function validateObject(vc) {

        if (vc.validKeys === 'typeface') {
            return checkTextStyles(vc);
        }

        return vc.fields.map((field) => {

            const value = field.key.split('.')
                .reduce((config, key) => {
                    if (config && config[key]) {
                        return config[key];
                    }
                    return null;
                } , vc.config);

            return validKeys[vc.validKeys].indexOf(value) > -1 ?
                null :
                `${vc.section}.${field.key}${ vc.key !== undefined ? '.' + vc.key  : ''}` ;
        });
    }

    function checkTextStyles(vc) {
        return vc.fields.map((field) => {

            const typeface = field.typeface.split('.')
                .reduce((config, key) => config[key], vc.config);
            const weight = field.weight.split('.')
                .reduce((config, key) => config[key], vc.config);

            if (typeface === null) {
                return null;
            }

            const font = currentTheme.getFont(typeface);

            if (!font) {
                return `${vc.section}.${field.typeface}${ vc.key !== undefined ? '.' + vc.key  : ''}`;
            }

            if (!font.weights[weight]) {
                return `${vc.section}.${field.weight}${ vc.key !== undefined ? '.' + vc.key  : ''}`;
            }

            return null;
        });
    }

    function flatMap(arr) {
        return arr.reduce((acc, val) => acc.concat(val), []);
    }

    function clean(arr) {
        return arr.reduce((acc, error) => {
            return error ? acc.concat(error) : acc;
        }, []);
    }
});
