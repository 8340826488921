'use strict';

angular.module('app')

.factory('getTrackingTitle', function (presentationState, $document)
{
    return function getTrackingTitle()
    {
        var parts = [];

        if (presentationState.presentation) {
            parts.push(presentationState.presentation.name);
        }

        if (presentationState.chapter) {
            parts.push(presentationState.chapterNum + '. ' + presentationState.chapter.name);
        }

        if (presentationState.slide) {
            var slideStr = presentationState.slide.name;
            //When previewing single slide, slide exists without slideNum.
            if (presentationState.slideNum) {
                slideStr = presentationState.slideNum + '. ' + slideStr;
            }

            parts.push(slideStr);
        }

        if (parts.length > 0) {
            return parts.join(" > ");
        }

        return $document[0].title;
    };
});
