'use strict';

angular.module('presentation.theme')

.factory('themeConfigImages', function ()
{
    return {
        backgroundOverlays: [],
        markers: {
            size: 20,
            borderColor: 'light',
            borderWidth: 3,
            color: 'color-1',
            active: 'color-2',
        }
    };
});
