'use strict';

angular.module('app')

.config(function (editorGatesProvider) {
   editorGatesProvider.setGates('comp-panel');
})

.directive('compPanel', function (compPanelBackwardsCompatibility, editorState, presentationState, currentTheme)
{
    return {
        restrict: 'A',
        require: '^component',
        templateUrl: 'scripts/editorComponents/compPanel/compPanel.html',
        link: link
    };

    function link ($scope, element, attributes, component)
    {
        let allowCommit = true;
        let presentation = presentationState.presentation;
        let theme = currentTheme();

        $scope.enableHeightOption = component.options.height;
        $scope.enableClientColorOptions = theme && theme.isBrandColorEnabled();

        $scope.data.height = $scope.data.height || 0;
        $scope.data.useBrandColor = $scope.data.useBrandColor || false;
        $scope.data.showHeightOption = $scope.data.showHeightOption || false;

        $scope.computed = {};

        if ($scope.enableClientColorOptions && $scope.data.useBrandColor && presentation && !editorState.editMode) {
            $scope.computed.bg = presentation.getClientsBrandColor();
        }

        if ($scope.data.backgroundColor) {
            $scope.computed.bg = $scope.data.backgroundColor;
        }

        // Backwards compatibility
        compPanelBackwardsCompatibility($scope);

        // load comp-panel options background colours
        $scope.backgroundColors = component.options.background.colors;

        $scope.getTrackables = getTrackables;
        $scope.updateHeight = updateHeight;
        $scope.getStyle = getStyle;

        function getStyle ()
        {
            let style = {};

            if ($scope.computed.bg) {
                style.backgroundColor = $scope.computed.bg;
            }

            return style;
        }

        function updateHeight ()
        {
            if (!$scope.enableHeightOption || !$scope.data.showHeightOption) {
                delete $scope.model.style.minHeight;
                return;
            }

            $scope.model.style.minHeight = $scope.model.data.height + 'px';
        }

        function getTrackables ()
        {
            return {
                'data.height': {
                    deepWatch: false,
                    onChange: updateHeight
                },
                'data.backgroundColor': {
                    deepWatch: false
                },
                'data.showHeightOption': {
                    deepWatch: false
                },
                'data.useBrandColor': {
                    deepWatch: false
                },
                'class': {
                    deepWatch: false
                }
            };
        }

        $scope.$watchGroup([
            'data.backgroundColor',
            'data.useBrandColor',
            'data.enableClientColorOptions',
            () => presentationState.editMode,
            () => presentationState.client,
        ], () => {

            $scope.computed.bg = $scope.data.backgroundColor || null;

            if (!$scope.data.useBrandColor || !$scope.enableClientColorOptions) {
                return;
            }

            if (theme) {
                $scope.computed.bg = theme.getDefaultClientBrandColor();
            }

            if (!presentationState.editMode && presentation) {
                $scope.computed.bg = presentation.getClientsBrandColor();
            }

        }, true);

        $scope.$watch('model.link', (newValue, oldValue) =>
        {
            if (newValue === oldValue || !allowCommit) {
                allowCommit = true;
                return;
            }

            editorState.history.commit({
                undo: () => {
                    allowCommit = false;
                    $scope.model.link = oldValue;
                },
                redo: () => {
                    allowCommit = false;
                    $scope.model.link = newValue;
                }
            });

        }, true);

        // Auto-focus the nested component list when focusing this comonent
        component.setFocusRequestHandler(function ()
        {
            $scope.$broadcast('component:request-focus', $scope.model.nested);
        });
    }

});
