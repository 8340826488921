'use strict';

angular.module('presentation.frontend')
    .factory('presentationTimeline', presentationTimeline);

function presentationTimeline (timelineLite, slideTransitionConfig)
{
    const tl = new timelineLite({
        paused: true,
        autoRemoveChildren: true,
        smoothChildTiming: true,
        onUpdate: onUpdate
    });

    const transitionDuration = slideTransitionConfig.duration + slideTransitionConfig.delay;

    function onUpdate () {
        // The faster a user clicks the faster the animation will be
        const timeScale = (tl.totalDuration() / transitionDuration - 1) * 0.2 + 1;
        
        if (timeScale >= 1) {
            tl.timeScale(timeScale);
        }
    }

    return tl;
}