'use strict';

angular.module('presentation.admin')

.factory('presentAction', function ($state, kdGate, $window)
{
    return {
        name: 'present',
        tooltip: 'Present',
        buttonClass: 'btn btn-default',
        template: '<i class="material-icon material-icon-ondemand-video"></i> Present',
        act: function (models)
        {
            if (kdGate.allows('presentation.present-start')) {
                $state.go('.present', {
                    id: models[0].id
                });
            } else {
                var url = $state.href('admin.editor.presentation.chapter.slide', {
                    presentationMode: 'slideshow',
                    id: models[0].id
                });

                $window.open(url, '_blank');
            }

        },
        show: function (model)
        {
            return model.type !== 'folder';
        }
    };
});
