'use strict';

angular.module('app')

.config(function (editorGatesProvider) {
   editorGatesProvider.setGates('client-logo');
})

.directive('clientLogo', function (
    $uibModal,
    assetCache,
    $window,
    $timeout,
    debounce,
    objectDefaults,
    presentationState
){
    return {
        replace: true,
        require: '^component',
        templateUrl: 'scripts/editorComponents/clientLogo/clientLogo.html',
        bindToController: true,
        controllerAs: '$ctrl',
        controller: function ($scope, $element)
        {
            const self = this;
            const component = $element.controller('component');
            const placeholderImage = component.options.placeholderImage;

            $scope.getTrackables = getTrackables;

            $scope.data = objectDefaults($scope.data, {
                backgroundPosition: '0 50%',
                width: '100%',
                height: '100px',
                useSecondaryLogo: false,
            });

            this.getClientLogo = function ()
            {
                let client = null;
                let assetKey = $scope.data.useSecondaryLogo ? "secondary_asset_id": "asset_id";

                if (presentationState.presentation && presentationState.presentation.client) {
                    client = presentationState.presentation.client;
                }

                if (!client || !client[assetKey]) {
                    this.computed.logoVisible = false;
                    this.style.backgroundImage = "url(" + placeholderImage + ")";
                    return;
                }

                assetCache.get(client[assetKey]).then((asset) =>
                {
                    this.computed.logoVisible = true;
                    this.style.backgroundImage = "url(" + asset.links.download + ")";
                });
            };

            this.state = component.editor.state;

            this.getProps = function ()
            {
                this.computed = {
                    logoVisible: false,
                    width: Number(splitValue($scope.data.width)[1]),
                    widthUnit: splitValue($scope.data.width)[2],
                    height: Number(splitValue($scope.data.height)[1]),
                    heightUnit: splitValue($scope.data.height)[2],
                };

                function splitValue(value) {
                    return value.match(/^(\d+(?:\.\d+)?)(.*)$/);
                }
            };

            this.updateStyles = function ()
            {
                if (!this.style) {
                    this.style = {};
                }
                this.style.backgroundPosition = $scope.data.backgroundPosition;
                this.style.width = $scope.data.width;
                this.style.height = $scope.data.height;
            };

            this.setProps = function ()
            {
                $scope.data.width = this.computed.width + this.computed.widthUnit;
                $scope.data.height = this.computed.height + this.computed.heightUnit;

                this.updateStyles();
            };

            this.toggleWidthUnit = function ()
            {
                this.computed.widthUnit = this.computed.widthUnit === 'px' ? '%' : 'px';
                this.setProps();
            };

            this.$onInit = function ()
            {
                this.getProps();
                this.updateStyles();

                $scope.$watch(
                    () => presentationState.presentation ? presentationState.presentation.client : 0,
                    self.getClientLogo.bind(self)
                );

                component.removeDataProperties([
                    'fixedHeight',
                    'size',
                    'imageWidth',
                    'unit',
                    'placeholder',
                    'style',
                    'imageConfig',
                    'sources',
                    'url',
                    'ratio',
                    'overlayPos',
                    'caption',
                    'anchor'
                ]);
            };

            function getTrackables ()
            {
                return {
                    'data.backgroundPosition': {
                        deepWatch: false,
                        onChange: () => self.updateStyles()
                    },
                    'data.width': {
                        deepWatch: false,
                        onChange: function () {
                            self.getProps();
                            self.updateStyles();
                        }
                    },
                    'data.height': {
                        deepWatch: false,
                        onChange: function () {
                            self.getProps();
                            self.updateStyles();
                        }
                    },
                    'data.useSecondaryLogo': {
                        deepWatch: false,
                        onChange: () => self.getClientLogo()
                    },
                };
            }
        },
    };

});
