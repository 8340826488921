'use strict';

angular.module('presentation.frontend', [
    'presentation.model',
    'datasheet.frontend',
    'ngKomondorMediaLibrary',
    'ngKomondorTargetedImagery',
    'ngKomondorEditor',
    'FBAngular'
]);
