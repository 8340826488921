'use strict';

angular.module('presentation.admin')

.controller('PresentCtrl', function (
    $rootScope,
    $scope,
    model,
    Share,
    ResourcePicker,
    Notification,
    $window,
    $state,
    $uibModal
) {
    $scope.model = model;

    Share.bindAll({expired : false, presentation_id: model.id, type: 'present'}, $scope, 'activeShares');
    Share.bindAll({expired : true, presentation_id: model.id, type: 'present'}, $scope, 'expiredShares');

    $scope.preview = function ()
    {
        let url = $state.href('admin.editor.presentation.chapter.slide', {
            presentationMode: 'slideshow',
            id: model.id
        });

        $window.open(url, '_blank');
    };

    $scope.createNewLink = function ()
    {
        $uibModal.open({
            templateUrl: 'app/presentation/admin/present/createPresentLink.html',
            controller: 'CreatePresentLinkCtrl',
            controllerAs: '$ctrl',
            bindToController: true,
            resolve: {
                model: $scope.model
            }
        });
    };
});
