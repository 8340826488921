'use strict';

angular.module('app')

.factory('currentTheme', function (presentationState, defaultTheme) {

    return () => {

        const presentation = presentationState.presentation;
        let theme = defaultTheme();

        if (presentation) {
            theme = presentation.theme;
        }

        return theme;
    };
});
