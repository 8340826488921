'use strict';

angular.module('app')

.value('tokenLoginUrl', '/auth/token-login')
.value('tokenValidateUrl', '/auth/token-validate')
.controller('ModalLoginCtrl', function ($scope, auth, $rootScope, $uibModalInstance, $http, tokenLoginUrl, kdAuth, tokenValidateUrl)
{
    $scope.user = {
        username: '',
        password: '',
        token: '',
        remember: false
    };

    $scope.showToken = false;

    $scope.generateToken = function ()
    {
        if (!$scope.showToken) {
            $http.post(tokenLoginUrl, $scope.user, {timeout: 4000}).then(function ()
            {
                $scope.showToken = true;
            });
        }
    };

    $scope.sendToken = function () {
        if ($scope.showToken && $scope.user.token) {

            $http.post(tokenValidateUrl, $scope.user).then(function (response)
            {
                let user = response.data.user;
                user.token = response.data.token;

                kdAuth.setUser(user);
                $uibModalInstance.close(kdAuth.user);
            }, function (response)
            {
                if ([400, 401, 422].indexOf(response.status) > -1) {
                    $scope.error = response.data;
                    return;
                }

                $uibModalInstance.dismiss(response);
            });
        }
    };

});
