'use strict';

angular.module('helpCentre')

.controller('HelpCentreCtrl', function ($scope, $rootScope, $window, categories)
{
    $scope.categories = categories;

    const unregisterListener = $rootScope.$on('$stateChangeSuccess', function (event, toState)
    {
        if (toState.name.includes('admin.help-centre')) {
            $window.scrollTo(0, 0);
        }
    });

    $scope.$on('$destroy', function () {
        unregisterListener();
    });
});
