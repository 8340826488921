'use strict';

angular.module('app')

.config(function (editorGatesProvider) {
   editorGatesProvider.setGates('bg-image');
})

.directive('bgImage', function (
    $window,
    $timeout,
    assetCache,
    objectDefaults,
    isPhantom,
    editorState
) {
    var controller = function ($scope)
    {
        ///////////////////
        // scope members //
        ///////////////////
        objectDefaults($scope.data, {
            asset_id: null,
            anchor: 'Centre Middle',
        });

        $scope.state = editorState;
        $scope.url = '';
        $scope.anchorOptions = {
            'Left Top': {
                class: 'left-top',
                percent: '0 0'
            },
            'Left Middle': {
                class: 'left-middle',
                percent: '0 50%'
            },
            'Left Bottom': {
                class: 'left-bottom',
                percent: '0 100%'
            },

            'Centre Top': {
                class: 'center-top',
                percent: '50% 0'
            },
            'Centre Middle': {
                class: 'center-middle',
                percent: '50% 50%'
            },
            'Centre Bottom': {
                class: 'center-bottom',
                percent: '100% 100%'
            },

            'Right Top': {
                class: 'right-top',
                percent: '100% 0'
            },
            'Right Middle': {
                class: 'right-middle',
                percent: '100% 50%'
            },
            'Right Bottom': {
                class: 'right-bottom',
                percent: '100% 100%'
            }
        };

        /////////////////////
        // scope functions //
        /////////////////////
        $scope.bgConfigChanged = bgConfigChanged;

        ////////////
        // events //
        ////////////
        $scope.$watch('data.asset_id', setBackgroundImage);

        //////////
        // init //
        //////////
        setBackgroundImage();
        deprecatedProperties();
        bgConfigChanged();
        backwardCompatibilityRemoveStyles();

        ///////////////////////
        // private functions //
        ///////////////////////
        function setBackgroundImage ()
        {
            if (!$scope.data.asset_id) {
                $scope.url = $scope.ctrl.options.placeholderImage;
                return;
            }

            assetCache.get($scope.data.asset_id).then(function (asset)
            {
                $scope.url = asset.links.download;

                if (isPhantom && asset.links.poster) {
                    $scope.url = asset.links.poster;
                }
            });
        }

        function deprecatedProperties ()
        {
            var list = ['bgConfig', 'background', 'size', 'imageWidth', 'placeholder'];

            angular.forEach(list, function (item) {

                if ( $scope.data[item] !== undefined ) {
                    delete $scope.data[item];
                }
            });
        }

        function bgConfigChanged (anchor)
        {
            if (anchor) {
                $scope.data.anchor = anchor;
            }
        }

        function backwardCompatibilityRemoveStyles ()
        {
            var stylesToRemove = [
                'backgroundImage',
                'backgroundSize',
                'backgroundPosition',
                'backgroundRepeat',
                'margin'
            ];

            angular.forEach(stylesToRemove, function (name) {
                $scope.ctrl.removeStyle(name);
            });
        }
    };

    return {
        restrict: 'A',
        replace: true,
        require: '^component',
        templateUrl: 'scripts/editorComponents/bgImage/bgImage.html',
        controller: controller
    };
});
