'use strict';

angular.module('komondor.multitenancy')

.factory('Company', function (DS)
{
    return DS.defineResource({
        name: 'Company',
        endpoint: 'companies',
        upsert: true,
        relations: {
            hasMany: {
                Themes: {
                    localField: 'themes',
                    foreignKey: 'company_id'
                },
                Domains: {
                    localField: 'domains',
                    foreignKey: 'company_id'
                },
            }
        },
        meta: {
            dates: ['created_at', 'updated_at']
        }
    });
});
