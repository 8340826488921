'use strict';

angular.module('presentation.admin')

.controller('CreatePresentLinkCtrl', function (
    $scope,
    $rootScope,
    model,
    DS,
    base_uri,
    moment,
    Share,
    $uibModalInstance,
    $filter,
    Client,
    $uibModal,
    Notification)
{
    this.base_uri = base_uri;
    this.client = null;
    this.missingClientTokens = 0;
    this.tokenStatus = [];

    if (model.client_id !== 0) {
        this.client = Client.get(model.client_id);
    }

    let uuid = DS.utils.guid().substr(0, 8);

    this.share = {
        contact_id: 0,
        presentation_id: model.id,
        client_id: model.client_id,
        client_name: this.client ? this.client.name : 'None',
        uuid: '',
        url: uuid,
        type: 'present',
        expiry: 'never'
    };

    $scope.$watch('$ctrl.share.url', function(newval)
    {
        this.share.uuid = $filter('slug')(newval);
    }.bind(this));

    $scope.$watch('$ctrl.share.client_id', function(newval)
    {
        this.client = Client.get(newval);
        this.checkTokenStatus();
    }.bind(this));

    this.save = () =>
    {
        var tokensMissing = false;
        angular.forEach(this.tokenStatus, (stat) =>
        {
            if (stat.status === false) {
                tokensMissing = true;
            }
            return false;
        });

        if (tokensMissing) {
            Notification.warning('Please fix missing client tokens first!');
            return;
        }

        Share.create({
            presentation_id: model.id,
            client_id: this.share.client_id,
            contact_id: this.share.contact_id,
            uuid: this.share.uuid,
            type: this.share.type,
            expires_at: null
        }).then((model) =>
        {
            $uibModalInstance.close(model);
        });
    };

    this.fixClientTokens = function ()
    {
        let modal = $uibModal.open({
            templateUrl: 'views/modals/missingClientTokens.html',
            controller: 'MissingClientTokensCtrl',
            windowClass: 'editor-modal',
            size: 'lg',
            resolve: {
                client: function ()
                {
                    return this.client;
                }.bind(this),
                missingTokens: function ()
                {
                    var missingTokens = [];

                    angular.forEach(this.tokenStatus, (token) =>
                    {
                        if (!token.status) {
                            missingTokens.push(token.token);
                        }
                    });

                    return missingTokens;
                }.bind(this)
            }
        });

        modal.result.then(this.checkTokenStatus.bind(this));
    };

    this.checkTokenStatus = () =>
    {
        var chapterSLides = model.getChapterSlides();

        this.tokenStatus = this.client ? this.client.getTokenStatus(chapterSLides) : [];

        this.missingClientTokens = 0;

        angular.forEach(this.tokenStatus, (token) => {
            if (!token.status) {
                this.missingClientTokens++;
            }
        });
    };

    this.checkTokenStatus();
});
