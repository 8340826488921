'use strict';

angular.module('app')

.directive('profilePresItem', function (Presentation, Share, $uibModal)
{
    return {
        replace: true,
        restrict: 'A',
        templateUrl: 'app/user-profile/profile-pres-item.html',
        controllerAs: '$ctrl',
        controller: function controller($scope)
        {
            this.data = $scope.value;

            this.state = {
                open: false
            };

            this.toggleContent = () => {
                this.state.open = !this.state.open;
            };

            this.openPresentation = () => {
                Presentation.inject(this.data).goInto();
                $scope.$dismiss();
            };

            this.openShareStats = (link) => {
                $uibModal.open({
                    templateUrl: `app/presentation/admin/share/share-analytics.html`,
                    windowClass: 'share-analytics',
                    controllerAs: '$ctrl',
                    controller: 'ShareAnalyticsCtrl',
                    resolve: {
                        model: () =>
                        {
                            return Share.findAll({
                                where: {id: link.id},
                                with: 'stats',
                            }).then(function (models)
                            {
                                $scope.$dismiss();
                                return models[0];
                            });
                        }
                    }
                });
            };
        }
    };
});

