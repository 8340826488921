'use strict';

angular.module('app')

.factory('efl', function (ThemeConfig)
{
    var theme = new ThemeConfig({
        White: '#FFFFFF',
        Black: '#000000',
        Gold: '#B69A42',
        Blue: '#26358C',
        Red: '#CC0835',
        Orange: '#EE7A15',
        'Light blue': '#389FE2',
        Green: '#2F9B3D',
        Grey: '#777A71',
        None: ''
    });

    theme.appConfig = function (options)
    {
        options.presentation.marginals.appendDateEnabled = false;
        options.presentation.marginals.chapterNumberEnabled = false;
    };

    var colourObject = theme.customColoursObjectInverted([
        'White',
        'Black',
        'Gold',
        'Blue',
        'Red',
        'Orange',
        'Light blue',
        'Green',
        'Grey'
    ]);

    var colourList = theme.customColoursList([
        'White',
        'Black',
        'Gold',
        'Blue',
        'Red',
        'Orange',
        'Light blue',
        'Green',
        'Grey'
    ]);


    /**
     * Modify the editor options to suit this theme
     *
     */
    theme.editorOptions = function (options)
    {
        delete options.nestableComponents.column.nested.tabs;

        options.componentOptions.textblock.tags = {
            h1: 'Hero heading',
            h2: 'Heading',
            h3: 'Sub-heading',
            h4: 'Stand-first',
            p: 'Paragraph',
            blockquote : 'Quote',
            cite: 'Cite'
        };

        delete options.componentOptions.textblock.tags.h5;
        delete options.componentOptions.textblock.tags.h6;

        options.componentOptions['shape-line'].defaultColor = theme.getColour('Red');
        options.componentOptions['shape-line'].allowedTags = {};

        options.componentOptions.column.allowedTags = {};
        options.componentOptions.grid.allowedTags = {};
        options.componentOptions.chart.allowedTags = {};
        options.componentOptions.image.allowedTags = {};
        options.componentOptions['chapter-header'].allowedTags = {};
        options.componentOptions['high-map'].allowedTags = {};
        options.componentOptions['bg-image'].allowedTags = {};
        options.componentOptions['video-player'].allowedTags = {};
        options.componentOptions['comp-panel'].allowedTags = {};

        options.componentOptions['comp-panel'].background = {
            default: theme.getColour('White'),
            colors: colourObject
        };

        options.textColours = theme.customColoursObject([
            'Gold',
            'Blue',
            'Red',
            'Orange',
            'Light blue',
            'Green',
            'Grey'
        ]);

        options.backgroundColors = theme.customColoursObjectInverted([
            'White',
            'Black',
            'Blue',
            'Red'
        ]);
    };

    /**
    * Modify the map config to suit this theme
    *
    */
    theme.mapConfig = function (options)
    {
        options.themes.purple = {
            name: 'Red',
            color: theme.getColour('Red'),
            nullColor: theme.getColour('Grey', 0.5),
            borderWidth: 0,
            borderColor: '#777A71Black',
        };
    };

    /**
     * Modify the chart colours to suit this theme
    */
    theme.chartThemeConfig = function (options)
    {
        options.themes.light.colors.highlight = theme.getColour('Red');
        options.themes.light.colors.arrows = theme.getColour('Red');
        options.themes.light.colors.series = colourList;

        options.themes.dark.colors.highlight = theme.getColour('Red');
        options.themes.dark.colors.arrows = theme.getColour('Red');
        options.themes.dark.colors.series = colourList;
    };

    theme.swatchColorsConfig = function (options)
    {
        options.default = theme.getColour('Red');
        options.colors = colourObject;
    };

    /**
     * Modify the presentation config to suit this theme
     *
    */
    theme.presentationConfig = function (options)
    {
        options.marginals = 'footer';
    };

    return theme;
});
