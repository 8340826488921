'use strict';

angular.module('presentation.theme')

.component('themeTabs', {
    templateUrl: 'app/presentation/theme/theme.builder/tabs/tabs/theme.builder.tabs.html',
    bindings: {
        config: '=',
        palette: '=',
        textStyles: '=',
        borderStyles: '=',
    },
    controller: function () {
        this.$onInit = () => {
            this.textStyleMap = this.textStyles.reduce((map, textStyle) => {
                map[textStyle.tag] = textStyle.name;
                return map;
            }, {});
        };
    }
});
