'use strict';

angular.module('app')
.factory('lottie', lottieFactory);

function lottieFactory ($window)
{
    $window.lottie.setQuality(2);

    return $window.lottie;
}
