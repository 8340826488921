'use strict';

angular.module('app')
.controller('CustomLogoCtrl', function ($scope, logo, $uibModalInstance, Asset, Notification)
{
    $scope.logo = angular.copy(logo);

    if (angular.isArray($scope.logo)) {
        $scope.logo = {asset_id: 0, brand_color: '#FFFFFF'};
    }

    $scope.$watch('logo.asset_id', function (newVal, oldVal) {

        var model = Asset.get(newVal);

        if (model && model.isSvg()) {
            Notification.error({
                title: 'This image format is not allowed!',
                message: 'Logo cannot be SVG, please use PNG or JPG.'
            });

            $scope.logo.asset_id = oldVal;
        }

        $scope.logo.asset_id = $scope.logo.asset_id || 0;

    });

    $scope.saveData = function ()
    {
        Object.keys($scope.logo).forEach(function(key) {
            logo[key] = $scope.logo[key];
        });

        $uibModalInstance.close($scope.logo);
    };

    $scope.cancelModal = function ()
    {
        $uibModalInstance.dismiss();
    };
});
