'use strict';

angular.module('app')

.factory('UserTheme', function(
    ThemeConfig,
    $rootScope,
    Theme,
    defaultThemeConfig,
    prepareEditorClientOptions,
    objectDefaults,
    $filter
) {
    return function(themeName) {

        // Stores theme configuration saved through Theme Builder
        let themeConfigData = { editor: {} };
        const model = Theme.filter({ name: themeName });

        // If model exist
        if (model.length > 0) {
            themeConfigData = angular.copy(model[0].config);
        } else {
            throw new Error("Theme not found");
        }

        // Theme color list
        const palette = themeConfigData.colors.palette;
        const paletteKeys = Object.keys(palette);

        // ThemeConfig instance
        const theme = new ThemeConfig(palette);

        // Ensure all defaults are set
        objectDefaults(themeConfigData, defaultThemeConfig());

        theme.getPanelName = function() {
            return 'Block';
        };

        theme.editorOptions = function(options)
        {
            const bgColors = paletteKeys || [];
            const textColors = bgColors;

            if (themeConfigData.tabs) {
                options.componentOptions.tabs.titlePosition = themeConfigData.tabs.position;
            }

            if (!themeConfigData.maps.enabled) {
                removeDisabledComponents('high-map', options);
            }

            if (!themeConfigData.charts.enabled) {
                removeDisabledComponents('chart', options);
            }

            if (!themeConfigData.shapes.enabled) {
                removeDisabledComponents('comp-panel', options);
            } else {
                // Panel component

                if (!themeConfigData.shapes.allowLegacyBg) {
                    options.componentOptions['comp-panel'].background = {};
                }

                options.componentOptions['comp-panel'].height = themeConfigData.shapes.allowHeightOptions || false;

                options.componentClasses['comp-panel'] = {
                    default: '',
                    classes: {}
                };

                options.componentOptions['comp-panel'].classValidator = (className, classes) =>
                {
                    if (className && className.startsWith('cirle-')) {
                        className = className.replace('cirle-', 'circle-');
                    }

                    if (!classes) {
                        return '';
                    }

                    const validClassList = Object.keys(classes.classes);
                    const defaultClass = validClassList.indexOf(classes.default) >= 0 ? classes.default : validClassList[0];

                    return validClassList.indexOf(className) >= 0 ? className : defaultClass;
                };

                angular.forEach(themeConfigData.shapes.shapeStyles, function(shapesStyle, key) {
                    if (key === 0) {
                        options.componentClasses['comp-panel']['default'] = shapesStyle.name;
                    }
                    options.componentClasses['comp-panel'].classes[shapesStyle.name] = shapesStyle.name;
                });

            }

            if (!themeConfigData.tables.enabled) {
                removeDisabledComponents('table', options);
            }

            if (!themeConfigData.tabs.enabled) {
                removeDisabledComponents('tabs', options);
            }

            if (!themeConfigData.iframes.enabled) {
                removeDisabledComponents('i-frame', options);
            }

            options.backgroundColors = theme.customColoursObjectInverted(bgColors);
            options.textColours = theme.customColoursObject(textColors);
            options.textColours = $filter('coloursFilter')(options.textColours, 'transparent');
            options.filteredColours = $filter('coloursFilter')(options.backgroundColors, 'transparent');

            options.componentOptions['shape-line'].margin = true;

            // Bullet points
            options.componentClasses.bullets = {
                title: 'Bullet style',
                default: '',
                classes: {}
            };

            angular.forEach(themeConfigData.typography.listStyles, function(listStyle, key) {
                if (!key) {
                    options.componentClasses.bullets['default'] = listStyle.className;
                }
                options.componentClasses.bullets.classes[listStyle.className] = listStyle.name;
            });

            // Text styles
            options.componentOptions.textblock.tags = {};
            options.componentOptions.textblock.tagVariants = {};
            angular.forEach(themeConfigData.typography.textStyles, function(style) {

                options.componentOptions.textblock.tags[style.tag] = style.name;

                if (style.variants) {
                    options.componentOptions.textblock.tagVariants[style.tag] = {};
                    angular.forEach(style.variants, function(variant) {
                        options.componentOptions.textblock.tagVariants[style.tag]['variant-' + variant.id] = variant.name;
                    });
                }
            });

            setBackgroundOverlayClasses(themeConfigData.images.backgroundOverlays, options);

            if (themeConfigData.brandColor.enabled) {
                prepareEditorClientOptions.update(options);
            }
        };

        theme.mapConfig = function(options)
        {
            var mapConfig = themeConfigData.maps.themes;

            angular.forEach(mapConfig, function(value, key) {
                var mapOptions = mapConfig[key];

                options.themes[key] = {
                    name: key,
                    color: palette[mapOptions.selectionColor],
                    nullColor: palette[mapOptions.nullColor],
                    borderWidth: mapOptions.borderWidth,
                    borderColor: palette[mapOptions.borderColor]
                };
            });
        };

        theme.chartThemeConfig = function(options)
        {
            const chartConfig = themeConfigData.charts.themes;

            angular.forEach(chartConfig, function(value, key)
            {
                const chartOptions = chartConfig[key];

                options.themes[key].colors.arrows = {
                    increased: palette[chartOptions.arrowColors.increase],
                    decreased: palette[chartOptions.arrowColors.decrease],
                    equal: palette[chartOptions.arrowColors.noChange]
                };

                options.themes[key].colors.highlight = palette[chartOptions.highlightColor];

                options.themes[key].colors.xAxis.line = palette[chartOptions.xAxis.line];
                options.themes[key].colors.yAxis.line = palette[chartOptions.yAxis.line];

                options.themes[key].colors.xAxis.lineWidth = chartOptions.xAxis.width;
                options.themes[key].colors.yAxis.lineWidth = chartOptions.yAxis.width;

                angular.forEach(chartOptions.seriesColors || [], function (sColor, sKey) {
                    if (palette[sColor.color]) {
                        options.themes[key].colors.series[sKey] = palette[sColor.color];
                    }
                });
            });
        };

        theme.presentationConfig = function(options)
        {
            options.marginals = themeConfigData.marginals.marginalsPosition;
        };

        return theme;
    };

    function setBackgroundOverlayClasses (backgroundOverlays, options)
    {
        const classes = {
            ' ': 'None',
        };

        if (backgroundOverlays.length) {
            angular.forEach(backgroundOverlays, function (backgroundOverlay) {
                // classes[backgroundOverlay.name] = backgroundOverlay.label;
                // classes[backgroundOverlay.uuid] = backgroundOverlay.label;
                classes[backgroundOverlay.order] = backgroundOverlay.label;
            });

            options.componentClasses['bg-targeted-image'] = {
                default: ' ',
                classes: classes,
            };

            options.componentClasses['video-player'] = {
                default: ' ',
                classes: classes,
            };
        }
    }

    function removeDisabledComponents (componentName, options)
    {
        delete options.nestableComponents.editor.back_layer[componentName];
        delete options.nestableComponents.editor.components[componentName];

        for (let component in options.nestableComponents) {
            if (!options.nestableComponents.hasOwnProperty(component)) {
                continue;
            }

            if (!options.nestableComponents[component].nested) {
                continue;
            }

            delete options.nestableComponents[component].nested[componentName];
        }

        delete options.nestableComponents[componentName];
    }
});
