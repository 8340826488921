'use strict';

angular.module('app')

.service('clipboard', function ($http, DS, toJson)
{
    this.type = null;

    this.setType = () =>
    {
        $http.get('/api/clipboard/type').then((response) =>
        {
            if (!response.data) {
                return;
            }

            this.type = response.data;
        });
    };

    this.copySlide = (slide) =>
    {
        return $http.post('/api/clipboard/copySlide', {
            'slide': slide
        }).then(() => {
            this.type = 'slide';
        });
    };

    this.pasteSlide = () =>
    {
        return $http.get('/api/clipboard/pasteSlide').then((response) =>
        {
            if (!response.data) {
                return null;
            }

            return DS.inject('Slide', response.data);
        });
    };

    this.copyChapter = (chapter) =>
    {
        return $http.post('/api/clipboard/copyChapter', {
            'chapter': toJson.chapter(chapter)
        }).then(() => {
            this.type = 'chapter';
        });
    };

    this.pasteChapter = () =>
    {
        return $http.get('/api/clipboard/pasteChapter').then((response) =>
        {
            if (!response.data) {
                return null;
            }

            return {
                chapter: DS.inject('Chapter', response.data.chapter),
                slides: response.data.slides.map((slide) => {
                    return DS.inject('Slide', slide);
                })
            };
        });
    };
});
