'use strict';

angular.module('app')

.factory('loadFile', function ($http, $injector, $q)
{
    return (file) =>
    {
        if ($injector.has('localData')) {
            const localData = $injector.get('localData');
            const contents = localData.getFileContents(file);
            return $q.resolve(contents);
        }

        return $http.get(file, {
            cache: true
        }).then((response) => {
            return response.data;
        });
    };
});
