'use strict';

angular.module('app')

.run(function (Icon) {}) // jshint ignore:line

.factory('Icon', function (DS, $state)
{
    return DS.defineResource({
        basePath: '/api/',
        name: 'Icon',
        endpoint: 'icons',
        upsert: true,
        defaultValues: {
            action_id: 0,
        },
        methods: {
            goInto: function ()
            {
                if (this.type === 'folder') {
                    $state.go('admin.icons', {folder_id: this.id, offset: null, name: null});
                } else {
                    $state.go('admin.icons.edit', {id: this.id});
                }
            }
        },
        relations: {
            hasMany: {
                IconToken: {
                    localField: 'tokens',
                    foreignKey: 'icon_id'
                }
            }
        }
    });
});
