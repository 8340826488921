'use strict';

angular.module('app')

.value('loginUrl', '/api/auth/login')
.value('twoFaLoginUrl', '/api/auth/login2fa')
.value('logoutUrl', '/api/auth/logout')
.value('userUrl', '/api/auth/me')

.service('auth', function ($rootScope, $q, $http, $state, loginUrl, twoFaLoginUrl, logoutUrl, userUrl, createTree, DS, kdAuth)
{
    this.loginTwoFa = function (data)
    {
        return $http.post(twoFaLoginUrl, data).then(function (response)
        {
            kdAuth.setUser(response.data);
            return kdAuth.user;
        });
    };

    this.login = function (data)
    {
        return $http.post(loginUrl, data).then(function (response)
        {
            if (response.data.status === 'two_fa') {
                return 'two_fa';
            }

            kdAuth.setUser(response.data);

            return kdAuth.user;
        });
    };

    this.logout = function ()
    {
        return $http.post(logoutUrl).then(function (response)
        {
            kdAuth.setUser(null);
            delete $rootScope.menu;

            DS.clear();

            return response;
        });
    };

    this.isLoggedIn = function ()
    {
        return kdAuth.user;
    };

    this.isGuest = function ()
    {
        if ($rootScope.offline) {
            return true;
        }

        return this.isLoggedIn() && parseInt(kdAuth.user.role_id) === 0;
    };

    this.getUser = function () {

        return $http.get(userUrl).then(function (response) //jshint ignore:line
        {
            kdAuth.setUser(response.data);
            return kdAuth.user;
        });

    };
});
