'use strict';

angular.module('presentation.admin')

.controller('ShareAnalyticsCtrl', function (model)
{
    this.tooltips = {
        averageDuration: 'Average time users have spent viewing the presentation and its slides.',
        views: 'The number of times the presentation has been viewed. Interactions within a 30 minute timeframe count as a single session.'
    };

    this.model = model;

    this.model.stats.timeOnChapterSlides = this.model.stats.timeOnChapterSlides || [];

    this.model.stats.timeOnChapterSlides = this.model.stats.timeOnChapterSlides.map((data) =>
    {
        data.name = '-';

        if (data.chapterSlide && data.chapterSlide.slide) {
            data.name = data.chapterSlide.slide.name;
        }

        return data;
    });

    this.comparator = function (a, b) {
        const valueA = String(a.value);
        const valueB = String(b.value);
        return String.naturalCompare(
            valueA.toLowerCase(),
            valueB.toLowerCase()
        );
    };
});
