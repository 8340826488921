"use strict";

angular
    .module("presentation.analytics", [
        "angulartics",
        "angulartics.heap",
        "angulartics.google.analytics",
    ])

    .config(function ($analyticsProvider) {
        $analyticsProvider.firstPageview(
            true
        ); /* Records pages that don't use $state or $route */
        $analyticsProvider.virtualPageviews(
            false
        ); /* Disable auto tracking as we do it manually now. */
    })

    .run(function (
        $window,
        $rootScope,
        $analytics,
        $location,
        getTrackingTitle,
        $timeout,
        presentationState,
        $stateParams
    ) {
        $rootScope.$on("$stateChangeSuccess", () => {
            if (
                $stateParams.presentationMode === "slideshow" &&
                presentationState.chapter_slide &&
                $window.ga
            ) {
                $window.ga(
                    "set",
                    "dimension3",
                    String(presentationState.chapter_slide.id)
                );
            }

            sendPageTrack();
        });

        if ($window.ga) {
            $rootScope.$on("ga:preload-start", (event, data) => {
                sendPageEvent("preloader-started", data.id);
                sendPageTrack("preloading");
            });

            $rootScope.$on("ga:preloaded", (event, data) => {
                $window.ga("set", "dimension4", String(data.duration));
                $window.ga("set", "dimension5", String(data.total));
                $window.ga("set", "dimension6", String(data.size));

                sendPageEvent("preloader-finished", data.presentation.id);
                sendPageTrack();
            });
        }

        function sendPageTrack(prepend) {
            $timeout(() => {
                $analytics.pageTrack({
                    location:
                        $location.protocol() + "://" + $location.host() + "/",
                    page: "/#" + (prepend ? prepend : "") + $location.path(),
                    title: getTrackingTitle(),
                });
            });
        }

        function sendPageEvent(action, pressId) {
            $timeout(() => {
                $window.ga("send", "event", {
                    eventCategory: "Preloader",
                    eventAction: action,
                    eventLabel: String($rootScope.link_id || 0),
                    eventValue: pressId || presentationState.presentation.id,
                });
            });
        }
    });
