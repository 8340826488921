'use strict';

angular.module('app')

.controller('LoginCtrl', function (
    $scope,
    $state,
    auth,
    $rootScope,
    $stateParams,
    validationNotification,
    homeState,
    Notification
) {
    $scope.user = {
        username: $stateParams.username || '',
        password: '',
        two_fa_code: '',
        remember: false
    };

    $scope.loginStyle = {
        backgroundImage: `url(${$rootScope.config.links.login_image})`
    };

    $scope.saml = $rootScope.config && $rootScope.config.saml.enabled;
    $scope.errorMessage = '';
    $scope.showTwoFaForm = false;

    $scope.twoFaLogin = function ()
    {
        auth.loginTwoFa($scope.user).then(function (use)
            {
                // Clear if there are some notification errors displayed
                Notification.clearAll();
                $state.go(homeState());

                if (!use.two_fa_enabled) {
                    Notification.success('You used backup code! Two factor authentication has been disabled!');
                }

            }, failedLogin);
    };

    $scope.login = function ()
    {
        $scope.error = '';

        auth.login($scope.user).then(function (response)
        {
            if (response === 'two_fa') {
                $scope.showTwoFaForm = true;
            } else {
                // Clear if there are some notification errors displayed
                Notification.clearAll();
                $state.go(homeState());
            }
        }, failedLogin);
    };

    function failedLogin (response)
    {
        $scope.error = true;

        if ([400, 422, 429].indexOf(response.status) > -1) {
            validationNotification.show(response.data, true);
            return;
        }

        if ([423].indexOf(response.status) > -1) {
            $scope.errorMessage = response.data;
            return;
        }

        Notification.error('There was a problem logging in, please try again later.');
    }

});
