"use strict";

angular
    .module("app")

    .config(function ($stateProvider) {
        $stateProvider.state("admin.editor", {
            url: "/{presentationMode:editor|slideshow}",
            abstract: true,
            params: {
                presentationMode: "editor",
            },
            resolve: {
                themes: function (Theme) {
                    return Theme.findAll();
                },
                offlineData: function ($rootScope, $injector) {
                    if ($rootScope.offline) {
                        var localData = $injector.get("localData");
                        return localData.storage.load();
                    }

                    return null;
                },
            },
            views: {
                "footer@": {
                    template: "",
                },
            },
        });

        $stateProvider.state("admin.editor.slide", {
            url: "/slide/{slideId}",
            resolve: {
                presentation: function () {
                    return null;
                },
                chapter: function () {
                    return null;
                },
                chapterSlides: function () {
                    return null;
                },
                slide: function (Slide, $stateParams) {
                    return Slide.find($stateParams.slideId).then(function (
                        slide
                    ) {
                        return slide;
                    });
                },
                slides: function () {
                    return [];
                },
                //Will be removed after preloaded changes to preload icons.
                icons: function (Icon) {
                    return Icon.findAll();
                },
                theme: function ($http, DS) {
                    return $http.get("api/themes/default").then((response) => {
                        return DS.inject("Theme", response.data);
                    });
                },
                presentLink: function () {
                    return null;
                },
                presenteeLink: function () {
                    return null;
                },
            },
            onEnter: function (
                PreviousState,
                themeChanger,
                theme,
                lockTimer,
                slide
            ) {
                PreviousState.saveState();
                themeChanger.setTheme(theme);

                if (!slide.locked_by) {
                    lockTimer.start(slide);
                }
            },
            onExit: function (slide, themeChanger, lockTimer) {
                slide.deleteChecksum();
                themeChanger.removeThemeClass();
                lockTimer.stop();
            },
            views: {
                "header@": {
                    templateUrl: "views/editor/presentation.menu.html",
                    controller: "PresentationMenuCtrl",
                },
                "slideOptions@admin.editor.slide": {
                    templateUrl: "views/editor/slide.options.html",
                    controller: "SlideOptionsCtrl",
                },
                "@admin": {
                    template: `<div class="presentation-transitions"
                                ng-class="[{'edit-mode': presentationState.editMode}, slideAnimation]"
                                ng-include=" 'views/editor/slide.html' "></div>`,
                    controller: "SlideCtrl",
                },
            },
        });

        // Preloads all assets in presentation
        $stateProvider.state("admin.editor.presentation", {
            url: "/{viewMode:presentation|presentLink}/{id}",
            abstract: true,
            params: {
                viewMode: "presentation",
            },
            resolve: {
                /**
                 * If we are in presentMode
                 *
                 * @param $stateParams
                 * @param Share
                 * @param $http
                 * @param $state
                 * @returns {null|*}
                 */
                presentLink: function ($stateParams, Share, $http, $state) {
                    if ($stateParams.viewMode === "presentLink") {
                        let link = Share.find($stateParams.id).then(function (
                            res
                        ) {
                            let presState = res.presenter_state;
                            if (presState.in_use && presState.in_use.id) {
                                $state.go("admin.presentations", {
                                    folder_id: res.presentation.folder_id,
                                    offset: null,
                                    name: null,
                                });
                                return null;
                            }
                            return res;
                        });

                        return link;
                    }
                    return null;
                },
                presenteeLink: function ($stateParams, Share, $rootScope) {
                    if (
                        $stateParams.presentationMode === "slideshow" &&
                        $rootScope.link_id
                    ) {
                        return Share.find($rootScope.link_id).then((link) => {
                            if (link.type === "present") {
                                return link;
                            }

                            return null;
                        });
                    }

                    return null;
                },

                /**
                 *
                 * @param offlineData - Keep this even if not used, it is needed for bundle.
                 */
                presentation: function (
                    offlineData,
                    presentLink,
                    Presentation,
                    $stateParams,
                    $rootScope,
                    $state,
                    $q,
                    $timeout
                ) {
                    let pressId = parseInt($stateParams.id);

                    if (presentLink) {
                        pressId = presentLink.presentation_id;
                    }

                    return Presentation.findAll(
                        {
                            id: pressId,
                            with: "chapters.chapterSlides.slide",
                        },
                        {bypassCache: true}
                    ).then(function (presentation) {
                        presentation = presentation[0];

                        presentation.forceChaptersDisplayOrder();

                        if ($rootScope.client_id) {
                            presentation.client_id = $rootScope.client_id;
                        }

                        //This is for bundle app, then editing different presentation to downloaded.
                        if (pressId !== presentation.id) {
                            $timeout(function () {
                                $state.go(
                                    "admin.editor.presentation.chapter.slide",
                                    {
                                        id: presentation.id,
                                    }
                                );
                            });

                            return $q.reject("Redirected!!");
                        }

                        return presentation;
                    });
                },
                chapters: function (Chapter, presentation) {
                    return presentation.chapters;
                },
                chapterSlides: function (ChapterSlide, chapters) {
                    var chapterSlides = [];

                    angular.forEach(chapters, function (chapter) {
                        chapter.forceSlidesDisplayOrder();

                        angular.forEach(
                            chapter.chapter_slides,
                            function (chapter_slide) {
                                chapterSlides.push(chapter_slide);
                            }
                        );
                    });

                    return chapterSlides;
                },
                slides: function (chapterSlides) {
                    return chapterSlides.map(function (chapterSlide) {
                        return chapterSlide.slide;
                    });
                },
                slide: function () {
                    return null;
                },
                client: function (Presentation, presentation) {
                    if (!presentation.client_id) {
                        return null;
                    }

                    return Presentation.loadRelations(presentation, [
                        "client",
                    ]).then(function (pres) {
                        return pres.client;
                    });
                },
                //Will be removed after preloaded changes to preload icons.
                icons: function (Icon) {
                    return Icon.findAll();
                },
                overlay: function (
                    $rootScope,
                    preloadOverlay,
                    preloader,
                    slides,
                    presentation,
                    $stateParams,
                    client
                ) {
                    client; // jshint ignore:line

                    // Do not run the preloader if in admin editor mode
                    if ($stateParams.presentationMode === "editor") {
                        return;
                    }

                    // Do not run the preloader in offline bundle mode
                    if ($rootScope.offline) {
                        return;
                    }

                    var loaded = preloader.load(slides, presentation);
                    var preloaderTheme = preloader.getTheme();

                    return preloadOverlay.create(
                        loaded,
                        preloader.getStatus(),
                        preloaderTheme,
                        presentation
                    );
                },
                analyticsSession: function (analytics, overlay, $window, $rootScope) {
                    overlay; // jshint ignore:line

                    if ($window.sessionStorage.getItem('track') === 'true' && $rootScope.config.analytics_session_enabled) {
                        return analytics.startSession();
                    } else {
                        return analytics.notifyNonConsent();
                    }
                },
            },
            onEnter: function (
                $rootScope,
                $stateParams,
                presentLink,
                presenteeLink,
                PreviousState,
                presenterService,
                presenteeService,
                presenterOverlay,
                presentation,
                presentationState,
                dropdownMenuBoundary,
                themeChanger,
                $state,
                lockTimer
            ) {
                PreviousState.saveState();
                $rootScope.pageTitle = presentation.name;
                presentationState.currentLayer = null;

                dropdownMenuBoundary.set({
                    top: 47,
                    bottom: 33,
                });

                themeChanger.setTheme(presentation.theme);

                if (
                    !presentation.locked_by &&
                    $stateParams.presentationMode === "editor"
                ) {
                    lockTimer.start(presentation);
                }

                if ($stateParams.viewMode === "presentLink" && presentLink) {
                    presenterService.init(presentLink);
                }

                if (presenteeLink && presenteeLink.type === "present") {
                    presenteeService.init(presenteeLink);
                }
            },
            onExit: function (
                $rootScope,
                analytics,
                presentation,
                dropdownMenuBoundary,
                themeChanger,
                lockTimer,
                presenterService,
                presenteeService
            ) {
                $rootScope.pageTitle = null;
                presentation.deleteChecksum();
                dropdownMenuBoundary.reset();
                themeChanger.removeThemeClass();
                lockTimer.stop();
                analytics.endSession();
                presenterService.endSession();
                presenteeService.end();
            },
            views: {
                "header@": {
                    templateUrl: "views/editor/presentation.menu.html",
                    controller: "PresentationMenuCtrl",
                },
                "sidemenu@": {
                    templateUrl: "views/editor/presentation.sidemenu.html",
                    controller: "SidemenuCtrl",
                },
                "@admin": {
                    template:
                        '<div ui-view class="presentation-transitions" ng-class="[{\'edit-mode\': presentationState.editMode}, slideAnimation]"></div>',
                    controller: "PresentationCtrl",
                },
            },
        });

        $stateProvider.state("admin.editor.presentation.print", {
            url: "/print/{slideId}/{popupId}/:clientId/:showInfo",
            views: {
                "header@": {
                    template: "",
                },
                "sidemenu@": {
                    template: "",
                },
                "@admin": {
                    controller: "PrintPresentationCtrl",
                    templateUrl: "views/presentation.print.html",
                },
            },
            params: {
                clientId: {
                    value: "0", //Needs to be string, otherwise - Error: Default value (0) for parameter 'clientId' is not an instance of Type (string)
                    squash: true,
                },
                showInfo: {
                    value: "0",
                    squash: true,
                }
            },
            resolve: {
                /**
                 * Overwriting default presentation client if we have another client in state params.
                 *
                 * @param client
                 * @param $stateParams
                 * @returns {*}
                 */
                client: function (Client, client, $stateParams) {
                    var clientId = parseInt($stateParams.clientId) || 0;

                    if (!clientId) {
                        return client;
                    }

                    return Client.find(clientId)
                        .then(function (newClient) {
                            return newClient || client;
                        })
                        .catch(function () {
                            //If not found, return default one..
                            return client;
                        });
                },
                loadedAssets: function (
                    presentation,
                    chapters,
                    chapterSlides,
                    slides,
                    client,
                    $stateParams,
                    Slide
                ) {
                    if (client) {
                        presentation.client_id = client.id;
                        presentation.client = client;
                    }

                    let popupId = parseInt($stateParams.popupId);

                    let slide = Slide.get($stateParams.slideId);
                    let popup = null;

                    let showInfo = parseInt($stateParams.showInfo);

                    angular.forEach(slide.popups, function (p) {
                        if (p.id === popupId) {
                            popup = p;
                        }
                    });

                    return {
                        presentation: presentation,
                        slide: slide,
                        popup: popup,
                        showInfo: showInfo
                    };
                },
            },
            data: {
                guestAccess: true,
            },
        });

        $stateProvider.state("admin.editor.presentation.chapter", {
            url: "/chapter/{chapterOrder:[0-9]+}",
            abstract: true,
            params: {
                chapterOrder: "1",
            },
            resolve: {
                chapter: function (
                    $window,
                    $stateParams,
                    Chapter,
                    presentation,
                    chapters,
                    presentationState,
                    Notification
                ) {
                    // After moving chapter to a different display_order, the resolved 'chapters' sequence is not updated.
                    // Hence need to use 'Chapter' and find the correct chapter
                    var list = Chapter.filter({
                        where: {
                            presentation_id: presentation.id,
                        },
                    });

                    var model = presentationState.findByDisplayOrder(
                        list,
                        $stateParams.chapterOrder
                    );

                    if (!model && chapters.length > 0) {
                        var message =
                            "Can't find chapter at position [" +
                            $stateParams.chapterOrder +
                            "]";

                        var alert = Notification.error({
                            message: message,
                            title: "Failed to load presentation",
                        });

                        alert.finally($window.history.back);

                        throw new Error(message);
                    }

                    return model;
                },
            },
        });

        $stateProvider.state("admin.editor.presentation.chapter.slide", {
            url: "/slide/{slideOrder:[0-9]+}",
            params: {
                slideOrder: "1",
            },
            onEnter: function (editorState) {
                if (editorState.history) {
                    editorState.history.clear();
                }
            },
            resolve: {
                slide: function (
                    $window,
                    $stateParams,
                    ChapterSlide,
                    Slide,
                    chapter,
                    slides,
                    presentationState,
                    analytics,
                    analyticsSession,
                    Notification,
                    $rootScope
                ) {
                    if ($window.sessionStorage.getItem('track') === 'true' && $rootScope.config.analytics_session_enabled) {
                        analyticsSession; // jshint ignore:line
                    }

                    if (!chapter) {
                        return null;
                    }

                    var list = ChapterSlide.filter({
                        where: {
                            chapter_id: chapter.id,
                        },
                    });

                    if (list.length === 0) {
                        return null;
                    }

                    var model = presentationState.findByDisplayOrder(
                        list,
                        $stateParams.slideOrder
                    );

                    if (!model) {
                        var message =
                            "Can't find slide at position [" +
                            $stateParams.slideOrder +
                            "]";
                        var alert = Notification.error({
                            message: message,
                            title: "Failed!",
                        });
                        alert.finally($window.history.back);

                        throw new Error(message);
                    }

                    if ($window.sessionStorage.getItem('track') === 'true' && $rootScope.config.analytics_session_enabled) {
                        analytics.startSlideView(model.id);
                    }

                    return Slide.get(model.slide_id);
                },
            },
            views: {
                "slideOptions@admin.editor.presentation": {
                    templateUrl: "views/editor/slide.options.html",
                    controller: "SlideOptionsCtrl",
                },
                "@admin.editor.presentation": {
                    templateUrl: "views/editor/slide.html",
                    controller: "SlideCtrl",
                },
            },
            data: {
                guestAccess: true,
            },
        });
    });
