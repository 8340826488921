'use strict';

angular.module('presentation.frontend')

.factory('preloaderConfig', function ($rootScope, themeInjector)
{
    var preloaderTemplatesUrl = 'views/editor/preloader';
    var activeTheme = 'default';
    var themes = {
        active: activeTheme,
        default : {
            templateUrl: preloaderTemplatesUrl + "/preloader-default.html"
        },
        circle : {
            templateUrl: preloaderTemplatesUrl + "/preloader-circle.html"
        },
        mask : {
            templateUrl: preloaderTemplatesUrl + "/preloader-mask.html"
        }
    };

    var options = {
        themes: themes,
        getTheme: function (name) {
            return themes[name];
        }
    };

    if ($rootScope.theme) {
        var theme = themeInjector.get($rootScope.theme);
        theme.preloader(options);
    }

    return options;
});
