'use strict';

angular.module('app')

.config(function (resourceStateProvider, modalStateProvider)
{
    resourceStateProvider
    .state('admin.slides', {
        url: 'slides',
        model: 'Slide',
        viewOptions: 'slideViewOptions',
        query: ['folder_id'],
        params: {
            folder_id: '0'
        },
        title: 'Slides',
        edit: {
            templateUrl: 'views/slide.edit.html',
            controller: 'SlideEditCtrl',
            resolve: {
                actions: ['Action', function (Action) {
                    return Action.findAll();
                }],
                actionMode: function () {
                    return 'edit';
                }
            }
        },
        breadcrumbs: true,
        move: true,
        deleteCheckDependencies: true
    });

    modalStateProvider
    .state('admin.slides.template', {
        url: '/template/{id:[0-9]+}',
        controller: 'SlideEditCtrl',
        templateUrl: 'scripts/resourceActions/template/template.html',
        resolve: {
            model: ['Slide', 'modalState', function (Slide, modalState) {
                return Slide.find(modalState.stateParams.id).then(function (data) {
                    return data;
                });
            }],
            actionMode: function () {
                return 'template';
            }
        }
    });

    modalStateProvider
    .state('admin.slides.master', {
        url: '/master/{id:[0-9]+}',
        controller: 'SlideEditCtrl',
        templateUrl: 'scripts/resourceActions/master/master.html',
        resolve: {
            model: ['Slide', 'modalState', function (Slide, modalState) {
                return Slide.find(modalState.stateParams.id).then(function (data) {
                    modalState.model = data;
                    return data;
                });
            }],
            actionMode: function () {
                return 'master';
            }
        },
        onDismiss: function (modalState) {
            modalState.model.DSRevert();
        }
    });

});
