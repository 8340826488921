'use strict';

angular.module('presentation.theme')

.directive('themeMarginalsLogo', function ()
{
    return {
        restrict: "E",
        scope: {},
        templateUrl: 'app/presentation/theme/theme.builder/slide-setup/marginals-logo/marginals-logo.html',
        bindToController: {
            config: "="
        },
        controllerAs: '$ctrl',
        controller: function ()
        {
            this.positionOptions = [
                {
                    label: 'Top Left',
                    propertyX: 'left',
                    propertyY: 'top'
                },
                {
                    label: 'Top right',
                    propertyX: 'right',
                    propertyY: 'top'
                },
                {
                    label: 'Bottom left',
                    propertyX: 'left',
                    propertyY: 'bottom'
                },
                {
                    label: 'Bottom right',
                    propertyX: 'right',
                    propertyY: 'bottom'
                }
            ];

            this.setLogoPosition = (positionObj) =>
            {
                this.config.position.label = positionObj.label;
                this.config.position.propertyX = positionObj.propertyX;
                this.config.position.propertyY = positionObj.propertyY;
            };
        }
    };
});
