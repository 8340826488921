'use strict';

angular.module('app')

.factory('targetedImagerySingleItemAction', function (targetedImageryAction, targetedImageryConfig)
{
    return angular.extend({}, targetedImageryAction, {
        name: 'targetedImagerySingleItem',
        tooltip: 'Targeted imagery',
        show: function (model) {
            if (!targetedImageryConfig.allowEdit) {
                return false;
            }
            return model.type === 'image' && model.targeted;
        }
    });
});
