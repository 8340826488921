'use strict';

angular.module('app')

.config(function (resourceStateProvider)
{
    resourceStateProvider
    .state('admin.clients', {
        url: 'clients',
        model: 'Client',
        viewOptions: 'clientViewOptions',
        query: ['folder_id'],
        params: {
            folder_id: '0'
        },
        title: 'Clients',
        edit: {
            templateUrl: 'app/client/client.edit.html',
            controller: 'ClientEditCtrl',
            resolve: {
                actions: ['Action', function (Action) {
                    return Action.findAll();
                }]
            },
            defaults: {
                type: 'client'
            }
        },
        breadcrumbs: true,
        move: true,
        deleteCheckDependencies: true
    });
});
