'use strict';

angular.module('app')

.factory('defaultConfigCache', function ($rootScope, themeInjector, kdAuth)
{
    let currentUserId;
    let configCache;

    return function (configName, options)
    {
        let themeConfig;

        if (currentUserId !== kdAuth.user.id) {
            currentUserId = kdAuth.user.id;
            configCache = {};
        }

        if (!configCache[$rootScope.theme]) {
            configCache[$rootScope.theme] = {};
        }

        themeConfig = configCache[$rootScope.theme];

        if (themeConfig[configName]) {
            return themeConfig[configName];
        }

        const theme = themeInjector.get($rootScope.theme);
        const newOptions = angular.copy(options);
        // Override options based on application theme
        theme[configName](newOptions);
        themeConfig[configName] = newOptions;

        return newOptions;
    };
});
