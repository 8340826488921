'use strict';

angular.module('komondor.multitenancy')

.config(function (resourceStateProvider)
{
    resourceStateProvider.state('admin.companies', {
        url: 'companies',
        model: 'Company',
        viewOptions: 'companyViewOptions',
        title: 'Companies',
        root: {
            resolve: {
                companies: ['Company', function (Company)
                {
                    return Company.findAll();
                }]
            }
        },
        edit: {
            templateUrl: 'app/admin/multitenancy/company/company.edit.html',
            controller: 'CompanyEditCtrl'
        }
    });
});
