'use strict';

angular.module('presentation.theme')

.factory('themeConfigShapes', function ()
{
    return {
        enabled: true,
        allowLegacyBg: false,
        allowHeightOptions: false,
        shapeStyles: [{
            type: 'circle',
            fill: true,
            fillColor: 'color-1',
            border: false,
            borderStyle: 'solid',
            borderWidth: 3,
            borderColor: 'dark',
            name: 'circle-solid-1',
            collapsed: false,
        }],
    };
});
