'use strict';

angular.module('presentation.frontend')

/**
 * This service adds an overlay element to the DOM that gets removed when a promise resolves
 * or you call the skip method
 */
.factory('preloadOverlay', function ($rootScope, $document, $compile, $q, $log, assetCache,  $timeout, $window)
{
    var deferred;
    var isConsent = true;
    $window.sessionStorage.setItem('track', true);

    /**
     * Remove the element if we ever change state
     */
    $rootScope.$on('$stateChangeSuccess', removeElement);

    /**
     * Add a compiled preloader template to the DOM
     *
     * @param Promise a promise that if resolved will close the overlay
     *
     * @return Promise
     */
    function create (loaded, status, theme, presentation)
    {
        var scope = $rootScope.$new();
        var introConfig = presentation.theme.config.intro || {};
        var pressConfig = presentation.config || {};

        scope.skip = skip;
        scope.status = status;
        scope.theme = theme;
        scope.consent = consent;
        scope.isConsent = isConsent;

        // Change this to be theming variables

        scope.intro = {
            enabled: introConfig.enabled && (pressConfig.intro || introConfig.autoEnableAll),
            text: null
        };

        //If intro page enabled check what text we need to show.
        if (scope.intro.enabled) {
            scope.intro.text = introConfig.displayPresentationName ? presentation.name : null;

            if (introConfig.displayImage) {

                if (introConfig.assetId) {
                    assetCache.get(introConfig.assetId).then(function (asset)
                    {
                        scope.backgroundUrl = asset.links.download;
                    });
                } else if (introConfig.assetUrl) {
                    scope.backgroundUrl = introConfig.assetUrl;
                }
            }
        }

        var preloaderThemeTemplate = scope.theme.templateUrl;
        var template = '<div id="preloader" ng-include=" \'' + preloaderThemeTemplate + '\' "></div>';
        var element = $compile(template)(scope);

        angular.element($document[0].body).append(element);

        // The promise that will close the overlay
        deferred = $q.defer();

        // Only resolve the overlay promise if preload is successful
        // Otherwise just show an error and leave the overlay to be resolved
        // by skip.
        loaded.then(function() {
            // only resolve if intro sceen isn't needed
            if (!scope.intro.enabled) {
                deferred.resolve();
            }
        }, function (reason) {
            $log.error(reason);
            scope.reason = reason;
        });

        // Remove overlay once loaded or skipped
        deferred.promise.then(removeElement);

        return deferred.promise;
    }

    /**
     * Remove the overlay from the DOM
     *
     * The compiled element could be stored as state on the preloader
     *
     * @return void
     */
    function removeElement ()
    {
        $timeout(function () {
            var element = $document[0].getElementById('preloader');
            angular.element(element).remove();
        });
    }

    /**
     * Skip the preloading
     *
     * @return void
     */
    function skip ()
    {
        if (deferred) {
            deferred.resolve();
        }
    }

    function consent ()
    {
        isConsent = !isConsent;

        $window.sessionStorage.setItem('track', isConsent);
    }

    return {
        create: create,
        skip: skip,
        consent:consent
    };
});
