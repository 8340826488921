'use strict';

angular.module('komondor-editor')

.directive('sidebarShapeLineFormatting', function ()
{
    return {
        replace: false,
        restrict: 'A',
        require: '^editor',
        templateUrl: 'scripts/editorComponents/shapeLine/shape-line-formatting/sidebar-shape-line-formatting.html',
        controllerAs: '$lineFormattingCtrl',
        controller: function ($scope, $element, editorState)
        {
            this.comScope = editorState.componentScope;

            this.$onInit = () =>
            {
               // console.log('sidebarShapeLineFormatting');
            };
        }
    };
});
