'use strict';

angular.module('app')

.service('defaultPresentationConfig', function (defaultConfigCache)
{
    const options = () =>
    {
        return {
            marginals: 'footer', // footer | header
            marginals_date: false,
            client_logo: 'none', // none | marginals | footer
        };
    };

    this.get = function () {
        return defaultConfigCache('presentationConfig', options());
    };
});
