'use strict';

angular.module('email')

.config(function ($stateProvider, resourceStateProvider)
{

    resourceStateProvider
    .state('admin.email-accounts', {
        url: 'email-accounts',
        model: 'EmailAccount',
        viewOptions: 'emailAccountViewOptions',
        title: 'Email accounts',
        edit: {
            templateUrl: 'app/email/account.edit.html',
            controller: 'EmailAccountEditCtrl'
        },
        move: false
    });

});
