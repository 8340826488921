'use strict';

angular.module('app')

.directive('slideHeight', function ($window, $timeout)
{
    var win = angular.element($window);

    var link = function ($scope, element)
    {
        $timeout(function ()
        {
            var winH;

            function init ()
            {
                checkHeight();
            }

            function checkHeight ()
            {
                winH = $window.innerHeight;
                setHeight();
            }

            function setHeight ()
            {
                element.css({
                    height: winH + 'px'
                });
            }

            function cleanUp ()
            {
                win.off('resize', checkHeight);
            }

            win.on('resize', checkHeight);
            $scope.$on('$destroy', cleanUp);

            init();
        });
    };

    return {
        restrict: 'A',
        link: link
    };
});
