'use strict';

angular.module('app')

.controller('restorePresentationCtrl', function (
    $scope,
    presentation_id,
    baseApiUrl,
    Metric,
    Presentation,
    DS,
    $http,
    $uibModalInstance
) {

    $scope.saveModel = function() {
        return $http.put(`${baseApiUrl}${Presentation.endpoint}/restore/${presentation_id}`).then((data) => {
            Metric.ejectAll();
            $scope.$close();

            DS.emit('restore', data.data);
        }).catch(error => {
            DS.emit('error', 'restore', 'presentation', error.data.error);
        });
    };

    $scope.cancelModal = function() {
        $uibModalInstance.dismiss();
        Presentation.ejectAll();
    };
});
