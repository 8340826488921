'use strict';

angular.module('app')

.controller('PasswordSettingsCtrl', function (
    $scope,
    $http,
    $uibModal,
    moment,
    $rootScope,
    isNumeric,
    $uibModalInstance,
    currentSettings) {

    this.data = currentSettings;

    if (angular.isArray(this.data)) {
        this.data = {};
    }

    this.last_reset_date = moment(currentSettings.last_password_reset_at).format("MMMM Do YYYY, h:mm:ss a");
    delete currentSettings.last_password_reset_at;

    let defaults = {
        passwords_lowerCase: 0,
        passwords_upperCase: 1,
        passwords_number: 0,
        passwords_specialChar: 1,
        passwords_resetOnFirstLogin: 0,
        passwords_rejectOldPasswords: 1,
        passwords_totalChars: 5,
        passwords_resetRecurring: 1,
        passwords_resetEveryWeeks: 52,
        auth_deactivate_enabled: 0,
        auth_deactivate_threshold: 5,
        auth_deactivate_email: 'support@bylder.io',
        force_two_fa_auth: 0,
    };

    Object.keys(defaults).map((key) => {
        this.data[key] = currentSettings[key] ? currentSettings[key] : defaults[key];

        if (isNumeric(this.data[key])) {
            this.data[key] = parseInt(this.data[key]);
        }
    });

    this.saveModel = () => {
        $http.post('/api/app_settings/update', this.data).then(() => {
            $uibModalInstance.close();
        });
    };

    $scope.resetPasswordConfirmation = () =>
    {
        const modal = $uibModal.open({
            templateUrl: 'app/admin/settings/passwords.reset.html',
            controller: 'PasswordsResetCtrl',
            controllerAs: '$ctrl',
            bindToController: true,
        });

        modal.result.then((data) => {
            this.last_reset_date = moment(data.last_password_reset_at.date).format("MMMM Do YYYY, h:mm:ss a");
        });
    };
});
