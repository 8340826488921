'use strict';

angular.module('app')

.service('forceChangePassword', function ($uibModal, kdAuth, $cookies, moment)
{
    this.modalInstance = null;

    this.getCookieKey = () => {
        return 'ignore-pswd-change-' + (kdAuth.user ? kdAuth.user.id : 0);
    };

    this.postpone = () => {
        $cookies.put(this.getCookieKey(), true, {
            'expires' : moment().add(1, 'days').toDate()
        });
    };

    this.postponed = () => {
        return $cookies.get(this.getCookieKey()) ? true : false;
    };

    this.close = () => {
        if (this.modalInstance) {
            this.modalInstance.close();
            this.modalInstance = null;
        }
    };

    this.showModal = () =>
    {
        if (this.postponed()) {
            return;
        }

        this.close();

        this.modalInstance = $uibModal.open({
            templateUrl: 'scripts/services/force-change-password/force-change-password.html',
            controller: 'ForceChangePassword',
            controllerAs: '$ctrl',
            keyboard: false,
            backdrop: 'static',
        });
    };
});
