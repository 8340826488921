'use strict';

angular.module('app')

    .decorator('usersViewOptions', function (
        $delegate,
        kdGate,
        deselectAction,
        multiDeleteAction,
        moment,
        editSingleItemAction,
        deleteSingleItemAction,
        kdLogin,
        notify,
        kdAuth,
        createAction,
        $rootScope,
        $http,
        Domain,
        $state,
        User,
        Notification,
        Stats,
        userProfileAction,
        usersExportAction
    ) {
        const newCreateAction = angular.copy(createAction);
        newCreateAction.template = '<i class="material-icon material-icon-add"></i> New user';
        newCreateAction.act = () => {
            Stats.get().then((stats) => {

                const maxUsers = $rootScope.config.domain.config ? $rootScope.config.domain.config.max_users : null;

                if (maxUsers && stats.number_users >= maxUsers) {
                    return Notification.warning({
                        title: `User limit reached`,
                        message: `Please contact your bylder support person to increase your limit.`
                    });
                }


                $state.go('.edit', {id: 0});
            });
        };

        const loginAsAction = {
            buttonClass: 'btn btn-default',
            tooltip: 'Login as user',
            template: '<span>Login as user</span>',
            act: function (models) {
                kdLogin.loginAs(models[0].id).catch(function () {
                    notify.alert('Failed to login as user.');
                });
            },
            show: function (model) {
                return model.id !== kdAuth.user.id && kdGate.allows('user-management.login-as-user');
            }
        };

        const activeToggle = {
            buttonClass: 'btn btn-default',
            tooltip: `Toggle active`,
            template: `<span>{{ $ctrl.model.active ? 'Deactivate' : 'Activate' }}</span>`,
            act: function (models) {
                const user = models[0];
                const status = !user.active;
                user.DSUpdate({active: status});
            },
            show: function (model) {
                return model.id !== kdAuth.user.id;
            }
        };

        const disable2FaAuth = {
            buttonClass: 'btn btn-default',
            tooltip: `Disable 2 Fa Auth`,
            template: `<span>Disable 2FA Auth</span>`,
            act: function (models) {
                notify.confirm('Are you sure?').result.then(function () {
                    $http.post('api/two-fa/disable', {user_id: models[0].id}).then(() => {
                        models[0].two_fa_enabled = false;
                        return Notification.succes('2Fa Authnetication has been disabled!');
                    });
                });
            },
            show: function (model) {
                return model.two_fa_enabled;
            }
        };

        $delegate.toolbar = [usersExportAction, newCreateAction];

        $delegate.moment = moment;

        $delegate.actions = [
            deselectAction,
            multiDeleteAction,
        ];

        $delegate.inlineActions = [
            editSingleItemAction,
        ];

        if( kdGate.allows('audit-logs')) {
            $delegate.inlineActions.push(userProfileAction);
        }

        $delegate.inlineDropdown = [
            loginAsAction,
            activeToggle,
            disable2FaAuth,
            deleteSingleItemAction
        ];

        $delegate.list.columns.splice(4, 1, {
                label: 'Active',
                name: 'active',
                template: `<i class="material-icon" ng-class="{'material-icon-check': !!model.active}"></i>`,
                sortable: true
            },
            {
                label: 'Last modified',
                name: 'updated_at',
                template: `<span class="no-wrap" uib-tooltip="{{ model.updated_at | date }}">
                        {{ options.moment(model.updated_at).fromNow() }}
                    </span>`,
                sortable: true
            },
            {
                label: '2Fa Auth enabled',
                name: 'updated_at',
                template: `<i class="material-icon" ng-class="{'material-icon-check': !!model.two_fa_enabled}"></i>`,
                sortable: true
            },
            {
                label: 'SSO',
                name: 'updated_at',
                template: `<i class="material-icon" ng-class="{'material-icon-check': !!model.sso_login}"></i>`,
                sortable: false
            },
            {
                label: 'Password',
                name: 'updated_at',
                template: `<i class="material-icon" ng-class="{'material-icon-check': !!model.pswd_login}"></i>`,
                sortable: false
            },
            {
                template: `<contextual-options actions="options.inlineActions"
                                   dropdown-actions="options.inlineDropdown"
                                   models="options.resourceList.selected"
                                   model="model"
                                   class="row-actions pull-right"></contextual-options>`
            });

        return $delegate;
    });
