'use strict';

angular.module('app')

.directive('thumbnailable', function () {
    return {
        restrict: 'A',
        controllerAs: '$ctrl',
        scope: {
            model: '=thumbnailable'
        },
        bindToController: true,
        controller: function ()
        {
            this.state = {
                loading: false,
                url: '',
            };

            this.$onInit = () =>
            {
                if (this.model.thumbnail && this.model.thumbnail.url) {
                    this.state.url = this.model.thumbnail.url;
                    return;
                }

                this.state.loading = true;
                this.state.url = 'images/loading.gif';

                this.model.makeThumbnail().then(() =>
                {
                    this.state.url = this.model.thumbnail.url;
                    this.state.loading = false;
                });
            };
        },
        template: "<div class=\"thumb-image\" ng-class=\"{'loading' : $ctrl.state.loading}\" " +
        "ng-style=\"{'background-image': 'url(' + $ctrl.state.url + ')'}\">" +
        "</div>"
    };
});
