'use strict';

angular.module('app')

.controller('2FaAuthDisableCtrl', function (
    $scope,
    $http,
    auth,
    Notification,
    $uibModalInstance) {

    this.data = {
        password: '',
        error : ''
    };

    this.disable = () =>
    {
        return $http.post('api/two-fa/disable', this.data).then((response) =>
        {
            this.data.error = '';

            return auth.getUser().then(() => {
                $uibModalInstance.close();
                Notification.success(response.data);
            });

        }).catch((response) =>
        {
            this.data.error = response.data;
        });
    };
});
