'use strict';

angular.module('app')



.service('lockTimer', function ($interval, $window, isPhantom)
{
    this.instance = null;
    this.model = null;

    this.unLock = () => {
        if (this.model) {
            this.model.unlock();
        }
    };

    this.start = (model) =>
    {
        if (isPhantom) {
            return;
        }

        this.stop();

        this.model = model;

        this.model.lockForOthers();

        this.instance = $interval(() =>
        {
            this.model.lockForOthers();
        }, 30000); //30 seconds

        $window.addEventListener('beforeunload',  this.unLock);
    };

    this.stop = () =>
    {
        if (this.instance) {
            $interval.cancel(this.instance);

            if (this.model) {
                this.model.unlock();
            }

            this.instance = null;
        }

        $window.removeEventListener('beforeunload',  this.unLock);
    };
});

