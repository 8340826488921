'use strict';

angular.module('presentation.frontend')

    /**
     * This service can be used to cause the browser to make $http requests for every media file
     * and datasheet required by a group of slides. Once those requests are successful the browser
     * cache will mean that you don't experience slow loading when you view slide.
     */
    .factory('preloader', function (
        $window,
        $document,
        $rootScope,
        $q,
        $http,
        Datasheet,
        Asset,
        targetedImageCache,
        assetCache,
        preloaderConfig,
        windowUrl,
        $timeout,
        Slide,
        IsMobile,
        moment
    ) {

        // Local properties
        var status;
        var presentation;
        var preloaderActiveTheme = preloaderConfig.themes.active;
        var startTime;
        var preloadContainer;
        var assetPromises = {};

        /**
         * Status constructor
         */
        function Status() {
            this.allAssets = [];
            this.event = null;
            this.loaded = [];
            this.notLoaded = [];
            this.size = 0;
            this.totalSize = 0;
            this.percent = 0;
            this.downloadingProgress = {};
            this.totalSizeProgress = {};

            this.totalLoaded = () => {
                return this.loaded.length;
            };

            this.total = () => {
                return this.allAssets.length;
            };

            this.updateAssetsLoaded = (model, loaded) => {
                if (loaded) {
                    this.loaded.push(model);
                } else {
                    this.notLoaded.push(model);
                }
            };

            this.calcPercentage = () => {
                $timeout(() => {
                    if (this.totalLoaded() >= this.total()) {
                        this.percent = 100;
                    } else {
                        //Never reduced percentage already loaded..
                        this.percent = Math.max(this.percent, this.size / this.totalSize * 100) || 0;
                    }
                });
            };

            this.updateLoadedSize = (key, loaded) => {
                if(loaded === undefined) {
                    return;
                }
                this.downloadingProgress[key] = loaded;
                this.size = Object.values(this.downloadingProgress).reduce((a, b) => a + b, 0);
                this.calcPercentage();
            };

            this.updateTotalSize = (key, size) => {
                this.totalSizeProgress[key] = size;
                this.totalSize = Object.values(this.totalSizeProgress).reduce((a, b) => a + b, 0);
                this.calcPercentage();
            };

            this.removeFromTotalSize = (key) => {
                delete this.totalSizeProgress[key];
                this.totalSize = Object.values(this.totalSizeProgress).reduce((a, b) => a + b, 0);
                this.calcPercentage();
            };

            this.addList = (items) => {
                angular.forEach(items, (item) => {
                    const key = item.constructor.name + '-' + item.id;

                    this.updateTotalSize(key, item.size);
                });

                return items;
            };
        }

        /**
         * Load everything required for a specific presentation, the returned
         * promise is rejected if anything fails to preload.
         *
         * @param  Array slides
         * @param  Array chapters
         * @param  Array presentation
         *
         * @return Promise
         */
        function load(slides, presentationObject) {
            preloadContainer = $document[0].getElementById('preloaded-images');

            if (preloadContainer) {
                preloadContainer.innerHTML = '';
            }

            presentation = presentationObject || {};

            status = new Status();
            status.allAssets = getPreloadObjects(slides);

            if (presentation.preloader && presentation.preloader.length) {
                status.allAssets.push(...presentation.preloader);
            }

            if (presentation.client && presentation.client.asset_id) {
                status.allAssets.push({
                    id: presentation.client.asset_id,
                    size: 'medium',
                    type: 'media'
                });
            }

            // Instantly resolve if no preloadObject or if we are offline
            // The offline check shouldn't be done here, the preloader doesn't need
            // to know about this, preloader.load shouldn't be called
            if (status.total() === 0 || $rootScope.offline) {
                status.calcPercentage();
                return $q.when(true);
            }

            $rootScope.$broadcast('ga:preload-start', presentationObject);


            startTime = moment();
            // Load the assets and resolve promise, if failed just show message and
            // leave the promise to be resolved by the skip function.
            return loadAssets(status.allAssets).finally(() => {
                const data = {
                    presentation: presentation,
                    duration: moment(moment().diff(startTime)).seconds(),
                    size: status.size,
                    total: status.total()
                };

                $rootScope.$broadcast('ga:preloaded', data);
            });
        }

        /**
         * Make an array of preloadObjects from an array of slides
         *
         * @param  Array slides
         *
         * @return Array
         */
        function getPreloadObjects(slides) {
            var arr = [];

            angular.forEach(slides, function (slide) {

                if (slide.popups && slide.popups.length > 0) {
                    angular.merge(arr, getPreloadObjects(slide.popups));
                    Slide.inject(slide.popups);
                }

                angular.forEach(slide.preloader, function (asset) {
                    this.push(asset);
                }, arr);
            });

            return arr;
        }

        /**
         * Getting assets by Ids, so we can get asset size, update status bar
         * and create array of promises to load urls provided in asset.preloader.
         */
        function loadAssets(preloadObjects) {
            let promises = {};
            let grouped = {
                media: [],
                datasheet: [],
                targeted: [],
            };

            let ids = (list) => {
                return list.map(item => item.id);
            };

            // Organise the ids and known URLs
            angular.forEach(preloadObjects, (preloadObj) => {
                grouped[preloadObj.type].push(preloadObj);
            });

            if (grouped.targeted.length) {
                promises.targeted = Asset.findAll({
                    ids: ids(grouped.targeted).join(','),
                    trash: '',
                    limit: 10000
                }).then(status.addList);
            }

            if (grouped.media.length) {
                promises.assets = Asset.findAll({
                    ids: ids(grouped.media).join(','),
                    trash: '',
                    limit: 10000,
                }).then(status.addList);
            }

            if (grouped.datasheet.length) {
                promises.datasheets = Datasheet.findAll({
                    ids: ids(grouped.datasheet).join(','),
                    limit: 10000 // arbitrary high number
                }).then(status.addList);
            }

            return $q.all(promises).then(() => {
                var promises = [];

                // Get each media file
                angular.forEach(grouped.media, (obj) => {
                    promises.push(getAsset(obj.id));
                });

                // Get each datasheet
                angular.forEach(grouped.datasheet, (obj) => {
                    promises.push(getDataSheet(obj.id));
                });

                // Generate Targeted Images
                angular.forEach(grouped.targeted, (obj) => {
                    promises.push(getTargetedImage(obj));
                });

                // Promise chain, resolve parent promise when this promise resolves
                return $q.all(promises);
            });
        }

        /**
         * Generated and loads targeted images.
         * If there is no client for presentation - instantly resolves.
         *
         * @param obj
         * @param mediaSizes
         * @returns {*}
         */
        function getTargetedImage(obj) {
            return assetCache.get(obj.id).then((asset) => {
                if (!asset.targeted) {
                    return getAsset(asset.id);
                }

                var client = presentation.client;

                var config = {
                    brandColor: '#FFFFFF',
                    assets: {
                        logo: client ? client.asset_id : 0,
                        message: 0
                    },
                    targetedMessage: null
                };

                if (obj.customLogo) {

                    config.brandColor = obj.customLogoData.brand_color || '#FFFFFF';
                    config.assets.logo = obj.customLogoData.asset_id || 0;

                } else {

                    if (obj.brandColor && client && client.brand_color) {
                        config.brandColor = client.brand_color;
                    }

                    if (obj.secondaryLogo && client) {
                        config.assets.logo = client.secondary_asset_id;
                    }
                }

                if (obj.targetedMessage) {
                    config.targetedMessage = obj.targetedMessageData;
                }

                return targetedImageCache.cache(obj.id, config).then((generatedAsset) => {
                    const oldKey = asset.constructor.name + '-' + asset.id;
                    const key = generatedAsset.constructor.name + '-' + generatedAsset.id;

                    status.removeFromTotalSize(oldKey);
                    status.updateTotalSize(key, generatedAsset.size);

                    return getAsset(generatedAsset.id);
                });
            });
        }

        /**
         * Get data of datasheet
         *
         * @param datasheet
         * @param asset
         * @returns {*}
         */
        function getDataSheet(id) {
            var datasheet = Datasheet.get(id);

            if (!datasheet) {
                status.updateAssetsLoaded(id, false);

                return $q.reject('Datasheet [' + id + '] not found.');
            }

            return datasheet.fetchData().then(() => {
                status.updateLoadedSize('sheet-' + id, datasheet.size);
                status.updateAssetsLoaded(datasheet, true);
            });
        }

        /**
         * Download images to browser cache and videos as blobs
         *
         * @param id
         * @returns {*}
         */
        function getAsset(id) {

            if (assetPromises[id]) {
                return assetPromises[id];
            }

            var asset = Asset.get(id);

            if (!asset) {
                status.updateAssetsLoaded(id, false);
                return $q.reject('Asset [' + id + '] not found.');
            }

            var promise = null;


            var url = asset.links.download;

            //If JSON
            if (asset.format.indexOf('json') >= 0) {
                promise = makeAngularRequest(asset, url);
            }

            if (asset.asset_type === 'video') {

                if (IsMobile) {
                    promise = $q.when(null);
                } else {
                    var videoType = asset.links.mp4 ? 'mp4' : 'webm';

                    // Create a promise to load the video
                    var videoPromise = makeNativeRequest(asset, asset.links[videoType], 'blob').then((response) => {
                        if (typeof new $window.XMLHttpRequest().responseType === 'string') {

                            asset.links[videoType] = windowUrl.createObjectURL(response);
                        }
                    });

                    if(asset.links.poster) {
                        // Create a promise to load the poster image
                        var posterPromise = makeNativeRequest(asset, asset.links.poster).then(() => {
                            const img = new $window.Image();
                            img.src = asset.links.poster;
                            preloadContainer.appendChild(img);
                        });

                        // Wait for both the video and poster to load
                        promise = $q.all([videoPromise, posterPromise]);
                    } else {
                        promise = videoPromise;
                    }

                }
            }


            //If anything else
            if (!promise) {
                promise = makeNativeRequest(asset, url);

                if (asset.asset_type === 'image') {
                    promise.then(function () {
                        const img = new $window.Image();
                        img.src = url;
                        preloadContainer.appendChild(img);
                    });
                }
            }

            assetPromises[id] = promise.then(() => {
                status.updateAssetsLoaded(asset, true);
            }, (response) => {
                //If unknown error, ignore it.
                //Ussually this error happens when browser runs out of memory..
                if (response.status === 0) {
                    status.updateAssetsLoaded(asset, true);
                    return true;
                } else {
                    status.updateAssetsLoaded(asset, false);
                }

                // If we return something other than a rejection promise here;
                // then the promise actually gets resolved.
                return $q.reject('Failed [' + url + ']: ' + response.status + ' - ' + response.data);
            });

            return assetPromises[id];
        }

        /**
         *
         * @param url
         * @returns {*}
         */
        function makeAngularRequest(asset, url) {
            var key = 'asset-' + asset.id;

            return $http.get(url, {
                cache: true
            }).then(function (resnponse) {
                status.updateLoadedSize(key, asset.size);

                return resnponse;
            });
        }

        /**
         *
         * @param method
         * @param url
         * @param type
         */
        function makeNativeRequest(asset, url, type) {
            var key = 'asset-' + asset.id;

            return new $window.Promise((resolve, reject) => {
                var xhr = new $window.XMLHttpRequest();
                xhr.open("GET", url);

                if (type) {
                    xhr.responseType = type;
                }

                xhr.onload = function () {
                    if (this.status >= 200 && this.status < 300) {
                        status.updateLoadedSize(key, asset.size);
                        resolve(xhr.response);
                    } else {
                        reject({
                            status: this.status,
                            statusText: xhr.statusText
                        });
                    }
                };

                xhr.onprogress = function (event) {
                    //On safari its XMLHttpRequestProgressEvent and it does not work..
                    //see LDT-639
                    if (event.constructor.name === 'ProgressEvent' && event.lengthComputable) {
                        status.updateLoadedSize(key, event.loaded);
                    }
                };

                xhr.onerror = function () {
                    reject({
                        status: this.status,
                        statusText: xhr.statusText
                    });
                };

                xhr.send();
            });
        }

        /**
         * Get status
         */
        function getStatus() {
            return status;
        }

        /**
         * Get preloader theme
         */
        function getTheme() {
            return preloaderConfig.getTheme(preloaderActiveTheme);
        }

        return {
            load: load,
            getStatus: getStatus,
            getTheme: getTheme,
        };
    });
