'use strict';

angular.module('app')

.factory('wasps', function (ThemeConfig)
{
    var theme = new ThemeConfig({
        Gold: '#FBBA00',
        // Default Colours
        Black: '#000000',
        White: '#ffffff',
        Grey: '#9B979B',
        None: 'transparent'
    });

    /**
     * Modify the editor options to suit this theme
     *
     */
    theme.editorOptions = function (options)
    {
        delete options.nestableComponents.column.nested.tabs;

        /**
         * Default component allowed tags
        */
        options.componentOptions.column.allowedTags = {};
        options.componentOptions.table.allowedTags = {};
        options.componentOptions.grid.allowedTags = {};
        options.componentOptions.chart.allowedTags = {};
        options.componentOptions.image.allowedTags = {};
        options.componentOptions['comp-panel'].allowedTags = {};
        options.componentOptions['chapter-header'].allowedTags = {};
        options.componentOptions['high-map'].allowedTags = {};
        options.componentOptions['bg-image'].allowedTags = {};
        options.componentOptions['shape-line'].allowedTags = {};
        options.componentOptions['video-player'].allowedTags = {};

        /**
         * Shape line options
        */
        options.componentOptions['shape-line'].margin = true;
        options.componentOptions['shape-line'].defaultColor = theme.getColour('Gold');

        /**
         * Text styles
        */
        options.componentOptions.textblock.tags = {
            h1: 'H1',
            h2: 'H2',
            h3: 'H3',
            h4: 'H4',
            h5: 'H5',
            h6: 'H6',
            p: 'Paragraph',
            blockquote : 'Quote',
            cite: 'Cite',
        };

        /**
         * Component colour options
        */
        options.textColours = theme.customColoursObject([
            'Gold',
            'Grey',
            'White',
            'Black',
        ]);

        options.backgroundColors = theme.customColoursObjectInverted([
            'Gold',
            'Grey',
            'White',
            'Black',
        ]);

        options.componentOptions['comp-panel'].background = {
            default: 'Gold',
            colors: theme.customColoursObjectInverted([
                'Gold',
                'Grey',
                'White',
                'Black',
            ])
        };

        /**
         * Component classes options
        */
        options.componentClasses['video-player'] =
        options.componentClasses['bg-targeted-image'] = {
            default: ' ',
            classes: {
                ' ': 'None',
                'bkg-1': 'Gold divider',
                'bkg-2': 'White divider',
            }
        };

        options.componentClasses.bullets = {
            default: 'circles',
            classes: {
                'list-unstyled': 'None',
                circles: 'Circles',
            }
        };

        /**
         * Chart themes
        */
        theme.mapConfig = function (options)
        {
            options.themes.red = {
                name: 'Gold',
                color: theme.getColour('Gold'),
                nullColor: theme.getColour('Gold', 0.3),
                borderWidth: 0,
                borderColor: theme.getColour('black'),
            };
        };

        /**
         * Map themes
        */
        theme.chartThemeConfig = function (options)
        {
            var colourList = theme.customColoursList([
                'Gold',
                'Grey',
                'White',
                'Black',
            ]);

            options.themes.light.colors.highlight = theme.getColour('Gold');
            options.themes.light.colors.series = colourList;

            options.themes.dark.colors.highlight = theme.getColour('Gold');
            options.themes.dark.colors.series = colourList;
        };

    };

    theme.presentationConfig = function (options)
    {
        options.marginals = 'header';
        options.marginals_date = true;
    };

    theme.slideConfig = function (options)
    {
        options.text.color = 'txt-light';
        options.background.color = '#000000';
    };

    theme.modalThemeConfig = function (options)
    {
        options.default = 'md';
        delete options.sizes.sm;
    };

    return theme;
});
