'use strict';

angular.module('presentation.admin')

.controller('ReleaseLinkModalCtrl', function (
    $scope,
    $uibModalInstance,
    Share,
    $http,
    share_id
) {

    this.releaseLink = true;

    $scope.expire = () =>
    {
        $http.post('/api/links/' + share_id + '/release').then((response) =>
        {
            Share.inject(response.data);

            $uibModalInstance.close();
        });
    };
});
