'use strict';

angular.module('presentation.theme')

.directive('themeAssets', function ( )
{
    return {
        restrict: "E",
        templateUrl: 'app/presentation/theme/components/theme.assets/theme.assets.html'
    };
});
