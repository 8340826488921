'use strict';

angular.module('app')

.controller('gridComponentListOptionsCtrl', function ($scope)
{
    $scope.moveGridCellUp = function ()
    {
        $scope.$emit('grid:move', $scope.model, -1);
    };

    $scope.moveGridCellDown = function ()
    {
        $scope.$emit('grid:move', $scope.model, +1);
    };
});
