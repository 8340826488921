'use strict';

angular.module('komondor-editor')

.directive('sidebarAeAnimationOptions', function ()
{
    return {
        replace: false,
        restrict: 'A',
        require: '^editor',
        templateUrl: 'scripts/editorComponents/aeAnimation/ae-animation-options/sidebar-ae-animation-options.html',
        controllerAs: '$animOptionsCtrl',
        controller: function ($scope, $element, editorState)
        {
            this.comScope = editorState.componentScope;

            this.$onInit = () =>
            {
               // console.log('sidebarBgTargetedImageOptions');
            };
        }
    };
});
