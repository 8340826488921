'use strict';

angular.module('app')

.config(function (editorGatesProvider) {
    editorGatesProvider.setGates('ae-animation');
})

.directive('aeAnimation', function (
    $window,
    objectDefaults,
    assetCache,
    lottie,
    $http,
    loadFile,
    isPhantom,
    editorState
) {
    return {
        replace: true,
        require: '^component',
        templateUrl: 'scripts/editorComponents/aeAnimation/aeAnimation.html',
        bindToController: true,
        controllerAs: '$ctrl',
        controller: function($scope, $element)
        {
            let defaults = {
                asset_id: null,
                loop: true
            };

            let el = $element[0].getElementsByClassName('bodymovin-container')[0];
            let instance = null;
            let promise = null;

            let destroyInstance = () =>
            {
                if (instance) {
                    instance.stop();
                    instance.destroy();
                }
            };

            $scope.editorState = editorState;
            $scope.placeholderSrc = $scope.ctrl.options.placeholderImage;
            $scope.data = objectDefaults($scope.data, defaults);

            $scope.$watchGroup(['data.asset_id', 'data.loop'], () =>
            {
                promise = assetCache.get($scope.data.asset_id).then((asset) =>
                {
                    destroyInstance();

                    return loadFile(asset.links.download).then((data) =>
                    {
                        instance = lottie.loadAnimation({
                            container: el,
                            renderer: 'svg',
                            loop: isPhantom ? false : $scope.data.loop,
                            autoplay: false,
                            animationData: data,
                            rendererSettings: {
                                progressiveLoad: true,
                                scaleMode: 'fit',
                            }
                        });

                        return instance;
                    });

                });

                promise.then((inst) =>
                {
                    insertCanvas(inst);

                    if (isPhantom) {
                        inst.goToAndStop(inst.totalFrames, true);
                    } else {
                        inst.play();
                    }

                }).catch(destroyInstance);

            });

            $scope.$on('$destroy', destroyInstance);

            $scope.$on('slideAnimationStart', () =>
            {
                if (!promise) {
                    return;
                }

                promise.then((inst) =>
                {
                    inst.goToAndStop(0, true);
                });
            });

            $scope.$on('slideAnimationComplete', () =>
            {
                if (!promise) {
                    return;
                }

                promise.then((inst) =>
                {
                    inst.play();
                });
            });

            // http://nicolasgallagher.com/canvas-fix-svg-scaling-in-internet-explorer/
            function insertCanvas (inst)
            {
                const canvas = angular.element(`
                    <canvas height="${inst.animationData.h}"
                            width="${inst.animationData.w}"></canvas>
                `);

                angular.element(inst.wrapper).append(canvas[0]);
            }
        },
    };
});
