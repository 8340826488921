'use strict';

angular.module('app')

.controller('presentationPrintModalCtrl', function (
    $scope,
    presentation,
    $window,
    $document,
    $http,
    $httpParamSerializer,
    $uibModalInstance
) {
    $scope.presentation = presentation;

    $scope.data = {
        client_id: presentation.client_id,
        popups: 0,
        high_res: 0,
        showInfo: 0
    };

    $scope.client_name = presentation.client ? presentation.client.name : 'None';

    function downloadFile(url, fileName) {
        const a = angular.element("<a>")[0];
        a.style.display = "none";
        $document[0].body.appendChild(a);
        a.href = url;
        a.setAttribute("download", fileName);
        a.click();

        // Cleanup
        $window.URL.revokeObjectURL(a.href);
        $document[0].body.removeChild(a);
    }

    $scope.print = function ()
    {
        const url = '/api/presentations/' + presentation.id + '/make-pdf';

        return $http.post(url, $scope.data).then(function (response) {

            if (response.data.url) {
                downloadFile(response.data.url, response.data.name);
            }

            $uibModalInstance.close();
        });
    };
});
