'use strict';

angular.module('presentation.theme')

.component('themePopupColours', {
    templateUrl: 'app/presentation/theme/theme.builder/popups/popup-colours/popup-colours.html',
    bindings: {
        model: '<'
    },
    controller: function (DS, themeConfigPopupsDefaultColour)
    {

        // Hold values for UI collapsible/visible states
        this.toggleFlags = {};

        this.$onInit = () =>
        {
            this.backwardsCompatibility();

            if (Object.keys(this.model.config.popups.colourProfiles).length < 1) {
                this.addColourProfile();
            }
        };

        this.backwardsCompatibility = () => {
            let colourProfiles = this.model.config.popups.colourProfiles;

            if (!angular.isObject(colourProfiles) || angular.isArray(colourProfiles)) {
                this.model.config.popups.colourProfiles = {};
            }

            if (this.model.config.popups.closeButtonAssetId !== undefined) {

                let colour = angular.copy(themeConfigPopupsDefaultColour);

                colour.name = 'Default colour profile';
                colour.closeButtonAssetId = this.model.config.popups.closeButtonAssetId;
                colour.closeButtonWidth = this.model.config.popups.closeButtonWidth;
                colour.closeButtonHeight = this.model.config.popups.closeButtonHeight;
                colour.closeButtonMargin = this.model.config.popups.closeButtonMargin;

                this.addColourProfile(colour);

                delete this.model.config.popups.closeButtonAssetId;
                delete this.model.config.popups.closeButtonWidth;
                delete this.model.config.popups.closeButtonHeight;
                delete this.model.config.popups.closeButtonMargin;
            }
        };

        this.addColourProfile = (profileData = null) =>
        {
            const key = 'colour-' + DS.utils.guid().substr(0, 8);
            const name = this.model.config.popups.colourProfiles.length === 0 ? 'Default colour profile' : 'New colour profile';

            let colour = angular.copy(themeConfigPopupsDefaultColour);
            colour.name = name;

            var data = profileData || colour;

            data.orderBy = Object.keys(this.model.config.popups.colourProfiles).length + 1;

            this.model.config.popups.colourProfiles[key] = data;
        };

        this.deleteColourProfile = (key) =>
        {
            delete this.model.config.popups.colourProfiles[key];
        };
    }
});
