'use strict';

angular.module('presentation.theme')

.directive('themeChartColors', function ()
{
    return {
        restrict: "E",
        templateUrl: 'app/presentation/theme/theme.builder/charts/chart-colors/chart-colors.html',
        scope: {
            model: '=',
            defaultColors: '=',
        }
    };
});
