'use strict';

angular.module('app')

.service('Stats', function ($http)
{
    const url = '/api/stats';

    this.get = () =>
    {
        return $http.get(url)
            .then((response) => response.data);
    };

});
