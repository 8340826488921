"use strict";

angular.module('app')

.factory('gridThumbnailableTemplate', function ()
{
    return function ()
    {
        return '<div class="thumb-wrapper" ng-click="admin.goToModel($event, model)">' +
                    '<div class="thumb" ng-if="model.type !== \'folder\'" thumbnailable="model"></div>' +
                    '<div class="icon" ng-if="model.type === \'folder\'">' +
                        '<span ng-class="options.getIcon(model, true)"></span>' +
                    '</div>' +
                '</div>';
    };
});