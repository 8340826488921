'use strict';

angular.module('app')

.service('twoFaSetup', function ($uibModal) {

    this.modalInstance = null;

    this.close = () => {
        if (this.modalInstance) {
            this.modalInstance.close();
            this.modalInstance = null;
        }
    };

    this.showModal = (force = false) => {

        this.close();

        this.modalInstance = $uibModal.open({
            templateUrl: 'app/admin/2fa-auth/2FaAuthCtrl.html',
            controller: '2FaAuthCtrl',
            controllerAs: '$ctrl',
            bindToController: true,
            backdrop: force ? 'static' : true,
            keyboard: !force,
            resolve: {
                force: force
            }
        });
    };
});
