'use strict';

angular
    .module('app')
    .factory('tweenMax', tweenMax);
    
function tweenMax ($window)
{
    return $window.TweenMax;
}
