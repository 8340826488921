'use strict';

angular.module('app')

.factory('iconViewOptions', function (
    $state,
    $q,
    extendDeep,
    Slide,
    multiDeleteAction,
    multiMoveAction,
    resourceViewOptions,
    gridNameTemplate,
    listNameTemplate,
    gridThumbTemplate,
    createAction,
    deselectAction,
    editSingleItemAction,
    deleteSingleItemAction,
    moveSingleItemAction,
    createFolderAction,
    kdGate,
    moment
) {
    var options = angular.copy(resourceViewOptions);
    var gatedCreateAction = angular.copy(createAction);
    var gatedCreateFolderAction = angular.copy(createFolderAction);

    var showCreateButton = function ()
    {
        return kdGate.allows('icon-repository.create');
    };

    gatedCreateAction.show = showCreateButton;
    gatedCreateAction.template = '<i class="material-icon material-icon-add"></i> New icon';
    gatedCreateFolderAction.show = showCreateButton;

    options.moment = moment;

    options.toolbar = [gatedCreateFolderAction, gatedCreateAction];

    options.sorts = [
        {
            label: "Type",
            sort: "type asc,name asc"
        }
    ];

    options.actions = [
        deselectAction,
        multiDeleteAction,
        multiMoveAction,
    ];

    options.inlineActions = [
        editSingleItemAction,
    ];

    options.inlineDropdown = [
        moveSingleItemAction,
        deleteSingleItemAction
    ];

    options.gridActions = [
        ...options.inlineActions,
        ...options.inlineDropdown,
    ];

    extendDeep(options, {
        tagging: true,
        taggingAdvanced: true,
        onItemClick: function ($event, model)
        {
            $event.stopPropagation();
            model.goInto();
        },
        list: {
            columns: [
                {
                    label: 'Name',
                    name: 'name',
                    template: listNameTemplate('name', 'asset.links.thumb', 'type'),
                    sortable: true
                },
                {
                    label: 'Editable',
                    name: 'editable',
                    template: '<i ng-show="model.editable" class="material-icon material-icon-check"></i>',
                    sortable: false
                },
                {
                    label: 'Last modified',
                    name: 'updated_at',
                    template: `<span class="no-wrap" uib-tooltip="{{ model.updated_at | date }}">
                                    {{ options.moment(model.updated_at).fromNow() }}
                                </span>`,
                    sortable: true
                },
                {
                    template: `<contextual-options actions="options.inlineActions"
                                                   dropdown-actions="options.inlineDropdown"
                                                   models="options.resourceList.selected"
                                                   model="model"
                                                   class="row-actions pull-right"></contextual-options>`
                }
            ],
        },
        grid: {
            fields: [
                {
                    template: gridThumbTemplate('asset.links.thumb', 'type'),
                },
                {
                    template: gridNameTemplate('name', 'type'),
                },
                {
                    template: `<contextual-options dropdown-actions="options.gridActions"
                                                   models="options.resourceList.selected"
                                                   model="model"
                                                   class="grid-actions pull-right"></contextual-options>`
                }
            ]
        }
    });

    return options;
});
