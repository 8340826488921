'use strict';

angular.module('app')

/**
 * This service adds an overlay element to the DOM that gets removed when a promise resolves
 * or you call the skip method
 */
.factory('presenteeOverlay', function ($rootScope, $document, $compile, $q, $log, assetCache, $timeout)
{
    let el;
    let onJoinToPresentation;
    let scope;

    function getElement (presentation)
    {
        scope = $rootScope.$new();
        let introConfig = presentation.theme.config.intro || {};
        let pressConfig = presentation.config || {};

        scope.joinToPresentation = joinToPresentation;
        scope.started = false;
        scope.viewer = null;
        scope.isOpened = false;

        // Change this to be theming variables

        scope.intro = {
            enabled: introConfig.enabled && (pressConfig.intro || introConfig.autoEnableAll),
            name: presentation.name
        };

        scope.backgroundUrl = '';

        //If intro page enabled check what text we need to show.
        if (scope.intro.enabled) {

            if (introConfig.displayImage) {

                if (introConfig.assetId) {
                    assetCache.get(introConfig.assetId).then(function (asset)
                    {
                        scope.backgroundUrl = asset.links.download;
                    });
                } else if (introConfig.assetUrl) {
                    scope.backgroundUrl = introConfig.assetUrl;
                }
            }
        }

        let template = '<div id="presentee-overlay" class="ng-hide" ng-include="\'views/editor/presentee.overlay.html\'"></div>';
        return  $compile(template)(scope);
    }

    function create (presentation, onJoin)
    {
        onJoinToPresentation = onJoin;
        angular.element($document[0].body).append(getElement(presentation));

        $timeout(() => {
            el = angular.element($document[0].getElementById('presentee-overlay'));
        });
    }

    function hideElement() {

        if (el) {
            el.addClass('ng-hide');
        }

    }

    function showElement() {
        if (el) {
            el.removeClass('ng-hide');
        }
    }

    /**
     * Remove the overlay from the DOM
     *
     * The compiled element could be stored as state on the preloader
     *
     * @return void
     */
    function removeElement ()
    {
        $timeout(function () {
            let element = $document[0].getElementById('presentee-overlay');
            angular.element(element).remove();
        });
    }

    function joinToPresentation ()
    {
        onJoinToPresentation();
    }

    function setStarted(isStarted)
    {
        scope.started = isStarted;
    }

    function setViewer(viewer)
    {
        scope.viewer = viewer;
    }

    function checkIsOpenedByPresenter(isOpened)
    {
        scope.isOpened = isOpened;
    }


    return {
        hideElement: hideElement,
        showElement: showElement,
        create: create,
        removeElement: removeElement,
        joinToPresentation: joinToPresentation,
        setStarted: setStarted,
        setViewer: setViewer,
        checkIsOpenedByPresenter: checkIsOpenedByPresenter
    };
});
