'use strict';

angular.module('presentation.theme')

.factory('themeConfigTypography', function ()
{
    const config = {
        typography: {
            fontUploaded: false,
            defaultFont: {
                typeface: null,
                weight: null,
                size: 16
            },
            textStyles: [],
            listStyles: [],
        },
        textStyleItem: {
            name: 'default',
            tag: 'p',
            class: 'p',
            typeface: null,
            weight: 'inherit',
            size: 18,
            lineHeight: 24,
            letterSpacing: 0,
            defaultBottomMargin: 10,
            bold: false,
            italic: false,
            underline: false,
            uppercase: false,
            default: false,
        }
    };

    // Recommended text styles
    const defaultTextStyles = [
        {name: 'Paragraph', tag: 'p'},
    ];

    // Build default recommended text styles
    angular.forEach(defaultTextStyles, (style) => {

        const textStyleCopy = angular.copy(config.textStyleItem);
        textStyleCopy.name = style.name;
        textStyleCopy.tag = style.tag;
        textStyleCopy.class = style.tag;

        config.typography.textStyles.push(textStyleCopy);
    });

    return config;
});
