'use strict';

angular.module('app')

.component('backgroundPosition', {
    require: {
        ngModel: 'ngModel'
    },
    template:
        '<div class="background-position-wrapper">' +
            '<div ng-repeat="position in $ctrl.positions"' +
                  'ng-class="{\'active\': $ctrl.ngModel.$modelValue === position }"' +
                  'ng-click="$ctrl.setPosition(position)"></div>' +
        '</div>',
    controller: function ()
    {
        this.positions = [
            // Top row
            '0 0',
            '50% 0',
            '100% 0',
            // Middle row
            '0 50%',
            '50% 50%',
            '100% 50%',
            // Bottom row
            '0 100%',
            '50% 100%',
            '100% 100%',
        ];

        this.setPosition = function (position)
        {
            this.ngModel.$setViewValue(position);
        };
    }
});
