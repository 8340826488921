'use strict';

angular.module('email')

.factory('EmailAccount', function (DS)
{
    return DS.defineResource({
        basePath: '/api/',
        name: 'EmailAccount',
        endpoint: 'emailAccounts',
        upsert: true
    });
});
