'use strict';

angular.module('app').factory('stripPopups', function (deepMap)
{
    return function stripPopups(object)
    {
        return deepMap(object, function (val, key)
        {
            if (key === 'html' && angular.isString(val)) {
                val = val.replace(/\sdata-slide-popup-id="(\d+)"/, '');
            }

            if (key === 'data-slide-popup-id') {
                return '';
            }

            return val;
        }, this);
    };
});
