'use strict';

angular.module('datasheet.model')

.service('dataResourceFactory', function (DS)
{
    var dataSheets = {};

    return {
        getResource: function (dataSheet)
        {
            var dataSheetResource = dataSheets[dataSheet.id];

            if (!dataSheetResource) {

                dataSheets[dataSheet.id] = {
                    resource: this.createResource(dataSheet.id),
                    updated_at: dataSheet.updated_at
                };

            } else if (dataSheet.updated_at !== dataSheetResource.updated_at) {
                dataSheetResource.resource.ejectAll();
            }

            return dataSheets[dataSheet.id].resource;
        },
        createResource: function (datasheetId)
        {
            return DS.defineResource({
                basePath: '/api/datasheets/' + datasheetId + '/',
                name: 'Data_' + datasheetId,
                endpoint: 'data',
                upsert: true
            });
        }
    };
});
