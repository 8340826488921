'use strict';

angular.module('presentation.theme')

.factory('themeConfigCharts', function ()
{
    const chartStyle = {
        highlightColor: 'color-1',
        arrowColors: {
            increase: 'color-4',
            decrease: 'color-2',
            noChange: 'color-3',
        },
        xAxis: {
            line: 'color-1',
            style: 'solid',
            width: 3
        },
        yAxis: {
            line: 'color-1',
            style: 'solid',
            width: 3
        },
        seriesColors: []
    };

    return {
        enabled: false,
        themes: {
            light: angular.copy(chartStyle),
            dark: angular.copy(chartStyle)
        }
    };
});
