'use strict';

angular.module('app')

.controller('slideDeleteCtrl', function ($q, $scope, $rootScope, $state, models, notify, Slide, ChapterSlide)
{
    this.models = models;
    this.slides = models.filter((model) => model.chapters_count < 1);
    this.slidesWithDependencies = models.filter((model) => model.chapters_count > 0);
    this.deleteConfirmation = '';

    this.actions = {
        remove: 'Remove slide from presentations',
        copy: 'Copy of slide remains within presentations’ '
    };

    this.action = 'remove';

    function isDeleteConfirmed (deleteConfirmation)
    {
        if (deleteConfirmation.toLowerCase() === 'delete') {
            return true;
        }

        $rootScope.$broadcast('kd:validation', {
            'delete-confirmation': ' '
        });

        return false;
    }

    this.clearValidation = () =>
    {
        $rootScope.$broadcast('kd:validation', {});
    };

    this.showUsage = (slide) =>
    {
        $state.go('admin.slides.usage', {
            id: slide.id
        });
    };

    this.deleteSlides = () =>
    {
        if (this.slidesWithDependencies.length > 0 &&
            !isDeleteConfirmed(this.deleteConfirmation)) {
            return;
        }

        const promises = models.map((model) => model.DSDestroy({
            params: {
                action: this.action
            }
        }));

        $q.all(promises).then(() =>
        {
            $scope.$close();
            Slide.ejectAll();
            ChapterSlide.ejectAll();
        },
        (response) => {

            notify.alert(response.data, {
                title: 'Failed to delete'
            })
            .result.finally($scope.$dismiss);
        });
    };

});
