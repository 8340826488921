'use strict';

angular.module('presentation.font')

.config(function (resourceStateProvider)
{
    resourceStateProvider.state('admin.fonts', {
        url: 'fonts',
        model: 'Font',
        viewOptions: 'fontViewOptions',
        title: 'Fonts',
        edit: {
            templateUrl: 'app/presentation/font/font.edit.html',
            controller: 'FontEditCtrl'
        }
    });
});
