'use strict';

angular.module('app')

.factory('redbull', function (ThemeConfig)
{
    var theme = new ThemeConfig({
        'White': '#F3F3F3',
        'Blue': '#001D46',
        'Red': '#DD0741',
        'None': ' '
    });

    /**
     * Modify the editor options to suit this theme
     *
     */
    theme.editorOptions = function (options)
    {
        delete options.nestableComponents.column.nested.tabs;

        // Colours
        options.textColours = theme.customColoursObject(['White', 'Blue', 'Red']);
        options.backgroundColors = theme.customColoursObjectInverted(['White', 'Blue', 'Red']);

        // Shape line
        options.componentOptions['shape-line'].margin = true;
        options.componentOptions['shape-line'].defaultColor = theme.getColour('Red');
        options.componentOptions['shape-line'].defaultHeight = 10;

        // Panel colours
        options.componentOptions['comp-panel'].background.colors = options.backgroundColors;

        // Bulletpoints
        options.componentClasses.bullets = {
            default: 'large',
            classes: {
                large: 'Large',
                small: 'Small'
            }
        };

        // Background image
        options.componentClasses['bg-targeted-image'] = {
            default: ' ',
            classes: {
                ' ': 'None',
                'bkg-1': 'Triangle blue',
            }
        };

        // Text styles
        options.componentOptions.textblock.tags = {
            h1: 'Header 1',
            h2: 'Header 2',
            h3: 'Header 3',
            h4: 'Header 4',
            p: 'Paragraph',
            h5: 'Small paragraph'
        };

        // Delete unused text tags
        delete options.componentOptions.textblock.tags.h6;
        delete options.componentOptions.textblock.tags.cite;
        delete options.componentOptions.textblock.tags.blockquote;

        // Configure available components
        options.nestableComponents.grid.nested.column = 'Column';
        options.nestableComponents.table.nested.column = 'Column';

    };

    theme.presentationConfig = function (options)
    {
        options.marginals_date = true;
    };

    theme.slideConfig = function (options)
    {
        options.text.color = 'txt-light';
    };

    return theme;
});
