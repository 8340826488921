'use strict';

angular.module('presentation.theme')

.factory('themeConfigCustom', function ()
{
    return {
        css: ''
    };
});
