'use strict';

angular.module('app')

.config(function (editorGatesProvider) {
    editorGatesProvider.setGates('i-frame');
})

.directive('iFrame', function ($window, objectDefaults, $sce, $uibModal, assetCache, isPhantom) {
    return {
        replace: true,
        require: '^component',
        templateUrl: 'scripts/editorComponents/iFrame/iFrame.html',
        bindToController: true,
        controllerAs: '$ctrl',
        controller: function($scope, $element)
        {
            const component = $element.controller('component');

            this.state = component.editor.state;

            $scope.data = objectDefaults($scope.data, {
                url: '',
                asset_id: 0,
                width: {
                    value: 100,
                    unit: '%',
                },
                height: {
                    value: '',
                },
                allow: {
                    fullscreen: true,
                }
            });

            $scope.computed = {
                posterUrl : '',
                print: isPhantom
            };

            $scope.changeWidthUnit = function ()
            {
                $scope.data.width.unit = $scope.data.width.unit === 'px' ? '%' : 'px';
            };

            $scope.trustSrc = function(src) {
                return $sce.trustAsResourceUrl(src);
            };

            $scope.openUrlPicker = function()
            {
                return $uibModal.open({
                    size: 'md',
                    templateUrl: 'scripts/editorComponents/iFrame/urlEditModal.html',
                    replace: false,
                    controller: 'urlModalCtrl',
                    windowClass: 'url-modal',
                    resolve: {
                        url: function () {
                            return $scope.data.url;
                        }
                    }
                }).result.then(function (url) {
                    $scope.data.url = url;
                });
            };

            $scope.clear = function ()
            {
                $scope.data.url = '';
            };

            $scope.$watch('data.asset_id', function () {

                if (!$scope.data.asset_id) {
                    $scope.computed.posterUrl = component.options.placeholderImage;
                    return;
                }

                assetCache.get($scope.data.asset_id).then(function (asset)
                {
                    $scope.computed.posterUrl = asset.links.download;
                });

            }, true);
        },
    };
});
