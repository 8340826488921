'use strict';

angular.module('presentation.theme')

.directive('themeMarginals', function ($filter)
{
    return {
        restrict: "E",
        templateUrl: 'app/presentation/theme/theme.builder/slide-setup/marginals/marginals.html',
        scope: {
            marginalsConfig: "=",
            sidebarColour: "=",
            palette: "="
        },
        controller: function ($scope)
        {
            $scope.marginalOptions = {

                leftSide: [
                    {
                        label: "Chapter name",
                        value: "chapterName",
                        sample: "1.Chapter name"
                    },
                    {
                        label: "Slide name",
                        value: "slideName",
                        sample: "Slide name"
                    },
                ],

                middle: [
                    {
                        label: "Presentation name",
                        value: "presentationName",
                        sample: "Presentation name"
                    },
                    {
                        label: "Last modified date",
                        value: "lastModified",
                        sample: $filter('date')(new Date(), 'd/M/yyyy')
                    },
                    {
                        label: "None",
                        value: " ",
                        sample: " "
                    },
                ],

                rightSide: [
                    {
                        label: "Page no. (In Chapter)",
                        value: "pageNo",
                        sample: "5"
                    },
                    {
                        label: "Page no. (In Total)",
                        value: "pageNoTotal",
                        sample: "98"
                    },
                    {
                        label: "Presentation pagination",
                        value: "presentationPagination",
                        sample: "10/160"
                    },
                    {
                        label: "Chapter pagination",
                        value: "chapterPagination",
                        sample: "10/16"
                    },
                    {
                        label: "Client logo",
                        value: "clientLogo",
                        sample: "<client logo>"
                    },
                    {
                        label: "Client logo/color",
                        value: "clientLogoColor",
                        sample: "<client logo/color>"
                    },
                ]
            };

            $scope.previewConfig = {
                left: getPreviewSample($scope.marginalsConfig.leftSide),
                middle: getPreviewSample($scope.marginalsConfig.middle),
                right: getPreviewSample($scope.marginalsConfig.rightSide),
            };

            $scope.updatePreview = function (position, data)
            {
                $scope.previewConfig[position] = data;
            };

            function getPreviewSample (value)
            {
                const marginalOptionsMap = [].concat(
                    $scope.marginalOptions.leftSide,
                    $scope.marginalOptions.middle,
                    $scope.marginalOptions.rightSide
                );

                const result = marginalOptionsMap.filter(function (item) {
                    return item.value === value;
                });

                return result.length ? result[0].sample : '';
            }

        }
    };
});
