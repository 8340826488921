'use strict';

angular.module('app')

.factory('fresqo', function (ThemeConfig)
{
    var theme = new ThemeConfig();

    /**
     * Modify the editor options to suit this theme
     *
     */
    theme.editorOptions = function (options)
    {
        delete options.nestableComponents.column.nested.tabs;

        options.componentOptions['comp-panel'].background = {
            default: ' ',
            colors: {
                ' ': 'None',
                transparent: 'White',
                '#000000': 'Black',
                '#FF4800': 'Red',
                '#21BABA': 'Cyan',
                '#F9EC02': 'Yellow'
            }
        };

        options.componentClasses['comp-panel'] = {
            default: 'block-default',
            classes: {
                'block-default': 'Default',
                'block-skew': 'Skew'
            }
        };

        options.componentOptions.textblock.tags = {
            h1: 'Header',
            h2: 'Sub-header',
            p: 'Paragraph'
        };

        options.textColours = {
            Black: '#4b4b4d',
            White: '#FFFFFF',
            Red: '#FF4800',
            Cyan: '#00CCCC',
            Yellow: '#F9EC02'
        };

        options.backgroundColors = {
            '#ffffff': 'White',
            '#000000': 'Black',
            '#FF4800': 'Red',
            '#21BABA': 'Cyan',
            '#F9EC02': 'Yellow'
        };

        options.componentOptions['shape-line'].defaultColor = '#FF4800';
        options.componentOptions.column.allowedTags = {};
        options.componentOptions.grid.allowedTags = {};
        options.componentOptions.chart.allowedTags = {};
        options.componentOptions.image.allowedTags = {};
        options.componentOptions['comp-panel'].allowedTags = {};
        options.componentOptions['chapter-header'].allowedTags = {};
        options.componentOptions['high-map'].allowedTags = {};
        options.componentOptions['bg-image'].allowedTags = {};
        options.componentOptions['shape-line'].allowedTags = {};
        options.componentOptions['video-player'].allowedTags = {};
    };

    /**
     * Modify the map config to suit this theme
     *
     */
    theme.mapConfig = function (options)
    {
        options.themes.grey = {
            name: 'Grey',
            color: '#8A8A8A',
            nullColor: 'rgba(138,138,138,0.3)',
            pinStyle: {
                backgroundColor: '#ffffff',
                border: '2px solid #8A8A8A',
            },
            labelStyle: {
                backgroundColor: 'rgba(255,255,255,0.7)',
                color: '#000000',
                border: '2px solid #000000',
                fontFamily: 'helvetica neue',
            },
            borderWidth: 0,
            borderColor: '#000000',
        };
    };

    /**
     * Modify the preloader to suit this theme
    */
    theme.preloader = function (options)
    {
        options.themes.active = 'mask'; // default, circle or mask
    };

    /**
     * Modify the chart colours to suit this theme
    */
    theme.chartThemeConfig = function (options)
    {
        options.themes.light.colors.series = [
            '#FF4800',
            '#00CCCC',
            '#F9EC02',
            '#F59638',
            '#FF00FF'
        ];

        options.themes.dark.colors.series = [
            '#FF4800',
            '#00CCCC',
            '#F9EC02',
            '#F59638',
            '#FF00FF'
        ];

        options.themes.light.colors.arrows = '#FF4800';
        options.themes.dark.colors.arrows = '#00CCCC';
    };

    return theme;

});
