'use strict';

angular.module('app')

.config(function (resourceStateProvider)
{
    resourceStateProvider
    .state('admin.contacts', {
        url: 'contacts',
        model: 'Contact',
        viewOptions: 'contactViewOptions',
        query: ['folder_id'],
        params: {
            folder_id: '0'
        },
        title: 'Contacts',
        edit: {
            templateUrl: 'app/contact/contact.edit.html',
            controller: 'ContactEditCtrl',
            resolve: {
                actions: ['Action', function (Action) {
                    return Action.findAll();
                }]
            },
            defaults: {
                type: 'contact'
            }
        },
        breadcrumbs: true,
        move: true,
        deleteCheckDependencies: false
    });
});
