'use strict';

angular.module('presentation.theme')

.directive('themeChartStyles', function ()
{
    return {
        restrict: "E",
        templateUrl: 'app/presentation/theme/theme.builder/charts/chart-styles/chart-styles.html',
        scope: {
            chartsConfig: "=",
            palette: "="
        },
        controller: ['$scope', function ($scope) {

            $scope.chartsConfig.dark.seriesColors = $scope.chartsConfig.dark.seriesColors || [];
            $scope.chartsConfig.light.seriesColors = $scope.chartsConfig.light.seriesColors || [];

            $scope.addSeriesColor = (theme) =>
            {
                $scope.chartsConfig[theme].seriesColors.push({
                    color: null
                });
            };

            $scope.removeSeriesColor = (theme, key) =>
            {
                $scope.chartsConfig[theme].seriesColors.splice(key, 1);
            };
        }]
    };
});
