'use strict';

angular.module('app')

/*
* A factory to simply pass a set of JSON defaults to
* the Angular Leaflet directive.
* https://github.com/tombatossals/angular-leaflet-directive
*/
.factory('mapConfigPresets', function ($window, highmapCustomRegions)
{
    // Set default regions to a custom World map
    highmapCustomRegions.addDefaults();

    return {
        options: {
            chart: {
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                borderWidth: 0,
                plotBorderWidth: 0,
                margin: 0,
                panning: false
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                map: {
                    mapData: $window.Highcharts.maps['custom/world'],
                    joinBy: ['name']
                }
            },
            mapNavigation: {
                buttonOptions: {
                    align: 'right',
                    verticalAlign: 'top',
                    width: 25,
                    height: 25,
                    style: {
                        fontSize: '18px',
                        fontWeight: 'bold',
                        textAlign: 'center'
                    }
                },
                buttons: {
                    zoomIn: {
                        x: -50,
                        y: 50
                    },
                    zoomOut: {
                        x: -50,
                        y: 85
                    }
                },
                enabled: false,
                enableDoubleClickZoom: false,
                enableMouseWheelZoom: false,
                enableTouchZoom: false
            },
            xAxis: {
                events: {
                    afterSetExtremes: function (e) {
                        return e;
                    }
                }
            },
            yAxis: {
                events: {
                    afterSetExtremes: function (e) {
                        return e;
                    }
                }
            },
            tooltip: {
                enabled: false
            }
        },
        chartType: 'map',
        credits: {
            enabled: false
        },
        title: {
            text: '',
            floating: true
        },
        series: []
    };
});
