'use strict';

angular.module('droppable', [])

.factory('Droppable', function ($document)
{
    return function (elem, options)
    {
        var startX,
            startY,
            x = 0,
            y = 0,
            stop,
            drag,
            container,
            containerRect,
            cssCentered,
            width,
            height,
            containerHeight,
            containerWidth;

        // Obtain options

        drag = options.drag;
        stop = options.stop;
        container = options.container;

        /**
         * It assumes that block's centered by default
         * using css transform:translate(-50%, -50%) if set to true
         * @type {boolean}
         */
        cssCentered = options.cssCentered;

        function init ()
        {

            containerRect = container ?
                container[0].getBoundingClientRect() :
                elem.parent()[0].getBoundingClientRect();
            width  = cssCentered ? elem[0].offsetWidth / 2 : elem[0].offsetWidth;
            height = cssCentered ? elem[0].offsetHeight / 2 : elem[0].offsetHeight;
            containerHeight = containerRect.height;
            containerWidth = containerRect.width;

            x = elem[0].offsetLeft;
            y = elem[0].offsetTop;

            setUnit();
        }

        // Handle drag event
        function mouseMove (event)
        {
            y = event.clientY - startY;
            x = event.clientX - startX;
            setPosition();

            if (drag) {
                drag(event);
            }
        }

        // Unbind drag events
        function mouseUp (event)
        {
            $document.unbind('mousemove', mouseMove);
            $document.unbind('mouseup', mouseUp);

            if (stop) {
                stop(event, x, y);
            }
        }

        function toPercent (value, overall)
        {
            return value / overall * 100 + '%';
        }

        function getDim (dim, isWidth)
        {
            var calcDim = dim,
                containerMeasurement = isWidth ? containerWidth : containerHeight,
                blockMeasuremnt = isWidth ? width : height;

            if (calcDim > containerMeasurement - blockMeasuremnt) {
                calcDim = containerMeasurement - blockMeasuremnt;
            } else if (cssCentered && calcDim < blockMeasuremnt) {
                calcDim = blockMeasuremnt;
            } else if (!cssCentered && calcDim < 0 ) {
                calcDim = 0;
            }

            return calcDim;
        }

        function setUnit ()
        {
            if (options.percentage) {
                x = toPercent(x, containerWidth);
                y = toPercent(y, containerHeight);
            } else {
                x += 'px';
                y += 'px';
            }
        }

        // Move element, within container if provided
        function setPosition ()
        {
            x = getDim(x, true);
            y = getDim(y);
            setUnit();
            setElementStyle();
        }

        function setElementStyle ()
        {
            elem.css({
                left: x,
                top: y
            });
        }

        return {
            startMove: function (event)
            {
                event.preventDefault();
                init();
                startX = event.clientX - elem[0].offsetLeft;
                startY = event.clientY - elem[0].offsetTop;

                $document.on('mousemove', mouseMove);
                $document.on('mouseup', mouseUp);
            },
            setToCenter: function ()
            {
                init();

                x = (containerWidth - width) / 2;
                y = (containerHeight - height) / 2;

                setUnit();
                setElementStyle();

                if (stop) {
                    stop(null, x, y);
                }
            }
        };
    };
});
