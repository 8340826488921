'use strict';

angular.module('app')

.factory('targetedImageBackwardsCompatibility', function ()
{
    return function ($scope)
    {
        // Add heightOn flag for new images
        if (Object.keys($scope.data).length === 0 && $scope.isFixedCanvasPresentation) {
            $scope.data.heightOn = true;
        }

        // Backward compatibility property mapping
        if ($scope.data.imageConfig) {
            $scope.data.width = $scope.data.imageConfig.width + $scope.data.imageConfig.unit;
        }

        // Replace image anchors from old style directly to CSS value
        if ($scope.data.anchor) {

            switch($scope.data.anchor) {

                case 'Left Top':
                    $scope.data.anchor = '0 0';
                    break;

                case 'Left Middle':
                    $scope.data.anchor = '0 50%';
                    break;

                case 'Left Bottom':
                    $scope.data.anchor = '0 100%';
                    break;

                case 'Centre Top':
                    $scope.data.anchor = '50% 0';
                    break;

                case 'Centre Middle':
                    $scope.data.anchor = '50% 50%';
                    break;

                case 'Centre Bottom':
                    $scope.data.anchor = '50% 100%';
                    break;

                case 'Right Top':
                    $scope.data.anchor = '100% 0';
                    break;

                case 'Right Middle':
                    $scope.data.anchor = '100% 50%';
                    break;

                case 'Right Bottom':
                    $scope.data.anchor = '100% 100%';
                    break;
            }
        }
    };
});
