'use strict';

angular.module('app')

.factory('masterUsageSingleItemAction', function ($state)
{
    return {
        name: 'usage',
        tooltip: 'Usage',
        buttonClass: 'btn btn-default',
        template: '<span>Usage</span>',
        act: function (models)
        {
            $state.go('.usage', {
                id: models[0].id
            });
        },
        show: function (model)
        {
            return model.type === 'master' && model.chapters_count > 0;
        }
    };
});
