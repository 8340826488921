'use strict';

angular.module('app')

.config(function (kdGateProvider)
{
    kdGateProvider.define('dropdown-permissions', function (user)
    {
        return user.permissions.indexOf("super-admin") > -1;
    });
});

