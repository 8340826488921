'use strict';

angular.module('IsMobile', [])

.provider('IsMobile', function ($windowProvider)
{
    const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    let IsMobile = false;

    return {

        check: function () {
            const win = $windowProvider.$get();
            if (regex.test(win.navigator.userAgent)) {
                IsMobile = true;
            }
        },

        $get: function () {
            return IsMobile;
        }
    };
});
