'use strict';

angular.module('app')

.factory('pieChartConfigurator', function (
    $log,
    extendDeep,
    ChartConfigFactory,
    presentationState,
    currentTheme)
{

    return {
        name: 'pie',
        label: 'Pie',

        /**
         * Render the filtered dataset to chart-friendly format, apply to current chart config.
         * Chart will be automatically updated upon chartConfig change.
         *
         * @param  {Object} chartConfig             Current chart configuration
         * @param  {Object} seriesConfig            Config that will be used to each item in seriesData
         * @param  {Object} seriesConfig.textColor  Colors to be apply to each shape
         * @param  {Object} data                    Filtered dataset that need to be rendered to chart-friendly format
         * @return {Void}
         */
        apply: function (chartConfig, seriesConfig, data, scopeDataProvider)
        {
            var renderedData = ChartConfigFactory.renderData(data);
            var seriesOptions = scopeDataProvider.getSeries();
            var theme = currentTheme();
            var presentation = presentationState.presentation;

            var seriesData = [];
            for (var i = 0; i < renderedData.categories.length; i++) {

                let color = seriesOptions[i] && seriesOptions[i].color ? seriesOptions[i].color : seriesConfig.colors[i];

                if (seriesOptions[i] && seriesOptions[i].useBrandColor) {

                    let newColor = null;

                    if (theme) {
                        newColor = theme.getDefaultClientBrandColor();
                    }

                    if (presentation && !presentationState.editMode) {
                        newColor = presentation.getClientsBrandColor();
                    }

                    if (newColor) {
                        color = newColor;
                    }
                }

                seriesData.push({
                    name: renderedData.categories[i],
                    y: renderedData.seriesData[0].data[i],
                    color: color
                });
            }

            extendDeep(chartConfig, {
                series: [{data: seriesData}],
            });
            // $log.debug('chartConfig', chartConfig);
        }
    };
});

