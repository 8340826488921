'use strict';

angular.module('presentation.theme')

.directive('themeTableStyles', function ()
{
    return {
        restrict: "E",
        templateUrl: 'app/presentation/theme/theme.builder/tables/table-styles/table-styles.html',
        scope: {
            tableStylesConfig: "=",
            textStyles: "=",
            palette: "="
        },
        controller: function ($scope)
        {
            const createObjectMap = function (styleArray, key, value) {
                return styleArray.reduce(function (map, style) {
                    map[style[key]] = style[value];
                    return map;
                }, {});                
            };

            $scope.textStylesMap = createObjectMap($scope.textStyles, 'tag', 'name');
        }
    };
});
