'use strict';

angular.module('task')

.run(function (Task) {}) // jshint ignore:line

.factory('Task', function (DS, $timeout, TaskNotification)
{
    return DS.defineResource({
        basePath: '/api/',
        name: 'Task',
        endpoint: 'tasks',
        upsert: true,
        methods: {
            updateValues: function (data)
            {
                angular.forEach(data, (value, key) => {
                    this[key] = value;
                });

                this.notification.scope.$apply();
            }
        },
        afterDestroy: function (resourceName, attrs, cb)
        {
            cb(null, attrs);
        },

        afterInject: (options, models) =>
        {
            models = angular.isArray(models) ? models : [models];

            angular.forEach(models, (model) =>
            {
                if (model) {
                    model.notification = new TaskNotification(model);
                }
            });
        },
        afterEject: (options, models) =>
        {
            models = angular.isArray(models) ? models : [models];

            angular.forEach(models, (model) =>
            {
                if (model && model.notification) {
                    model.notification.kill();
                }
            });
        }
    });
});
