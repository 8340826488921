'use strict';

angular.module('app')

.factory('aeg', function (ThemeConfig)
{
    var theme = new ThemeConfig({
        'White': '#FFFFFF',
        'Black': '#000000',
        'Dark blue': '#081746',
        'Silver': '#b8b6b8',
        'None': ' '
    });

    /**
     * Modify the editor options to suit this theme
     *
     */
    theme.editorOptions = function (options)
    {
        options.textColours = theme.customColoursObject(['White', 'Black', 'Dark blue', 'Silver']);
        options.backgroundColors = theme.customColoursObjectInverted(['White', 'Black', 'Dark blue', 'Silver']);
        options.componentOptions['shape-line'].defaultColor = theme.getColour('White');

        options.componentOptions.textblock.tags = {
            h1: 'Extra large text',
            h2: 'Large heading',
            h3: 'Medium heading',
            h4: 'Small heading',
            h6: 'Large paragraph',
            h5: 'Paragraph',
            p: 'Small paragraph'
        };

        options.componentOptions.column.config.push({
            name: '5/2/5 Column',
            value: [5,2,5]
        });

        options.componentClasses.bullets = {
            default: 'paragraph',
            classes: {
                paragraph: 'Paragraph',
                'small-paragraph': 'Small paragraph',
                'dark-paragraph': 'Dark paragraph',
                'dark-small-paragraph': 'Dark small paragraph',
            }
        };

        delete options.componentOptions.textblock.tags.cite;
        delete options.componentOptions.textblock.tags.blockquote;

        angular.merge(options.componentOptions.tabs, {
            titlePosition: 'bottom'
        });

        // Configure available components
        options.nestableComponents.grid.nested.column = 'Column';
        options.nestableComponents.table.nested.column = 'Column';
    };

    theme.presentationConfig = function (options)
    {
        options.marginals = 'header';
    };

    theme.slideConfig = function (options)
    {
        options.text.color = 'txt-light';
    };

    theme.modalThemeConfig = function (options)
    {
        options.sizes.suite = {
            label: 'Suite',
            class: 'modal-suite',
            disableBackdrop: true,
        };
    };

    return theme;
});
