'use strict';

angular.module('presentation.theme')

.directive('themeShapeStyles', function ()
{
    return {
        restrict: "E",
        templateUrl: 'app/presentation/theme/theme.builder/shapes/shape-styles/shape-styles.html',
        scope: {
            shapeStylesConfig: "=",
            palette: "="
        },
        controller: function($scope) {

            var getStylesCount = function ()
            {
                return Object.keys($scope.shapeStylesConfig).length + 1;
            };

            $scope.addShapeStyle = function()
            {
                var stylesCount = getStylesCount();
                var defaultStyleName = 'circle-solid-' + stylesCount;

                var defaultShapeStyle = {
                    type: 'circle',
                    fill: true,
                    fillColor: 'dark',
                    border: false,
                    borderStyle: 'solid',
                    borderWidth: 3,
                    borderColor: 'light',
                    name: defaultStyleName,
                    collapsed: false
                };

                // Add a new object with the default shape style values to the array of shape styles
                $scope.shapeStylesConfig.push(defaultShapeStyle);
            };

            $scope.updateStyle = function ($event, shapeStyle, newStyle)
            {
                var stylesCount = getStylesCount();
                // Border style to be "solid-1", "dashed-2"
                $event.preventDefault();

                angular.extend(shapeStyle, newStyle);
                shapeStyle.name = shapeStyle.type + '-' + shapeStyle.borderStyle + '-' + stylesCount;
            };

            // Remove a shape style at a specific position
            $scope.deleteShapeStyle = function(index)
            {
                $scope.shapeStylesConfig.splice(index, 1);
            };

            // Collapse shape style if expanded and vice versa
            $scope.toggleCollapse = function(index)
            {
                $scope.shapeStylesConfig[index].collapsed = !$scope.shapeStylesConfig[index].collapsed;
            };
        }
    };
});
