'use strict';

angular.module('app')

.factory('prepareLimitedEditorOptions', function ()
{
    return function (editorOptions)
    {
        editorOptions.createMoveDelete = ['textblock', 'bullets', 'floating-box'];

        angular.forEach(editorOptions.nestableComponents, function (components)
        {
            angular.forEach(components, function (nested)
            {
                angular.forEach(nested, function (compLabel, compName)
                {
                    if (editorOptions.createMoveDelete.indexOf(compName) < 0) {
                        delete nested[compName];
                    }
                });
            });
        });
    };
});
