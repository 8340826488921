'use strict';

angular.module('presentation.theme')

.component('themeIframes', {
    templateUrl: 'app/presentation/theme/theme.builder/iframes/iframes/theme.builder.iframes.html',
    bindings: {
        config: '=',
    }
});
