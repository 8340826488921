'use strict';

angular.module('app')
       
.config(function (kdGateProvider)
{
    const limitedGates = [
        'editor:component-column.options',
        'editor:component-textblock.class-list',
        'editor:component-grid.class-list',
        'editor:component-comp-panel.class-list',
        'editor:component-image.class-list',
        'editor:component-targeted-image.class-list',
    ];

    for (let i = 0; i < limitedGates.length; ++i) {
        kdGateProvider.define(`${limitedGates[i]}`, function (data) {
            return data.permissions.indexOf('slide.edit-limited') > -1;
        });
    }
});