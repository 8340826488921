'use strict';

angular.module('app')

/**
 * drawer for highlight shapes
 */
.factory('ChartHighlights', function ()
{
    return function (chart)
    {
        return {
            chart: chart,
            shapes: [],

            clean: function ()
            {
                // remove previous drawn highlight shapes
                angular.forEach(this.shapes, function (item) {
                    var elem = angular.element(item.element);
                    elem.remove();
                });

                this.shapes = [];
            },

            /**
             * draw highlight shapes around the bars/columns in chart
             *
             * @param  {Array} rects        Rects of each bar/column to draw
             * @param  {String} color       Color of shapes.
             * @param  {String} chartType   Type of chart. (only bar/column need to draw highlights)
             * @return {Void}
             */
            draw: function (rects, color, chartType)
            {
                this.clean();

                if (!rects) {
                    return;
                }

                if (chartType !== 'bar' && chartType !== 'column') {
                    return;
                }

                var self = this;
                angular.forEach(rects, function (rect) {
                    self.shapes.push(chart.renderer
                        .rect(rect.left, rect.top, rect.width, rect.height, rect.radius)
                        .attr({
                            opacity: 0.5,
                            fill: color,
                            zIndex: 1
                        })
                        .add());
                });
            }
        };
    };
});
