'use strict';

angular.module('app')

.factory('editSingleItemAction', function (editAction)
{
    return angular.extend({}, editAction, {
        name: 'editSingleItem',
        show: function (model) {
            return model.editable !== false;
        }
    });
});
