'use strict';

angular.module('app')

.run(function (Client) {}) // jshint ignore:line

.factory('Client', function (DS, $state)
{
    return DS.defineResource({
        basePath: '/api/',
        name: 'Client',
        endpoint: 'clients',
        upsert: true,
        defaultValues: {
            action_id: 0,
        },
        methods: {
            goInto: function ()
            {
                if (this.type === 'folder') {
                    $state.go('admin.clients', {folder_id: this.id, offset: null, name: null});
                } else {
                    $state.go('admin.clients.edit', {id: this.id});
                }
            },
            getData: function ()
            {
                var data = {
                    client_name: this.name,
                    client_logo: this.asset_id
                };

                if (this.data) {
                    angular.forEach(this.data, function (value)
                    {
                        data['client_' + value.key] = value.value;
                    });
                }

                return data;
            },
            getTokenStatus: function (chapterSlides)
            {
                var clientData = this.getData();
                var tokenStatus = [];

                angular.forEach(chapterSlides, function (chapterSlide)
                {
                    angular.forEach(chapterSlide.slide.tokens, function (token)
                    {
                        tokenStatus.push({
                            token: token,
                            status: clientData['client_' + token] ? true : false
                        });
                    });
                });

                return tokenStatus;
            }
        }
    });
});
