'use strict';

angular.module('app')

.config(function (resourceStateProvider, modalStateProvider)
{
    modalStateProvider
    .state('admin.slides.slideDelete', {
        url: '/slide-delete/{ids}',
        controllerAs: 'ctrl',
        controller: 'slideDeleteCtrl',
        templateUrl: 'scripts/resourceActions/slideDelete/slideDelete.html',
        resolve: {
            models: ['Slide', 'modalState', function (Slide, modalState) {
                return Slide.findAll({
                    with: ['dependencies'],
                    where: {
                        id: {
                            in: modalState.stateParams.ids.split(',')
                        }
                    },
                }).then(function (models) {
                    modalState.models = models;
                    return models;
                });
            }],
        },
        onDismiss: function (modalState) {
            modalState.models.map((model) => model.DSRevert());
        }
    });

});
