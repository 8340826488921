'use strict';

angular.module('app')

.controller('HeaderCtrl', function (
    $rootScope,
    $scope,
    $state,
    $location,
    $http,
    auth,
    changePassword,
    kdAuth,
    kdGate,
    twoFaSetup,
    loginState,
    $uibModal,
    homeState,
    userProfileAction
) {
    $scope.isNavCollapsed = true;
    $scope.showContactsGroup = kdGate.allows('clients') || kdGate.allows('contacts');
    $scope.homeState = homeState();

    $scope.options = [
        {
            label: 'Logout',
            action: logout
        }
    ];


    $scope.logoStyle = $rootScope.config.links.logo ? {
        background: `url(${$rootScope.config.links.logo}) no-repeat left center / contain`
    } : null;

    $scope.currentUser = kdAuth.user;

    if (!$scope.currentUser.sso_logged_in) {
        $scope.options.unshift({
            label: 'Change password',
            action: editCurrentUser
        });
    }

    $scope.$on('kdAuth:userChanged', function () {

        $scope.currentUser = kdAuth.user;
        $state.go(homeState());
    });

    $scope.gotoRootFolder = function (stateName, event)
    {
        if ($state.current.name !== stateName) {
            return;
        }

        event.preventDefault();
        $state.go($state.current, {folder_id: 0}, {reload: true, inherit: false});
    };

    $scope.executeOption = function (option)
    {
        option.action();
    };

    $scope.canViewAdmin = function ()
    {
        return kdGate.allows('domain-management') ||
            kdGate.allows('user-management') ||
            kdGate.allows('audit-logs') ||
            kdGate.allows('settings') ||
            kdGate.allows('theme');
    };

    $scope.showProfile = function ()
    {
        userProfileAction.act([$scope.currentUser]);
    };

    $scope.passwordSettings = function ()
    {
        $uibModal.open({
            templateUrl: 'app/admin/settings/passwordSettings.html',
            controller: 'PasswordSettingsCtrl',
            controllerAs: '$ctrl',
            bindToController: true,
            resolve: {
                currentSettings: function ()
                {
                    return $http.get('api/app_settings/all').then(function (response) {
                        return response.data;
                    });
                }
            }
        });
    };

    $scope.storageSettings = function ()
    {
        $uibModal.open({
            templateUrl: 'app/admin/settings/storageSettings.html',
            controller: 'StorageSettingsCtrl',
            controllerAs: '$ctrl',
            bindToController: true,
            // resolve: {
            //     currentSettings: function ()
            //     {
            //         return $http.get('api/app_settings/all').then(function (response) {
            //             return response.data;
            //         });
            //     }
            // }
        });
    };

    $scope.twoFaAuth = function ()
    {
        twoFaSetup.showModal();
    };

    function editCurrentUser ()
    {
        return new changePassword();
    }

    function logout ()
    {
        auth.logout()
        .then(function ()
        {
            $state.go(loginState());
        });
    }
});
