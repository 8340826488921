'use strict';

angular.module('app')

.directive('devPanel', function ()
{
    function link ($scope, element)
    {
        element.css({
            position: 'fixed',
            bottom: 0,
            right: 0,
            fontSize: '12px',
            zIndex: 999,
        });
    }

    return {
        restrict: 'A',
        template: '<div class="dev-panel">' +
                    '<button ng-repeat="tool in devTools" ng-click="tool.action()">{{ tool.label }}</button>' +
                  '</div>',
        link: link,
    };
});
