'use strict';

angular.module('presentation.model')

.run(function (Slide) {}) // jshint ignore:line

.factory('Slide', function (DS, $state, $rootScope, md5, $http, qHttp, $q, kdGate)
{
    return DS.defineResource({
        basePath: '/api/',
        name: 'Slide',
        endpoint: 'slides',
        upsert: true,
        defaultValues: {
            action_id: 0
        },
        methods: {
            isLimited: function () {
                if (kdGate.allows('slide.edit-limited')) {
                    return false;
                } else {
                    return this.type === 'slide';
                }
            },
            goInto: function ()
            {
                if (this.type === 'folder') {
                    $state.go('admin.slides', {folder_id: this.id, offset: null, name: null});
                } else {
                    $state.go('admin.editor.slide', {slideId: this.id});
                }
            },
            getContentChecksum: function ()
            {
                var string = this.name;
                string += angular.toJson(this.components);
                string += angular.toJson(this.config);
                string += angular.toJson(this.back_layer);
                string += this.type;

                return md5.createHash(string);
            },
            snapshotContent: function (force)
            {
                if (!this.contentChecksum || force) {
                    this.contentChecksum = this.getContentChecksum();
                }
            },
            deleteChecksum: function ()
            {
                if (this.contentChecksum) {
                    delete this.contentChecksum;
                }
            },
            hasContentChanged: function ()
            {
                return this.contentChecksum && this.contentChecksum !== this.getContentChecksum();
            },
            cloneSlide: function (extend)
            {
                extend = extend || {};
                const hasPopups = this.popups ? this.popups.length > 0 : false;

                if (!hasPopups || $rootScope.offline) {

                    let newOne = angular.extend({}, this, extend);
                    delete newOne.id;

                    return $q.resolve(DS.inject('Slide', newOne));
                }

                //Let the backend to replace popups.
                return $http.post('/api/slides/clone', {slide: this}).then(function (response)
                {
                    angular.extend(response.data, extend);
                    delete response.data.id;

                    return DS.inject('Slide', response.data);
                });
            },
            lockForOthers: function ()
            {
                return $http.post('/api/slides/lock', {id: this.id});
            },
            unlock: function ()
            {
                return $http.post('/api/slides/unlock', {id: this.id});
            },
            makeThumbnail: function()
            {
                return qHttp({
                    method: 'get',
                    url: '/api/slides/' + this.id + '/thumbnail'
                }).then((data) =>
                {
                   this.thumbnail = data.data;

                   return this;
                });
            }
        },
        computed: {
            isMaster: function () {
                return this.type === 'master';
            },
            classes: ['type', 'editable', function (type, editable) {

                var slideClasses = type === 'folder' ? ['hidden'] : [];

                if (editable) {
                    slideClasses.push('glyphicon-ok');
                } else {
                    slideClasses.push('glyphicon-ban-circle');
                }

                return slideClasses;
            }]
        },
        relations: {
            hasMany: {
                ChapterSlide: {
                    localField: 'chapter_slides',
                    foreignKey: 'slide_id'
                }
            }
        },
        afterDestroy: function (resourceName, attrs, cb)
        {
            $rootScope.$broadcast('destroy', resourceName, attrs);

            DS.emit('destroy');

            cb(null, attrs);
        },
        beforeInject: function (options, slide)
        {
            if (!slide.id) {
                slide.id = DS.utils.guid();
            }
        }
    });
});
