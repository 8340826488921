'use strict';

angular.module('app')

.controller('SlideEditCtrl', function (
    $scope,
    $stateParams,
    model,
    actionMode,
    kdGate,
    $rootScope
) {
    var defaultAssetType = null;

    $scope.model = model;

    $scope.model.sidebar_options = $scope.model.sidebar_options || $rootScope.config.presentation.editor.sidebar_options_default;
    $scope.actionMode = actionMode;

    switch (actionMode) {

        case 'template':
            $scope.newModel = angular.copy(model);

            $scope.newModel.source_id = model.id;

            angular.extend($scope.newModel, {
                name: '[Template] ' + model.name,
                type: 'template'
            });

            delete $scope.newModel.id;
            break;

        case 'master':
            angular.extend($scope.model, {
                name: '[Master] ' + model.name,
                type: 'master',
                template_id: 0,
                folder_id: 0
            });
            $scope.newModel = $scope.model;
            break;

        default:
            $scope.assetTypes = [];

            if (kdGate.allows('slide.edit-master')) {
                $scope.assetTypes.push('master');
                defaultAssetType = 'master';
            }

            if (kdGate.allows('slide.edit-template')) {
                $scope.assetTypes.push('template');
                defaultAssetType = 'template';
            }

            //Set default type
            $scope.model.type = $scope.model.type || defaultAssetType;

            if ($scope.model.folder_id === undefined) {
                $scope.model.folder_id = $stateParams.folder_id || 0;
            }
            $scope.newModel = $scope.model;

            // init
            break;
    }

    $scope.saveModel = function ()
    {
        $scope.newModel.DSCreate().then(function (data)
        {
            $scope.$close(data);
        });
    };
});
