'use strict';

angular.module('app')

.directive('printPicture', function ($window, debounce)
{
    function link (scope, element)
    {
        scope.clipper = element.children()[0];
        var image = angular.element(scope.clipper).children()[0];

        image.onload = function ()
        {
            var imageHeight = image.naturalHeight || image.height;
            var imageWidth = image.naturalWidth || image.width;
            var imageRatio = imageWidth / imageHeight;
            var aspectRatio = scope.clipper.offsetWidth / scope.clipper.offsetHeight;

            if (imageRatio > aspectRatio) {
                angular.element(image).addClass('fit-height');
                angular.element(image).removeClass('fit-width');
            } else {
                angular.element(image).removeClass('fit-height');
                angular.element(image).addClass('fit-width');
            }
        };

        var debounced = debounce(image.onload, 250);

        angular.element($window).on('resize', debounced);

        scope.$watchGroup( ['clipper.clientWidth', 'clipper.clientHeight'], function ()
        {
            image.onload();
        });

        scope.$on('$destroy', function ()
        {
            angular.element($window).off('resize', debounced);
        });
    }

    return {
        scope: {
            src: '=printPicture',
            contain: '=',
        },
        link: link,
        template:   '<div class="print-clipper" ng-class="{\'contain\': contain}">' +
                        '<img ng-src="{{src}}"  />' +
                    '</div>',
    };

});
