'use strict';

angular.module('app')

.controller('ContactEditCtrl', function ($scope, $stateParams, model, DS)
{
    $scope.model = model;
    $scope.model.type = $scope.model.type || 'contact';
    $scope.model.code = $scope.model.code || DS.utils.guid().substr(0, 6).toUpperCase();
    $scope.model.assets = $scope.model.assets || [];

    // Create clients in the current folder
    if ($scope.model.folder_id === undefined) {
        $scope.model.folder_id = $stateParams.folder_id || 0;
    }

    $scope.computed = {
        assets: $scope.model.assets || []
    };

    $scope.addAsset = function ()
    {
        $scope.model.assets.push({
            id: 0
        });
    };

    $scope.removeAsset = function (asset)
    {
        let index = $scope.model.assets.indexOf(asset);

        $scope.model.assets.splice(index, 1);
    };

    $scope.saveModel = function ()
    {
        $scope.model.DSCreate().then(function ()
        {
            $scope.$close($scope.model);
        });
    };
});
