'use strict';

angular.module('app')

.factory('cloneAction', function ($state, kdGate)
{
    return {
        name: 'clone',
        tooltip: 'Clone',
        buttonClass: 'btn btn-default',
        template: '<i class="material-icon material-icon-content-copy"></i> Clone',
        act: function (models)
        {
            $state.go('.clone', {
                id: models[0].id
            });
        },
        show: function (model)
        {
            if (model.type === 'folder') {
                return false;
            }

            //You cannot clone if you cannot create a presentation
            if (!kdGate.allows('presentation.create')) {
                return false;
            }

            //You can clone a template only if you can edit a template
            if (model.template) {
                return kdGate.allows('presentation.edit-template');
            }

            //Otherwise can clone.
            return true;
        }
    };
});
