'use strict';

angular.module('presentation.theme')

.component('themePopupsCloseButton', {
    templateUrl: 'app/presentation/theme/theme.builder/popups/popups-close-button/popups-close-button.html',
    bindings: {
        asset: '=',
        width: '=',
        height: '=',
        margin: '='
    }
});
