'use strict';

angular.module('app')

.factory('highmapCustomRegions', function (
    $window,
    extendDeep,
    defaultMapRegionsConfig,
    highcharts
) {
    // Default region settings
    // Please extend settings here if it's needed
    var regionDefaults = defaultMapRegionsConfig.get();
    var mapName = 'custom/world';

    /**
     * Create region by its name
     * @param {String} region
     * @returns {Object}
     */
    var createRegion = function (region)
    {
        var regionShapeDefaults = regionDefaults[region] && regionDefaults[region].shape ?
            regionDefaults[region].shape : {};

        return extendDeep(regionShapeDefaults, {
            id: region.toUpperCase(),
            type: 'Feature',
            geometry: {
                coordinates: [],
                type: 'MultiPolygon'
            },
            properties: {
                name: region,
                'iso-a2': region.toUpperCase(),

                // Custom attribute defining if it's region
                type: "region"
            }
        });
    };

    /**
     * Add coordinates from country to region
     * @param {Object} region
     * @param {Object} shape
     */
    var addCoordinates = function (region, shape)
    {
        if (shape.geometry.type === 'MultiPolygon') {
            angular.forEach(shape.geometry.coordinates, function (coord)
            {
                region.geometry.coordinates.push(coord);
            });
        } else if (shape.geometry.type === 'Polygon') {
            region.geometry.coordinates.push(shape.geometry.coordinates);
        }
    };

    /**
     * Get array of all defined region names
     * @returns {Array}
     */
    var getRegionNames = function ()
    {
        return Object.keys(regionDefaults);
    };

    /**
     *  Extend Highcharts Map to avoid all regions to display by default
     *  As it's causing region layers overlapping country layers
     */
    var setDefaults = function ()
    {
        highcharts.maps[mapName].regions = highcharts.maps[mapName].regions || [];

        (function (H) {

            H.wrap(H.Series.prototype, 'setData', function (proceed) {
                if (this.chart.options.chart.type === 'map') {
                    var number = arguments[1].length,
                        item,
                        i;

                    // Remove all regions starting from the end
                    for (i = number - 1; i >= 0; i--) {
                        item = arguments[1][i];

                        if (item.properties && item.properties.type === 'region') {
                            arguments[1].splice(i, 1);
                        } else {
                            break;
                        }
                    }
                }

                proceed.apply(this, Array.prototype.slice.call(arguments, 1));
            });

        }(highcharts));
    };

    /**
     * Push regions to map array
     * @param {Array} items
     */
    var pushRegions = function (items)
    {
        // Loop through regions and push to a map
        angular.forEach(items, function (region, regionName)
        {
            highcharts.maps[mapName].features.push(region);

            if (highcharts.maps[mapName].regions.indexOf(region.name) === -1) {
                highcharts.maps[mapName].regions.push(regionName);
            }
        });
    };

    setDefaults();

    return {

        /**
         * Add all regions defined in config
         */
        addDefaults: function (mapNameToAddTo)
        {
            mapName = mapNameToAddTo ? mapNameToAddTo : mapName;
            var regions = getRegionNames();
            this.add(regions);
        },

        /**
         * Add specific regions
         * @param {Array} regions
         */
        add: function (regions)
        {

            var items = {},
                partOfRegion,
                shapeId;

            // Exclude already existing regions
            regions = regions.filter(function (region)
            {
                return highcharts.maps[mapName].regions.indexOf(region) < 0;
            });

            angular.forEach(highcharts.maps[mapName].features, function (shape)
            {
                angular.forEach(regionDefaults, function (region, regionName)
                {
                    shapeId = shape.properties["iso-a2"];
                    partOfRegion = regions.indexOf(regionName) !== -1 &&
                                   region.countries.indexOf(shapeId) !== -1;

                    if (partOfRegion) {

                        // Add to array if it's a new item
                        if (!items[regionName]) {
                            items[regionName] = createRegion(regionName);
                        }

                        addCoordinates(items[regionName], shape);
                    }
                });
            });

            pushRegions(items);
        }
    };

});
