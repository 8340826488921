'use strict';

angular.module('presentation.theme')

.config(function (ThemeValidatorProvider)
{
    ThemeValidatorProvider.add(function(config) {
        const textStyles = config.typography.textStyles;

        const textStyleFields = [
            {
                typeface: 'typeface',
                weight: 'weight',
            },
        ];

        return [{
            section: 'typography',
            fields: textStyleFields,
            validKeys: 'typeface',
            config: textStyles
        }];
    });
});
