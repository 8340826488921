'use strict';

angular.module('app')

.config(function (editorGatesProvider) {
   editorGatesProvider.setGates('bg-targeted-image');
})

.directive('bgTargetedImage', function (
    assetCache,
    presentationState,
    targetedImageCache,
    objectDefaults,
    editorState,
    $uibModal,
    isPhantom,
    clientTokens
) {

    return {
        restrict: 'A',
        replace: true,
        require: '^component',
        templateUrl: 'scripts/editorComponents/bgTargetedImage/bgTargetedImage.html',
        controller: function ($scope, $rootScope)
        {
            objectDefaults($scope.data, {
                asset_id: null,
                anchor: 'Centre Middle',
                secondaryLogo: false,
                brandColor: false,
                targetedMessage: false,
                targetedMessageData: {html: '', className: ''},
                customLogo: false,
                customLogoData: {asset_id: 0, brand_color: '#FFFFFF'}
            });

            $scope.offline = $rootScope.offline;
            $scope.state = editorState;
            $scope.url = '';
            $scope.isTargetedImage = false;

            $scope.anchorOptions = {
                'Left Top': {
                    class: 'left-top',
                    percent: '0 0'
                },
                'Left Middle': {
                    class: 'left-middle',
                    percent: '0 50%'
                },
                'Left Bottom': {
                    class: 'left-bottom',
                    percent: '0 100%'
                },

                'Centre Top': {
                    class: 'center-top',
                    percent: '50% 0'
                },
                'Centre Middle': {
                    class: 'center-middle',
                    percent: '50% 50%'
                },
                'Centre Bottom': {
                    class: 'center-bottom',
                    percent: '100% 100%'
                },

                'Right Top': {
                    class: 'right-top',
                    percent: '100% 0'
                },
                'Right Middle': {
                    class: 'right-middle',
                    percent: '100% 50%'
                },
                'Right Bottom': {
                    class: 'right-bottom',
                    percent: '100% 100%'
                }
            };

            $scope.bgConfigChanged = bgConfigChanged;
            $scope.editTargetedMessage = editTargetedMessage;
            $scope.editCustomLogo = editCustomLogo;
            $scope.getTrackables = getTrackables;

            $scope.$watchGroup([
                'data.asset_id',
                'data.secondaryLogo',
                'data.brandColor',
                'data.targetedMessage',
                'data.targetedMessageData',
                () => presentationState.editMode,
                () => presentationState.client
            ], setImageProperties, true);

            this.$onInit = () =>
            {
                deprecatedProperties();
                bgConfigChanged();
                backwardCompatibilityRemoveStyles();
            };

            function deprecatedProperties ()
            {
                const list = ['bgConfig', 'background', 'size', 'imageWidth', 'placeholder'];

                angular.forEach(list, function (item) {

                    if ($scope.data[item] !== undefined) {
                        delete $scope.data[item];
                    }
                });
            }

            function setImageProperties ()
            {
                if (!$scope.data.asset_id) {
                    $scope.url = $scope.ctrl.options.placeholderImage;
                    return;
                }

                const setImage = function (asset) {
                    $scope.url = asset.links.download;

                    if (isPhantom && asset.links.poster) {
                        $scope.url = asset.links.poster;
                    }

                    $scope.isTargetedImage = asset.targeted;
                };

                const client = presentationState.presentation ? presentationState.presentation.client : null;

                assetCache.get($scope.data.asset_id).then(function (asset)
                {
                    if (presentationState.editMode ||
                        !client && !$scope.data.customLogo &&
                        !$scope.data.targetedMessage ||
                        !asset.targeted ||
                        asset.isSvg() ||
                        asset.areas.length === 0) {

                        setImage(asset);

                    } else {

                        const config = {
                            brandColor: '#FFFFFF',
                            assets: {
                                logo: client ? client.asset_id : 0,
                                message: 0
                            },
                            targetedMessage: null
                        };

                        if ($scope.data.customLogo) {

                            config.brandColor = $scope.data.customLogoData.brand_color || '#FFFFFF';
                            config.assets.logo = $scope.data.customLogoData.asset_id || 0;

                        } else {

                            if ($scope.data.secondaryLogo && client) {
                                config.assets.logo = client.secondary_asset_id;
                            }

                            if ($scope.data.brandColor && client && client.brand_color) {
                                config.brandColor = client.brand_color;
                            }
                        }

                        if ($scope.data.targetedMessage) {
                            config.targetedMessage = angular.copy($scope.data.targetedMessageData);
                            config.targetedMessage.html = clientTokens(config.targetedMessage.html);
                        }

                        targetedImageCache.cache($scope.data.asset_id, config)
                            .then(setImage);
                    }
                });

            }

            function bgConfigChanged (anchor)
            {
                if (anchor) {
                    $scope.data.anchor = anchor;
                }
            }

            function editTargetedMessage ()
            {
                deSelectedComponent();

                const modal = $uibModal.open({
                    templateUrl: 'views/modals/targetedMessage.html',
                    controller: 'TargetedMessageCtrl',
                    windowClass: 'editor-modal',
                    size: 'lg',
                    resolve: {
                        message: function () {
                            return $scope.data.targetedMessageData;
                        }
                    }
                });

                modal.result.then(function (html) {
                    $scope.data.targetedMessageData = html;
                });
            }

            function editCustomLogo ()
            {
                deSelectedComponent();

                const modal = $uibModal.open({
                    templateUrl: 'views/modals/targetedCustomLogo.html',
                    controller: 'CustomLogoCtrl',
                    windowClass: 'editor-modal',
                    resolve: {
                        logo: function () {
                            return $scope.data.customLogoData;
                        }
                    }
                });

                modal.result.then(function (data) {
                    $scope.data.customLogoData = data;
                });
            }

            function backwardCompatibilityRemoveStyles ()
            {
                const stylesToRemove = [
                    'backgroundImage',
                    'backgroundSize',
                    'backgroundPosition',
                    'backgroundRepeat',
                    'margin'
                ];

                angular.forEach(stylesToRemove, function (name) {
                    $scope.ctrl.removeStyle(name);
                });
            }

            function deSelectedComponent ()
            {
                $scope.$emit('component:de-select');
            }


            function getTrackables ()
            {
                return {
                    'data.asset_id': {
                        deepWatch: false
                    },
                    'data.anchor': {
                        deepWatch: false
                    },
                    class: {
                        deepWatch: false
                    },
                    'data.brandColor': {
                        deepWatch: false
                    },
                    'data.secondaryLogo': {
                        deepWatch: false
                    },
                    'data.targetedMessage': {
                        deepWatch: false
                    },
                    'data.targetedMessageData': {
                        deepWatch: true
                    }
                };
            }
        }
    };
});
