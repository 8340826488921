'use strict';

angular.module('app')

.config(function (editorGatesProvider) {
   editorGatesProvider.setGates('chart');
})

/*
* This directive will display a chart, using angular
* highcharts directive.
* https://github.com/pablojim/highcharts-ng
*/
.directive('chart', function (
    $http,
    $q,
    $timeout,
    DefaultDataRepo,
    $window,
    debounce,
    editorState,
    presentationState,
    Notification
) {
    return {
        restrict: 'EA',
        require: ['^component', '^editor', '?^datasheetRepository'],
        templateUrl: 'scripts/editorComponents/chart/chart.html',
        link: link,
        controller: 'ChartCtrl'
    };

    function link ($scope, element, attributes, controllers)
    {

        // **HACK** always skip add/remove chart component from undo/redo
        editorState.history.cancelLast();
        editorState.history.commit({
            undo: () => {
                Notification.warning({
                    title: 'Undo warning',
                    message: 'Chart component cannot be removed with undo'
                });
            },
            redo: () => {
                Notification.warning({
                    title: 'Redo warning',
                    message: 'Chart component cannot be added with redo'
                });
            },
        });

        const Win = angular.element($window);
        const reflow = debounce($scope.setSize, 250);

        var componentCtrl = controllers[0];
        var editor = controllers[1];
        var datasheetRepositoryCtrl = controllers[2];


        $scope.rootElement = componentCtrl.editor.element[0];
        $scope.element = element;

        $scope.slide = editor.model;

        if ($scope.slide.popup) {
            $scope.slide = presentationState.slide;
        }
        
        $scope.getElementWidth = function ()
        {
            return componentCtrl.getElement()[0].offsetWidth;
        };

        $scope.selectMe = function ()
        {
            componentCtrl.select();
        };

        // If datasheetRepository exist, chart will be getting data from datasheetRepositoryCtrl.repo
        if (datasheetRepositoryCtrl) {
            $scope.init(datasheetRepositoryCtrl.repo, true);
        // By default, use defaultDataRepo
        } else {
            $scope.init(new DefaultDataRepo(), false);
        }

        // Bind to resize
        Win.bind('resize', reflow);
        $scope.$watch(() => element[0].offsetWidth, reflow);

        // Destroy binding
        $scope.$on('$destroy', () => {
            Win.off('resize', reflow);
        });
    }
});
