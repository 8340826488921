'use strict';

angular.module('komondor-editor')

.directive('sidebarFloatingBoxOptions', function ()
{
    return {
        replace: false,
        restrict: 'A',
        require: '^editor',
        templateUrl: 'scripts/editorComponents/floatingBox/floatingBox-options/sidebar-floatingBox-options.html',
        controllerAs: '$comFloatOptsCtrl',
        controller: function ($scope, $element, editorState)
        {
            this.comScope = editorState.componentScope;
        }
    };
});
