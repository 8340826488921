'use strict';

angular.module('link-component')

.service('linkModal', function (
    hyperlinkEditModal,
    slideModal,
    slideModalConfig,
    $q,
    presentationState,
    analytics,
    editorState
){
    let modalInstance;
    let options;

    this.open = open;
    this.click = click;
    this.setOptions = setOptions;

    // Extra options for custom modal behaviour
    function setOptions (value) {
        options = value;
    }

    function open (linkNodeData)
    {
        editorState.history.clear();

        const slideId = slideModalConfig.getSlideId(linkNodeData);

        //If popup...
        if (slideId) {
            const slideSize = linkNodeData['data-slide-popup-size'] || 'md';
            const slideColourProfile = linkNodeData['data-slide-popup-colour'] || null;
            modalInstance = slideModal.open(slideId, slideSize, slideColourProfile);
        } else {
            const target = linkNodeData.target ? linkNodeData.target : false;
            const href = linkNodeData.href === '#temp_url#' ? '' : linkNodeData.href;
            const inner = linkNodeData['data-inner'] || null;
            modalInstance = hyperlinkEditModal.open(href, target, inner, options);
        }

        return modalInstance.result.then(function (result)
        {
            editorState.history.clear();

            const defer = $q.defer();
            let prop;

            // Remove empty values from an object
            for (prop in angular.copy(result)) {
                if (result[prop] === null) {
                    delete result[prop];
                }
            }

            defer.resolve(result);

            return defer.promise;
        });
    }

    function nearestAncestorA (node) {

        let i = 0;
        while(node && node.tagName !== 'A' && i < 10) {
            node = node.parentNode;
            i++;
        }

        return node.tagName === 'A' ? node : null;
    }

    function click ($event, attributes, $scope)
    {
        if (modalInstance) {
            modalInstance.dismiss();
        }

        const slideId = slideModalConfig.getSlideId(attributes);

        if (slideId) {
            $event.preventDefault();
            $event.stopPropagation();
            $event.stopInteractionHandlers = true;

            const slideSize = attributes['data-slide-popup-size'] || 'md';
            const slideColourProfile = attributes['data-slide-popup-colour'] || null;

            modalInstance = slideModal.open(slideId, slideSize, slideColourProfile);

            activeModalHandler(modalInstance, angular.element($event.currentTarget));

            $event.preventDefault();
            return;
        }

        const inner = slideModalConfig.getInnerId(attributes);

        if (inner) {

            if (inner.includes('asset-file-')) {

                const assetId = parseInt(inner.match(/\d+/).join(""));

                if (!presentationState.editMode) {
                    analytics.startAction({
                        component: 'link',
                        action: 'asset',
                        subject_id: assetId,
                        subject_name: ''
                    }, $scope, true);
                }

                //Prevent to fire onbeforeonload for old links.
                if ($event.target) {

                    let node = nearestAncestorA($event.target);

                    if (node) {
                        node.setAttribute('target', '_blank');
                    }
                }

                return;

            } else {

                const chapterSlide = presentationState.getByPressUnique(inner);

                if (!presentationState.editMode) {
                    analytics.startAction({
                        component: 'link',
                        action: 'inner',
                        subject_id: chapterSlide.id,
                        subject_name: chapterSlide.slide.name
                    }, $scope, true);
                }

                if (chapterSlide) {
                    chapterSlide.goInto();
                }

                $event.preventDefault();
                return;
            }
        }

        if (attributes.href) {

            if (attributes.href === '#') {
                $event.preventDefault();
                return;
            } else {
                if (!presentationState.editMode) {
                    analytics.startAction({
                        component: 'link',
                        action: 'external',
                        subject_id: attributes.href,
                        subject_name: attributes.href
                    }, $scope, true);
                }
            }
        }
    }

    function activeModalHandler(modalInstance, element)
    {
        element.addClass('active');
        modalInstance.closed.then(function () {
            element.removeClass('active');
        });
    }
});
