'use strict';

angular.module('app')

.config(function (editorGatesProvider) {
   editorGatesProvider.setGates('column');
})

.directive('column', function ($timeout, objectDefaults)
{
    return {
        restrict: 'A',
        replace: true,
        require: ['^component', '^editor'],
        templateUrl: 'scripts/editorComponents/column/column.html',
        controller: function ($scope, $element)
        {
            const maxColumns = 4;
            const classPrefix = 'col-xs-';
            const componentCtrl = $element.controller('component');
            const editorCtrl = $element.controller('editor');

            $scope.limited = editorCtrl.options.limited;
            $scope.columnOptions = componentCtrl.options.config;
            $scope.useIcons = componentCtrl.options.useIcons || false;

            $scope.data.applyStyleToParent = true;
            $scope.getTrackables = getTrackables;
            $scope.changeNumColumns = changeNumColumns;
            $scope.getStyle = getStyle;
            $scope.singleCol = singleCol;

            objectDefaults($scope.data, {
                columnConfig: $scope.columnOptions[3],
                list: createDefaultColumns(maxColumns),
                width: 100,
                unit: '%',
                class: '',
                printHide: false
            });

            this.$onInit = function ()
            {
                backwardsCompatibility();
                adjustLayout();
            };

            function changeNumColumns (options)
            {
                $scope.data.columnConfig = options;
                adjustLayout();
            }

            function singleCol ()
            {
                return $scope.data.columnConfig.name.toLowerCase() === $scope.columnOptions[0].name.toLowerCase();
            }

            function getStyle ()
            {
                const style = {
                    width: $scope.data.width + $scope.data.unit,
                    float: 'none'
                };

                return singleCol() ? style : '';
            }

            function adjustLayout ()
            {
                for (let i = 0; i < $scope.data.list.length; i++) {
                    const col = $scope.data.list[i];
                    const size = $scope.data.columnConfig.value[i];
                    col.width = size ? classPrefix + size : 0;
                }
            }

            function createDefaultColumns (num)
            {
                const arr = [];

                for (let i = 0; i < num; i++) {
                    arr.push({nested: []});
                }
                return arr;
            }

            function backwardsCompatibility ()
            {
                const style = $scope.data.style || $scope.model.style;

                if (!style || Object.keys(style).length === 0) {
                    return;
                }

                $scope.model.componentStyle = angular.copy(style);

                delete $scope.data.style;
                delete $scope.model.style;
            }

            function getTrackables()
            {
                return {
                    'data.columnConfig': {
                        deepWatch: false,
                        callOnChangeAfter: true,
                        onChange: adjustLayout
                    },
                    'data.width': {
                        deepWatch: false,
                    },
                    'data.unit': {
                        deepWatch: false,
                    },
                    'data.printHide': {
                        deepWatch: false,
                    },
                };
            }
        }
    };
});
