'use strict';

angular.module('presentation.theme')

.directive('themePrimaryColours', function() {
    return {
        restrict: "E",
        templateUrl: 'app/presentation/theme/theme.builder/colours/primary-colours/primary-colours.html',
        scope: false
    };
});