'use strict';

angular.module('presentation.frontend')

.directive('slideFadein', function ()
{
    return {
        restrict: 'A',
        controllerAs: '$ctrl',
        bindToController: true,
        scope: {
            delay: '<slideFadein',
            enabled: '<slideFadeinEnabled'
        },
        controller: function ($scope, $element, $timeout, tweenMax, $window, isPhantom)
        {
            var timeout;
            var duration = 0.5;

            var setSlideAlpha = function (alpha)
            {
                tweenMax.set($element[0], {autoAlpha: alpha});
            };

            var fadeSlide = function ()
            {
                $scope.$broadcast('slideAnimationStart');

                tweenMax.to($element[0], duration, {
                    autoAlpha: 1,
                    onComplete: () => {
                        $scope.$broadcast('slideAnimationComplete');
                    }
                });
            };

            var applyFade = function ()
            {
                if (isPhantom) {
                    return false;
                }

                var delay = this.delay || 0;

                $timeout.cancel(timeout);

                if (!this.enabled || delay === 0) {
                    setSlideAlpha(1);
                    return;
                }

                setSlideAlpha(0);

                timeout = $timeout(fadeSlide, delay * 1000);
            };

            this.$onInit = applyFade;
            this.$onChanges = applyFade;
        }
    };
});
