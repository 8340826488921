'use strict';

angular.module('app')

.factory('lagardere', function (ThemeConfig)
{
    var theme = new ThemeConfig({
        'Navy Blue': '#1A1E45',
        'Sea Blue': '#00426C',
        Blue: '#369CE1',
        Yellow: '#FFBD32',
        // Default Colours
        Black: '#191A1F',
        White: '#ffffff',
        Grey: '#bcb9b7',
        None: 'transparent'
    });

    /**
     * Modify the editor options to suit this theme
     *
     */
    theme.editorOptions = function (options)
    {
        delete options.nestableComponents.column.nested.tabs;

        /**
         * Default component allowed tags
        */
        options.componentOptions.column.allowedTags = {};
        options.componentOptions.table.allowedTags = {};
        options.componentOptions.grid.allowedTags = {};
        options.componentOptions.chart.allowedTags = {};
        options.componentOptions.image.allowedTags = {};
        options.componentOptions['comp-panel'].allowedTags = {};
        options.componentOptions['chapter-header'].allowedTags = {};
        options.componentOptions['high-map'].allowedTags = {};
        options.componentOptions['bg-image'].allowedTags = {};
        options.componentOptions['shape-line'].allowedTags = {};
        options.componentOptions['video-player'].allowedTags = {};

        /**
         * Shape line options
        */
        options.componentOptions['shape-line'].margin = true;
        options.componentOptions['shape-line'].defaultColor = theme.getColour('Blue');

        /**
         * Text styles
        */
        options.componentOptions.textblock.tags = {
            h1: 'H1',
            h2: 'H2',
            h3: 'H3',
            h4: 'H4',
            h5: 'H5',
            h6: 'H6',
            p: 'Paragraph',
            blockquote : 'Quote',
            cite: 'Cite',
        };

        /**
         * Component colour options
        */
        options.textColours = theme.customColoursObject([
            'White',
            'Black',
            'Grey',
            'Navy Blue',
            'Sea Blue',
            'Yellow'
        ]);

        options.backgroundColors = theme.customColoursObjectInverted([
            'White',
            'Black',
            'Grey',
            'Navy Blue',
            'Sea Blue',
        ]);

        options.componentOptions['comp-panel'].background = {
            default: 'White',
            colors: theme.customColoursObjectInverted([
                'White',
                'Black',
                'Grey',
                'Navy Blue',
                'Sea Blue',
                'Yellow'
            ])
        };

        /**
         * Component classes
        */
        options.componentClasses.bullets = {
            default: 'dash',
            classes: {
                dash: 'Dash',
                'dash-yellow': 'Dash Yellow',
            }
        };


        /**
         * Chart themes
        */
        theme.mapConfig = function (options)
        {
            options.themes.blue = {
                name: 'Blue',
                color: theme.getColour('Sea Blue'),
                nullColor: theme.getColour('Sea BLue', 0.3),
                borderWidth: 0,
                borderColor: theme.getColour('black'),
            };
        };

        /**
         * Map themes
        */
        theme.chartThemeConfig = function (options)
        {
            var arrowsConfig = {
                increased: theme.getColour('Green'),
                decreased: theme.getColour('Red'),
                equal: theme.getColour('Grey')
            };

            var colourList = theme.customColoursList([
                'Navy Blue',
                'Sea Blue',
                'Blue',
                'Grey',
                'White',
            ]);

            options.themes.light.colors.arrows = arrowsConfig;
            options.themes.light.colors.highlight = theme.getColour('Blue');
            options.themes.light.colors.series = colourList;

            options.themes.dark.colors.arrows = arrowsConfig;
            options.themes.dark.colors.highlight = theme.getColour('Blue');
            options.themes.dark.colors.series = colourList;
        };

        options.componentOptions.bullets.margin = true;

    };

    theme.presentationConfig = function (options)
    {
        options.marginals = 'header';
        options.marginals_date = true;
    };

    theme.slideConfig = function (options)
    {
        options.text.color = 'txt-light';
    };

    return theme;
});
