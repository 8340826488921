'use strict';

angular.module('komondor.multitenancy')

.controller('CompanyEditCtrl', function ($scope, $state, model)
{
    $scope.model = model;

    $scope.saveModel = function ()
    {
        $scope.model.DSCreate().then($scope.$close);
    };
});
