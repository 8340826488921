'use strict';

angular.module('presentation.theme')

.factory('themeConfigTabs', function ()
{
    return {
        enabled: false,
        position: 'top',
        color: 'dark',
        bg: 'light',
        activeColor: 'light',
        activeBg: 'color-1',
        divider: {
            size: 1,
            color: 'dark'
        },
        title: {
            color: 'dark',
            bg: 'transparent',
            fontStyle: 'p'
        },
        slider: {
            color: 'light',
            bg: 'color-1'
        },
        info: {
            fontStyle: 'p'
        }
    };
});
