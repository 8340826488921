'use strict';

angular.module('datasheet.frontend')

/**
 * Check if the datasheet selected/fetched *does* contain a country/region column. If not, the data
 * will not be able to be display on map-component.
 */
.factory('DatasheetForMapValidator', function ()
{
    return function (regions)
    {

        return {

            /**
             * region/country name array
             *
             * @type {Array}
             */
            regions: regions,

            /**
             * invalid results after validation
             *
             * @type {Array}                    Array of invalid result objects.
             *               Object.keyword     Keyword that is invalid.
             *               Object.suggestions Suggestions to replace the keyword.
             */
            results: [],

            /**
             * validate input datasheetData columns w.r.t this.regions
             *
             */
            validate: function ()
            {
                var results = [];

                this.results = results;
            },

            /**
             * generate warning/alert message based on validation results
             *
             * @return {Object}
             */
            getMessage: function ()
            {
                // generate success message
                if (!this.results.length) {
                    return null;
                }

                // generate invalid message

                return {
                    msg: ''
                };
            }

        };
    };

});
