'use strict';

angular.module('app')

.factory('columnChartConfigurator', function (
    $log,
    extendDeep,
    ChartConfigFactory,
    presentationState,
    currentTheme)
{

    return {
        name: 'column',
        label: 'Column',

        /**
         * Render the filtered dataset to chart-friendly format, apply to current chart config.
         * Chart will be automatically updated upon chartConfig change.
         *
         * @param  {Object} chartConfig         Current chart configuration
         * @param  {Object} seriesConfig        Config that will be used to each item in seriesData
         * @param  {Object} seriesConfig.colors Colors to be apply to each column
         * @param  {Object} data                Filtered dataset that need to be rendered to chart-friendly format
         * @return {Void}
         */
        apply: function (chartConfig, seriesConfig, data, scopeDataProvider)
        {
            var renderedData = ChartConfigFactory.renderData(data);
            var seriesData = renderedData.seriesData;
            var seriesOptions = scopeDataProvider.getSeries();
            let theme = currentTheme();
            let presentation = presentationState.presentation;

            angular.forEach(seriesData, function (item, idx) {
                item.color = seriesOptions[idx].color ? seriesOptions[idx].color : seriesConfig.colors[idx];

                if (seriesOptions[idx].useBrandColor) {

                    let color = null;

                    if (theme) {
                        color = theme.getDefaultClientBrandColor();
                    }

                    if (presentation && !presentationState.editMode) {
                        color = presentation.getClientsBrandColor();
                    }

                    if (color) {
                        item.color = color;
                    }
                }
            });

            extendDeep(chartConfig, {
                options: {
                    chart: {
                        spacingTop: 60
                    }
                },
                xAxis: {
                    categories: renderedData.categories
                },
                series: seriesData
            });

            // $log.debug('chartConfig', chartConfig);
        }
    };
});
