'use strict';

angular.module('presentation.theme')

.component('themePopupSizes', {
    templateUrl: 'app/presentation/theme/theme.builder/popups/popup-sizes/popup-sizes.html',
    bindings: {
        model: '<'
    },
    controller: function (objectDefaults, themeConfigPopups)
    {
        this.$onInit = () =>
        {
            let defaultSizes = themeConfigPopups.modalSizes;

            this.model.config.popups.modalSizes = this.model.config.popups.modalSizes || {};

            objectDefaults(this.model.config.popups.modalSizes, defaultSizes);
        };
    }
});
