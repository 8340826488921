'use strict';

angular.module('app')

.filter('numAbbrev', function () {

    // Logic taken from https://github.com/domharrington/js-number-abbreviate
    return function (number, decPlaces)
    {
        var isNegative = number < 0;
        var abbrev = ['k', 'm', 'b', 't'];

        number = Math.abs(number);

        decPlaces = Math.pow(10, decPlaces) || 1;

        if (number < 1000) {
            number = Math.round(number * decPlaces ) / decPlaces;
        } else {
            for (var i = abbrev.length - 1; i >= 0; i--) {

                var size = Math.pow(10, (i + 1) * 3);

                if (size <= number) {
                    number = Math.round(number * decPlaces / size) / decPlaces;

                    if (number === 1000 && i < abbrev.length - 1) {
                        number = 1;
                        i++;
                    }

                    number += abbrev[i];

                    break;
                }
            }

        }

        return isNegative ? '-' + number : number;
    };

});
