'use strict';

angular.module('app')

.factory('moveSingleItemAction', function (moveAction)
{
    return angular.extend({}, moveAction, {
        name: 'moveSingleItem',
        show: function (model) {
            return !model.deleted_at && model.editable !== false;
        }
    });
});
