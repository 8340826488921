'use strict';

angular.module('presentation.theme')

.config(function (ThemeValidatorProvider)
{
    ThemeValidatorProvider.add(function(config) {
        const borderStyles = config.tables.borderStyles;

        const colorFields = [
            {key: 'color'},
        ];

        return [{
            section: 'tables',
            fields: colorFields,
            validKeys: 'colour',
            config: borderStyles
        }];
    });
});
