'use strict';

angular.module('app')

.factory('dropdownMenuBoundary', function ()
{
    const defaultBoundaries = {
        top: 0,
        bottom: 0
    };
    let boundaries = angular.copy(defaultBoundaries);

    return  {

        get: function () {
            return boundaries;
        },

        set: function (newBoundaries) {
            angular.extend(boundaries, newBoundaries);
        },

        reset: function () {
           boundaries = angular.copy(defaultBoundaries);
        },
    };
})

.directive('dropdownMenu', function ($window, $timeout, dropdownMenuBoundary)
{
    return {
        restrict: 'C',
        controller: function ($scope, $element)
        {
            const offset = 3;
            const delay = 100;

            const button = $element.parent();
            const dropdown = $element;

            let buttonData = null;
            let dropdownData = null;
            let directionDown = true;

            let maxTop = 0;
            let maxBottom = 0;
            let overlap = 0;

            const getButtonData = function ()
            {
                return button[0].getBoundingClientRect();
            };

            const getDropdownData = function ()
            {
                return dropdown[0].getBoundingClientRect();
            };

            const checkBottomLargestSide = function ()
            {
                return $window.innerHeight - buttonData.bottom > buttonData.top;
            };

            const checkOverlap = function ()
            {
                dropdownData = getDropdownData();

                if (directionDown) {
                    return dropdownData.bottom - ($window.innerHeight - maxBottom);
                } else {
                    return maxTop - dropdownData.top;
                }
            };

            const resetHeight = function ()
            {
                dropdown[0].style.overflow = dropdown[0].style.height = '';
            };

            const setUp = function ()
            {
                let boundary = dropdownMenuBoundary.get();
                maxTop = boundary.top;
                maxBottom = boundary.bottom;

                buttonData = getButtonData();

                button.removeClass('dropup');
                directionDown = true;
                resetHeight();
            };

            const clickHandle = function ()
            {
                // Check if open
                if (button.hasClass('open')) {
                    return;
                }

                // Hide menu while checking
                dropdown[0].style.visibility = 'hidden';

                $timeout(function () {

                    setUp();

                    overlap = checkOverlap();

                    // Check if dropdown goes off bottom
                    if (overlap > 0) {
                        // Check which side is largest
                        directionDown = checkBottomLargestSide();
                        // Display on largest side
                        button[directionDown ? 'removeClass' : 'addClass']('dropup');
                        // Check overlap again
                        overlap = checkOverlap();
                        // Remove overlap from height
                        if (overlap > 0) {
                            dropdown[0].style.overflow = 'auto';
                            dropdown[0].style.height = `${dropdownData.height - overlap - offset}px`;
                        }
                    }

                    if (overlap < 0) {
                        resetHeight();
                    }

                    dropdown[0].style.visibility = '';

                }, delay);
            };

            button.bind('click', clickHandle);

            $scope.$on('$destroy', function () {
                button.unbind('click', clickHandle);
            });
        },
    };
});
