'use strict';

angular.module('presentation.theme')

.config(function (ThemeValidatorProvider)
{
    ThemeValidatorProvider.add(function(config) {
        const gradient = config.gradient;

        const textStyleFields = [
            {key: 'color'},
        ];

        return [{
            section: 'slide setup',
            fields: textStyleFields,
            validKeys: 'colour',
            config: gradient
        },];
    });
});
