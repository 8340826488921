'use strict';

angular.module('app')

.factory('bylder', function (ThemeConfig)
{
    var theme = new ThemeConfig({
        'Santas Gray': '#979BB4',
        'Comet': '#5C607E',
        'Oxford Blue': '#3C405F',
        'Blue Haze': '#C5C9DA',
        'Athens Gray': '#ECEDF3',
        'None': ' '
    });

    theme.editorOptions = function (options)
    {
        options.textColours = theme.customColoursObject(['Santas Gray', 'Comet', 'Oxford Blue', 'Blue Haze', 'Athens Gray']);
        options.backgroundColors = theme.customColoursObjectInverted(['Santas Gray', 'Comet', 'Oxford Blue', 'Blue Haze', 'Athens Gray']);
        options.componentOptions['shape-line'].defaultColor = theme.getColour('Comet');

        options.componentOptions.textblock.tags = {
            h1: 'Extra large text',
            h2: 'Large heading',
            h3: 'Medium heading',
            h4: 'Small heading',
            h6: 'Large paragraph',
            h5: 'Paragraph',
            p: 'Small paragraph'
        };
    };

    theme.presentationConfig = function (options)
    {
        options.marginals = 'header';
    };

    return theme;
});
