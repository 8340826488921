'use strict';

angular.module('presentation.model')

.run(function (ChapterSlide) {}) // jshint ignore:line

.factory('ChapterSlide', function (DS, $state)
{
    return DS.defineResource({
        basePath: '/api/',
        name: 'ChapterSlide',
        endpoint: 'chapterSlides',
        upsert: true,
        relations: {
            belongsTo: {
                Chapter: {
                    localField: 'chapter',
                    localKey: 'chapter_id'
                },
                Slide: {
                    localField: 'slide',
                    localKey: 'slide_id'
                }
            }
        },
        methods: {
            goInto: function ()
            {
                $state.go('admin.editor.presentation.chapter.slide',{
                    chapterOrder: this.chapter.display_order,
                    slideOrder: this.display_order,
                    id: this.chapter.presentation_id
                });
            },
        },
        beforeInject: function (options, chapter_slide)
        {
            if (!chapter_slide.id) {
                chapter_slide.id = DS.utils.guid();
            }
        }
    });
});
