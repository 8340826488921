'use strict';

angular.module('presentation.theme')

.factory('themeConfigMaps', function ()
{
    return {
        enabled: false,
        themes: {
            light: {
                selectionColor: "color-1",
                nullColor: "light",
                border: false,
                borderWidth: 2,
                borderColor: "light",
            },
            dark: {
                selectionColor: "color-1",
                nullColor: "dark",
                border: false,
                borderWidth: 2,
                borderColor: "dark",
            }
        }
    };
});
