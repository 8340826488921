"use strict";

angular
    .module("app")

    .controller(
        "PrintPresentationCtrl",
        function (
            $scope,
            $window,
            loadedAssets,
            defaultEditorOptions,
            editorState,
            presentationState,
            clientTokens,
            isPhantom
        ) {
            const editorOptions = defaultEditorOptions.get();

            editorOptions.controls = false;
            var htmlEl = angular.element($window.document.documentElement);

            editorState.editMode = false;
            editorState.printMode = true;

            htmlEl.addClass("print-view");
            if (isPhantom) {
                htmlEl.addClass("client-phantomjs");
            } else {
                htmlEl.addClass("client-browser");
            }

            $scope.presentationState = presentationState;

            $scope.presentation = loadedAssets.presentation;
            $scope.slide = loadedAssets.slide;
            $scope.popup = loadedAssets.popup;
            $scope.showSlideInfo = loadedAssets.showInfo;
            // $scope.slide.showAllInfo = $location.search().showInfo === "1" ? true : false;

            $scope.printPresentation = function () {
                $window.print();
            };

            $scope.$on("$destroy", function () {
                htmlEl.removeClass("print-view");
            });
        }
    );
