'use strict';

angular.module('app')

.directive('bindContentEditable', function ($sce, htmlSanitizer)
{
    const setEditable = function (element, value)
    {
        if (value) {
            element.attr('contenteditable', 'true');
        } else {
            element.removeAttr('contenteditable');
        }
    };

    const read = function (element, ngModel, sanitizerRules, trimWhiteSpace)
    {
        const raw = element.html();
        let html = htmlSanitizer(raw, sanitizerRules);

        if (trimWhiteSpace) {
            html = html.replace(/&nbsp;/gi, ' ');
            html = html.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
        }

        // If the html has changed make the control dirty
        if (ngModel.$viewValue !== html) {
            ngModel.$setDirty();
        }

        // Write to data model.
        ngModel.$setViewValue(html);

        // If the processing changed the value, we need to
        // render it back to the view straight away
        if (html !== raw) {
            ngModel.$render();
        }
    };

    const link = function ($scope, element, attr, controller)
    {
        const ngModel = controller[0];
        const editableModifierCtrl = controller[1];
        const sanitizerRules = $scope.rules ? $scope.rules : {br: 'no attributes'};

        $scope.$on('editable:reformat', function ()
        {
            const modelValue = ngModel.$modelValue;
            const formatters = ngModel.$formatters;
            let idx = formatters.length;

            let viewValue = modelValue;
            while (idx--) {
                viewValue = formatters[idx](viewValue);
            }
            if (ngModel.$viewValue !== viewValue) {
                ngModel.$viewValue = ngModel.$$lastCommittedViewValue = viewValue;
                ngModel.$render();
            }
        });

        if (editableModifierCtrl) {
            ngModel.$formatters.push(editableModifierCtrl.formatter);
        }

        $scope.$watch('editable', function ()
        {
            setEditable(element, $scope.editable);
        });

        ngModel.$render = function ()
        {
            element.html($sce.getTrustedHtml(ngModel.$viewValue || ''));
        };

        element.on('blur keyup', function (event)
        {
            $scope.$evalAsync(function ()
            {
                read(element, ngModel, sanitizerRules, event.type === 'blur');
            });
        });

        setEditable(element, $scope.editable);
    };

    return {
        restrict: 'A',
        scope: {
            editable: '=bindContentEditable',
            rules: '=?'
        },
        require: ['ngModel', '?^editableModifier'],
        link: link
    };
});
