'use strict';

angular.module('app')

.config(function (resourceStateProvider)
{
    resourceStateProvider
    .state('admin.icons', {
        url: 'icons',
        model: 'Icon',
        viewOptions: 'iconViewOptions',
        query: ['folder_id'],
        params: {
            folder_id: '0'
        },
        title: 'Icons',
        edit: {
            templateUrl: 'app/icon/icon.edit.html',
            controller: 'IconEditCtrl',
            resolve: {
                actions: ['Action', function (Action)
                {
                    return Action.findAll();
                }]
            },
            defaults: {
                type: 'icon'
            }
        },
        breadcrumbs: true,
        move: true,
        deleteCheckDependencies: true
    });
});
