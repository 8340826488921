'use strict';

angular.module('link-component')

.directive('sidebarLink', function (presentationState)
{
    return {
        replace: false,
        restrict: 'A',
        require: '^editor',
        templateUrl: 'scripts/editorComponents/link/sidebar-link/sidebar-link.html',
        controllerAs: '$linkCtrl',
        controller: function ($scope, $element, editorState)
        {
            this.componentScope = editorState.componentScope;

            this.computed = {
                innerSlide: null,
            };

            $scope.$watch("$linkCtrl.componentScope.model.link['data-inner']", () =>
            {
                let inner = null;

                if (this.componentScope.model.link) {
                    inner = this.componentScope.model.link['data-inner'];
                }

                let chapterSlide = null;

                if (inner) {
                    chapterSlide = presentationState.getByPressUnique(inner);
                }

                this.computed.innerSlide = null;

                if (chapterSlide) {
                    this.computed.innerSlide = chapterSlide.slide;
                }
            });
        }
    };
});
