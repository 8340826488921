'use strict';

angular.module('presentation.theme')

.directive('themeFontUpload', function () {
    return {
        restrict: "E",
        templateUrl: 'app/presentation/theme/theme.builder/typefaces/font-upload/font-upload.html',
        scope: {
          model: '='
        },
        controllerAs: '$ctrl',
        controller: function (
            $scope,
            Uploader,
            baseApiUrl,
            ajaxHeaders,
            $cookies,
            Notification,
            Font
        ) {
            const url = baseApiUrl + 'fonts';
            const headers = angular.extend({
                'X-XSRF-TOKEN': $cookies.get('XSRF-TOKEN')
            }, ajaxHeaders);

            this.uploader = new Uploader({
                url,
                headers,
                autoUpload: true,
                onSuccessItem,
                onCompleteAll,
                onBeforeUploadItem,
                onErrorItem,
            });

            function onSuccessItem (item, fonts)
            {
                Font.inject(fonts);
                $scope.model.DSSave({quietMode: true})
                    .then((model) => {
                        model.setDefaultFonts();
                    });
            }

            function onCompleteAll ()
            {
                Notification.success('All files uploaded.');
            }

            function onBeforeUploadItem (item)
            {
                item.formData = [{theme_id: $scope.model.id}];
            }

            function onErrorItem (item, response)
            {
                if (!response.file) {
                    Notification.error('Unknown error happened while saving.');
                    return;
                }

                Notification.error(response.file[0]);
            }

        }
    };
});
