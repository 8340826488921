'use strict';

angular.module('app')

/*
* A factory to simply pass a set of JSON defaults to
* the Angular HighMaps directive.
* https://github.com/pablojim/highcharts-ng
*/
.factory('mapSeriesConfigPresets', function ()
{
    return {
        cursor: 'pointer',
        allAreas: false,
        name: '',
        countries: '',
        data: [],
        color: '#999999',
        dataLabels: {
            enabled: true,
            color: '#333333',
            useHTML: true,
        },
    };

});
