'use strict';

angular.module('email')

.factory('emailAccountViewOptions', function (extendDeep, resourceViewOptions, listView)
{
    var options = angular.copy(resourceViewOptions);

    extendDeep(options, {
        list: {
            columns: [
                {
                    label: 'Name',
                    name: 'name',
                    sortable: true
                },
                {
                    label: 'Username',
                    name: 'username',
                    sortable: true
                },
                {
                    label: 'Host',
                    name: 'host',
                    sortable: true
                },
                {
                    label: 'Last modified',
                    name: 'updated_at',
                    sortable: true,
                    template: '<span class="resource-ui-lastmodified">{{ model.updated_at | date }}</span>'
                }
            ]
        },
        views: [listView]
    });

    return options;
});
