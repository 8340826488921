'use strict';

angular.module('komondor-editor')

.directive('sidebarChartFormatting', function ()
{
    return {
        replace: false,
        restrict: 'A',
        require: '^editor',
        templateUrl: 'scripts/editorComponents/chart/chart-formatting/sidebar-chart-formatting.html',
        controllerAs: '$chartFormatCtrl',
        controller: function ($scope, $element, editorState)
        {

            this.comScope = editorState.componentScope;

            this.$onInit = () =>
            {
               // console.log('sidebarBgTargetedImageOptions');
            };
        }
    };
});
