'use strict';

angular.module('app').directive('passwordRules', function ($rootScope)
{
    function link($scope)
    {
        let rules = {
            'Passwords must include at least one lower case character' : 'passwords_lowerCase',
            'Passwords must include at least one upper case character' : 'passwords_upperCase',
            'Passwords must include at least one number' : 'passwords_number',
            'Passwords must include at least one special character (!, #, @, etc.)' : 'passwords_specialChar',
        };

        const password_rules = $rootScope.config.access.password_rules;
        
        $scope.rules = [
            'Minimum length of passwords is ' + password_rules.passwords_totalChars
        ];

        angular.forEach(rules, (ruleKey, ruleName) => {
            if (parseInt(password_rules[ruleKey])) {
                $scope.rules.push(ruleName);
            }
        });
    }

    return {
        restrict: 'E',
        link: link,
        template: '<div class="password-rules">' +
            '<div ng-repeat="rule in rules"><small>-{{rule}};</small></div>' +
            '</div>',
    };

});
