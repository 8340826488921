'use strict';

angular.module('app')

.config(function (editorGatesProvider) {
   editorGatesProvider.setGates('targeted-image');
})

.directive('targetedImageWrapper', function () {
    return {
        restrict: 'C',
        require: '^targetedImage',
        controller: function ($scope, $element)
        {
            const imageComponent = $element.controller('targetedImage');
            imageComponent.addImageWrapperElement($element[0]);
        }
    };
})

.directive('targetedImage', function (
    $rootScope,
    $uibModal,
    assetCache,
    $window,
    $timeout,
    debounce,
    objectDefaults,
    presentationState,
    targetedImageCache,
    clientTokens,
    editorState,
    isPhantom,
    targetedImageBackwardsCompatibility
) {
    return {
        replace: true,
        require: '^component',
        templateUrl: 'scripts/editorComponents/targetedImage/targetedImage.html',
        controller: function ($scope, $element)
        {
            let innerElement = null;
            const component = $element.controller('component');
            const imageDefaults = {
                asset_id: null,
                caption: '',
                ratio: [16, 9],
                overlayPos: 'bottom',
                anchor: '50% 50%',
                width: '100%',
                height: '',
                targetedMessage: false,
                targetedMessageData: {html: '', className: ''},
                customLogo: false,
                customLogoData: {asset_id: 0, brand_color: '#FFFFFF'},
            };

            $scope.offline = $rootScope.offline;
            $scope.isFixedCanvasPresentation = presentationState.isFixedCanvas();
            $scope.isTargetedImage = false;
            $scope.imageResizable = component.options.resizable;
            $scope.imageOverlay = component.options.overlay;

            // Backwards compatibility
            targetedImageBackwardsCompatibility($scope);

            $scope.data = objectDefaults($scope.data, imageDefaults);
            $scope.state = component.editor.state;

            // Computed properties
            $scope.computed = {
                width: Number(splitValue($scope.data.width)[1]),
                widthUnit: splitValue($scope.data.width)[2],
                height: $scope.data.height,
                style: {},
                url: '',
                allowMarkers: component.options.markers,
                printClass: getPrintClass($scope.data.anchor)
            };

            // Component options
            $scope.overlayPositions = {
                top: 'Top',
                middle: 'Middle',
                bottom: 'Bottom'
            };

            $scope.overlayPositionsIcons = {
                top: 'images/sidebar/image/top.svg',
                middle: 'images/sidebar/image/centre.svg',
                bottom: 'images/sidebar/image/bottom.svg'
            };

            $scope.setOverlayPosition = setOverlayPosition;
            $scope.popupFun = popupFun;
            $scope.imageStyleChanged = imageStyleChanged;
            $scope.addMarker = addMarker;
            $scope.editTargetedMessage = editTargetedMessage;
            $scope.editCustomLogo = editCustomLogo;
            $scope.getTrackables = getTrackables;
            $scope.changeWidthUnit = changeWidthUnit;

            function setOverlayPosition (overlayPos)
            {
                $scope.data.overlayPos = overlayPos;
            }

            function popupFun ()
            {
                $uibModal.open({
                    windowClass: 'image-modal',
                    size: 'lg',
                    templateUrl: 'ng-komondor-editor/components/imageModal.html',
                    controller: ['$scope', 'image', function ($scope, image) {
                        $scope.image = image;
                    }],
                    resolve: {
                        image: function () {
                            return $scope.data;
                        }
                    }
                });
            }

            function changeWidthUnit ()
            {
                $scope.computed.widthUnit = $scope.computed.widthUnit === 'px' ? '%' : 'px';
            }

            function updateComputedWidth ()
            {
                $scope.computed.width = Number(splitValue($scope.data.width)[1]);
                $scope.computed.widthUnit = splitValue($scope.data.width)[2];
            }

            function updateComputedHeight ()
            {
                $scope.computed.height = $scope.data.height;
            }

            function imageStyleChanged ()
            {
                $scope.data.width = $scope.computed.width + $scope.computed.widthUnit;
                $scope.data.height = $scope.computed.height;

                $scope.computed.style = {
                    width: $scope.data.width,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                };

                if ($scope.data.heightOn && $scope.computed.height || !$scope.data.heightOn && $scope.data.setAspectRatio) {

                    $timeout(function () {
                        const ratio = $scope.data.ratio[1] / $scope.data.ratio[0];

                        $scope.computed.style.backgroundImage = 'url(' + $scope.computed.url + ')';
                        $scope.computed.style.backgroundPosition = $scope.data.anchor;
                        $scope.computed.printClass = getPrintClass($scope.data.anchor);

                        // Backwards compatibility related - use height for new presentations
                        // with heightOn flag and aspect ratio for old presentations
                        if ($scope.data.heightOn && $scope.computed.height) {
                            $scope.computed.style.height = $scope.computed.height + 'px';
                        } else {
                            $scope.computed.style.height = innerElement.clientWidth * ratio + 'px';
                        }

                        // If constrain image option is checked add background-size: contain
                        if ($scope.data.setConstrain) {
                            $scope.computed.style.backgroundSize = 'contain';
                        }
                    });

                } else {
                    $scope.computed.style.height = 'auto';
                    $scope.computed.style.backgroundPosition = 'initial';
                }
            }

            function addMarker ()
            {
                const newMarker = {
                    type: 'image-marker'
                };

                $scope.model.markers = $scope.model.markers || [];
                $scope.model.markers.push(newMarker);

                $timeout(function () {
                    $scope.$broadcast('component:request-focus', newMarker);
                });

                editorState.history.commit({
                    undo: () => {
                        $scope.model.markers.pop();
                    },
                    redo: () => {
                        $scope.model.markers.push(newMarker);
                    }
                });
            }

            function editTargetedMessage ()
            {
                deSelectedComponent();

                const modal = $uibModal.open({
                    templateUrl: 'views/modals/targetedMessage.html',
                    controller: 'TargetedMessageCtrl',
                    windowClass: 'editor-modal',
                    size: 'lg',
                    resolve: {
                        message: function () {
                            return $scope.data.targetedMessageData;
                        }
                    }
                });

                modal.result.then(function (data) {
                    $scope.data.targetedMessageData = data;
                });
            }

            function editCustomLogo ()
            {
                deSelectedComponent();

                const modal = $uibModal.open({
                    templateUrl: 'views/modals/targetedCustomLogo.html',
                    controller: 'CustomLogoCtrl',
                    windowClass: 'editor-modal',
                    resolve: {
                        logo: function () {
                            return $scope.data.customLogoData;
                        }
                    }
                });

                modal.result.then(function (data) {
                    $scope.data.customLogoData = data;
                });
            }

            function deSelectedComponent ()
            {
                $scope.$emit('component:de-select');
            }

            function setImageProperties ()
            {
                if (!$scope.data.asset_id) {
                    $scope.computed.url = component.options.placeholderImage;
                    $scope.imageStyleChanged();
                    return;
                }

                const setImage = function (asset) {

                    $scope.computed.url = asset.links.download;

                    if (isPhantom && asset.links.poster) {
                        $scope.computed.url = asset.links.poster;
                    }

                    $scope.isTargetedImage = asset.targeted;
                    $scope.imageStyleChanged();
                };

                let client = presentationState.presentation ? presentationState.presentation.client : null;

                assetCache.get($scope.data.asset_id).then(function (asset)
                {
                    if (presentationState.editMode ||
                        !client && !$scope.data.customLogo &&
                        !$scope.data.targetedMessage ||
                        !asset.targeted ||
                        asset.isSvg() ||
                        asset.areas.length === 0) {

                        setImage(asset);

                    } else {

                        const config = {
                            brandColor: '#FFFFFF',
                            assets: {
                                logo: client ? client.asset_id : 0,
                                message: 0
                            },
                            targetedMessage: null
                        };

                        if ($scope.data.customLogo) {

                            config.brandColor = $scope.data.customLogoData.brand_color || '#FFFFFF';
                            config.assets.logo = $scope.data.customLogoData.asset_id || 0;

                        } else {

                            if ($scope.data.brandColor && client && client.brand_color) {
                                config.brandColor = client.brand_color;
                            }

                            if ($scope.data.secondaryLogo && client) {
                                config.assets.logo = client.secondary_asset_id;
                            }
                        }

                        if ($scope.data.targetedMessage) {
                            config.targetedMessage = angular.copy($scope.data.targetedMessageData);
                            config.targetedMessage.html = clientTokens(config.targetedMessage.html);
                        }

                        targetedImageCache.cache($scope.data.asset_id, config)
                            .then(setImage);
                    }
                });
            }

            function getPrintClass(anchor) {

                const horMap = {
                    '0': 'left',
                    '50': 'center',
                    '100': 'right',
                };

                const verMap = {
                    '0': 'top',
                    '50': 'middle',
                    '100': 'bottom',
                };

                const splitted = anchor.split(" ");
                const hor = parseInt(splitted[0]);
                const ver = parseInt(splitted[1]);

                return horMap[hor] + '-' + verMap[ver];
            }

            function getTrackables ()
            {
                return {
                    'data.width': {
                        deepWatch: false,
                        callOnChangeAfter: true,
                        onChange: updateComputedWidth
                    },
                    'data.height': {
                        deepWatch: false,
                        callOnChangeAfter: true,
                        onChange: updateComputedHeight
                    },
                    'data.asset_id': {
                        deepWatch: false
                    },
                    'data.hasCaption': {
                        deepWatch: false
                    },
                    'data.caption': {
                        deepWatch: false
                    },
                    'data.overlayPos': {
                        deepWatch: false
                    },
                    'data.hasOverlay': {
                        deepWatch: false,
                    },
                    'data.ratio[0]': {
                        deepWatch: false
                    },
                    'data.ratio[1]': {
                        deepWatch: false
                    },
                    'data.setAspectRatio': {
                        deepWatch: false
                    },
                    'data.setConstrain': {
                        deepWatch: false
                    },
                    'data.anchor': {
                        deepWatch: false
                    },
                    class: {
                        deepWatch: false
                    },
                    'data.brandColor': {
                        deepWatch: false
                    },
                    'data.secondaryLogo': {
                        deepWatch: false
                    },
                    'data.targetedMessage': {
                        deepWatch: false
                    },
                    'data.targetedMessageData': {
                        deepWatch: true
                    }
                };
            }

            this.$onInit = function ()
            {
                component.removeDataProperties([
                    'fixedHeight',
                    'height',
                    'size',
                    'imageWidth',
                    'unit',
                    'placeholder',
                    'style',
                    'imageConfig',
                    'sources',
                    'url',
                    'targetedMessageHtml'
                ]);

                setImageProperties();
                imageStyleChanged();
            };

            // Allow a single image-wrapper element to add its self
            this.addImageWrapperElement = function (element)
            {
                if (innerElement !== null) {
                    return;
                }

                innerElement = element;
            };

            angular.element($window).on('resize', debounce(() => imageStyleChanged(), 250));

            $scope.$on('$destroy', function () {
                angular.element($window).off('resize', debounce(() => imageStyleChanged(), 250));
            });

            $scope.$watchGroup([
                'data.asset_id',
                'data.secondaryLogo',
                'data.brandColor',
                'data.targetedMessage',
                'data.targetedMessageData',
                () => presentationState.editMode,
                () => presentationState.client,
            ], setImageProperties, true);

            $scope.$watchGroup([
                'computed.width',
                'computed.widthUnit',
                'computed.height',
                'data.ratio[0]',
                'data.ratio[1]',
                'data.setAspectRatio',
                'data.anchor',
                'data.setConstrain'
            ], debounce(imageStyleChanged, 250));
        }
    };

    function splitValue (value) {
        return value.match(/^(\d+(?:\.\d+)?)(.*)$/);
    }
});
