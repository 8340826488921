'use strict';

angular.module('datasheet.frontend')

.controller('DataSeriesModalCtrl', function (
    $scope,
    $log,
    $uibModalInstance,
    $timeout,
    dataResourceFactory,
    DatasetUtil,
    Datasheet,
    datasheets,
    dataseries,
    typeConfig,
    brandColorEnabled,
    RegionNameValidator,
    DatasheetForMapValidator,
    SwatchColorsConfig,
    tinycolor,
    ResourcePicker,
    defaultEditorOptions
) {
    ///////////////////
    // local members //
    ///////////////////
    // dataSeries is used to populate map component
    var isForMap = !!typeConfig.isForMap;
    var countryRegionList = isForMap ? typeConfig.countryList.concat(typeConfig.regionList) : null;

    // for country name validation
    if (isForMap) {
        var regionNameValidator = new RegionNameValidator(countryRegionList);
        var datasheetForMapValidator = new DatasheetForMapValidator(countryRegionList);
    }

    var defaultSwatchColor = SwatchColorsConfig.default;

    ///////////////////
    // scope members //
    ///////////////////
    // dataSeries is used to populate chart on the popup modal of map component
    $scope.isForChartOnMap = !!typeConfig.isForChartOnMap;

    //Is brand color enabled.
    $scope.brandColorEnabled = brandColorEnabled;

    // dataseries as output when closing this modal
    $scope.seriesData = dataseries || [defaultSeriesModel()];

    // datasheets available for each dataseries
    $scope.datasheets = datasheets.concat();

    // disabled sorting, for sorting will disrupt generateSelectionData()
    $scope.gridOptions = {
        data: [],
        columnDefs: [defaultIdColModel()],
        enableSorting: false,
        enableCellEditOnFocus: true,
        flatEntityAccess: true,
        rowTemplate: '<div ng-click="grid.appScope.selectCell(row, col)" ' +
                        'ng-repeat="col in colContainer.renderedColumns track by col.colDef.name" ' +
                        'ng-class="{ highlight: grid.appScope.rowSelection[row.entity.index] }" ' +
                        'class="ui-grid-cell" ui-grid-cell></div>'
    };
    $scope.showHints = false;
    $scope.isForMap = isForMap;

    /**
     * calculated selected index of rows, used for rowTemplate for highlighting purpose
     *
     *      [true, false, false, true.....]
     *
     * @type {Array}
     */
    $scope.rowSelection = [];
    /**
     * calculated selected cells, used for cellTemplate for highlighting purpose
     *
     *      [
     *          [key1: true, key2: false, key3: false, key4: true, ...]
     *      ]
     *
     * @type {Array}
     */
    $scope.cellSelection = [];
    const editorOptions = defaultEditorOptions.get();
    // Series color
    $scope.seriesSwatches = editorOptions.filteredColours ? editorOptions.filteredColours : SwatchColorsConfig.colors;
    $scope.seriesDefaultColor = defaultSwatchColor;



    /////////////////////
    // scope functions //
    /////////////////////
    $scope.datasheetChanged = datasheetChanged;
    $scope.selectCell = selectCell;
    $scope.addSeries = addSeries;
    $scope.removeSeries = removeSeries;
    $scope.selectSeries = selectSeries;
    $scope.updateLabelField = updateLabelField;
    $scope.openResourcePicker = openResourcePicker;
    $scope.getDataSheetLabel = getDataSheetLabel;
    $scope.toggleHint = function ()
    {
        $scope.showHints = !$scope.showHints;
        $scope.hintText = $scope.showHints ? 'Hide' : 'Show';
    };
    $scope.close = function ()
    {
        if ($scope.isForChartOnMap) {

            // strip out redundent data
            angular.forEach($scope.seriesData, function (series) {
                delete series.fieldsData;
                delete series.valueField;
                delete series.selectedLabel;
                delete series.selectedValue;
            });
        }

        $uibModalInstance.close($scope.seriesData);
    };

    //////////
    // init //
    //////////
    init();

    ///////////////////////
    // private functions //
    ///////////////////////
    function init ()
    {
        // $log.debug($scope.datasheets);
        // $log.debug($scope.seriesData);

        /*
         * the passed in dataseries has 'data' field,
         *      meaning original/filtered dataset exist
         *      There is no need to loadDataset later again.
         */
        if ($scope.isForChartOnMap) {

            /*
             * Since this modal if for chart inside countryDetailModal triggered by map-component, change
             * in the dataseries of map-component may cause the datasereis of the chart to be invalid.
             *
             *      e.g. previously we draw a chart for country *France* with field *population* *GDP*, when
             *           dataseries of map changed to a datasheet with no *population* and *GDP* fields, it
             *           will cause error.
             *
             * Check if the $scope.datasheets[N].data is different from $scope.seriesData[N].data.
             *
             *     - If different, it means the data-series for the map-component, is changed to a different
             *       datasource, hence need to RESET $scope.seriesData to empty/default.
             *
             *     - If same, it means the data-series for the map-component is from the same datasource.
             */
            var i;
            if ($scope.seriesData && $scope.seriesData[0].data) {
                var flag = true;

                for (i = 0; i < $scope.seriesData.length; i++) {

                    if ($scope.datasheets[i]) {
                        flag = hasSameKeys($scope.datasheets[0].data[0], $scope.seriesData[0].data[0]);

                        if (!flag) {
                            // if first row of data in datasheets and first row of data in seriesData has different keys
                            //  meaning prev stored seriesData is from a different source
                            $scope.seriesData = [defaultSeriesModel()];
                            break;
                        }
                    } else {
                        // if datasheets count is less than prev stored seriesData
                        //  meaning prev stored seriesData is from a different source
                        $scope.seriesData = [defaultSeriesModel()];
                    }
                }
            }

            // datasheets[N].data is in the format of
            // {
            //      0 : {country: australia, beer: 100, car: 101, insurance: 102, ...}
            //      1 : {country: germany, beer: 110, car: 111, insurance: 112, ...}
            // }
            // This is the filtered data that belongs to the map component.
            // It is the superset of data for chart in countryDetails
            //
            //
            // datasheets[N].fieldsData :
            // [
            //      {id: 1, field: 'beer'},
            //      {id: 2, field: 'car'},
            //      {id: 3, field: 'insurance'},
            // ]
            // This is to be used to filter data for chart in countryDetails
            //
            //
            // datasheets[N].filterQuery :
            // {
            //      where: {
            //          'Air travel for business' : {'in': [11]}
            //          'Air travel for leisure' : {'in': [21]}
            //      }
            // }
            angular.forEach($scope.datasheets, function (data) {
                var keys = DatasetUtil.findKeys(data.data);

                var o = [];
                for (i = 0; i < keys.length; i++) {

                    if (keys[i].toLowerCase() === 'country') {
                        continue;
                    }
                    o.push({id: i});
                    o[o.length - 1].field = keys[i];
                }
                data.fieldsData = o;

                data.filterQuery = null;
            });

            // If seriesData is brand new, meaning there is no prev-stored seriesData.
            // We should copy all datasheets to seriesData.
            if ($scope.seriesData.length === 1 && $scope.seriesData[0].selectedLabel === null) {
                $scope.seriesData = angular.copy($scope.datasheets);
            }
            // if prev-stored seriesData exists
            //  copy all datasheets to seriesData, except keeping prev-stored 'filterQuery'
            else {
                var m = angular.copy($scope.datasheets);

                for (i = 0; i < m.length; i++) {
                    m[i].filterQuery = $scope.seriesData[i].filterQuery;
                }

                $scope.seriesData = m;
            }
        }

        setSeriesColors();

        // delay till modal animation(in) stopped
        $timeout(function () {
            selectSeries(0);
        }, 500);
    }

    /** data series related function */
    function defaultSeriesModel ()
    {
        return {
            selectedDatasheet: null,
            selectedDatasheetId: null,
            selectedLabel: null,
            selectedValue: null,
            color: '',
            useBrandColor: false,
            filterQuery: null
        };
    }
    function defaultIdColModel ()
    {
        return {
            name: 'id',
            width: 70
        };
    }

    function addSeries ()
    {
        $scope.seriesData.push(defaultSeriesModel());
    }

    function removeSeries (idx)
    {
        if ($scope.seriesData.length === 1) {
            return;
        }

        $scope.seriesData.splice(idx, 1);

        if ($scope.seriesSelectedIdx === idx) {
            if (idx === 0) {
                selectSeries($scope.seriesSelectedIdx);
            } else {
                selectSeries($scope.seriesSelectedIdx - 1);
            }
        } else {
            if (idx < $scope.seriesSelectedIdx) {
                selectSeries($scope.seriesSelectedIdx - 1);
            }
        }
    }

    function selectSeries (idx)
    {
        $scope.seriesSelectedIdx = idx;
        $scope.seriesSelected = $scope.seriesData[idx];
        $scope.gridOptions.data = [];

        if ($scope.seriesSelected.selectedDatasheetId || $scope.seriesSelected.data) {
            datasheetChanged();
        }

        updateLabelField();

        updateInfoDisplayStr();
    }

    function updateLabelField ()
    {
        if (!isForMap) {
            $scope.alerts = [];
            return;
        }

        if (!$scope.seriesSelected.selectedLabel) {
            $scope.alerts = [];
            return;
        }

        var label = $scope.seriesSelected.selectedLabel;

        for (var i = 0; i < $scope.gridOptions.data.length; i++) {
            if (countryRegionList.indexOf($scope.gridOptions.data[i][label]) > -1) {

                // if at least 1 value in this field is a country or region
                //  meaning the label field chosen is correct, but some value under this column might be mis-spelled
                $scope.alerts = [];

                return;
            }
        }

        // if none of the values in this field is a country or region
        $scope.alerts = [{msg: 'Please choose a label field which represents country or region!'}];
    }

    function updateInfoDisplayStr ()
    {
        for (var i = 0; i < $scope.datasheets.length; i++) {
            if ($scope.datasheets[i].id === $scope.seriesSelected.selectedDatasheetId) {
                $scope.seriesDatasheetDisplay = '<b>Datasheet </b>: ' + $scope.datasheets[i].name;
            }
        }

        if (!$scope.seriesSelected.filterQuery) {
            $scope.seriesFilterDisplay = 'No filter is specified.';
            return;
        }

        var filterStrs = [];

        for (var o in $scope.seriesSelected.filterQuery.where) {
            if ($scope.seriesSelected.filterQuery.where[o]['in'].length === 1) {
                filterStrs.push('<b><u>' + o + '</u></b> is ' + $scope.seriesSelected.filterQuery.where[o]['in'][0]);
            } else {
                filterStrs.push('<b><u>' + o + '</u></b> in ' + $scope.seriesSelected.filterQuery.where[o]['in'].join(', '));
            }
        }

        $scope.seriesFilterDisplay = '<b>Filtered by</b> : ' + filterStrs.join(' and ');
    }

    /** datasheet related functions */
    function datasheetChanged (isManual)
    {
        // for chart on countryDetail popup
        if ($scope.isForChartOnMap) {
            // pre-select label/value
            $scope.seriesSelected.selectedLabel = $scope.seriesSelected.selectedLabel || null;
            $scope.seriesSelected.selectedValue = $scope.seriesSelected.selectedValue || null;

            // we use angular.copy here to avoid '$$Hash Key' column appearing
            setData(angular.copy($scope.seriesSelected.fieldsData));

        // for chart/map
        } else {

            $scope.keys = null;
            $scope.gridOptions.data = [];

            if (isManual) {
                $scope.seriesSelected.filterQuery = null;
                $scope.seriesSelected.selectedLabel = null;
                $scope.seriesSelected.selectedValue = null;
            }

            var sheet = Datasheet.get($scope.seriesSelected.selectedDatasheetId);
            sheet.fetchData().then(function (data)
            {
                var dataset = [];

                for (var i = 0; i < data.length; i++) {

                    dataset.push({});

                    // Add index on each row for selecting functionality
                    data[i].index = i;

                    for (var o in data[i]) {

                        if (typeof data[i][o] !== 'function') {
                            dataset[i][o] = data[i][o];
                        }
                    }
                }
                $scope.keys = DatasetUtil.findKeys(data);

                // validation to check if datasheet is appropreate for map-component
                if (isForMap) {

                    datasheetForMapValidator.validate(dataset);
                    generateAlerts();
                }

                // NOTE: here right after getting the data from resource, we need to set the default *selectedLabel*
                // and *selectedValue*.
                //
                // For dataSeries popup for map (isForMap: true), we need set *selectedLabel* to the column which contains
                // region or country.
                //      e.g. 'country', 'Country', 'region', 'Countries', etc...
                //
                // For dataseries popup for chart, we just need to pre-select the first column.
                $scope.seriesSelected.selectedLabel = $scope.seriesSelected.selectedLabel || $scope.keys[0];
                $scope.seriesSelected.selectedValue = $scope.seriesSelected.selectedValue || $scope.keys[1];

                setData(dataset, Datasheet.get($scope.seriesSelected.selectedDatasheetId));

                updateLabelField();

                postUiGridDataChange();
            });
        }
    }

    /** ui-grid related functions */
    // filterQuery format
    // {
    //     where: {
    //         field1: {
    //             'in': [value1, value2, ...]
    //         },
    //         field2: {
    //             'in': [value1, value2, ...]
    //         },
    //         ...
    //     }
    // }

    function selectCell (row, col)
    {
        var filterQuery = $scope.seriesSelected.filterQuery || {where: {}};
        var newSelect = {
            colName: col.field,
            cellValue: row.entity[col.field]
        };

        // field col.field is already in the filter
        if (filterQuery.where[newSelect.colName]) {

            var idx = filterQuery.where[newSelect.colName]['in'].indexOf(newSelect.cellValue);

            // deselect/remove this value if it already exist
            if (idx > -1) {
                filterQuery.where[newSelect.colName]['in'].splice(idx, 1);

                if (!filterQuery.where[newSelect.colName]['in'].length) {
                    delete filterQuery.where[newSelect.colName];
                }
            } else {
                filterQuery.where[newSelect.colName]['in'].push(newSelect.cellValue);
            }
        }
        // field col.field is NOT in the filter
        else {
            filterQuery.where[newSelect.colName] = {in: [newSelect.cellValue]};
        }

        $scope.seriesSelected.filterQuery = filterQuery;
        postUiGridDataChange();
    }

    // Check whether a cell is in the filters object to be highlighted
    $scope.inFilters = function (row, col) {

        var filterQuery = $scope.seriesSelected.filterQuery || {where: {}};
        var cellValue = row[col];

        return filterQuery.where[col] && filterQuery.where[col].in.indexOf(cellValue) !== -1 ? true : false;
    };

    /**
     * Post actions to be done after ui-grid data change, triggered by the following
     *     - datasheet change
     *     - cell/filter selection change
     *
     * @return {[type]}
     */
    function postUiGridDataChange ()
    {
        updateInfoDisplayStr();

        generateSelectionData();

        if ($scope.isForMap) {
            regionNameValidator.validate($scope.gridOptions.data, $scope.rowSelection, $scope.seriesSelected.selectedLabel);
        }

        generateAlerts();
    }

    /**
     * Generation selected row and cell data based on $scope.seriesSelected.filterQuery
     * and $scope.gridOptions.data
     *
     *      - For now we assume $scope.gridOptions.data is the actual order of rows displayed.
     *        However when we enabled *sorting* for ui-grid, this may not be the case anymore.
     *        Need to find out the way/api to get the correct data in order.
     *        e.g. rowContainer.renderedRows track by $index
     */
    function generateSelectionData ()
    {
        $scope.rowSelection = [];
        $scope.cellSelection = [];

        if (!$scope.seriesSelected.filterQuery) {
            return;
        }

        var gridData = $scope.gridOptions.data;
        var where = $scope.seriesSelected.filterQuery.where;

        for (var i = 0; i < gridData.length; i++) {
            $scope.cellSelection.push([]);

            var total = 0;
            var matches = 0;
            for (var o in gridData[i]) {
                $scope.cellSelection[i][o] = false;

                if (where[o]) {
                    total++;

                    if (where[o].in.indexOf(gridData[i][o]) >= 0) {
                        $scope.cellSelection[i][o] = true;
                        matches++;
                    }
                }
            }
            $scope.rowSelection[i] = total === matches;
        }
    }

    /** ui-grid related functions */
    function setData (dataset, dataseriesItem)
    {
        $scope.gridOptions.data = dataset;

        $scope.gridOptions.columnDefs = DatasetUtil.guessColumnDefs(
            dataset,
            defaultIdColModel(),
            {
                width: 200,
                enableCellEdit: false,
                cellTemplate: '<div class="ui-grid-cell-contents"' +
                                'ng-class="{ selected: grid.appScope.inFilters(row.entity, col.field) }">{{ COL_FIELD }}</div>'

            },
            dataseriesItem);
    }

    function hasSameKeys (objA, objB)
    {
        var keys1 = Object.keys(objA).sort();
        var keys2 = Object.keys(objB).sort();

        if (keys1.join(',') === keys2.join(',')) {
            return true;
        }
        return false;
    }

    /**
     * generate alert messages and insert into $scope.alerts
     */
    function generateAlerts ()
    {
        // reset
        $scope.alerts = [];

        var messages = null;

        // generate alerts for region/country name validataion
        if (isForMap) {
            messages = regionNameValidator.getMessage();
            if (messages) {
                $scope.alerts.push(messages);
            }
        }

        // generate alerts for validating if datasheet is suitable for map-component
        if (isForMap) {
            messages = datasheetForMapValidator.getMessage();
            if (messages) {
                $scope.alerts.push(messages);
            }
        }
    }

    function setSeriesColors ()
    {
        if ($scope.seriesData && $scope.seriesData.length) {

            var color, i;
            for (i = 0; i < $scope.seriesData.length; i++) {
                if ($scope.seriesData[i].color) {
                    color = tinycolor($scope.seriesData[i].color);
                    $scope.seriesData[i].color = color._ok ? $scope.seriesData[i].color : defaultSwatchColor;
                }
            }
        }
    }

    function openResourcePicker()
    {
        var picker = new ResourcePicker({
            viewOptions: 'datumViewOptions',
            model: 'Datasheet',
            query: {
                where: {
                    folder_id: 0
                }
            },
            types: ['datasheet']
        });

        picker.result.then(function (data)
        {
            $scope.seriesSelected.selectedDatasheetId = data[0].id ? data[0].id : null;
            datasheetChanged(true);
        });
    }

    function getDataSheetLabel()
    {
        var dataSheet = Datasheet.get($scope.seriesSelected.selectedDatasheetId);

        if (dataSheet) {
            return dataSheet.name;
        }

        return '';
    }

});
