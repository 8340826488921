'use strict';

angular.module('app')

.controller('ChartCtrl', function (
    $scope,
    $element,
    $injector,
    $q,
    $log,
    $timeout,
    extendDeep,
    chartStylePresets,
    presentationState,
    Datasheet,
    DataSeriesModal,
    ChartConfigFactory,
    ChartHighlights,
    ChartComparisonArrows,
    ChartComparisonPercentage,
    IconToken,
    currentTheme,
    IconSearch,
    numAbbrevFilter,
    debounce,
    $rootScope,
    DS,
    isPhantom
){
    ///////////////////
    // local members //
    ///////////////////
    // Editor instance
    var editor = $element.controller('editor');
    // Store the last used configurator so we can undo it
    var configurator;
    // current data repo used to *loadAllDatasheets* and *loadDatasetByFilter*
    var currDataRepo;
    // All available datasheets
    var datasheets = [];

    var customConfig = ChartConfigFactory.getCustomConfig();

    var chart;                      // will be initiated in $scope.chartConfig.func
    var highlightDrawer;            // will be initiated in $scope.chartConfig.func
    var comparisonArrowDrawer;      // will be initiated in $scope.chartConfig.func
    var comparisonPercentageSwitch;      // will be initiated in $scope.chartConfig.func

    // to indicate the chart is inside mapDetailModal
    var isForChartOnMap = false;

    // Flag when chart has dataseries
    var isChartReady = false;

    var chartTypes = ['bar', 'bar-stack', 'column', 'column-stack', 'line', 'pie'];

    $scope.chartIcons = {
        'bar': 'images/sidebar/chart/horizontal-bar.svg',
        'bar-stack': 'images/sidebar/chart/bar-stacked.svg',
        'column': 'images/sidebar/chart/vertical-bar.svg',
        'column-stack': 'images/sidebar/chart/column-stacked.svg',
        'line': 'images/sidebar/chart/line-chart.svg',
        'pie': 'images/sidebar/chart/pie-chart.svg'
    };

    var scopeDataProvider = {
        getSeries: function ()
        {
            return $scope.data.dataseries;
        }
    };

    var defaultHeight = 620;
    var themeOptions = ['light', 'dark'];
    var defaultThemeOption = 'dark';

    ///////////////////
    // scope members //
    ///////////////////
    $scope.presentationState = presentationState;
    $scope.presentation = presentationState.presentation;

    $scope.data.type = $scope.data.type || 'bar';
    $scope.slide = $scope.slide || editor.model;

    // backward compatibility
    if (typeof $scope.data.theme === 'number') {
        $scope.data.theme = $scope.data.theme === 0 ? 'light' : 'dark';
    }

    var theme = $scope.data.theme || getSlideTheme();

    // backwards compatibility - don't store theme in data as it's part of slide
    delete $scope.data.theme;

    $scope.data.height = $scope.data.height || defaultHeight;
    $scope.data.heightUnit = $scope.data.heightUnit || 'px';
    $scope.data.highlightLabels = $scope.data.highlightLabels || [];
    $scope.data.enableXAxis = $scope.data.enableXAxis !== undefined ? $scope.data.enableXAxis : true;
    $scope.data.enableYAxis = $scope.data.enableYAxis !== undefined ? $scope.data.enableYAxis : true;
    $scope.data.enableLegend = $scope.data.enableLegend !== undefined ? $scope.data.enableLegend : true;
    $scope.data.invertValues = $scope.data.invertValues !== undefined ? $scope.data.invertValues : false;
    $scope.data.enableDataLabel = $scope.data.enableDataLabel !== undefined ? $scope.data.enableDataLabel : true;
    $scope.data.disableGrouping = $scope.data.disableGrouping !== undefined ? $scope.data.disableGrouping : false;
    $scope.data.enableLabelAbbrev = $scope.data.enableLabelAbbrev !== undefined ? $scope.data.enableLabelAbbrev : false;
    $scope.data.labelAbbrevDecimals = $scope.data.labelAbbrevDecimals !== undefined ? $scope.data.labelAbbrevDecimals : 0;

    // backward compatible - change old '|in' filter to 'in'
    angular.forEach($scope.data.dataseries, function (dataseries) {
        if (!dataseries.filterQuery || !dataseries.filterQuery.where) {
            return;
        }
        angular.forEach(dataseries.filterQuery.where, function (item) {
            if (item['|in']) {
                item['in'] = item['|in'];
                delete item['|in'];
            }
        });
    });

    // Set up the chart config on the scope
    $scope.chartConfig = extendDeep({}, chartStylePresets);
    $scope.chartConfig.options.chart.events.click = function ()
    {
        $scope.selectMe();
        $scope.$apply();
    };
    // get hold of the reference object to highchart
    $scope.chartConfig.func = function (c)
    {
        // store local reference to the highChart Object
        chart = c;

        if (isPhantom) {
            chart.renderer.url = 'chart-' + DS.utils.guid();
        }

        // initiated when local reference of chart is available
        highlightDrawer = new ChartHighlights(chart);
        comparisonArrowDrawer = new ChartComparisonArrows(chart);
        comparisonPercentageSwitch = new ChartComparisonPercentage(chart);

        if (!$scope.data || !$scope.data.dataseries) {
            configDataSeries();
        }
    };

    // list of labels/categories of chart
    $scope.labelList = [];

    /////////////////////
    // scope functions //
    /////////////////////
    $scope.init = init;
    $scope.configDataSeries = configDataSeries;
    $scope.configChanged = configChanged;
    $scope.setSize = setSize;
    $scope.tabHeading = tabHeading;
    $scope.hideComparisonOptions = hideComparisonOptions;
    $scope.getChartTypes = getChartTypes;
    $scope.toggleHeightUnit = toggleHeightUnit;
    $scope.getTrackables = getTrackables;

    ////////////
    // events //
    ////////////
    // watch highlightLabels change triggered by chart options
    $scope.$watchCollection('data.highlightLabels', function (newV, oldV)
    {
        // for first time chartController is loaded
        if (newV === oldV) {
            return;
        }

        configChanged();
    });

    // watch highlightLabels change triggered by chart options
    $scope.$watch(getSlideTheme, function (newV)
    {
        if (theme === newV) {
            return;
        }

        theme = newV;

        configChanged();
    });

    // watch edit mode
    $scope.$watch('presentationState.editMode', function ()
    {
        configChanged();
    });

    ///////////////////////
    // private functions //
    ///////////////////////
    /**
     * init the controller with different dataRepo, from the link of chart directive
     *
     * @param  {Object}  dataRepo
     * @param  {Boolean} hasDatasheetRepositoryDir
     */
    function init (dataRepo, hasDatasheetRepositoryDir)
    {
        isForChartOnMap = hasDatasheetRepositoryDir;

        currDataRepo = dataRepo;

        configChanged();
    }

    /**
     * fire up a modal to config/select series
     */
    function configDataSeries ()
    {
        currDataRepo.loadAllDatasheets().then(function (results)
        {
            datasheets = results;

            var appTheme = currentTheme();

            var brandColorEnabled = appTheme && appTheme.isBrandColorEnabled();
            var modalInstance = new DataSeriesModal(datasheets, angular.copy($scope.data.dataseries), {isForChartOnMap: isForChartOnMap}, brandColorEnabled);

            modalInstance.result.then(function (data)
            {
                $scope.data.dataseries = data;

                configChanged();
            });
        });
    }

    /**
     * Make the chart expand to full width of container and chosen height.
     *
     *      - Delay by a bit because sometimes(after config data-series of chart, then close modal),
     *        the scrollbar is not visible yet. The width of the element might not be accural (since
     *        page scrollbar may appear soon), hence delay for the modal to hide, and re-rendering completes
     *
     */
    function setSize ()
    {
        // Skip if input value is empty
        if ($scope.data.height === '') {
            return;
        }

        // Set default height if it's not a number or false, null, 0
        if (isNaN($scope.data.height) || !$scope.data.height) {
            $scope.data.height = $scope.data.heightUnit === '%' ? 100 : defaultHeight;
        }

        var height = $scope.data.height,
            width  = $scope.element[0].offsetWidth;

        if ($scope.data.heightUnit === '%') {

            var setRelativeSize = function ()
            {
                // Take a value between 5% and 100%
                $scope.data.height = Math.min(Math.max(5, $scope.data.height), 100);
                height = $scope.rootElement.offsetHeight / 100 * $scope.data.height;
                redrawChart(height, width);
            };

            if (isChartReady) {
                setRelativeSize();

            //Set timeout if DOM is not rendered to catch correct dimensions
            } else {
                $timeout(setRelativeSize);
            }
        } else {
            redrawChart(height, width);
        }
    }

    /**
     * Trigger a redraw of highLights and comparison arrows
     * @returns Null
     */
    var drawHighlightComparisonArrows = debounce(function ()
    {
        if (!isChartReady) {
            return;
        }

        $timeout(function () {
            drawHighlights(getHighlightRects());
            drawComparisonArrows(getComparisonRects());
            renderComparisonPercentage(getComparisonRects());
        });

    }, 150);

    /**
     * Get an offset from a right side for comparison arrow bar chart
     * @returns Number || String
     */
    var labelFormatter = function ()
    {
        var tokenStr, token, seriesLabelWidth, width = 0, spacing = 5;
        var label = this;
        var chart = this.chart;
        var chartType = chart.options.chart.type;

        if (angular.isNumber(label.value) && !isNaN(label.value)) {

            if ($scope.model.data.enableLabelAbbrev) {
                label.value = numAbbrevFilter(label.value, $scope.model.data.labelAbbrevDecimals);
            } else if (angular.isDefined(chart.options.suffix)) {
                label.value += chart.options.suffix;
            }

            return label.value;
        }

        if (!$scope.data.iconLabels) {
            return label.value;
        }

        tokenStr = String(label.value);
        token = IconSearch.search(tokenStr.toLowerCase());

        if (!token || !token.length || !token[0].icon.asset)
        {
            return label.value;
        }

        token = token[0];

        /**
         * Y axis for bar type chart labels
         *
         * Use fixed width as there is no sense to get
         * a width of what hasn't been generated yet
         */
        if (chartType === 'bar') {
            width = 50;

        // X axis for line/column type chart labels
        } else {
            seriesLabelWidth = Math.round(chart.plotWidth / chart.series[0].processedXData.length);
            width = Math.min(seriesLabelWidth, 70) - spacing;
        }

        return '<div class="chart-custom-label" style="width: ' + width + 'px; height: ' + width + 'px;">' +
        '<img style="width: 100%; height: 100%; object-fit: contain;" src="' + token.icon.asset.links.download + '">' +
        '</div>';
    };

    function redrawChart (height, width)
    {
        // skip if it's the same
        if ($scope.chartConfig.size &&
            $scope.chartConfig.size.height === height &&
            $scope.chartConfig.size.width === width) {
            return;
        }

        $scope.chartConfig.size = {
            height: height,
            width: width
        };

        drawHighlightComparisonArrows();
    }

    function getChartTypes ()
    {
        if (!$scope.data.dataseries) {
            return;
        }

        var condition = $scope.data.dataseries.length > 1 ? ' ' : 'stack';

        return chartTypes.filter(function (type) {
            return type.indexOf(condition) < 0;
        });
    }

    /**
     * upon config change, load filtered data, and feed data to chart configurator
     */
    function configChanged ()
    {
        // if no dataseries, meaning no dataseries is chosen/filtered yet,
        // there is no point to proceed
        if (!$scope.data.dataseries) {
            return;
        }

        // if there is only one series and the users has a stacked
        // chart selected change it to a non stacked chart type
        if ($scope.data.dataseries.length <= 1 && $scope.data.type.indexOf('stack') >= 0) {
            $scope.data.type = $scope.data.type.replace('-stack', '');
        }

        processData().then(function (data)
        {
            // Sort all data by id before using
            angular.forEach(data, function (obj) {
                obj.data.sort(function (a, b) {
                    if (a.id < b.id) {
                        return -1;
                    } else if (a.id > b.id) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            });

            updateConfigOptions(data);
        });
    }

    function hideComparisonOptions ()
    {
        if (!isChartReady) {
            return true;
        }

        return $scope.data.type === 'line' || $scope.data.type === 'pie' || $scope.data.dataseries.length > 2 || $scope.data.type.indexOf('-stack') > -1;
    }

    /**
     * Update chart config
     * @param {Null|Array} data
     */
    function updateConfigOptions (data)
    {

        customConfig.updateDataLabelFormatter($scope.data.iconLabels, labelFormatter);

        var chartConfig = angular.copy($scope.chartConfig);
        $scope.chartConfig = null;

        // update custom config based on $scope.data properties
        customConfig.updateType($scope.data.type);

        customConfig.updateTheme(theme);

        if ($scope.data.enableLabelAbbrev) {
            $scope.data.dataLabelSuffix = '';
        }

        customConfig.setLabelAbbreviation($scope.data.enableLabelAbbrev, $scope.data.labelAbbrevDecimals);
        customConfig.setPrefixSuffix($scope.data.dataLabelPrefix, $scope.data.dataLabelSuffix);
        customConfig.updateDataLabelFormat();

        customConfig.updateAxis($scope.data.enableXAxis, $scope.data.enableYAxis, $scope.data.invertValues);
        customConfig.updateLegend($scope.data.enableLegend);
        customConfig.updateDataLabelEnabled($scope.data.enableDataLabel);
        customConfig.updateSeriesGrouping(!$scope.data.disableGrouping);

        chartConfig = extendDeep(chartConfig, customConfig.config);

        // Process if data set
        if (data) {

            isChartReady = true;

            // Switch to the new chart type
            configurator = $injector.get(customConfig.getChartType() + 'ChartConfigurator');

            configurator.apply(chartConfig, {
                colors: customConfig.getThemeColors()                    // for bar/column/line chart
            }, data, scopeDataProvider);

            chartConfig.options.chart.marginRight = getChartMarginRight(chartConfig);
        }

        customConfig.ghostDataSeries($scope.data.ghostSeries, chartConfig.series, customConfig.getThemeColors());

        $timeout(() => {
            $scope.chartConfig = chartConfig;
            setSize();

            // update labelList, for chart options(highlight multi-select)
            $scope.labelList = $scope.chartConfig.xAxis.categories;

            // Draw highlights and comparison-arrows
            if ($scope.chartConfig.options.chart.type === 'bar' || $scope.chartConfig.options.chart.type === 'column') {

                drawHighlightComparisonArrows();
            }
        });
    }

    /**
     * Get an offset from a right side for comparison arrow bar chart
     * @returns {*}
     */
    function getChartMarginRight (config)
    {
        return config.options.chart.type === 'bar' && !disableComparison('comparisonArrow', config) ? 120 : null;
    }

    /**
     * Get all label indexes
     * @returns {Array}
     */
    function getAllLabelIndexes ()
    {
        var list = [];
        for (var i = 0; i < $scope.labelList.length; i++) {
            list.push(i);
        }

        return list;
    }

    /**
     *
     */
    function getUnitRectHelperParams ()
    {
        return {
            chartType: $scope.chartConfig.options.chart.type,
            isStacking: $scope.chartConfig.options.plotOptions.series.stacking === 'normal',
            prefix: $scope.data.dataLabelPrefix,
            suffix: $scope.data.dataLabelSuffix
        };
    }

    /**
     * Get rects for comparison texts or arrows
     * @returns {*|Array}
     */
    function getComparisonRects ()
    {
        var rects = [];

        if (!disableComparison('comparisonArrow') || !disableComparison('comparisonPercentage')) {

            rects = ChartConfigFactory.getChartUnitRect(
                chart,
                getAllLabelIndexes(),
                getUnitRectHelperParams(),
                0,
                0,
                "comparison");
        }

        return rects;
    }

    /**
     * Get rects to highlight column(s)
     * @param {Array} option
     * @returns {*|Array}
     */
    function getHighlightRects ()
    {
        var highlightIndexes = getHighlightIndexes();
        var rects = [];

        if (highlightIndexes.length) {

            rects = ChartConfigFactory.getChartUnitRect(
                chart,
                highlightIndexes,
                getUnitRectHelperParams(),
                30,
                30,
                "highlight");
        }

        return rects;
    }

    /**
     * Return highlight indexes
     * @returns {Array}
     */
    function getHighlightIndexes () {

        // array of indexes of bar/column to draw highlights. e.g. [0, 3, 4]
        var highlightIndexes = [];
        for (var i = 0; i < $scope.data.highlightLabels.length; i++) {

            var index = $scope.labelList.indexOf($scope.data.highlightLabels[i]);

            if (index !== -1) {
                highlightIndexes.push(index);
            }
        }

        var cleanHighlight = !highlightIndexes.length || !chart.series[0];

        // clean all highlights if no input or no series data
        if (cleanHighlight && highlightDrawer) {
            highlightDrawer.clean();
        }

        return highlightIndexes;
    }

    /**
     * Check if comparison feature has to be disabled
     * @param {string} option
     * @param {object} option
     * @returns {boolean}
     */
    function disableComparison (option, config)
    {
        config = config || $scope.chartConfig;

        return !$scope.model.data[option] ||
                config.series.length > 2 ||
                config.options.plotOptions.series.stacking === 'normal' ||
                !config.series[0];
    }

    /**
     * draw highlight shapes around the highlight points
     */
    function drawHighlights (rects)
    {
        highlightDrawer.draw(rects, customConfig.getHighlightColor(), $scope.chartConfig.options.chart.type);
    }

    /**
     * Draw comparison arrows inbetween adjacent bar/column
     * @param array rects
     */
    function drawComparisonArrows (rects)
    {
        if (disableComparison('comparisonArrow')) {
            comparisonArrowDrawer.clean();
            return;
        }

        comparisonArrowDrawer.draw(
            rects,
            customConfig.getComparisonArrowColor(),
            $scope.chartConfig.options.chart.type,
            disableComparison('comparisonPercentage'));
    }

    /**
     * Generate percentage comparison text
     * @param array rects
     */
    function renderComparisonPercentage (rects)
    {

        if (disableComparison('comparisonPercentage')) {
            comparisonPercentageSwitch.clean();
            return;
        }

        comparisonPercentageSwitch.switch(
            rects,
            customConfig.getComparisonTextColor(),
            $scope.chartConfig.options.chart.type);
    }

    /**
     * load the actual filtered data from datasheets
     */
    function processData ()
    {
        var deferred = $q.defer();

        // refresh the data array in $scope.data.dataseries[N], in case it was changed in mapcomponent's dataSeriesConfig
        //  e.g. originally mapComponent has 3($scope.data.dataseries[N].data.length) countries, now it has 5(datasheets[N].data.length)
        //
        //      - might have a bug here, sometimes when we alter the map data AFTER creating a chart in map-popup, error occurs in the for-loop below
        if (isForChartOnMap) {
            for (var i = 0; i < datasheets.length; i++) {
                $scope.data.dataseries[i].data = datasheets[i].data;
            }
        }

        // if there is no selectedLabel|selectedValue, set default to 'name'|'value'
        for (var o in $scope.data.dataseries) {
            if (!$scope.data.dataseries[o].selectedLabel) {
                $scope.data.dataseries[o].selectedLabel = 'name';
            }
            if (!$scope.data.dataseries[o].selectedValue) {
                $scope.data.dataseries[o].selectedValue = 'value';
            }
        }

        currDataRepo.loadDatasetByFilter($scope.data.dataseries)
            .then(function (data)
            {
                for (var i = 0; i < data.length; i++) {
                    // if invalid data exist for filtered dataset, set the value of 'selectedValue' to 0 by default
                    for (var j = 0; j < data[i].length; j++) {
                        if (data[i][j][$scope.data.dataseries[i].selectedValue] === undefined) {
                            data[i][j][$scope.data.dataseries[i].selectedValue] = 0;
                        }
                    }

                    data[i] = {
                        data: data[i],
                        labelField: $scope.data.dataseries[i].selectedLabel,
                        valueField: $scope.data.dataseries[i].selectedValue,
                        filterQuery: $scope.data.dataseries[i].filterQuery
                    };
                }

                deferred.resolve(data);
            });

        return deferred.promise;
    }

    /**
     * view helper to lookup tab headings based on data.type
     * e.g. data.type === 'pie' only has 3 tabs so needs different headings
     * @param integer tabIndex
     * @returns {string}
     */
    function tabHeading (tabIndex)
    {
        var headings = ['1', '2', '3', '4'];

        if ($scope.data.type === 'pie') {
            headings[1] = '';
            headings[2] = '2';
            headings[3] = '3';
        }

        return headings[tabIndex];
    }

    function toggleHeightUnit ()
    {
        $scope.data.heightUnit = $scope.data.heightUnit === 'px' ? '%' : 'px';
        setSize();
    }

    function getSlideTheme()
    {
        let theme = defaultThemeOption;

        if ($rootScope.slideTextColor) {
            theme = $rootScope.slideTextColor;
        }

        if ($scope.slide && $scope.slide.config && $scope.slide.config.text && !$scope.slide.popup) {
           theme = $scope.slide.config.text.color;
        }

        theme = theme.replace('txt-', '');

        if (themeOptions.indexOf(theme) === -1) {
            return defaultThemeOption;
        }

        return theme;
    }

    function getTrackables ()
    {
        return {
            'data.enableDataLabel': {
                deepWatch: false,
                callOnChangeAfter: true,
                onChange: configChanged
            },
            'data.enableLegend': {
                deepWatch: false,
                callOnChangeAfter: true,
                onChange: configChanged
            },
            'data.comparisonArrow': {
                deepWatch: false,
                callOnChangeAfter: true,
                onChange: configChanged
            },
            'data.comparisonPercentage': {
                deepWatch: false,
                callOnChangeAfter: true,
                onChange: configChanged
            },
            'data.ghostSeries': {
                deepWatch: false,
                callOnChangeAfter: true,
                onChange: configChanged
            },
            'data.enableLabelAbbrev': {
                deepWatch: false,
                callOnChangeAfter: true,
                onChange: configChanged
            },
            'data.labelAbbrevDecimals': {
                deepWatch: false,
                callOnChangeAfter: true,
                onChange: configChanged
            },
            'data.dataLabelPrefix': {
                deepWatch: false,
                callOnChangeAfter: true,
                onChange: configChanged
            },
            'data.dataLabelSuffix': {
                deepWatch: false,
                callOnChangeAfter: true,
                onChange: configChanged
            },
            'data.iconLabels': {
                deepWatch: false,
                callOnChangeAfter: true,
                onChange: configChanged
            },
            'data.enableXAxis': {
                deepWatch: false,
                callOnChangeAfter: true,
                onChange: configChanged
            },
            'data.enableYAxis': {
                deepWatch: false,
                callOnChangeAfter: true,
                onChange: configChanged
            },
            'data.height': {
                deepWatch: false,
                callOnChangeAfter: true,
                onChange: setSize
            },
            'data.heightUnit': {
                deepWatch: false
            },
            'data.invertValues': {
                deepWatch: false,
                callOnChangeAfter: true,
                onChange: configChanged
            },
            'data.type': {
                deepWatch: false,
                callOnChangeAfter: true,
                onChange: configChanged
            },
            'data.highlightLabels': {
                deepWatch: true
            },
        };
    }

});
