'use strict';

angular.module('komondor-editor')

.directive('sidebarTargetedImageOptions', function ()
{
    return {
        replace: false,
        restrict: 'A',
        require: '^editor',
        templateUrl: 'scripts/editorComponents/targetedImage/targeted-image-options/sidebar-targeted-image-options.html',
        controllerAs: '$tiOptionsCtrl',
        controller: function ($scope, $element, editorState)
        {
            this.comScope = editorState.componentScope;

            this.$onInit = () =>
            {
               // console.log('sidebarTargetedImageOptions');
            };

            this.valignMap = {
                Top: 'top',
                Middle: 'center',
                Bottom: 'bottom',
            };
        }
    };
});
