'use strict';

angular.module('app')

.controller('highMapCtrl', function (
    $scope,
    $rootScope,
    $log,
    $q,
    $timeout,
    $compile,
    extendDeep,
    mapConfigPresets,
    DefaultDataRepo,
    DataSeriesModal,
    CountryDetailModal,
    notify,
    Notification,
    highMapService,
    defaultHighMapThemes,
    highmapMarkerFormatter
) {
    ///////////////////
    // local members //
    ///////////////////
    // used in reCompileDataLabels
    var dataLabelsScope;

    // current data repo used to *loadAllDatasheets* and *loadDatasetByFilter*
    var currDataRepo = new DefaultDataRepo();

    /**
     * complete set of FILTERED data from datasheet, with other properties
     *
     * @type {Array}
     * @type {Array} fullDatasets[0].data
     * @type {Array} fullDatasets[0].filterQuery    The user input filter query used to extract data
     *                                              from the original datasheet.
     *                                              {
     *                                                  where: {
     *                                                      country: { in: [..., ...] },
     *                                                      total: { in: [..., ..., ...] }
     *                                                  }
     *                                              }
     * @type {Array} fullDatasets[0].labelField     Selected label field.
     * @type {Array} fullDatasets[0].valueField     Selected value field
     */
    var fullDatasets = [];

    // local reference to highMap object
    var highMap;

    var iconsNotFound = {};

    ///////////////////
    // scope members //
    ///////////////////
    const HighMapThemes = defaultHighMapThemes.get();
    $scope.themes = HighMapThemes.themes;
    $scope.themeOptions = HighMapThemes.getThemeOptions();

    /**
     * list of country/region names
     *
     * @type {Array}
     */
    $scope.countryList = null;
    $scope.regionList = null;

    // TODO - The flag option can be added
    // back in when icon repo is done
    $scope.data.pinType = $scope.data.pinType || 'pins';
    $scope.data.showLabels = $scope.data.showLabels || false;
    $scope.data.showValues = $scope.data.showValues || false;
    $scope.data.showAllAreas = $scope.data.showAllAreas || false;
    $scope.data.themeName = $scope.data.themeName || $scope.themeOptions[0];

    // Map configuration
    $scope.mapConfig = extendDeep({}, mapConfigPresets);

    // Map navigation and chart configuration options
    $scope.mapNavOptions = $scope.mapConfig.options.mapNavigation;
    $scope.chartOptions = $scope.mapConfig.options.chart;

    // Variables for custom map zoom
    var defaultZoomExtremes = {
        xAxis: {
            min: null,
            max: null
        },
        yAxis: {
            min: null,
            max: null
        }
    };

    $scope.mapNavEnabled = $scope.mapNavEnabled || false;
    $scope.hideMapOverlay = false;
    $scope.data.zoomExtremes = $scope.data.zoomExtremes || defaultZoomExtremes;
    // Keep copy of scope values
    $scope.zoomExtremesOld = angular.copy($scope.data.zoomExtremes);

    // get hold of the reference object to highMap
    $scope.mapConfig.func = function (m)
    {
        highMap = m;

        // Update map zoom from DB values
        $scope.updateMapZoom($scope.data.zoomExtremes);

        if (!$scope.data || !$scope.data.dataseries) {
            configDataSeries();
        }
        // auto populate data by the saved dataseries
        else {
            configChanged();
            getCountryRegionList();
        }
    };

    $scope.updateMapZoom = function (zoomExtrs)
    {
        if (zoomExtrs.xAxis.min && zoomExtrs.xAxis.max &&
           (zoomExtrs.yAxis.min && zoomExtrs.yAxis.max)) {

            highMap.xAxis[0].setExtremes(zoomExtrs.xAxis.min, zoomExtrs.xAxis.max);
            highMap.yAxis[0].setExtremes(zoomExtrs.yAxis.min, zoomExtrs.yAxis.max);
        } else {
            $scope.resetMapZoom();
        }
    };

    $scope.resetMapZoom = function ()
    {
        highMap.mapZoom();
    };

    var mapZoomChanged = function ()
    {
        return $scope.zoomExtremesOld.xAxis.min !== $scope.data.zoomExtremes.xAxis.min ||
               $scope.zoomExtremesOld.xAxis.max !== $scope.data.zoomExtremes.xAxis.max ||
               ($scope.zoomExtremesOld.yAxis.min !== $scope.data.zoomExtremes.yAxis.min ||
               $scope.zoomExtremesOld.yAxis.max !== $scope.data.zoomExtremes.yAxis.max);
    };

    // Event listener for when the map has been zoomed in/out and pan (xAxis)
    $scope.mapConfig.options.xAxis.events.afterSetExtremes = function (e)
    {
        $scope.data.zoomExtremes.xAxis.min = e.min;
        $scope.data.zoomExtremes.xAxis.max = e.max;
    };

    // Event listener for when the map has been zoomed in/out and pan (yAxis)
    $scope.mapConfig.options.yAxis.events.afterSetExtremes = function (e)
    {
        $scope.data.zoomExtremes.yAxis.min = e.min;
        $scope.data.zoomExtremes.yAxis.max = e.max;
    };

    // Toggles zoom, panning and show zoom controls
    $scope.toggleMapCustomZoom = function (trigger)
    {
        $scope.mapNavEnabled = !$scope.mapNavEnabled;

        // Apply borders when in zoom mode
        if ($scope.mapNavEnabled) {
            $scope.chartOptions.borderColor = 'rgba(0,185,229,.5)';
            $scope.chartOptions.borderWidth = 5;

            // https://github.com/highcharts/highmaps-release/blob/master/modules/map.src.js#L2278
            $scope.mapConfig.options.chart.panning = 'xy';
        } else {
            $scope.chartOptions.borderColor = 'transparent';
            $scope.chartOptions.borderWidth = 0;
            $scope.mapConfig.options.chart.panning = false;
        }

        // Enable zoom/panning in map
        $scope.mapNavOptions.enabled = $scope.mapNavEnabled;
        $scope.mapNavOptions.enableDoubleClickZoom = $scope.mapNavEnabled;
        $scope.mapNavOptions.enableMouseWheelZoom = $scope.mapNavEnabled;
        $scope.mapNavOptions.enableTouchZoom = $scope.mapNavEnabled;

        // Check if zoom has changed and hasn't been saved
        if (mapZoomChanged() && trigger)
        {
            var modal = notify.confirm("Do you want to keep the changed zoom level on your map?");

            modal.result.then(function ()
            {
                $scope.save(trigger);
            }, function ()
            {
                $scope.updateMapZoom($scope.zoomExtremesOld);
            });
        }
    };

    // Save map (slide) from components button
    $scope.save = function (trigger)
    {
        if (mapZoomChanged()) {
            $scope.zoomExtremesOld = angular.copy($scope.data.zoomExtremes);
        }

        // Go back to main options
        if (!trigger) {
            $scope.toggleMapCustomZoom();
        }
    };


    // backward compatiable - change old '|in' filter to 'in'
    angular.forEach($scope.data.dataseries, function (dataseries) {

        if (!dataseries.filterQuery) {
            return;
        }

        angular.forEach(dataseries.filterQuery.where, function (item) {
            if (item['|in']) {
                item['in'] = item['|in'];
                delete item['|in'];
            }
        });
    });

    /////////////////////
    // scope functions //
    /////////////////////
    $scope.configDataSeries = configDataSeries;
    $scope.configCountryDetailModal = configCountryDetailModal;
    $scope.configChanged = configChanged;
    $scope.clickMarker = clickMarker;

    ///////////////////////
    // private functions //
    ///////////////////////
    /**
     * open up DataSeriesModal allowing user to update dataSeries feeding into the map component
     */
    function configDataSeries ()
    {
        if (!$scope.countryList || !$scope.regionList) {
            getCountryRegionList();
        }

        currDataRepo.loadAllDatasheets().then(function (datasheets)
        {
            var modalInstance = new DataSeriesModal(datasheets, $scope.data.dataseries, {
                isForMap: true,
                countryList: $scope.countryList,
                regionList: $scope.regionList
            });

            modalInstance.result.then(function (data)
            {
                $scope.data.dataseries = data;
                configChanged();

            });

        }, $log.error);

    }

    /**
     *  open up CountryDetailModal allowing user to update content of the country-detail popup
     */
    function configCountryDetailModal ()
    {
        var modalInstance = new CountryDetailModal($scope.data.countryDetailModel, fullDatasets, {isEditMode: true});

        modalInstance.result.then(function (data)
        {
            $scope.data.countryDetailModel = data;
        });
    }

    /**
     * Extract countryList and regionList from highMap's confituration/options
     *     - highMap.options.plotOptions.map.mapData.features
     *     - Should also save "country-abbrev" as a list, and feed into DataSeriesModal at configDataSeries()
     *       for country-name-validation
     */
    function getCountryRegionList ()
    {
        var o = highMapService.getCountryRegionList(highMap);

        $scope.countryList = o.countryList;
        $scope.regionList = o.regionList;
    }

    /**
     * event handler for clicking on marker (pin/flag/labels)
     *
     * @param  {String} markerName Country/region name attached to marker
     */
    function clickMarker (markerName)
    {
        popupCountryDetailModal(markerName);
    }

    /**
     * event handler for clicking on each region/country shape on the map
     * To popup the country details for editing/viewing.
     *
     * @param  {String} name Country/region name for the CountryDetailModal
     */
    function popupCountryDetailModal (name)
    {
        return new CountryDetailModal($scope.data.countryDetailModel, fullDatasets, {currCountry: name});
    }

    /**
     * upon config change, load filtered data, and feed data to highMapService
     *     - highMap will refresh/redraw after this,
     *     - necessary post-actions need to be done. e.g. $compile dataLabels
            -triggerOption: type of option triggering this function (E.g.: flags)
     */
    function configChanged (triggerOption)
    {
        $scope.theme = HighMapThemes.setTheme($scope.data.themeName);

        processData().then(function (data)
        {
            highmapMarkerFormatter.setData($scope.data, iconsNotFound);
            highMapService.apply($scope.mapConfig, {
                events: {
                    click: function (e)
                    {
                        popupCountryDetailModal(e.point.name);
                    }
                },
                dataLabels: {
                    formatter: highmapMarkerFormatter.get($scope.data.pinType)
                },
                showAllAreas: $scope.data.showAllAreas
            }, data, $scope.data.dataseries, $scope.theme);

            // re-compile dataLabels (markers, labels, flags) of map
            // otherwise ng-click on labels/markers/flags will not work
            $timeout(function () {

                // Some flag icons haven't been found
                // Notify admin with message
                var iconsNotFoundArr = Object.keys(iconsNotFound);

                if (iconsNotFoundArr.length > 0 &&
                    $scope.data.pinType === "flags" &&
                    triggerOption === "flags")
                {
                    Notification.warning("The following flag icon(s) couldn't been found in the icon repository: " + iconsNotFoundArr.join(', '));
                }

                if (!highMap.series[0]) {
                    return;
                }

                var dataLabelsDiv = highMap.series[0].dataLabelsGroup.div;
                if (dataLabelsDiv) {
                    dataLabelsDiv = angular.element(dataLabelsDiv);

                    if (dataLabelsScope) {
                        dataLabelsScope.$destroy();
                    }
                    dataLabelsScope = $scope.$new();
                    $compile(dataLabelsDiv)(dataLabelsScope);
                }
            });
        });
    }

    /**
     * load the actual filtered data from datasheets
     *     - since $scope.fullDatasets is updated, this promise can be resolve without any data
     */
    function processData ()
    {
        var deferred = $q.defer();

        currDataRepo.loadDatasetByFilter($scope.data.dataseries)
            .then(function (data)
            {
                for (var i = 0; i < data.length; i++) {

                    // Backwards compability
                    // FilterQuery come as Array in legacy maps
                    // This code will convert them to an Object instead
                    if ($scope.data.dataseries[i].filterQuery && Array.isArray($scope.data.dataseries[i].filterQuery.where))
                    {
                        var obj = $scope.data.dataseries[i].filterQuery.where.reduce(function (o, v, m)
                        {
                            o[m] = v;
                            return o;
                        }, {});

                        $scope.data.dataseries[i].filterQuery.where = obj;
                    }

                    data[i] = {
                        data: data[i],
                        labelField: $scope.data.dataseries[i].selectedLabel,
                        valueField: $scope.data.dataseries[i].selectedValue,
                        filterQuery: $scope.data.dataseries[i].filterQuery
                    };
                }

                fullDatasets = angular.copy(data);

                deferred.resolve(data);
            });

        return deferred.promise;
    }
});
