'use strict';

angular.module('app')

.service('defaultHighMapThemes', function (defaultConfigCache)
{
    const options = {
        theme: null,
        themeName: null,
        themes: {
            light: {
                name: 'Light',
                color: '#efefef',
                nullColor: 'rgba(239,239,239,0.3)',
                borderWidth: 0,
                borderColor: '#efefef',
            },
            dark: {
                name: 'Dark',
                color: '#424242',
                nullColor: 'rgba(66,66,66,0.3)',
                borderWidth: 0,
                borderColor: '#424242',
            }
        },
        setTheme: function (themeName) {

            if (!this.themes[themeName]) {
                themeName = this.getThemeOptions()[0];
            }

            this.themeName = themeName;
            this.theme = this.themes[themeName];

            return this.theme;
        },
        getThemeOptions: function () {
            var list = [];

            angular.forEach(this.themes, function (item, name) {
                list.push(name);
            });

            return list.sort();
        }
    };

    this.get = function () {
        return defaultConfigCache('mapConfig', options);
    };
});
