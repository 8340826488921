'use strict';

angular.module('app')

.controller('PresentationMenuCtrl', function (
    $scope,
    $rootScope,
    $stateParams,
    presentation,
    PreviousState,
    editorState,
    presentationState,
    ResourcePicker,
    slide,
    chapterSlides,
    $uibModal,
    $state,
    $http,
    base_uri,
    notify,
    kdAuth,
    Notification,
    presenterService,
    $window
) {

    this.$onInit = () =>
    {
        if (presentation) {
            checkClientTokens();
        }

        $scope.test = 'sufas';
    };

    const clientTokenInfo = {};


    $scope.presentationState = presentationState;
    $scope.presenterService = presenterService;
    $scope.previousState = PreviousState.getState();
    $scope.editorState = editorState;
    $scope.isSingleSlide = !presentation;
    $scope.offline = $rootScope.offline;
    $scope.fixClientTokens = fixClientTokens;

    $scope.selectPresentationClient = function ()
    {
        const picker = new ResourcePicker({
            viewOptions: 'clientViewOptions',
            model: 'Client',
            query: {
                where: {
                    folder_id: 0
                }
            },
            types: ['client']
        });

        picker.result.then(function (data) {
            presentationState.client = presentation.client = data[0];
            $rootScope.$broadcast('editable:reformat');
        });
    };

    $scope.syncToServer = function ()
    {
        if (!$window.navigator.onLine) {
            Notification.error('You are currently offline!');
            return;
        }

        return notify.confirm("Are you sure? Online presentation will be overwritten by your changes.").result.then(function ()
        {
            let promise;

            if (kdAuth.user && kdAuth.user.token) {
                promise = presentation.saveOnline();
            } else {
                const modal = $uibModal.open({
                    templateUrl: 'views/login.modal.html',
                    controller: 'ModalLoginCtrl',
                    backdrop: 'static',
                    keyboard: false,
                    size: 'md'
                });

                promise = modal.result.then(function ()
                {
                    return presentation.saveOnline();
                });
            }

            promise.catch(function (error)
            {
                //If conflict show resolve conflict model
                if (error.status === 403 || error.status === 404) {
                    resolveConflict(error.status);
                } else if (error.status === -1) {
                    Notification.error('You are currently offline!');
                } else {
                    Notification.error('Unknown error!.');
                }
            });

            return promise;
        });
    };

    $scope.unsavedChanges = function ()
    {
        return $scope.isSingleSlide ? slide.hasContentChanged() : presentation.hasContentChanged();
    };

    $scope.getSaveButtonTemplate = function ()
    {
        return presentationState.saving ?
            `<span class="animate-spinner material-icon material-icon-cached"></span>` :
            `Save`;
    };

    $scope.save = function ()
    {
        $rootScope.$broadcast('slide:save');
    };

    $scope.stopPresenting = function ()
    {
        presenterService.endSession();
    };

    $scope.$on('slide:saved', function ()
    {
        if (presentation) {
            presentation.client = presentationState.client;
            checkClientTokens();
        }

        editorState.history.clear();
    });

    $scope.$on('$destroy', () => clearClientTokenWarning(true));

    function resolveConflict (status)
    {
        const modal = $uibModal.open({
            templateUrl: 'views/modals/presentationConflict.html',
            controller: 'presentationConflictCtrl',
            backdrop: 'static',
            keyboard: false,
            size: 'md',
            resolve: {
                presentation: function () {
                    return presentation;
                },
                status: function () {
                    return status;
                }
            }
        });

        return modal.result;
    }

    function checkClientTokens()
    {
        clientTokenInfo.status = presentation.client ? presentation.client.getTokenStatus(presentation.getChapterSlides()) : [];
        const noErrors = clientTokenInfo.status.every((token) => token.status);

        if (!noErrors) {
            if (!clientTokenInfo.warning) {
                Notification.warning({
                    templateUrl: 'views/notification/missing.client.token.html',
                    closeOnClick: false,
                    scope: $scope,
                    delay: null,
                }).then((scope) => {
                    clientTokenInfo.warning = scope;
                });
            }
        } else {
            clearClientTokenWarning();
        }
    }

    function clearClientTokenWarning(force)
    {
        if (clientTokenInfo.warning) {
            clientTokenInfo.warning.kill(force);
            delete clientTokenInfo.warning;
        }
    }

    function fixClientTokens()
    {
        const modal = $uibModal.open({
            templateUrl: 'views/modals/missingClientTokens.html',
            controller: 'MissingClientTokensCtrl',
            windowClass: 'editor-modal',
            size: 'lg',
            resolve: {
                client: () => presentation.client,
                missingTokens: () => clientTokenInfo.status.map((item) => item.token)
            }
        });

        modal.closed.then(() => {
            checkClientTokens();
        });
    }
});
