'use strict';

angular.module('filters.date', [])

.config(function ($provide)
{
    $provide.decorator('dateFilter', function ($delegate)
    {
        return function (date, format, timezone)
        {
            format = format || 'MMM d, y HH:mm';
            return $delegate(date, format, timezone);
        };
    });
});
