'use strict';

angular.module('app')

.factory('LoadChapterSlides', function (Chapter, ChapterSlide, Slide)
{
    var _presentation, _chapters, _callback;

    function chapterSlides (presentation, callback)
    {
        _callback = callback;
        _presentation = presentation;
        _chapters = presentation.chapters;
        loadChapterSlides();
    }

    function loadChapterSlides ()
    {
        var chapterIds = Chapter.filter({presentation_id: _presentation.id}).map(function (item) {
            return item.id;
        });

        return ChapterSlide.findAll({
            where: {
                chapter_id: {
                    in: chapterIds
                }
            }
        }).then(function ()
        {
            Chapter.linkAll({}, ['chapter_slides']);
            return loadSlides();
        });
    }

    function loadSlides ()
    {
        var slideIds = [];
        angular.forEach(_chapters, function (chapter) {
            slideIds = slideIds.concat(chapter.chapter_slides.map(function (item) {
                return item.slide_id;
            }));
        });

        return Slide.findAll({
            where: {
                id: {
                    in: slideIds
                }
            }
        }).then(function ()
        {
            ChapterSlide.linkAll({}, ['slide']);
            return _callback();
        });
    }

    return chapterSlides;
});
