'use strict';

angular.module('presentation.theme')

.config(function (ThemeValidatorProvider)
{
    ThemeValidatorProvider.add(function(config) {
        const tables = config.tables;

        const colourFields = [
            {key: 'tableStyles.cellHighlightColor'},
        ];

        const textStyleFields = [
            {key: 'tableStyles.bodyTextstyle'},
            {key: 'tableStyles.headerTextstyle'},
        ];

        const borderStyleFields = [
            {key: 'tableStyles.bodyBorderStyle'},
            {key: 'tableStyles.headerBorderStyle'},
        ];

        return [{
            section: 'tables',
            fields: colourFields,
            validKeys: 'colour',
            config: tables
        }, {
            section: 'tables',
            fields: textStyleFields,
            validKeys: 'text',
            config: tables
        }, {
            section: 'tables',
            fields: borderStyleFields,
            validKeys: 'border',
            config: tables
        }];
    });
});
