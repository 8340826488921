'use strict';

angular.module('presentation.v2', [])

.run(function ($rootScope, $window)
{
    console.warn('Running in v2 development mode!'); // jshint ignore:line

    $rootScope.angularV2 = {
        openPresentation: (presentation) => {
            const presentationId = presentation.id;
            const chapterSlideId = presentation.chapters[0].chapter_slides[0].id;
            $window.open(`/v2#/viewer/${presentationId}/${chapterSlideId}`, '_blank');
        }
    };

});
