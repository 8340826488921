'use strict';

angular
    .module('app')
    .factory('highcharts', highcharts);

function highcharts ($window)
{
    return $window.Highcharts;
}
