'use strict';

angular
    .module('app')
    .factory('windowUrl', windowUrl);
    
function windowUrl ($window)
{
    if ($window.URL) {
        return $window.URL;
    }

    if ($window.webkitURL) {
        return $window.webkitURL;
    }

    return null;
}
