'use strict';

angular.module('app')

.controller('masterUsageCtrl', function (model, $window)
{
    this.$onInit = () =>
    {
        this.model = model;
        this.dependencies = model.dependencies.Presentations;
    };

    this.openTab = (url) =>
    {
        $window.open(url, '_blank');
    };

});
