'use strict';

angular.module('app')

.factory('DefaultDataRepo', function ($q, $log, Datasheet)
{
    return function ()
    {
        return {

            /**
             * load all datasheets available from database
             *
             * @return {Promise}
             */
            loadAllDatasheets: function ()
            {
                return Datasheet.findAll({
                    where: {
                        type: {
                            '!==': 'folder'
                        }
                    }
                }, {bypassCache: false});
            },

            /**
             * findAll data of the datasheet by *datasheetId* fitered by *filterQuery*
             *     - used in chart/map controllers
             *     - used to get filtered data from original datasheet, to be feed into highchart/highmap
             *
             * @param  {Array}  dataseries
             * @param  {Number} dataseries[n].datasheetId   ID of the datasheet
             * @param  {Object} dataseries[n].filterQuery   Query expression
             * @return {Promise}
             */
            loadDatasetByFilter: function (dataseries)
            {
                if (!dataseries || !dataseries.length) {
                    return $q.reject();
                }

                let dataSheetPromises = [];

                angular.forEach(dataseries, function (item)
                {
                    let getOne = Datasheet.find(item.selectedDatasheetId, {bypassCache: false}).then(function (sheet)
                    {
                        return sheet.fetchData().then(function ()
                        {
                            return sheet.getDataResource().filter(item.filterQuery);
                        });
                    });

                    dataSheetPromises.push(getOne);
                });

                return $q.all(dataSheetPromises);
            }
        };

    };
});
