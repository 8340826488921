'use strict';

angular.module('editorComponents.timeline')

.directive('timelineTable', function ()
{
    return {
        restrict: 'A',
        require: 'timelineViewport',
        bindToController: true,
        controller: function ($element)
        {
            this.$onInit = function ()
            {
                var timelineViewport = $element.controller('timelineViewport');
                timelineViewport.attachContainer($element);
            };
        }
    };
});
