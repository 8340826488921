'use strict';

angular.module('app')

.factory('multiDeleteAction', function (deleteAction)
{
    return angular.extend({}, deleteAction, {
        name: 'multiDelete',
        show: function (models) {
            return models.length > 1 && models.every(function (element) {
                return !element.deleted_at && element.editable !== false;
            });
        }
    });
});
