'use strict';

angular.module('presentation.theme')

.directive('themeTextStyles', function ()
{
    return {
        restrict: "E",
        templateUrl: 'app/presentation/theme/theme.builder/typography/text-styles/text-styles.html',
        scope: {
            model: "=",
        },
        bindToController: true,
        controllerAs: '$ctrl',
        controller: function($scope, DS)
        {
            const defaultTextStyle = {
                name: 'New text style',
                tag: '',
                typeface: null,
                weight: 'normal',
                size: 18,
                lineHeight: 24,
                letterSpacing: 0,
                defaultBottomMargin: 10,
                bold: false,
                italic: false,
                underline: false,
                uppercase: false,
                variants: []
            };

            const defaultVariantStyle = {
                id: null,
                name: 'New text style variant',
                typeface: null,
                weight: 'normal',
                size: 18,
                lineHeight: 24,
                letterSpacing: 0,
                defaultBottomMargin: 10,
                bold: false,
                italic: false,
                underline: false,
                uppercase: false,
            };

            const availableTags = [
                'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'cite', 'blockquote'
            ];

            $scope.fontsManagerCollapsed = true;
            // Array to hold the weights for text style select dropdowns
            $scope.selectedFontWeights = [];
            // Hold values for UI collapsible/visible states
            $scope.toggleFlags = [];
            $scope.variantToggleFlags = {};

            this.$onInit = function ()
            {
                getAvailableTags(this.model.config.typography.textStyles);
            };

            this.canAddTextStyles = () =>
            {
                return availableTags.length > 0;
            };

            this.addTextStyle = () =>
            {
                const textStyle = angular.copy(defaultTextStyle);
                textStyle.tag = textStyle.class = availableTags.pop();
                this.model.config.typography.textStyles.push(textStyle);

                setToggleFlags(textStyle.tag);
            };

            this.addTextStyleVariant = (textStyle) =>
            {
                textStyle.variants = textStyle.variants || [];

                let variant = angular.copy(defaultVariantStyle);
                variant.id = DS.utils.guid();
                textStyle.variants.push(variant);

                $scope.variantToggleFlags[variant.id] = {
                    collapsed: false,
                    editable: false,
                };
            };

            this.deleteTextStyle = (index) =>
            {
                availableTags.splice(0, 0, this.model.config.typography.textStyles[index].tag);
                availableTags.sort();
                this.model.config.typography.textStyles.splice(index, 1);
            };

            this.deleteTextStyleVariant = (textStyle, index) =>
            {
                textStyle.variants.splice(index, 1);
            };

            function setToggleFlags (tag, noCollapsed)
            {
                $scope.toggleFlags[tag] = {
                    collapsed: noCollapsed ? false : true,
                    editable: false,
                };
            }

            function getAvailableTags (textStyles)
            {
                angular.forEach(textStyles, function (textStyle, idx) {

                    let noCollapsed = idx === 0 ? true : false;
                    setToggleFlags(textStyle.tag, noCollapsed);

                    const index = availableTags.indexOf(textStyle.tag);
                    availableTags.splice(index, 1);
                });
                availableTags.sort();
            }
        }
    };
});
