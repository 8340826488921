'use strict';

angular.module('editorComponents.timeline')

.factory('timelineModal', function ($uibModal)
{
    return {
        open: function (options)
        {
            return $uibModal.open({
                templateUrl: 'scripts/editorComponents/timeline/modal/timeline.modal.html',
                controller: 'timelineModalCtrl',
                controllerAs: '$ctrl',
                windowClass: 'timeline-config',
                resolve: {
                    Options: function () {
                        return options;
                    }
                }
            });
        }
    };
});
