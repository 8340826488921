'use strict';

angular.module('datasheet.admin')

.config(function ($stateProvider, resourceStateProvider)
{
    $stateProvider
    .state('admin.datasheets.editor', {
        url: '/editor/{id}',
        views: {
            '@admin': {
                templateUrl: 'app/datasheet/admin/spreadsheet.html',
                controller: 'SpreadsheetEditorCtrl',
            },
        },
        resolve: {
            datasheet: function (Datasheet, $stateParams)
            {
                return Datasheet.find($stateParams.id);
            }
        }
    });

    resourceStateProvider
    .state('admin.datasheets', {
        url: 'datasheets',
        model: 'Datasheet',
        modelFieldName: 'name',
        viewOptions: 'datumViewOptions',
        title: 'Data',
        params: {
            folder_id: '0',
        },
        query: ['folder_id'],
        edit: {
            templateUrl: 'app/datasheet/admin/datasheet.edit.html',
            controller: 'DatasheetEditCtrl',
            resolve: {
                actions: ['Action', function (Action) {
                    return Action.findAll();
                }]
            }
        },
        breadcrumbs: true,
        move: true,
        deleteCheckDependencies: true
    });
});
