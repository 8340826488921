'use strict';

angular.module('presentation.theme')

.config(function (ThemeValidatorProvider)
{
    ThemeValidatorProvider.add(function(config) {
        const charts = config.charts;

        const colourFields = [
            {key: 'themes.dark.arrowColors.decrease'},
            {key: 'themes.dark.arrowColors.increase'},
            {key: 'themes.dark.arrowColors.noChange'},
            {key: 'themes.dark.highlightColor'},
            {key: 'themes.dark.xAxis.line'},
            {key: 'themes.dark.yAxis.line'},
            {key: 'themes.light.arrowColors.decrease'},
            {key: 'themes.light.arrowColors.increase'},
            {key: 'themes.light.arrowColors.noChange'},
            {key: 'themes.light.highlightColor'},
            {key: 'themes.light.xAxis.line'},
            {key: 'themes.light.yAxis.line'},
        ];

        return [{
            section: 'charts',
            fields: colourFields,
            validKeys: 'colour',
            config: charts
        }];
    });
});
