'use strict';

angular.module('presentation.frontend')

/**
 * presentationState service must be initialise and its state change handler
 * function bound to the stateChangeSuccess
 */
.run(function ($rootScope, presentationState)
{
    $rootScope.$on('$stateChangeSuccess', function ($event, toState, toParams)
    {
        presentationState.onStateChangeSuccess(toState, toParams);
    });

    $rootScope.$on('$stateChangeStart', function ($event, toState, toParams)
    {
        presentationState.onStateChangeStart(toState, toParams);
    });
})

/**
 * presentationState has too many responsibilities:
 *
 * - keeping track of the active models during a presentation
 * - determining whether navigation is possible forwards/backwards
 * - handling navigation forwards/backwards
 * - handling fullscreen mode
 * - keeping track of edit mode
 * - keeping track of slideshow/editor mode
 */
.factory('presentationState', function (
    $rootScope,
    $state,
    $window,
    Fullscreen,
    Presentation,
    Share,
    Slide,
    editorState,
    PreviousState,
    throttle,
    kdGate,
    slideTransitionConfig,
    IsMobile
) {
    return {

        // Editor
        editor: null,
        modalEditor: null,
        editMode: true,
        presentationMode: null,
        editorOptions: null,

        // Presentation Models
        presentation: null,
        chapter: null,
        slide: null,

        nextSlideModel: null,
        nextChapterModel: null,

        // Presnet mode
        presentLink: null,

        // State params for order
        chapterNum: 1,
        slideNum: 1,

        aspectRatio: '16:9',
        currentLayer: 'FrontLayer',

        client: null,

        // State
        saving: false,
        previewFromLayer: 'FrontLayer',
        previewLayer: 'FrontLayer',

        onStateChangeStart: function (toState, toParams)
        {
            this.presentationMode = toParams.presentationMode;
        },

        /**
         * Update properties on state change
         */
        onStateChangeSuccess: function (toState, toParams)
        {
            if (/^admin.(editor|slideshow).presentation.chapter.slide/.test(toState.name) && toParams.id) {

                let pressId = toParams.id;

                this.nextChapterModel = null;
                this.nextSlideModel = null;

                if (toParams.viewMode === 'presentLink') {
                    this.presentLink = Share.get(toParams.id);
                    pressId = this.presentLink.presentation_id;
                }

                this.presentation = Presentation.get(pressId);
                this.client = this.presentation.client;
                this.chapter = this.presentation.chapters ? this.findByDisplayOrder(this.presentation.chapters, toParams.chapterOrder) : null;
                this.chapter_slide = this.chapter ? this.findByDisplayOrder(this.chapter.chapter_slides, toParams.slideOrder) : null;
                this.slide = this.chapter_slide ? this.chapter_slide.slide : null;

                this.chapterNum = this.chapter ? this.chapter.display_order : 0;
                this.slideNum = this.chapter_slide ? this.chapter_slide.display_order : 0;
                editorState.presentationClient = this.client;

                this.setNextChapterSlide();

                return;
            }

            this.reset();

            if (/^admin.(editor|slideshow).presentation.print/.test(toState.name) && toParams.id) {
                this.presentation = Presentation.get(toParams.id);
                this.slide = Slide.get(toParams.slideId);

                this.chapter_slide = this.slide.chapter_slides[0];
                this.chapter = this.chapter_slide ? this.chapter_slide.chapter : null;

                this.client = this.presentation.client;
                this.editMode = false;
                return;
            }

            if (/^admin.editor.slide/.test(toState.name) && toParams.slideId) {
                this.slide = Slide.get(toParams.slideId);
                this.setEditMode(this.slide.editable);
            }
        },
        reset: function ()
        {
            this.presentation = null;
            this.presentLink = null;
            this.client = null;
            this.chapter = null;
            this.chapter_slide = null;
            this.slide = null;

            this.chapterNum = null;
            this.slideNum = null;
        },
        toggleEditMode: function ()
        {
            this.setEditMode(!this.editMode);
        },
        setEditMode: function (editMode)
        {
            // If editMode is not set, lets keep current edit mode and just update editor edit mode.
            if (editMode !== undefined) {
                this.editMode = editMode ? true : false;
            }

            if (!this.slide || !this.slide.editable || this.slideShowMode()) {
                this.setEditorEditMode(false);
            } else {
                this.setEditorEditMode(this.editMode);
            }
        },
        toggleSidebarEditMode: function ()
        {
            this.setSidebarEditMode(!editorState.sidebarEditMode);

            $rootScope.$broadcast('component:de-select');
            $rootScope.$broadcast('canvas:resize');
        },
        setSidebarEditMode: function (sidebarEditMode)
        {
            editorState.sidebarEditMode = sidebarEditMode &&
                                          this.presentation &&
                                          this.presentation.editable;
        },
        setEditorEditMode: function (editMode)
        {
            if (this.editor) {
                this.editor.setEditMode(editMode);

                if (this.modalEditor) {
                    this.modalEditor.setEditMode(editMode);
                }

            } else {
                editorState.editMode = editMode;
            }
        },
        slideShowMode: function ()
        {
            return $state.params.presentationMode === 'slideshow';
        },
        hasSlide: function (modifier)
        {
            if (!this.presentation || !this.chapter) {
                return false;
            }

            const slideN = Number(this.slideNum) + modifier;

            // slide doesn't exist
            if (!this.findByDisplayOrder(this.chapter.chapter_slides, slideN)) {

                // next/prev chapter exist
                if (this.findByDisplayOrder(this.presentation.chapters, this.chapterNum + modifier)) {
                    return 1;
                }
                // next/prev chapter doesn't exist
                else {
                    return 0;
                }
            // slide exist
            } else {
                return -1;
            }
        },
        setNextChapterSlide: function ()
        {
            const chapterN = Number(this.chapterNum) + 1;
            const slideN = Number(this.slideNum) + 1;

            if (this.presentation.chapters) {
                this.nextChapterModel = this.findByDisplayOrder(this.presentation.chapters, chapterN);
            }

            if (this.nextChapterModel && this.nextChapterModel.chapter_slides && !this.nextSlideModel) {
                this.nextSlideModel = this.nextChapterModel.chapter_slides[0].slide;
            }

            if (!this.nextChapterModel && this.chapter && this.chapter.chapter_slides) {
                let chapterSlide = this.findByDisplayOrder(this.chapter.chapter_slides, slideN);

                if (chapterSlide) {
                    this.nextSlideModel = chapterSlide.slide;

                    if (!this.nextChapterModel) {
                        this.nextChapterModel = chapterSlide.chapter;
                    }
                }

            }
        },
        changeSlide: throttle(function (modifier)
        {
            slideTransitionConfig.changeDirection(modifier);

            const self = this;
            const flag = this.hasSlide(modifier);

            this.slideNum = Number(this.slideNum) + modifier;
            this.currentLayer = 'FrontLayer';

            if (flag === 1) {
                this.changeChapter(modifier);
            } else if (flag === 0) {
                // revert back this.slideNum
                this.slideNum -= modifier;
            } else if (flag === false) {
                return;
            }

            $state.go($state.current.name, {
                id: self.presentLink ? self.presentLink.id : this.presentation.id,
                chapterOrder: self.chapterNum,
                viewMode: self.presentLink ? 'presentLink' : 'presentation',
                slideOrder: self.slideNum
            });

        }, slideTransitionConfig.duration * 1000),
        getSlideIndex: function (relativeTo)
        {
            if (!this.presentation || !this.chapter || !this.chapter_slide) {
                return 1;
            }

            relativeTo = relativeTo || 'Chapter';

            return this.presentation.getChapterSlides().reduce((displayOrder, chapterSlide, index) =>
            {
                if (chapterSlide.id !== this.chapter_slide.id) {
                    return displayOrder;
                }

                displayOrder = relativeTo === 'Chapter' ? chapterSlide.display_order : index + 1;
                return displayOrder;
            }, 0);
        },
        getByPressUnique: function (pressUnique)
        {
            if (!this.presentation) {
                return null;
            }

            return this.presentation.getChapterSlides().find((chapterSlide) =>
            {
                return chapterSlide.press_unique === pressUnique;
            });
        },
        getTotalSlides: function ()
        {
            return this.presentation ? this.presentation.getChapterSlides().length : 1;
        },
        changeChapter: function (modifier)
        {
            this.chapterNum = Number(this.chapterNum);
            this.chapterNum += modifier;

            if (modifier > 0) {
                this.slideNum = 1;
            } else {
                this.slideNum = this.findByDisplayOrder(this.presentation.chapters, this.chapterNum).chapter_slides.length;
            }
        },
        toggleFullscreen: function ()
        {
            if (Fullscreen.isEnabled()) {
                Fullscreen.cancel();
            } else {
                Fullscreen.all();
            }
        },
        findByDisplayOrder: function (items, index)
        {
            var zeroIndex = Number(index) - 1;

            items.sort(function (a, b) {
                return a.display_order - b.display_order;
            });

            return items[zeroIndex];
        },
        gotoPrintView: function ()
        {
            $state.go('admin.editor.presentation.print');
        },
        backTopresentation: function ()
        {
            $state.go('admin.editor.presentation.chapter.slide', PreviousState.getState());
        },
        isFixedCanvas: function ()
        {
            if (!this.presentation) {
                return true;
            } else if  (this.presentation && !this.presentation.config) {
                return false;
            } else {
                return this.presentation.config.aspectRatioCanvas || false;
            }
        },
        isPresentMode: function ()
        {
            return !!this.presentLink;
        },
        isSidebarOptions: function ()
        {
            if (!kdGate.allows('presentation.sidebar-options')) {
                return false;
            }

            if (!this.slide) {
                return false;
            }

            if (!this.slide.editable) {
                return false;
            }

            if (!this.presentation) {

                //single slide is opened!
                if (['master', 'template'].indexOf(this.slide.type) > -1) {
                    return this.slide.sidebar_options || false;
                }

                return false;
            }

            if (this.presentation.theme && this.presentation.theme.system) {
                return false;
            }

            if  (!this.presentation.config) {
                return false;
            }

            return this.presentation.config.sidebarOptions || $rootScope.config.presentation.editor.sidebar_options_default;
        },
        getStateClasses: function ()
        {
            const stateClasses = [];

            stateClasses.push('presentation-mode-' + this.presentationMode);

            if (this.isFixedCanvas()) {
                stateClasses.push('fixed-canvas');
            }

            if (IsMobile) {
                stateClasses.push('is-mobile');
            }

            return stateClasses;
        },
        goToPreview: function ()
        {
            this.previewFromLayer = this.currentLayer;

            // Switch to preview layer
            if (this.previewFromLayer !== this.previewLayer) {
                $rootScope.$broadcast('toggle-editing-layer', this.previewLayer);
            }

            $rootScope.$broadcast('toggle-targeted-video', false);

            this.toggleEditMode();
            this.toggleSidebarEditMode();
        },
        goToEdit: function ()
        {
            // Switch back to layer that was before
            if (this.previewFromLayer !== this.previewLayer) {
                $rootScope.$broadcast('toggle-editing-layer', this.previewFromLayer);
            }

            $rootScope.$broadcast('toggle-targeted-video', true);

            this.toggleEditMode();
            this.toggleSidebarEditMode();
        },
        getEditorOptions: function ()
        {
            return this.editorOptions;
        },
        setEditorOptions: function (options)
        {
            this.editorOptions = options;
        },
        editable: function ()
        {
            return this.presentation ? this.presentation.editable : this.slide.editable;
        }
    };
});
