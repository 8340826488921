'use strict';

angular.module('app')

.factory('printAction', function ($window, $uibModal)
{
    return {
        name: 'print',
        tooltip: 'PDF',
        buttonClass: 'btn btn-default',
        template: '<i class="material-icon material-icon-picture-as-pdf"></i> PDF',
        act: function (models) {

            $uibModal.open({
                templateUrl: 'views/modals/presentationPrintModal.html',
                controller: 'presentationPrintModalCtrl',
                size: 'md',
                resolve: {
                    presentation: function () {
                        return models[0];
                    }
                }
            });
        },
        show: function (model) {
            return model.type !== 'folder';
        }
    };
});
