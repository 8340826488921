'use strict';

angular.module('app')

.factory('twelfthman', function (ThemeConfig)
{
    var theme = new ThemeConfig({
        Gold: '#A68756',
        White: '#F1F1F1',
        'Grey 1': '#C8C8C8',
        'Grey 2': '#414042',
        'Grey 3': '#313032',
        'Grey 4': '#808080',
        'Blue': '#00B6DA',
        'Blue 2': '#4FB5D2',
        'None': ' '
    });

    /**
     * Modify the editor options to suit this theme
     *
     */
    theme.editorOptions = function (options)
    {
        options.textColours = theme.customColoursObject(['White', 'Grey 2', 'Gold', 'Blue']);
        options.backgroundColors = theme.customColoursObjectInverted(['White', 'Grey 1', 'Grey 3', 'Gold']);

        options.nestableComponents.column.nested['video-player'] = 'Video';

        options.componentOptions.textblock.tags.h6 = "Small paragraph";
        delete options.componentOptions.textblock.tags.cite;
        delete options.componentOptions.textblock.tags.blockquote;

        options.componentOptions.column.allowedTags = {};
        options.componentOptions.table.allowedTags = {};
        options.componentOptions.grid.allowedTags = {};
        options.componentOptions.chart.allowedTags = {};
        options.componentOptions.image.allowedTags = {};
        options.componentOptions['high-map'].allowedTags = {};
        options.componentOptions['bg-image'].allowedTags = {};
        options.componentOptions['bg-targeted-image'].allowedTags = {};
        options.componentOptions['targeted-image'].allowedTags = {};
        options.componentOptions['video-player'].allowedTags = {};
        options.componentOptions['shape-line'].allowedTags = {};
        options.componentOptions['comp-panel'].allowedTags = {};
        options.componentOptions['client-logo'].allowedTags = {};

        options.componentClasses.bullets.classes.square = 'Square';
    };

    theme.chartThemeConfig = function (options)
    {
        var arrowsConfig = {
            increased: theme.getColour('Green'),
            decreased: theme.getColour('Red'),
            equal: theme.getColour('Grey')
        };

        var colourList = theme.customColoursList([
            'Gold',
            'White',
            'Blue 2',
            'Grey 4',
            'Grey 2',
            'Grey 1',
        ]);

        options.themes.light.colors.arrows = arrowsConfig;
        options.themes.light.colors.highlight = theme.getColour('Gold');
        options.themes.light.colors.series = colourList;

        options.themes.dark.colors.arrows = arrowsConfig;
        options.themes.dark.colors.highlight = theme.getColour('Gold');
        options.themes.dark.colors.series = colourList;
    };


    return theme;
});
