'use strict';

angular.module('AppUuid', [])

.provider('appUuid', function ($injector)
{
    const testValue = Math.random().toString(36).substring(5);

    this.$get = function () {

        return function () {
            if ($injector.has('app_uuid')) {
                return $injector.get('app_uuid');
            }

            return testValue;
        };
    };
});
