'use strict';

angular.module('app')

.controller('SidemenuCtrl', function (
    $scope,
    $timeout,
    $state,
    $stateParams,
    $uibModal,
    $window,
    presentation,
    ChapterSlide,
    Chapter,
    Slide,
    extendDeep,
    Fullscreen,
    editorState,
    presentationState,
    presenteeService,
    kdGate,
    $q,
    $rootScope,
    Notification,
    auth,
    ResourcePicker,
    newSlideAction,
    notify,
    presenteeLink,
    clipboard
) {
    ///////////////////
    // scope members //
    ///////////////////
    $scope.selected = {};
    $scope.gotoPrintView = presentationState.gotoPrintView;
    $scope.presentation = presentation;
    $scope.offline = $rootScope.offline;
    $scope.isGuest = auth.isGuest();
    $scope.clipboard = clipboard;
    $scope.sidemenuClass = 'sidemenu-popout';

    $scope.sidemenuToggle = sidemenuToggle;
    $scope.sidemenuOver = sidemenuOver;

    if (presenteeLink) {
        $scope.presenteeState = presenteeService.state;
    } else {
        $scope.presenteeState = {
            allow_control: true
        };
    }

    $scope.saveAsMaster = saveAsMaster;
    $scope.createTemplate = createTemplate;
    $scope.cloneSlide = cloneSlide;

    ///////////////////////
    // private functions //
    ///////////////////////
    this.$onInit = () =>
    {
        Chapter.bindAll({
            where: {presentation_id: presentation.id},
            sort: ['display_order', 'ASC']
        }, $scope, 'chapters');

        let unbindWatch = $scope.$watch('chapters', function ()
        {
            unbindWatch();
            attachHandlers();
        });

        $timeout(() =>
        {
            if (presentation.editable &&
                kdGate.allows('presentation.create') &&
                !presentationState.slideShowMode())
            {
                if ($scope.chapters.length === 0) {
                    $scope.newChapter();
                // Show popup to add a new slide if there is only chapter without any slides
                } else if ($scope.chapters.length === 1  && $scope.chapterSlides && $scope.chapterSlides.length === 0) {
                    $scope.newSlide($scope.chapters[0]);
                }
            }
        });
    };

    let popOutTimer = startPopOutTimer();

    function startPopOutTimer ()
    {
        return $timeout(function () {
            $scope.sidemenuClass = '';
            cancelTimer();
        }, 2000);
    }

    function sidemenuToggle ($event, startTimer)
    {
        $event.stopPropagation();

        if ($scope.sidemenuClass === 'sidemenu-over') {
            $scope.sidemenuClass = 'sidemenu-popout';

            if (startTimer) {
                popOutTimer = startPopOutTimer();
            }

        } else {
            $scope.sidemenuClass = 'sidemenu-over';
        }
    }

    function sidemenuOver ($event, className)
    {
        $event.stopPropagation();

        if (popOutTimer) {
            cancelTimer();
        }

        if ($scope.sidemenuClass === 'sidemenu-over') {
            return;
        }

        $scope.sidemenuClass = className;
    }

    function cancelTimer ()
    {
        $timeout.cancel(popOutTimer);
        popOutTimer = null;
    }

    $rootScope.$on('slide:saved', () =>
    {
        updateSelected($state.params.chapterOrder, $state.params.slideOrder);
        $timeout(() =>
        {
            $state.reload('admin.editor.presentation.chapter');
        });
    });

    function stopPropagation (callback)
    {
        return ($event) =>
        {
            $event.stopPropagation();
            return callback.apply(this, arguments);
        };
    }

    function attachHandlers ()
    {
        $scope.editorState = editorState;

        updateSelected($state.params.chapterOrder, $state.params.slideOrder);

        $scope.$on('$stateChangeSuccess', (event, toState, toParams) =>
        {
            if (toParams.chapterOrder && toParams.slideOrder) {
                updateSelected(toParams.chapterOrder, toParams.slideOrder);
            }
        });
    }

    // loading the content of slides and linking to chapter.chapter_slides
    function updateSelected (chapterOrder, slideOrder)
    {
        if ($scope.chapters.length === 0) {
            return;
        }

        $scope.selected = {
            chapter: $scope.chapters[parseInt(chapterOrder) - 1]
        };

        if (!$scope.selected.chapter) {
            return;
        }

        ChapterSlide.bindAll({
            where: {chapter_id: $scope.selected.chapter.id},
            sort: ['display_order', 'ASC']
        }, $scope, 'chapterSlides');

        // delay until $scope.chapterSlides is updated/refreshed
        $timeout(() =>
        {
            $scope.selected.slide = $scope.chapterSlides[parseInt(slideOrder) - 1];
        });
    }

    $scope.goHome = stopPropagation(() =>
    {
        gotoSlide(1,1);
    });

    $scope.printPresentation = stopPropagation(() =>
    {
        $uibModal.open({
            templateUrl: 'views/modals/presentationPrintModal.html',
            controller: 'presentationPrintModalCtrl',
            size: 'md',
            resolve: {
                presentation: function () {
                    return presentation;
                }
            }
        });
    });

    $scope.downloadPresentation = stopPropagation(() =>
    {
        $window.open(getPresentationUrl('download'), '_blank');
        Notification.success('Downloading ZIP');
    });

    $scope.toggleFullscreen = stopPropagation(() =>
    {
        if (Fullscreen.isEnabled()) {
            Fullscreen.cancel();
        } else {
            Fullscreen.all();
        }
    });

    function insertChapter(chapter, afterChapter)
    {
        const insertAt = afterChapter.display_order + 1;
        chapter.display_order = insertAt;
        chapter.presentation_id = presentation.id;
        chapter.name = `[Copy] ${chapter.name}`;

        for (let i=0; i<$scope.chapters.length; i++) {
            const slot = $scope.chapters[i].display_order;
            $scope.chapters[i].display_order = slot >= insertAt ? slot+1 : slot;
        }

        return chapter;
    }

    $scope.newChapter = function ()
    {
        let chapter = {
            name: 'New chapter',
            presentation_id: presentation.id,
            display_order: $scope.chapters.length + 1
        };

        chapter = Chapter.inject(chapter);

        $scope.newSlide(chapter);
    };

    $scope.newSlide = function (chapter)
    {
        const options = {
            model: 'Slide',
            title: 'Slide picker',
            viewOptions: 'slideViewOptions',
            types: ['template', 'master'],
            toolbar: [newSlideAction]
        };

        $scope.chapter = chapter;
        $scope.modal = new ResourcePicker(options);

        $scope.modal.result.then((slides) =>
        {
            slides = [].concat(slides);
            let canAdd = true;
            let msg = "";

            slides.forEach((slide) =>
            {
                if (slide.type === 'template') {
                    slide.editable = true;
                }

                if (!chapter.canAddSlide(slide)) {
                    msg = 'It is not possible to add a Master slide multiple times into the same chapter!';
                    canAdd = false;
                }

                if ($rootScope.offline) {
                    if (slide.popups && slide.popups.length > 0) {
                        msg = 'Adding slide with popups is not supported offline!';
                        canAdd = false;
                    }

                    if (slide.popup) {
                        msg = 'Adding popups slides is not supported offline!';
                        canAdd = false;
                    }
                }
            });

            if (canAdd) {
                createNewSlide(chapter, slides);
            } else {
                Notification.warning(msg);
            }

        }, () =>
        {
            if ($scope.chapter.chapter_slides.length === 0) {
                Chapter.eject($scope.chapter.id);
            }
        });
    };

    function getPresentationUrl (type)
    {
        let url = presentation.links[type];

        if (presentationState.client) {
            url += '?client_id=' + presentationState.client.id;
        }

        return url;
    }

    function createNewSlide (chapter, slides, bypassSelected, noPrefix)
    {
        slides = [].concat(slides);

        let addAt = chapter.chapter_slides.length + 1; //By default at the end..

        //But if selected, then after selected slide.
        if ($scope.selected && $scope.selected.slide && !bypassSelected) {
            addAt = $scope.selected.slide.display_order + 1;
        }

        let promises = [];
        slides.forEach((slide, index) =>
        {
            promises.push(addSlideToChapter(chapter, slide, addAt + index, noPrefix));
        });

        $q.all(promises).then((chapterSlides) =>
        {
            const last = chapterSlides.pop();
            if (last) {
                $timeout(() => {
                    gotoSlide(last.chapter.display_order, last.display_order);
                });
            }
        });
    }

    function addSlideToChapter (chapter, slide, index, noPrefix)
    {
        if (slide.type === 'master') {
            return $q.resolve(chapter.addSlide(slide, index));
        }

        const extend = {
            type: 'slide',
            name: `${noPrefix ? "" : "[New] "}${slide.name}`,
            template_id: slide.type === 'template' ? slide.id : 0,
            folder_id: 0,
            action_id: 0,
        };

        return slide.cloneSlide(extend).then((newSlide) =>
        {
            return chapter.addSlide(newSlide, index);
        });
    }

    function gotoSlide (chapter_displayOrder, slide_displayOrder)
    {
        $state.go('admin.editor.presentation.chapter.slide', {
            chapterOrder: chapter_displayOrder,
            slideOrder: slide_displayOrder || null
        }, {reload: 'admin.editor.presentation.chapter'});
    }

    function deleteConfirmation (options)
    {
        return notify.open(options, {
            cancel: {
                label: 'Cancel',
                buttonClass: 'btn btn-default btn-block',
                act: (modalInstance) => modalInstance.dismiss()
            },
            delete: {
                label: 'Delete',
                buttonClass: 'btn btn-danger btn-block',
                act: (modalInstance, result) => {
                    modalInstance.close(result);
                }
            }
        });
    }

    $scope.removeSlide = (list, item, $event) =>
    {
        $event.preventDefault();
        $event.stopPropagation();

        deleteConfirmation({
            title: 'Delete slide',
            message: 'Are you sure you want to delete this slide?',
        }).result.then(() => {
            const modifier = list.length === item.display_order ? -1 : 0;
            const slide_displayOrder = item.display_order + modifier;
            destroyItem(list, item, item.chapter.display_order, slide_displayOrder);
        });
    };

    $scope.copySlide = (slide, $event) =>
    {
        $event.preventDefault();

        clipboard.copySlide(slide).then(() =>
        {
            Notification.success('Slide copied to clipboard!');
        });
    };

    $scope.copyChapter = (chapter, $event) =>
    {
        $event.preventDefault();

        clipboard.copyChapter(chapter).then(() =>
        {
            Notification.success('Chapter copied to clipboard!');
        });
    };

    $scope.pasteChapter = (chapterAfter, $event) =>
    {
        $event.preventDefault();

        clipboard.pasteChapter().then((data) => {

            if (!data) {
                Notification.warning('Clipboard is empty.');
                return;
            }

            createNewSlide(insertChapter(data.chapter, chapterAfter), data.slides, true, true);
        });
    };

    $scope.pasteSlide = (chapterSlideAfter, $event) =>
    {
        $event.preventDefault();

        let chapterSlide = chapterSlideAfter;

        clipboard.pasteSlide().then((newSlide) => {

            if (!newSlide) {
                Notification.warning('Clipboard is empty.');
                return;
            }

            if (!chapterSlide.chapter.canAddSlide(newSlide)) {
                let msg = 'It is not possible to add a Master slide multiple times into the same chapter!';
                Notification.warning(msg);
                return;
            }

            if (newSlide.type !== 'master') {
                newSlide.name = chapterSlide.chapter.getSlideUniqName(newSlide.name, 'Copy');
            }

            let index = chapterSlide.display_order + 1;
            let newChapterSlide = chapterSlide.chapter.addSlide(newSlide, index);

            if (newChapterSlide) {
                Notification.success('Slide pasted successfully!');
            }
        });
    };

    $scope.removeChapter = (list, item, $event) =>
    {
        $event.preventDefault();
        $event.stopPropagation();

        deleteConfirmation({
            title: 'Delete chapter',
            message: 'Are you sure you want to delete this chapter and its slides?',
        }).result.then(() => {
            const modifier = list.length === item.display_order ? -1 : 0;
            const chapter_displayOrder = item.display_order + modifier;
            destroyItem(list, item, chapter_displayOrder, 1);
        });
    };

    function destroyItem (list, item, chapterOrder, slideOrder)
    {
        item.DSEject();

        if ($state.params.chapterOrder === chapterOrder && $state.params.slideOrder === slideOrder) {
            $state.reload('admin.editor.presentation.chapter');
        } else {
            $timeout(() =>
            {
                gotoSlide(chapterOrder, slideOrder);
            });
        }

        orderList(list, item);

        Notification.success('Successfully removed');
    }

    /**
     * post action after moved row
     *     - $state.go with new chapterOrder/slideOrder
     */
    $scope.movedRow = function (movedItem)
    {
        let params = {
            chapterOrder: parseInt($state.params.chapterOrder),
            slideOrder: parseInt($state.params.slideOrder)
        };

        if (movedItem.chapter) { //moved ChapterSlide
            params.chapterOrder = movedItem.chapter.display_order;
            params.slideOrder = movedItem.display_order;
        } else {
            params.chapterOrder = movedItem.display_order;

            //If moved not selected Chapter, go to a first slide of that chapter.
            if ($scope.selected.chapter && $scope.selected.chapter.id !== movedItem.id) {
                params.slideOrder = 1;
            }
        }

        const options = {
            reload: 'admin.editor.presentation.chapter'
        };

        $state.go('admin.editor.presentation.chapter.slide', params, options);
    };

    function orderList (list, item)
    {
        // Order slide list by display_order ASC before destroying
        list.sort((obj1, obj2) =>
        {
            return obj1.display_order - obj2.display_order;
        });

        let idx = 1;
        list.forEach((listItem) =>
        {
            if (listItem === item) {
                return;
            }

            if (listItem.display_order !== idx) {
                listItem.display_order = idx;
            }

            idx++;
        });
    }

    function saveAsMaster (slide)
    {
        const modal = $uibModal.open({
            templateUrl: 'scripts/resourceActions/master/master.html',
            controller: function ($scope, model)
            {
                $scope.model = angular.copy(model);

                model.snapshotContent(true);

                angular.extend($scope.model, {
                    name: '[Master] ' + model.name,
                    type: 'master',
                    template_id: 0,
                    folder_id: 0
                });

                $scope.saveModel = function ()
                {
                    $scope.$close($scope.model);
                    Notification.success('Master slide created');
                };
            },
            windowClass: 'editor-modal',
            resolve: {
                model: function () {
                    return slide;
                },
                actionMode: function () {
                    return 'master';
                }
            },
        });

        modal.result.then(function (model) {
            Slide.inject(model);
        });

        modal.result.catch(function () {
            Slide.revert(slide.id);
        });
    }

    function createTemplate (slide)
    {
        $uibModal.open({
            templateUrl: 'scripts/resourceActions/template/template.html',
            controller: 'SlideEditCtrl',
            windowClass: 'editor-modal',
            resolve: {
                model: function () {
                    return slide;
                },
                actionMode: function () {
                    return 'template';
                }
            },
        });
    }

    function cloneSlide (slide)
    {
        const modal = $uibModal.open({
            templateUrl: 'views/slide.edit.html',
            controller: function ($scope, model)
            {
                const originalModel = model;

                $scope.cloning = true;
                $scope.model = angular.copy(originalModel);

                angular.extend($scope.model, {
                    name: `${model.name}`,
                    folder_id: 0,
                    type: 'slide',
                });
                delete $scope.model.id;

                $scope.saveModel = function ()
                {
                    const afterDisplayOrder = originalModel.chapter_slides[0].display_order;

                    $scope.model.cloneSlide().then(function (newSlide)
                    {
                        $scope.$close({afterDisplayOrder: afterDisplayOrder, newModel: newSlide});
                    });
                };
            },
            windowClass: 'editor-modal',
            resolve: {
                model: function () {
                    return slide;
                }
            },
        });

        modal.result.then(function (data)
        {
            const chapter = presentationState.chapter;

            if (chapter) {
                const afterDisplayOrder = data.afterDisplayOrder;
                const newModel = data.newModel;
                const newSlide = Slide.inject(newModel);

                let newOrder = createGapForNewSlide(afterDisplayOrder);

                const newChapterSlide = ChapterSlide.inject({
                    chapter_id: chapter.id,
                    slide_id: newSlide.id,
                    display_order: newOrder,
                });

                $state.go('admin.editor.presentation.chapter.slide', {
                    chapterOrder: newChapterSlide.chapter.display_order,
                    slideOrder: newChapterSlide.display_order
                });

                Notification.success('Slide cloned');
            }
        });

        function createGapForNewSlide (afterDisplayOrder)
        {
            const chapterLength = presentationState.chapter.chapter_slides.length;

            for (let index = 0; index < chapterLength; index++) {
                let chapterSlide = presentationState.chapter.chapter_slides[index];

                if (chapterSlide.display_order > afterDisplayOrder) {
                    chapterSlide.display_order = chapterSlide.display_order + 1;
                }
            }

            return afterDisplayOrder + 1;
        }

    }
});
