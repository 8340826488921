'use strict';

angular.module('presentation.theme')

.component('themeBackgroundOverlayOptions',  {
    templateUrl: 'app/presentation/theme/theme.builder/images/background/background-overlay-options/background-overlay-options.html',
        bindings: {
        item: '<',
    },
});
