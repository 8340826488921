'use strict';

angular.module('app')

.factory('nudgeable', function ($document)
{
    let elem = null;
    let container = null;
    let containerHeight = 0;
    let containerWidth = 0;
    let increment = 1;
    let x = 0;
    let y = 0;
    let height = 0;
    let width = 0;
    let callback = null;

    const position = {};

    return {
        select: function (newElem, newContainer, newCallback)
        {
            elem = newElem;
            container = newContainer;
            callback = newCallback;
            $document.on('keydown', keyDown);
        },
        deselect: function () {
            elem = container = null;
            $document.off('keydown', keyDown);
        }
    };

    function keyDown (event)
    {
        if (!elem) {
            return;
        }

        getDimensions();
        updatePosition(event);
        setElementStyle();
        callback(position);
    }

    function getDimensions ()
    {
        width = elem[0].clientWidth;
        height = elem[0].clientHeight;

        containerHeight = container[0].clientHeight;
        containerWidth = container[0].clientWidth;

        x = elem[0].offsetLeft;
        y = elem[0].offsetTop;
    }

    function updatePosition (event)
    {
        const keys = {
            'ArrowLeft': () => x = x - increment,
            'ArrowRight': () => x = x + increment,
            'ArrowUp': () => y = y - increment,
            'ArrowDown': () => y = y + increment,
        };

        if (keys[event.code]) {
            keys[event.code]();
        }
    }

    function setElementStyle ()
    {
        const left = x / containerWidth  * 100;
        const top = y / containerHeight * 100;

        if (left >= 0 && left <= 100) {
            position.left = left.toFixed(3) + '%';
        }

        if (top >= 0 && top <= 100) {
            position.top = top.toFixed(3) + '%';
        }

        elem.css(position);
    }
});