'use strict';

angular.module('editorComponents.timeline')

.component('timelineViewport', {
    require: {
        timeline: '^timeline'
    },
    templateUrl: 'scripts/editorComponents/timeline/views/timeline.viewport.html',
    controller: 'timelineViewportCtrl',
    bindings: {
        data: '<'
    }
});
