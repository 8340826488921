'use strict';

angular.module('komondor-editor')

.directive('sidebarShapeLineColour', function ()
{
    return {
        replace: false,
        restrict: 'A',
        require: '^editor',
        templateUrl: 'scripts/editorComponents/shapeLine/shape-line-colour/sidebar-shape-line-colour.html',
        controllerAs: '$lineColourCtrl',
        controller: function ($scope, $element, editorState)
        {
            this.comScope = editorState.componentScope;

            this.$onInit = () =>
            {
               // console.log('sidebarShapeLineFormatting');
            };
        }
    };
});
