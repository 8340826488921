'use strict';

angular.module('presentation.model')

.run(function (Chapter) {}) // jshint ignore:line

.factory('Chapter', function (DS)
{
    return DS.defineResource({
        basePath: '/api/',
        name: 'Chapter',
        endpoint: 'chapters',
        upsert: true,
        methods: {
            forceSlidesDisplayOrder: function ()
            {
                var chapter_slides = this.chapter_slides;

                chapter_slides.sort(function (a, b)
                {
                    return parseInt(a.display_order) - parseInt(b.display_order);
                });

                angular.forEach(chapter_slides, function(chapter_slide, key)
                {
                    chapter_slide.display_order = key + 1;
                });
            },
            canAddSlide: function (slide)
            {
                if (slide.type !== 'master') {
                    return true;
                }

                //No master slide exists here, can add it.
                return !this.chapter_slides.some(function (cs)
                {
                    return cs.slide_id === slide.id;
                });
            },
            /**
             * Adds slide to this chapter at specified index
             * And fixes the display order for the rest of the slides.
             * Index is the display order new slide is going to be
             *
             * @param slide
             * @param index
             */
            addSlide: function (slide, index)
            {
                angular.forEach(this.chapter_slides, function(chapter_slide)
                {
                    //All slides after new slide gets display order increased
                    //Leaving a spot for a new slide.
                    if (chapter_slide.display_order >= index) {
                        chapter_slide.display_order += 1;
                    }
                });

                //injecting new Chapter Slide.
                var chapterSlide = DS.inject('ChapterSlide', {
                    chapter_id: this.id,
                    slide_id: slide.id,
                    display_order: index, //This spot should be free now, as everything >= this is increased by 1
                });

                //Forcing everything to be in order.
                this.forceSlidesDisplayOrder();

                return chapterSlide;
            },
            getSlideUniqName: function (originalName, prefix)
            {
                let re = new RegExp("^\[" + prefix + "[0-9 ]*\] ","g");
                originalName = originalName.replace(re, '');

                const hasName = (name) => {
                    let has = false;
                    angular.forEach(this.chapter_slides, (chapter_slide) => {
                        if (chapter_slide.slide.name === name) {
                            has = true;
                        }
                    });

                    return has;
                };

                let newName = '[' + prefix + '] ' + originalName;
                let i = 0;

                while (hasName(newName)) {
                    i++;
                    newName = '[' + prefix + ' ' + i + '] ' + originalName;
                }

                return newName;
            }
        },
        relations: {
            hasMany: {
                ChapterSlide: {
                    localField: 'chapter_slides',
                    foreignKey: 'chapter_id'
                }
            }
        },
        beforeInject: function (options, chapter)
        {
            if (!chapter.id) {
                chapter.id = DS.utils.guid();
            }
        }
    });
});
