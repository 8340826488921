'use strict';

angular.module('app')

.controller('ContactPresentationsCtrl', function (
    $scope,
    shares
) {
    $scope.shares = shares;

});
