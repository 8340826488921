'use strict';

angular.module('app')

.service('prepareEditorClientOptions', function ($window, $document)
{
    this.update = (options) =>
    {
        options.backgroundColors.client_brad_color = "Client color";
        options.textColours['Client color'] = 'client_brad_color';


        options.onColorText = function (colour, $scope) {

            if (colour === 'client_brad_color') {

                $scope.execCommand("removeFormat", false, "foreColor");

                if ($window.getSelection) {

                    var sel = $window.getSelection();

                    if (sel.rangeCount) {
                        var range = sel.getRangeAt(0).cloneRange();

                        var font = $document[0].createElement("font");
                        font.classList.add('client-brand-color');
                        font.setAttribute("style", "color: #client_brand_color !important;");

                        range.surroundContents(font);

                        sel.removeAllRanges();
                        sel.addRange(range);
                    }
                }

                return true;
            }

            return false;
        };

        options.onColorTextBg = function (colour, $scope) {

            if (colour === 'client_brad_color') {

                $scope.execCommand("removeFormat", false, "hiliteColor");

                if ($window.getSelection) {

                    var sel = $window.getSelection();

                    if (sel.rangeCount) {
                        var range = sel.getRangeAt(0).cloneRange();

                        var span = $document[0].createElement("span");
                        span.classList.add('client-brand-color');
                        span.setAttribute("style", "background-color: #client_brand_color !important;");

                        range.surroundContents(span);

                        sel.removeAllRanges();
                        sel.addRange(range);
                    }
                }

                return true;
            }

            return false;
        };
    };
});

