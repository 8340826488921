'use strict';

angular.module('presentation.theme')

.factory('cloneThemeAction', function ($http, $state, notify, Notification, DS)
{
    return {
        name: 'clone',
        tooltip: 'Clone',
        buttonClass: 'btn btn-default',
        template: '<i class="material-icon material-icon-content-copy"></i> Clone',
        act: function (models)
        {
            notify.confirm('Are you sure you want to clone this theme?').result.then(function ()
            {
                return $http.post('/api/themes/' + models[0].id + '/clone').then((response) => {
                    var theme = DS.inject('Theme', response.data);
                    Notification.success('Theme has been cloned!');
                    $state.reload('admin.themes');

                    return theme.DSSave();
                });
            });
        },
        show: function ()
        {
            //Otherwise can clone.
            return true;
        }
    };
})

.factory('themeViewOptions', function (
    resourceViewOptions,
    extendDeep,
    listNameTemplate,
    createAction,
    listView,
    kdGate,
    moment,
    editSingleItemAction,
    deleteSingleItemAction,
    cloneThemeAction,
    deselectAction,
    multiDeleteAction
) {
    function showCreateButton ()
    {
        return kdGate.allows('theme.create');
    }

    let newCreateAction = angular.copy(createAction);
    newCreateAction.template = '<i class="material-icon material-icon-add"></i> New theme';
    newCreateAction.show = showCreateButton;

    let newMultiDeleteAction = angular.copy(multiDeleteAction);
    newMultiDeleteAction.show = (models) => {

        if (multiDeleteAction.show(models)) {
            return showCreateButton();
        }

        return false;
    };

    let newEditSingleItemAction = angular.copy(editSingleItemAction);
    newEditSingleItemAction.show = showCreateButton;

    let newCloneThemeAction = angular.copy(cloneThemeAction);
    newCloneThemeAction.show = showCreateButton;

    let newDeleteSingleItemAction = angular.copy(deleteSingleItemAction);
    newDeleteSingleItemAction.show = showCreateButton;

    const options = angular.copy(resourceViewOptions);

    extendDeep(options, {
        moment: moment,
        actionsInside: [newEditSingleItemAction, newCloneThemeAction, newDeleteSingleItemAction],
        actions: [deselectAction, newMultiDeleteAction],
        queryDefaults: {
            where: {
                system: {
                    '==': 0
                }
            },
        },
        toolbar: [newCreateAction],
        onItemClick: function ($event, model)
        {
            if (!model.system) {
                $event.stopPropagation();
                model.goInto();
            }
        },
        views: [listView],
        list: {
            columns: [
                {
                    label: 'Label',
                    name: 'label',
                    template: '<div ng-if="model.system">{{model.label}}</div>' +
                                    '<div ng-if="!model.system">' +
                                    listNameTemplate('label', null, 'theme') +
                                    '</div>',
                    sortable: true
                },
                {
                    label: 'System',
                    name: 'system',
                    sortable: false,
                    template: '<i ng-show="model.system" ' +
                                    'class="material-icon material-icon-check"></i>',
                },
                {
                    label: 'Default',
                    name: 'is_default',
                    sortable: false,
                    template: '<i ng-show="model.is_default" ' +
                                    'class="material-icon material-icon-check"></i>',
                },
                {
                    label: 'Last modified',
                    name: 'updated_at',
                    template: `<span class="no-wrap" uib-tooltip="{{ model.updated_at | date }}">
                                    {{ options.moment(model.updated_at).fromNow() }}
                                </span>`,
                    sortable: true
                },
                {
                    template: `<contextual-options actions="options.actionsInside"
                                                   models="options.resourceList.selected"
                                                   model="model"
                                                   class="row-actions pull-right"></contextual-options>`
                }
            ]
        }
    });

    return options;
});
