'use strict';

angular.module('app')

.decorator('mediaViewOptions', function (
    $delegate,
    kdGate,
    deselectAction,
    multiDeleteAction,
    multiMoveAction,
    moment,
    editSingleItemAction,
    targetedImagerySingleItemAction,
    moveSingleItemAction,
    deleteSingleItemAction,
    Asset,
    $rootScope,
    createFolderAction,
    $state,
    Notification
) {
    $delegate.moment = moment;
    $delegate.taggingAdvanced = true;

    $delegate.toolbar = [createFolderAction, {
        name: 'upload',
        buttonClass: 'btn btn-primary',
        template: '<i class="material-icon material-icon-upload"></i> Upload new',
        act: function ()
        {
            const max =  $rootScope.config.domain.config ? $rootScope.config.domain.config.max_storage_gb : null;

            if (max && Asset.meta.size > max) {
                return Notification.warning({
                    title: `Storage limit reached`,
                    message: `Please contact your bylder support person to increase your limit.`
                });
            }

            $state.go('.upload');
        },
        show: function ()
        {
            return true;
        }
    }];

    $delegate.actions = [
        deselectAction,
        multiDeleteAction,
        multiMoveAction,
    ];

    $delegate.inlineActions = [
        editSingleItemAction,
    ];

    $delegate.inlineDropdown = [
        targetedImagerySingleItemAction,
        moveSingleItemAction,
        deleteSingleItemAction
    ];

    $delegate.gridActions = [
        ...$delegate.inlineActions,
        ...$delegate.inlineDropdown,
    ];

    $delegate.tagging = true;

    $delegate.footerTemplate = 'views/media.footer.html';

    $delegate.meta = () =>
    {
        let max = $rootScope.config.domain && $rootScope.config.domain.config ? $rootScope.config.domain.config.max_storage_gb : 0;
        const meta = {
            max: max || 0,
            size: Asset.meta && Asset.meta.size || 0,
            totalSize: Asset.meta && Asset.meta.totalSize || 0,
        };

        meta.percentage = meta.max && (meta.size / meta.max * 100).toFixed(2) || 0;

        return meta;
    };

    $delegate.sorts = [
        {
            label: "Type",
            sort: "type asc,name asc"
        }
    ];

    angular.forEach($delegate.toolbar, function (item)
    {
        item.show = function () {
            return kdGate.allows('media.create');
        };
    });

    $delegate.rowClassifier = function (row, classes) {
        const viewLabel = this.views[this.viewIndex].label;
        classes[viewLabel] = !!row.targeted;
        classes['status-targeted-imagery'] = !!row.targeted;
        return classes;
    };

    $delegate.list.columns.splice(1, 0, {
        template: `<div ng-if="::!!model.targeted"
                        class="status-targeted-imagery-label">Targeted</div>`,
    });

    $delegate.list.columns.splice(4, 1, {
        label: 'Last modified',
        name: 'updated_at',
        template: `<span class="no-wrap" uib-tooltip="{{ model.updated_at | date }}">
                        {{ options.moment(model.updated_at).fromNow() }}
                    </span>`,
        sortable: true
    },
    {
        template: `<contextual-options actions="options.inlineActions"
                                   dropdown-actions="options.inlineDropdown"
                                   models="options.resourceList.selected"
                                   model="model"
                                   class="row-actions pull-right"></contextual-options>`
    });

    $delegate.grid.fields.push({
        template: `<contextual-options dropdown-actions="options.gridActions"
                                       models="options.resourceList.selected"
                                       model="model"
                                       class="grid-actions pull-right"></contextual-options>`
    });

    return $delegate;
});
