'use strict';

angular.module('app')

.factory('DataToken', function (zeroWidthSpaceCleaner) {

    var tokenOpen = '{[';
    var tokenEnd = ']}';
    var tokenWrapperClass = 'data-token';

    function generateToken (datasheetIdx, valueField)
    {
        return tokenOpen + 'dataseries_' + datasheetIdx + '_' + valueField + tokenEnd;
    }

    /**
     * parse each editable content, find the ones with data-token,
     *     convert to actual data and replace in its original context
     *
     */
    function parseTokensInString (value, fullDatasets, currCountry)
    {
        // $log.debug('*** parseTokensInString', value, fullDatasets, currCountry);

        var o = findTokensInStr(value, fullDatasets, currCountry);
        o.reverse();

        for (var i = 0; i < o.length; i++) {
            value = spliceSlice(value, o[i].index, o[i][0].length, '<span class="' + tokenWrapperClass + '">' + o[i].value + '</span>');
        }

        return value;
    }

    /**
     * get the matched tokens inside a text string
     *
     */
    function findTokensInStr (str, fullDatasets, currCountry)
    {
        // $log.debug('*** findTokensInStr', str, fullDatasets, currCountry);

        var myArray = [];
        var myRe = /\{\[(.+?)\]\}/g;
        var tokenList = [];

        while ((myArray = myRe.exec(str)) !== null) {

            // Backwards compatibility for string containing zero width chars
            myArray[1] = zeroWidthSpaceCleaner.remove(myArray[1]);
            var o = parseTokenToObj(myArray[1]);
            o = getDataByToken(o, fullDatasets, currCountry);
            myArray.value = o;

            tokenList.push(myArray);
        }

        return tokenList;
    }

    /**
     * convert token string to token Object
     *
     */
    function parseTokenToObj (tokenStr, fullDatasets, currCountry)
    {
        var arr = tokenStr.split('_');

        return {
            dataSeriesIdx: parseInt(arr[1]),
            labelField: currCountry,
            valueField: arr[2]
        };
    }

    /**
     * convert the data-token to its actual value in fullDatasets
     *
     * @param  {Object} fullDatasets    - complete datasets
     * @param  {Object} tokenObj        - token object
     * @param  {String} currCountry
     */
    function getDataByToken (tokenObj, fullDatasets, currCountry)
    {
        // $log.debug('*** getDataByToken', tokenObj, fullDatasets, currCountry);
        var dataset = fullDatasets[tokenObj.dataSeriesIdx];
        currCountry = currCountry.toLowerCase();

        for (var i = 0; i < dataset.data.length; i++) {
            var countryName = dataset.data[i][dataset.labelField] || '';

            if (countryName.toLowerCase() === currCountry) {
                return dataset.data[i][tokenObj.valueField];
            }
        }

        // Value not found so return empty string
        return '';
    }

    /**
     * [spliceSlice description]
     *
     * @param  {string} str     - full string
     * @param  {number} index   - start index
     * @param  {number} count   - length of token, to be replaced
     * @param  {string} add     - to be inserted into string, at position 'index'
     * @return {string}
     */
    function spliceSlice (str, index, count, add) {
        return str.slice(0, index) + (add || "") + str.slice(index + count);
    }

    return {
        generateToken: generateToken,
        parseTokensInString: parseTokensInString
    };
});
