'use strict';

angular.module('presentation.theme')

.directive('themeColorList', function ()
{
    return {
        restrict: "E",
        templateUrl: 'app/presentation/theme/components/theme.colour-list/theme.colour-list.html',
        scope:{
            model: '=',
            defaultColors: '=',
        },
        link: function ($scope)
        {
            $scope.toggleSelection = function(color)
            {
                var idx = $scope.model.indexOf(color.name);

                if (idx > -1) {
                    $scope.model.splice(idx, 1);
                } else {
                    $scope.model.push(color.name);
                }
            };
        }
    };
});
