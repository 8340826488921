'use strict';

angular.module('app').factory('auditLogViewOptions', function (
    resourceViewOptions,
    extendDeep,
    listView,
    moment,
    restoreAction
) {
    const options = angular.copy(resourceViewOptions);

    options.inlineActions = [restoreAction];
    options.gridActions = [...options.inlineActions];

    extendDeep(options, {
        moment: moment,
        views: [listView],
        with: ['user', 'metricable'],
        toolbar: [],
        filters: {
            templateUrl: 'app/admin/audit-logs/audit-logs.filters.html'
        },
        sorts: [
            {
                label: "Time and date",
                sort: "created_at desc"
            },
            {
                label: "Asset type",
                sort: "metricable_type asc"
            },
            {
                label: "Action",
                sort: "action asc"
            }
        ],
        onItemClick: function ($event) {
            $event.stopPropagation();
        },
        list: {
            columns: [
                {
                    label: 'User',
                    template: `<div>{{ model.user.name }}</div>`,
                    sortable: false,
                    editable: false,
                },
                {
                    label: 'Asset type',
                    name: 'metricable_type',
                    sortable: false,
                    editable: false,
                },
                {
                    label: 'Asset name',
                    name: 'asset_name',
                    template: `<span>{{model.data.modelName}}</span>`,
                    sortable: false,
                    editable: false,
                },
                {
                    label: 'Action',
                    name: 'action',
                    sortable: false,
                    editable: false,
                },

                {
                    label: 'Time and date',
                    name: 'created_at',
                    sortable: false,
                    editable: false,
                    template: `<span class="no-wrap" uib-tooltip="{{ model.created_at | date }}">
                                    {{ options.moment(model.created_at).format("Do MMM YYYY, HH:mm:ss") }}
                                </span>`,
                },
                {
                    template: `<contextual-options actions="options.inlineActions"
                                                   models="options.resourceList.selected"
                                                   model="model"
                                                   class="row-actions pull-right"></contextual-options>`
                }
            ]
        }
    });
    return options;
})

.controller('ribbonFilterController', function ($scope, moment, $rootScope, $location, $window, $timeout, Metric) {

    this.activeFilters = {};

    this.filtersStorage = $window.sessionStorage.getItem('activeFilters');
    this.paramsStorage = $window.sessionStorage.getItem('params');


    this.dateFilter = {
        from: moment().subtract(14, 'day').toDate(),
        to: moment().toDate()
    };

    $scope.$watch('$vm.dateFilter', () => {

        if (this.dateFilter.from) {
            $scope.$ctrl.params.where.created_at = {
                '>=' : moment(this.dateFilter.from).format('YYYY-MM-DD')
            };
        } else {
            $scope.$ctrl.params.where.created_at = {};
        }

        if (this.dateFilter.to) {
            $scope.$ctrl.params.where.updated_at = {
                '<=' : moment(this.dateFilter.to).format('YYYY-MM-DD')
            };
        } else {
            $scope.$ctrl.params.where.updated_at = {};
        }

        $scope.$ctrl.refreshModels();
        this.saveFiltersToStorage($scope.$ctrl.params.where);

    }, true);

    this.$onInit = () => {

        this.setFilter('created_at', {
            '>=' : moment(this.dateFilter.from).format('YYYY-MM-DD')
        }, 'created_at');

        this.setFilter('updated_at', {
            '<=': moment(this.dateFilter.to).format('YYYY-MM-DD')
        }, 'updated_at');

        if (this.filtersStorage) {
            this.activeFilters = angular.fromJson(this.filtersStorage);
        }

        if (this.paramsStorage) {

            $scope.$ctrl.params.where = angular.fromJson(this.paramsStorage);

            $timeout(function () {
                $scope.$ctrl.refreshModels();
            }, 200);
        }

        this.processQueryFilters($scope.$ctrl.params.where);
    };

    // Sets competition, year or language filter
    this.setFilter = (param, value, label) => {

        $scope.$ctrl.params.where[param] = value;
        $scope.$ctrl.refreshModels();

        this.activeFilters[param] = {value, label};

        this.saveFiltersToStorage($scope.$ctrl.params.where);
    };

    // Clears competition, year or language filter
    this.clearFilter = (param) => {
        $scope.$ctrl.params.where[param] = undefined;
        $scope.$ctrl.refreshModels();

        this.removeActiveFilter(param);
    };

    // Removes specified parameter from activeFilters object
    this.removeActiveFilter = (param) => {
        angular.forEach(this.activeFilters, function (value, key) {
            if (key === param) {
                delete this.activeFilters[param];
            }
        }.bind(this));

        this.saveFiltersToStorage($scope.$ctrl.params.where);
    };

    // Serialize filter related variables and store in sessionStorage
    this.saveFiltersToStorage = (params) => {
        $window.sessionStorage.setItem('activeFilters', angular.toJson(this.activeFilters));
        $window.sessionStorage.setItem('params', angular.toJson(params));
    };

    // Called on init to process the query from URL and update the activeFilters object
    this.processQueryFilters = () =>
    {
        let action = $location.search().action;
        let metricable_type = $location.search().metricable_type;
        let from = $location.search().created_at;
        let to = $location.search().updated_at;

        if (from) {
            from = moment(from.split(':').pop()).toDate();
        }

        if (to) {
            to = moment(to.split(':').pop()).toDate();
        }

        this.dateFilter = {
            from: from,
            to: to,
        };


        this.activeFilters = {};

        // Check if status is archived
        if (action) {
            this.activeFilters.action = {
                value: action,
                label: 'Action: ' + action
            };
        }

        if (action) {
            this.activeFilters.action = {
                value: action,
                label: 'Action: ' + action
            };
        }

        if (metricable_type) {
            this.activeFilters.metricable_type = {
                value: metricable_type,
                label: 'Type: ' + metricable_type
            };
        }
    };

    $rootScope.$on('$locationChangeSuccess', function () {
        this.saveFiltersToStorage($scope.$ctrl.params.where);
        this.processQueryFilters($scope.$ctrl.params.where);
    }.bind(this));

    $scope.$watch($scope.$ctrl.lastModified, function ()
    {
        if (Metric.filters) {

            const newFilters = {};

            if (Metric.filters.metricable_type) {
                newFilters.Type = {
                    data: parseType(Metric.filters.metricable_type),
                    param: 'metricable_type',
                };
            }

            if (Metric.filters.action) {
                newFilters.Action = {
                    data: parseAction(Metric.filters.action),
                    param: 'action',
                };
            }

            $scope.filters = newFilters;
        }
    });

    function parseType(types)
    {
        return types.map(function (type)
        {
            return {
                label: type,
                param: 'metricable_type',
                paramValue: type
            };
        });
    }

    function parseAction(actions)
    {
        return actions.map(function (action)
        {
            return {
                label: action,
                param: 'action',
                paramValue: action
            };
        });
    }
});
