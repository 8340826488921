'use strict';

angular.module('editorComponents.timeline')

.controller('timelineCellCtrl', function ($scope, $element)
{
    var modelStyles = function ($event, styles)
    {
        $event.stopPropagation();

        for (var key in styles) {
            this.model.style[key] = styles[key];
        }
    };

    this.$onInit = function ()
    {
        this.componentCtrl = $element.controller('component');
        this.viewportCtrl = $element.controller('timelineViewport');

        $element.addClass('timeline-' + this.model.type);

        if (this.model.type === 'placeholder') {
            return;
        }

        if (this.model.type === 'event') {
            this.viewportCtrl.attachEvent(this.model, $element);
            this.model.style.width = this.model.style.width || '150px';
        }

        if (this.model.children) {
            $element.attr('colspan', this.model.children.length);
        }
        // Set event options
        this.timelineEventOptions = {
            templateUrl: 'scripts/editorComponents/timeline/views/timelineEventOptions.html',
            controller: 'timelineEventOptionsCtrl',
            model: this.model,
            backgroundColors: this.componentCtrl.options.background.colors
        };

        $scope.$on('event:styles', modelStyles.bind(this));
    };
});
