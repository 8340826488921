'use strict';

angular.module('app')

.factory('templateAction', function ($state, $rootScope, kdGate)
{
    return {
        name: 'template',
        tooltip: 'Template',
        buttonClass: 'btn btn-default',
        template: '<span>Template</span>',
        act: function (models)
        {
            $state.go('.template', {
                id: models[0].id
            });
        },
        show: function (model)
        {
            let can = model.type !== 'folder' && kdGate.allows('slide.edit-template') &&
                kdGate.allows('slide.create');

            if (model.type === 'master') {
                return can && kdGate.allows('slide.create-template-from-master');
            }

            return can;
        }
    };
});
