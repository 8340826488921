'use strict';

angular.module('komondor-editor')

.directive('sidebarGridOptions', function ()
{
    return {
        replace: false,
        restrict: 'A',
        require: '^editor',
        templateUrl: 'scripts/editorComponents/grid/grid-options/sidebar-grid-options.html',
        controllerAs: '$comGridOptsCtrl',
        controller: function ($scope, $element, editorState)
        {
            this.comScope = editorState.componentScope;

            this.$onInit = () =>
            {
               //console.log('sidebarGridOptions');
            };
        }
    };
});
