'use strict';

angular.module('datasheet.frontend')

/**
 * Check the filtered data(rows) values in the selectedLabel column (which should be names of country/region)
 */
.factory('RegionNameValidator', function (Fuse)
{
    return function (regions)
    {
        var fuseOptions = {
            threshold: 0.5
        };

        var fuse = new Fuse(regions, fuseOptions);

        return {

            /**
             * region/country name array
             *
             * @type {Array}
             */
            regions: regions,

            /**
             * invalid results after validation
             *
             * @type {Array}                    Array of invalid result objects.
             *               Object.keyword     Keyword that is invalid.
             *               Object.suggestions Suggestions to replace the keyword.
             */
            results: [],

            /**
             * validate input region names w.r.t this.regions
             *
             * @param  {Array} gridData             ui-grid data in array.
             * @param  {Array} rowSelection         Selection data flags for gridData. e.g. [true, false, false, true.....]
             * @param  {Array} labelField           Name of the field which was chosen as Region/Country names.
             *                                      e.g. Country, countries, area, region, etc...
             */
            validate: function (gridData, rowSelection, labelField)
            {
                var self = this;
                var results = [];

                angular.forEach(gridData, function (rowData, idx) {

                    var flag = rowSelection[idx];
                    var keyword = rowData[labelField];

                    if (flag && self.regions.indexOf(keyword) === -1) {

                        var fuseResults = fuse.search(keyword);
                        for (var i = 0; i < fuseResults.length; i++) {
                            fuseResults[i] = self.regions[fuseResults[i]];
                        }

                        results.push({
                            keyword: keyword,
                            suggestions: fuseResults
                        });
                    }
                });

                this.results = results;
            },

            /**
             * generate warning/alert message based on validation results
             *
             * @return {Object}
             */
            getMessage: function ()
            {
                // generate success message
                if (!this.results.length) {
                    return null;
                }

                // generate invalid message
                var str = '';
                angular.forEach(this.results, function (item) {

                    var suggestions = item.suggestions.length > 3 ?
                            item.suggestions.slice(0, 3).join(',') + '...' :
                            item.suggestions.join(',');

                    str += item.keyword + ': ' + suggestions + '; ';
                });

                return {
                    msg: 'The following country names are not acceptable (no abbreviations please). ' +
                        'Please edit them in the datasheet. ' +
                        '(' + str + ')'
                };
            }

        };
    };

});
