'use strict';

angular.module('presentation.admin')

.controller('ShareCtrl', function (
    $rootScope,
    $scope,
    model,
    Share,
    ResourcePicker,
    Notification,
    $uibModal
) {
    $scope.model = model;

    Share.bindAll({expired : false, presentation_id: model.id, type: 'shared'}, $scope, 'activeShares');
    Share.bindAll({expired : true, presentation_id: model.id, type: 'shared'}, $scope, 'expiredShares');

    $scope.createNewLink = function ()
    {
        $uibModal.open({
            templateUrl: 'app/presentation/admin/share/createLink.html',
            controller: 'CreateLinkCtrl',
            controllerAs: '$ctrl',
            bindToController: true,
            resolve: {
                model: $scope.model
            }
        });
    };
});
