'use strict';

angular.module('presentation.theme')

.controller('ThemeEditCtrl', function (
    $scope,
    $state,
    model,
    defaultThemeConfig
) {
    $scope.model = model;

    if (!$scope.model.config || angular.isArray($scope.model.config)) {
        $scope.model.config = defaultThemeConfig();
    }

    $scope.$watch('model.label', function (NewLabel, OldLabel)
    {
        if (NewLabel !== OldLabel) {
            $scope.model.name = NewLabel.trim()
                                         .toLowerCase()
                                         .replace(new RegExp("[!@#$%&*[\\]{}().,<>/`~;:'\"|?^\\\\+=]", "g"), "")
                                         .replace(new RegExp(" ", "g"), "-");
        }
    });

    $scope.saveModel = function ()
    {
        const model = $scope.model;

        model.DSCreate(model).then(function ()
        {
            $scope.$close(model);
        });
    };
});
