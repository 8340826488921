'use strict';

angular
    .module('datasheet.frontend')
    .factory('Fuse', fuse);
/*
* For docs see https://github.com/krisk/Fuse
*/
function fuse ($window)
{
    return $window.Fuse;
}