'use strict';

angular.module('app')

.factory('userProfileAction', function ($uibModal, $http)
{
    return {
        buttonClass: 'btn btn-default',
        template: '<i class="material-icon material-icon-chart"></i> Stats',
        size: 'lg',
        act: function (models)
        {
            $uibModal.open({
                templateUrl: 'app/user-profile/user-profile-modal.html',
                controller: 'UserProfileCtrl',
                resolve: {
                    user: () => {
                        return models[0];
                    },
                    stats: () => {
                        return $http.get('/api/metrics/user/' + models[0].id).then(function (response) {
                            return response.data;
                        });
                    }
                }
            });
        },
        show: function () {
            return true;
        }
    };
});