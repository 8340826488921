'use strict';

angular.module('datasheet.frontend')

.factory('DatasetUtil', function (uiGridConstants)
{
    return {

        /**
         * retrieve the list of keys of objects in an array of objects
         *
         *      - typically each object in array should have exactly the same array of keys
         *
         * @param  {Array} dataset  Array of objects.
         * @return {Array}          Array of key names.
         */
        findKeys: function (dataset)
        {
            var indexedKeys = {};
            var keys = [];

            // Cycle through the current datasheet
            // and process the data
            angular.forEach(dataset, function (row) {

                angular.forEach(row, function (value, property) {
                    if (indexedKeys[property]) {
                        return;
                    }

                    keys.push(property);
                    indexedKeys[property] = true;
                });

            });

            return keys;
        },

        guessColumnDefs: function (dataset, idColDef, colDefTemplate, datasheet)
        {
            var columnDefs = [idColDef],
                columns = datasheet && datasheet.getDataOrder ? datasheet.getDataOrder() : null,
                first,
                column;

            // Use first column if order is not set
            if (!columns) {
                first = dataset[0];
                columns = first ? Object.keys(angular.copy(first)) : [];
            }

            angular.forEach(columns, function (value) {
                if (value === 'id') {
                    // Ensure the default sort is by ID
                    columnDefs[0] = {
                        sort: {
                            direction: uiGridConstants.ASC,
                            priority: 0
                        },
                        field: value,
                        width: 70,
                        enableCellEdit: false
                    };
                    return;
                }

                column = angular.copy(colDefTemplate);
                column.name = value;

                columnDefs.push(column);
            });

            return columnDefs;
        }
    };
});
