'use strict';

angular.module('komondor-editor')

.directive('sidebarColumnFormatting', function ()
{
    return {
        replace: false,
        restrict: 'A',
        require: '^editor',
        templateUrl: 'scripts/editorComponents/column/column-formatting/sidebar-column-formatting.html',
        controllerAs: '$comColFormatCtrl',
        controller: function ($scope, $element, editorState)
        {
            this.comScope = editorState.componentScope;
        }
    };
});
