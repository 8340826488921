'use strict';

angular.module('helpCentre')
.directive('helpCentreFeaturedVideo', function ()
{
    return {
        templateUrl: 'app/help-centre/directives/featuredVideo/featuredVideo.html',
        restrict: 'A',
        scope: {
            videoConfig: "="
        }
    };
});
