'use strict';

angular.module('presentation.frontend')

.directive('slideMarginals', function($filter, $sce) {
    return {
        restrict: 'E',
        controllerAs: '$ctrl',
        bindToController: true,
        templateUrl: 'app/presentation/frontend/directives/slideMarginals.html',
        scope: {
            presentation: '=',
            client: '=',
            chapter: '=',
            chapterSlide: '=',
            slide: '=',
        },
        controller: function(defaultPresentationConfig, presentationState, $scope)
        {
            $scope.$watch('$ctrl.presentation.client', function ()
            {
               this.$onInit();
            }.bind(this));

            this.$onInit = function ()
            {
                this.presentationConfig = defaultPresentationConfig.get();

                this.marginalPosition = this.presentationConfig.marginals;

                if (!this.marginalPosition && this.presentation) {
                    this.marginalPosition = this.presentation.theme.config.marginals.headerFooterStyle.position;
                }

                const totalSlideCount = presentationState.getTotalSlides();
                const slideIndexInPresentation = presentationState.getSlideIndex('Presentation');
                const slideIndex = presentationState.getSlideIndex();
                const date = this.presentation ? this.presentation.updated_at : new Date();
                const dataFormatted = $filter('date')(date, 'd/M/yyyy');

                // Client logo
                this.clientLogo = '';
                this.clientColor = '';

                let client = this.client;

                if (!client && this.presentation) {
                    client = this.presentation.client;
                }

                if (client) {
                    if (client.asset) {
                        this.clientLogo = client.asset.links.download;
                    }

                    if (client.brand_color) {
                        this.clientColor = client.brand_color;
                    }
                }

                this.marginalOptions = {
                    none: '',
                    presentationName: this.presentation ? this.presentation.name : null,
                    slideName: this.slide.name,
                    chapterName: this.chapter ? this.chapter.name : null,
                    lastModified: dataFormatted,
                    pageNo: slideIndex,
                    pageNoTotal: slideIndexInPresentation,
                    presentationPagination: `${slideIndexInPresentation}/${totalSlideCount}`,
                    chapterPagination: 1,

                    // Backward compatibility options
                    totalSlideCount: totalSlideCount,
                    slideIndex: slideIndex,
                    slideIndexInPresentation: slideIndexInPresentation,
                    clientLogo: this.clientLogo ? `<div class="marginal-client-logo">
                        <img class="img-responsive" src="${this.clientLogo}" />
                    </div>` : '',
                    clientLogoColor: this.clientLogo ? `<div style="background-image: linear-gradient(to left, ${this.clientColor} 0%, rgba(0, 0, 0, 0) 100%);" class="marginal-client-logo gradient">
                        <img class="img-responsive" src="${this.clientLogo}" />
                    </div>` : ''
                };

                if (this.presentation && this.chapter) {
                    this.marginalOptions.chapterPagination = `${this.chapterSlide.display_order}/${this.chapter.chapter_slides.length}`;
                }

                if (this.presentation && this.presentation.theme) {
                    // Skip legacy themes
                    if (this.presentation.theme.system || !this.presentation.theme.config.marginals.enabled) {
                        return;
                    }

                    const marginalConfig = this.presentation.theme.config.marginals.headerFooterStyle;

                    this.marginalsConfig = {
                        left: this.marginalOptions[marginalConfig.leftSide],
                        middle: this.marginalOptions[marginalConfig.middle],
                        right: this.marginalOptions[marginalConfig.rightSide],
                    };

                    this.marginalsLogoConfig = this.presentation.theme.config.marginals.logo;
                }
            };

            this.trustAsHtml = function(string)
            {
                return $sce.trustAsHtml(`${string}`);
            };
        }
    };
});
