'use strict';

angular.module('app').directive('analyticsPopupsLogs', function ()
{
    return {
        restrict: 'EA',
        controllerAs: '$ctrl',
        bindToController: true,
        templateUrl: 'app/analytics/popups/popups.html',
        scope: {
            logs: '=',
        },
        controller: function ()
        {
            this.$onInit = () =>
            {
                this.grouped = this.getGroupedData();
                this.data = [];

                Object.keys(this.grouped).forEach((id) => {

                    let list = this.grouped[id];
                    let tabName = null;
                    let sum = 0;

                    list.forEach((log) => {
                        tabName = log.subject_name;
                        sum = sum + log.seconds;
                    });

                    let tmp = {
                        name: tabName,
                        clicks: list.length,
                        avg: list.length ? Math.round(sum / list.length) : 0,
                    };

                    this.data.push(tmp);
                });
            };

            this.getGroupedData = () =>
            {
                let grouped = {};

                this.logs.forEach((item) => {
                    grouped[item.subject_id] = grouped[item.subject_id] || [];
                    grouped[item.subject_id].push(item);
                });

                return grouped;
            };
        }
    };
});
