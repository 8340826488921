'use strict';

angular.module('presentation.theme')

.directive('themeMapPreview', function ()
{
    return {
        restrict: "E",
        replace: true,
        templateUrl: 'app/presentation/theme/theme.builder/maps/map-preview/map-preview.html',
        templateNamespace: 'svg',
        scope: {
            selectionColor: '=?',
            unselectedColor: '=?',
            withBorder: "=?",
            borderColor: '=?',
            borderWidth: '=?'
        }
    };
});
