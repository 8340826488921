'use strict';

angular.module('presentation.theme')

.factory('themeConfigColors', function ()
{
    return {
        palette: {
            transparent: 'transparent',
            light: '#FFFFFF',
            dark: '#000000',
            'color-1': '#5d59d7',
            'color-2': '#D0021B',
            'color-3': '#C9E6FF',
            'color-4': '#7ED321',
        },
        presentationSidebar: {
            homeZone: 'dark',
            chapters: 'dark',
            slides: 'dark',
            selection: 'color-1',
            sidebar: 'color-1',
            sidebarSecond: 'transparent',
            text: 'light',
        },
    };
});
