'use strict';

angular.module('editorComponents.timeline')

.config(function (editorGatesProvider) {
   editorGatesProvider.setGates('timeline');
})

.directive('timeline', function ()
{
    return {
        restrict: 'A',
        replace: true,
        require: ['^component', '^editor'],
        template: '<div class="timeline-wrap">' +
                      '<timeline-viewport data="model.data" class="timeline-viewport"></timeline-viewport>' +
                  '</div>',
        controller: 'timelineCtrl',
        bindToController: true,
        controllerAs: '$ctrl'
    };
});
