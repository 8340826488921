'use strict';

angular.module('app')

.decorator('actionsViewOptions', ['$delegate', 'kdGate', 'deselectAction', 'multiDeleteAction', 'moment', 'editSingleItemAction', 'deleteSingleItemAction', function (
                                   $delegate,   kdGate,   deselectAction,   multiDeleteAction,   moment,   editSingleItemAction,   deleteSingleItemAction
) {

    $delegate.moment = moment;

    $delegate.actions = [
        deselectAction,
        multiDeleteAction,
    ];

    $delegate.inlineActions = [
        editSingleItemAction,
        deleteSingleItemAction
    ];

    $delegate.list.columns.splice(4, 1, {
        label: 'Last modified',
        name: 'updated_at',
        template: `<span class="no-wrap" uib-tooltip="{{ model.updated_at | date }}">
                        {{ options.moment(model.updated_at).fromNow() }}
                    </span>`,
        sortable: true
    },
    {
        template: `<contextual-options actions="options.inlineActions"
                                   models="options.resourceList.selected"
                                   model="model"
                                   class="row-actions pull-right"></contextual-options>`
    });

    return $delegate;
}]);
