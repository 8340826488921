'use strict';

var bundle = angular.module('bundle', [ // jshint ignore:line
    'app',
    'ngMockE2E',
    'LocalForageModule'
]);

bundle.run(function ($document, $window, $state)
{
    // Check if IE9 or lower redirect
    if ($document[0].all && !$window.atob) {
        $state.go('guest.not-supported');
    }
});

bundle.config(function ($httpProvider, base_uri)
{
    $httpProvider.interceptors.push(function ($q, $injector) {
        return {
            request: function (config) {

                if (config.method === "POST") {

                    var kdAuth = $injector.get('kdAuth');

                    if (kdAuth.user) {
                        config.headers['X-User'] = kdAuth.user.id;
                        config.headers['X-Auth-Token'] = kdAuth.user.token;
                    }

                    config.url = base_uri + config.url;
                }

                return config || $q.when(config);
            }
        };
    });
});

// Stop them from accessing admin states on bundle.
bundle.run(function ($rootScope, $state)
{
    $rootScope.$on('$stateChangeStart',
        function (event, toState)
        {
            var allowedStates = [
                'presentation',
                'admin.editor.presentation.chapter.slide',
                'guest.forbidden',
                'admin.error.offline-editing-not-supported',
            ];

            if (allowedStates.indexOf(toState.name) === -1) {
                if (toState.name !== 'guest.forbidden') {
                    event.preventDefault();
                    return $state.go('guest.forbidden');
                }
            }
        });
});

bundle.run(function ($httpBackend)
{
    $httpBackend.whenGET(/views\/.*/).passThrough();
    $httpBackend.whenPOST(/.*\/api\/presentations\/sync/).passThrough();
    $httpBackend.whenPOST(/.*\/auth\/token-login/).passThrough();
    $httpBackend.whenPOST(/.*\/auth\/token-validate/).passThrough();
});

/**
 * /api/???/???
 */
bundle.run(function ($httpBackend, localData)
{
    var regexShow = new RegExp('^/api/([A-Za-z]+)/([A-Za-z0-9]+)(\\?|$)');

    $httpBackend.whenGET(regexShow).respond(function (method, url)
    {
        var resource = url.match(regexShow)[1];
        var id = url.match(regexShow)[2];

        localData.setResource(resource);

        return localData.show(id) ?
            [200, localData.show(id)] :
            [404, 'Not Found'];
    });
});

/**
 * /file/*.json ???
 */
bundle.run(function ($httpBackend, localData)
{
    var regexShow = new RegExp('^files/([A-Za-z0-9]+)');

    $httpBackend.whenGET(regexShow).respond(function (method, url)
    {
        var contents = localData.getFileContents(url);

        return [200, contents];
    });
});

/**
 * /api/???
 */
bundle.run(function ($httpBackend, localData)
{
    var regexIndex = new RegExp('^/api/([A-Za-z]+)(\\?|$)');

    $httpBackend.whenGET(regexIndex).respond(function (method, url)
    {
        var resource = url.match(regexIndex)[1];

        return [200, localData.setResource(resource).index()];
    });
});

/**
 * /api/datasheets/1/data
 */
bundle.run(function ($httpBackend, localData)
{
    var regexIndex = new RegExp('^/api/datasheets/([A-Za-z0-9]+)/data(\\?|$)');

    $httpBackend.whenGET(regexIndex).respond(function (method, url)
    {
        var id = url.match(regexIndex)[1];

        localData.setResource('datasheets');

        var item = localData.show(id);
        if (!item) {
            return [404, 'Not Found'];
        }

        if (!item.data) {
            return [200, []];
        }

        return [200, item.data];
    });
});

bundle.service('localData', function (
    $window,
    $rootScope,
    $localForage,
    $state,
    appUuid
) {
    var resource = '';
    var storageKey = appUuid() + '.offlinePressData';

    var storage = {
        save: function (resource, object)
        {
            var toSave = [];
            toSave.push(object);

            return $localForage.setItem(storageKey, angular.toJson(toSave));
        },
        get: function (resource)
        {
            return $window.localData[resource] || [];
        },
        load: function ()
        {
            return $localForage.getItem(storageKey).then(
            function (item)
            {
                if (item) {
                    $window.localData.presentations = angular.fromJson(item);
                }

                return $window.localData;
            },
            function ()
            {
                if ($rootScope.offline_edit) {
                    $state.go('admin.error.offline-editing-not-supported');
                }
            });
        }
    };

    this.setResource = function (resourceName)
    {
        resource = resourceName;

        return this;
    };

    this.getResource = function ()
    {
        return resource;
    };

    this.show = function (id)
    {
        var result = null;

        angular.forEach(storage.get(resource), function (item) {
            // this needs to be loose as it could potentially be either integer or string
            if (id == item.id) { // jshint ignore:line
                result = item;
            }
        });

        return result;
    };

    this.index = function ()
    {
        var data = storage.get(resource);

        var array = Object.keys(data).map(function (k)
        {
            return data[k];
        });

        return array;
    };

    this.getFileContents = function (file)
    {
        var data = storage.get('filesContent');
        return data[file] || {};
    };

    this.storage = storage;
});
