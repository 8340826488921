'use strict';

angular.module('app').directive('targetedMessageEditor', function ($injector, $rootScope, $document, $sce, themeInjector, debounce)
{
    return {
        scope: {
            model: '=',
        },
        restrict: 'E',
        templateUrl: 'scripts/directives/clientMessageEditor/targeted.message.editor.html',
        controller: function ($scope)
        {
            $scope.textColours = {};
            $scope.focusTargetedMessage = true;
            
            $scope.model.textAlignment = $scope.model.textAlignment || 'center';

            $scope.htmlValue = $sce.getTrustedHtml($scope.model.html);

            $scope.alignOptions = {
                left : 'Left',
                center : 'Center',
                right : 'Right',
            };

            if ($rootScope.theme) {
                let theme = themeInjector.get($rootScope.theme);
                $scope.textColours = theme.palette;
            }
        },
        link: function ($scope, element)
        {
            let input = angular.element(element[0].getElementsByClassName("client-message-editor-input"));

            let debounceFunc = debounce(function ()
            {
                $scope.$evalAsync(function ()
                {
                    let text = input.text();
                    let raw = input.html();
                    let html = '';

                    if (text) {
                        html = $sce.getTrustedHtml(raw);
                    }

                    $scope.model.html = html;
                });

            }, 250);

            $scope.colorText = function (colour)
            {
                $document[0].execCommand('foreColor', false, colour);
                debounceFunc();
            };

            $scope.setProperty = function (prop, value)
            {
                $scope.model[prop] = value;
                debounceFunc();
            };

            $scope.execCommand = function (command, showDefaultUI, value)
            {
                $document[0].execCommand(command, showDefaultUI, value);
                debounceFunc();
            };

            input.on('keyup', debounceFunc);
        }
    };
});
