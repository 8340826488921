'use strict';

angular.module('app')

.config(function (editorGatesProvider) {
   editorGatesProvider.setGates('high-map');
})

/*
* This directive will display a map, using angular leaflet
* directive.
* https://github.com/tombatossals/angular-leaflet-directive
*/
.directive('highMap', function ()
{
    var componentName = 'highMap';
    var fileLocation = 'scripts/editorComponents/' + componentName + '/';

    return {
        restrict: 'EA',
        require: ['^component', '^editor'],
        replace: false,
        templateUrl: fileLocation + componentName + '.html',
        link: function link ($scope, element, attributes, controllers) {

            // Link controllers
            var componentCtrl = controllers[0];

            $scope.selectMe = function ()
            {
                // do nothing if already selected
                if (element.parent().attr('class').indexOf('selected') > -1) {
                    return;
                }

                componentCtrl.select();
            };

            $scope.addFloatingBox = function ()
            {
                $scope.model.nested = $scope.model.nested || [];
                $scope.model.nested.push({
                    type: 'floating-box'
                });

            };
        },
        controller: 'highMapCtrl'
    };
});
