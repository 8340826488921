'use strict';

angular.module('presentation.theme')

.directive('themeColorSingle', function ()
{
    return {
        restrict: "E",
        templateUrl: 'app/presentation/theme/components/theme.colour-single/theme.colour-single.html',
        scope: {
            model: '=',
            defaultColors: '='
        },
        link: function ($scope)
        {
            $scope.radioName = 'radio' + $scope.$id;

            $scope.toggleSelection = function(color)
            {
                $scope.model = color.name;
            };
        }
    };
});
