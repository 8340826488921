'use strict';

angular.module('app')

.factory('football-league', function (ThemeConfig)
{
    var theme = new ThemeConfig();

    /**
     * Modify the editor options to suit this theme
     *
     */
    theme.editorOptions = function (options)
    {
        delete options.nestableComponents.column.nested.tabs;

        options.componentClasses['comp-panel'] = {
            default: 'gradient-radial',
            classes: {
                'gradient-radial': 'Radial gradient',
                'presentation-cover': 'Presentation cover',
                'chapter-cover': 'Chapter cover',
                shaded: 'Shaded'
            }
        };

        options.componentOptions['comp-panel'].background = {
            default: ' ',
            colors: {
                ' ': 'None',
                transparent: 'White',
                '#000000': 'Black',
                '#003164': 'Blue',
                '#4CBEE4': 'Light blue'
            }
        };

        options.componentOptions.textblock.tags = {
            h1: 'Hero Heading',
            h2: 'Heading',
            h3: 'Sub-heading',
            //h4: 'Sub-heading (Medium)',
            //h5: 'Sub-heading (Small)',
            h6: 'Stand-first',
            p: 'Paragraph',
            blockquote : 'Quote',
            cite: 'Cite'
        };

        options.componentOptions.textblock.allowedTags = {
            b: 'no attributes',
            u: 'no attributes',
            sup: 'no attributes',
            sub: 'no attributes',
            font: true,
            a: true,
        };

        options.componentOptions['shape-line'].defaultColor = '#4CBEE4';

        options.textColours = {
            Black: '#555557',
            White: '#FFFFEF',
            Green: '#83C62D',
            Red: '#EE2A19',
            'Light Green': '#16B7B1',
            Orange: '#FA6C22',
            'Light Orange': '#FFA92E',
            Blue: '#003164',
            'Light blue': '#4CBEE4',
            Purple: '#9048A1'
        };

        options.backgroundColors = {
            '#ffffff': 'White',
            '#C7C4C2': 'Black',
            '#003164': 'Blue',
            '#4CBEE4': 'Light blue',
        };
    };

    /**
     * Modify the map config to suit this theme
     *
     */
    theme.mapConfig = function (options)
    {
        options.themes.grey = {
            name: 'Grey',
            color: '#8A8A8A',
            nullColor: 'rgba(138,138,138,0.3)',
            pinStyle: {
                backgroundColor: '#ffffff',
                border: '2px solid #8A8A8A',
            },
            labelStyle: {
                backgroundColor: 'rgba(255,255,255,0.7)',
                color: '#000000',
                border: '2px solid #000000',
                fontFamily: 'helvetica neue',
            },
            borderWidth: 0,
            borderColor: '#000000',
        };
    };

    return theme;
});
