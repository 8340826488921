'use strict';

angular.module('komondor.multitenancy')

.config(function (resourceStateProvider, modalStateProvider)
{
    resourceStateProvider.state('admin.domains', {
        url: 'domains',
        model: 'Domain',
        viewOptions: 'domainViewOptions',
        title: 'Domains',
        root: {
            resolve: {
                companies: ['Company', function (Company)
                {
                    return Company.findAll();
                }],
                actions: ['Action', function (Action)
                {
                    return Action.findAll();
                }]
            }
        },
        edit: {
            templateUrl: 'app/admin/multitenancy/domain/domain.edit.html',
            controller: 'DomainEditCtrl'
        }
    });

    modalStateProvider
    .state('admin.domains.settings', {
        url: '/settings/{id:[0-9]+}',
        controller: 'DomainEditCtrl',
        templateUrl: 'app/admin/multitenancy/domain/settings.html',
        resolve: {
            model: ['Domain', 'modalState', function (Domain, modalState) {
                return Domain.find(modalState.stateParams.id);
            }]
        }
    });
});
