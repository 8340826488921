'use strict';

angular.module('app')

.run(function (Contact) {}) // jshint ignore:line

.factory('Contact', function (DS, $state)
{
    return DS.defineResource({
        basePath: '/api/',
        name: 'Contact',
        endpoint: 'contacts',
        upsert: true,
        defaultValues: {
            action_id: 0,
        },
        methods: {
            goInto: function ()
            {
                if (this.type === 'folder') {
                    $state.go('admin.contacts', {folder_id: this.id, offset: null, name: null});
                } else {
                    $state.go('admin.contacts.edit', {id: this.id});
                }
            }
        }
    });
});
