'use strict';

angular.module('app')

.factory('isNumeric', function ()
{
    return function isNumeric(val) {
        return !(val instanceof Array) && val - parseFloat(val) + 1 >= 0;
    };
});
