'use strict';

angular.module('app')

.controller('PresentationCtrl', function (
    $scope,
    $rootScope,
    presentation,
    Presentation,
    slideTransitionConfig,
    $document,
    $timeout,
    PresentationKeyBindings
) {

    // Remove deprecated config options which have been moved to themes
    if (presentation.config &&
        (presentation.config.marginals ||
        presentation.config.client_logo ||
        presentation.config.marginals_date ||
        presentation.config.marginals_hide_chapter_no))
    {
        delete presentation.config.marginals;
        delete presentation.config.client_logo;
        delete presentation.config.marginals_date;
        delete presentation.config.marginals_hide_chapter_no;

        // Save changes
        Presentation.save(presentation.id);
    }

    var transition_id = presentation.config && presentation.config.transition_id || 0;
    var keyBindings = new PresentationKeyBindings(presentation);


    $scope.slideAnimation = slideTransitionConfig.options[transition_id].class;

    // Presentation shortcuts
    $document.bind('keydown', keyBindings.handler);

    $timeout(function ()
    {
        presentation.snapshotChecksum();
    });

    $scope.$on('$destroy', function () {
        $document.unbind('keydown',  keyBindings.handler);
    });
});
