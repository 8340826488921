'use strict';

angular
    .module('presentation.frontend')
    .animation('.transition-fade-in', transitionFadeIn);

function transitionFadeIn (
    presentationTimeline,
    slideTransitionConfig,
    tweenMax,
    $window
) {

    return {
        enter: enter,
        leave: leave,
    };

    function enter (element, done)
    {            
        const fromOption = {
            autoAlpha: 0,
            zIndex: 1,
        };

        const toOptions = {
            autoAlpha: 1,
            zIndex: 1,
            delay: slideTransitionConfig.delay,
            immediateRender: true,
            ease: $window.Sine.easeInOut,
            onComplete: function ()
            {
                done();
            }
        };

        presentationTimeline.add(
            tweenMax.fromTo(
                element,
                slideTransitionConfig.duration,
                fromOption,
                toOptions
            )
        );
    }

    function leave (element, done)
    {
        presentationTimeline.add(
            tweenMax.fromTo(element,
                slideTransitionConfig.duration,
                {
                    opacity: 1,
                },
                {
                    opacity: 1,
                    zIndex: 0,
                    onComplete: function ()
                    {
                        done();
                    }
                }
            ),
            `-= ${slideTransitionConfig.duration}`
        );

        presentationTimeline.play();
    }
}
