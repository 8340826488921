'use strict';

angular.module('komondor-editor')

.directive('sidebarGridPosition', function ()
{
    return {
        replace: false,
        restrict: 'A',
        require: '^editor',
        templateUrl: 'scripts/editorComponents/grid/grid-position/sidebar-grid-position.html',
        controllerAs: '$comGridPositionCtrl',
        controller: function ($scope, $element, editorState)
        {
            this.comScope = editorState.componentScope;

            this.$onInit = () =>
            {
               //console.log('sidebarGridFormatting');
            };

            this.moveGridCellUp = function ()
            {
                this.comScope.$emit('grid:move', this.comScope.model, -1);
            };

            this.moveGridCellDown = function ()
            {
                this.comScope.$emit('grid:move', this.comScope.model, +1);
            };
        }
    };
});
