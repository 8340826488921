'use strict';

angular.module('app')

.controller('UserProfileCtrl', function ($scope, user, stats, moment, DS)
{
    $scope.last_login = stats.login ? moment(stats.login).toDate() : '-';

    $scope.login_count = stats.login_count || 0;
    $scope.presentations = stats.presentations || [];
    $scope.user = DS.inject('User', user);

    $scope.isEmpty = $scope.presentations.length === 0;

    $scope.saveForm = () => {
        $scope.user.DSSave();
    };

});
