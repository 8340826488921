'use strict';

angular.module('app')

.factory('ajaxHeaders', function ()
{
    return {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    };
});