'use strict';

angular.module('presentation.theme')

.run(function (Theme) {}) // jshint ignore:line

.factory('Theme', function (DS, $state)
{
    return DS.defineResource({
        name: 'Theme',
        endpoint: 'themes',
        upsert: true,
        computed: {
            type: function () {
                return 'Theme';
            }
        },
        methods: {
            goInto: function ()
            {
                $state.go('admin.theme-build.introduction', {
                    id: this.id
                });
            },
            getFont: function (name)
            {
                return this.font_families.reduce((font, fontFamily) => {
                    if (fontFamily.name === name) {
                        font = fontFamily;
                    }
                    return font;
                }, null);
            },
            getFontWeights: function (name)
            {
                const font = this.getFont(name);
                return font ? font.weights : {'Inherit': {}, 'Bold': {}};
            },
            getDefaultFont: function ()
            {
                return this.font_families.find(function (fontFamily) {
                    return fontFamily.system === 1;
                });
            },
            setDefaultFonts: function ()
            {
                const defaultFont = this.config.typography.defaultFont;

                if (!defaultFont.typeface) {
                    defaultFont.typeface = this.getDefaultFont().name;
                }

                if (!defaultFont.weight) {
                    defaultFont.weight = 'Inherit';
                }
            },
            isBrandColorEnabled: function()
            {
                if (!this.config.brandColor) {
                    return false;
                }

                if (!this.config.brandColor.enabled) {
                    return false;
                }

                return true;
            },
            getDefaultClientBrandColor: function()
            {
                if (!this.isBrandColorEnabled()) {
                    return null;
                }

                let palette = this.config.colors.palette || {};

                return palette[this.config.brandColor.defaultColor] || null;
            },
        },
        relations: {
            hasMany: {
                Domain: {
                    localField: 'domains',
                    foreignKey: 'theme_id'
                },
                Font: {
                    localField: 'fonts',
                    foreignKey: 'theme_id'
                }
            }
        }
    });
});
