'use strict';

angular.module('app')

.controller('urlModalCtrl', function (
    $scope,
    $rootScope,
    $uibModalInstance,
    notify,
    url
) {

    $scope.data = {
        url: url
    };

    $scope.ok = function ()
    {
        $uibModalInstance.close($scope.data.url);
    };

    $scope.cancel = function ()
    {
        $uibModalInstance.dismiss();
    };
});
