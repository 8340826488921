'use strict';

angular.module('presentation.admin')

.config(function ($stateProvider, resourceStateProvider, modalStateProvider)
{
    resourceStateProvider
    .state('admin.presentations', {
        url: 'presentations',
        model: 'Presentation',
        viewOptions: 'presentationViewOptions',
        query: ['folder_id'],
        params: {
            folder_id: '0',
        },
        title: 'Presentations',
        root: {
            resolve: {
                actions: ['Action', function (Action) {
                    return Action.findAll(null, {bypassCache: false});
                }],
                themes: ['Theme', function (Theme) {
                    return Theme.findAll(null, {bypassCache: true});
                }],

                // IMPORTANT: REMOVE UNCESSARY CLIENTS CALL, NOW GETTING CLIENT INFO FROM PRESENTATION
                
                // clients: ['Client', function (Client) {
                //     return Client.findAll(null, {bypassCache: true});
                // }]
            }
        },
        edit: {
            templateUrl: 'views/presentation.edit.html',
            controller: 'PresentationEditCtrl',
            defaults: {
                type: 'presentation'
            },
            resolve: {
                themes: ['Theme', function (Theme) {
                    return Theme.findAll(null, {bypassCache: true});
                }],
                client: ['Presentation', 'modalState', function (Presentation, modalState)
                {
                    if (parseInt(modalState.stateParams.id) > 0) {
                        return Presentation.find(modalState.stateParams.id).then(function (model)
                        {
                            if (!model.client_id) {
                                return model;
                            }

                            return Presentation.loadRelations(model, ['client']);
                        });
                    } else {
                        return null;
                    }
                }]
            }
        },
        breadcrumbs: true,
        move: true,
    });

    modalStateProvider
    .state('admin.presentations.clone', {
        url: '/clone/{id:[0-9]+}',
        controller: 'PresentationEditCtrl',
        templateUrl: 'views/presentation.clone.html',
        reloadOnExit: 'admin.presentations',
        resolve: {
            themes: ['Theme', function (Theme) {
                return Theme.findAll(null, {bypassCache: true});
            }],
            model: ['Presentation', 'modalState', (Presentation, modalState) => {
                return Presentation.find(modalState.stateParams.id).then(model => {
                    if (!model.client_id) {
                        return model;
                    }

                    return Presentation.loadRelations(model, ['client']);
                });
            }]
        }
    });

    modalStateProvider
    .state('admin.presentations.share', {
        url: '/share/{id:[0-9]+}',
        controller: 'ShareCtrl',
        templateUrl: 'app/presentation/admin/share/share.html',
        reloadOnExit: 'admin.presentations',
        windowClass: 'share-action-form',
        size: 'lg',
        resolve: {
            model: ['Presentation', 'modalState', 'Share', function (Presentation, modalState, Share)
            {
                return Presentation.find(modalState.stateParams.id).then(function (model)
                {
                    if (!model.client_id) {
                        return model;
                    }

                    return Presentation.loadRelations(model, ['client']);

                }).then(function (model)
                {
                    return Share.findAll({
                        where: {presentation_id: model.id, type: 'shared'},
                        with: 'stats,contact',
                    }).then(function ()
                    {
                        return model;
                    });
                });
            }]
        }
    });

    modalStateProvider
    .state('admin.presentations.present', {
        url: '/present/{id:[0-9]+}',
        controller: 'PresentCtrl',
        templateUrl: 'app/presentation/admin/present/present.html',
        reloadOnExit: 'admin.presentations',
        windowClass: 'present-action-form',
        size: 'lg',
        resolve: {
            model: ['Presentation', 'modalState', 'Share', 'kdGate', '$uibModalStack', function (Presentation, modalState, Share, kdGate, $uibModalStack)
            {
                if (!kdGate.allows('presentation.present-start')) {
                    angular.element(function () {
                        $uibModalStack.dismissAll();
                    });
                    return {};
                }

                return Presentation.find(modalState.stateParams.id).then(function (model)
                {
                    if (!model.client_id) {
                        return model;
                    }

                    return Presentation.loadRelations(model, ['client']);

                }).then(function (model)
                {
                    return Share.findAll({
                        where: {presentation_id: model.id, type: 'present'},
                        with: 'stats,contact',
                    }).then(function ()
                    {
                        return model;
                    });
                });
            }]
        }
    });

});
