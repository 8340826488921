'use strict';

angular.module('app')

.factory('homeState', function (kdGate)
{
    return function homeState()
    {
        if (kdGate.allows('presentation')) {
            return 'admin.presentations';
        }

        if (kdGate.allows('media')) {
            return 'admin.media';
        }

        if (kdGate.allows('slide')) {
            return 'admin.slides';
        }

        if (kdGate.allows('data')) {
            return 'admin.datasheets';
        }

        return null;
    };
});
