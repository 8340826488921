'use strict';

angular.module('slide-popup')

.service('slideModal', function (
    $uibModal,
    $document,
    Slide,
    slideModalConfig,
    presentationState,
    defaultModalThemeConfig
) {
    this.open = function (slideId, newSlideSize, newSlideColour, parentNode, animation)
    {

        const modalConfig = defaultModalThemeConfig.get();
        const slideSize = newSlideSize || 'md';
        const slideColour = newSlideColour || null;

        let sizeClass = '';

        if (modalConfig.sizes && modalConfig.sizes[slideSize]) {
            sizeClass = modalConfig.sizes[slideSize].class || '';
        }

        parentNode = parentNode || 'section.slide';
        animation = animation === undefined ? true : animation;

        return $uibModal.open({
            templateUrl: 'scripts/editorComponents/link/slideModal/slideModal.html',
            windowClass: 'slide-popup ' + sizeClass + ' ' + slideColour,
            appendTo: angular.element($document[0].querySelector(parentNode)),
            animation: animation,
            backdrop: !!animation,
            resolve: {
                slideSize: function () {
                    return slideSize;
                },
                slideColour: function () {
                    return slideColour;
                },
                slide: function () {
                    if (slideId) {
                        return Slide.find(slideId, {bypassCache: true});
                    } else {
                        let defaultPopup = slideModalConfig.getModelDefaultItem();

                        if (presentationState.slide) {
                            defaultPopup.slide_id = presentationState.slide.id;
                        }

                        return Slide.createInstance(defaultPopup);
                    }
                },
                isNew: function () {
                    return !slideId;
                }
            },
            controller: 'slideModalCtrl'
        });
    };
});
