'use strict';

angular.module('link-component')

.service('hyperlinkEditModal', function ($uibModal, $document)
{
    this.open = function (href, target, inner, options)
    {
        return $uibModal.open({
            size: 'md',
            templateUrl: 'scripts/editorComponents/link/hyperlinkEditModal.html',
            replace: false,
            controller: 'hyperlinkEditModalCtrl',
            windowClass: 'link-modal',
            appendTo: angular.element($document[0].querySelector('section.slide')),
            resolve: {
                href: function () {
                    return href;
                },
                target: function () {
                    return target;
                },
                inner: function () {
                    return inner;
                },
                options: function () {
                    return options;
                }
            }
        });
    };
});
