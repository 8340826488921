'use strict';

angular.module('komondor.multitenancy')

.factory('loginAsAdminAction', function ($window)
{
    return {
        name: 'login',
        tooltip: 'Login as Admin',
        buttonClass: 'btn btn-default',
        template: '<i class="material-icon material-icon-input"></i> Login',
        act: function (models)
        {
            $window.open('/api/domains/' + models[0].id + '/login', '_blank');
        },
        show: function ()
        {
            return true;
        }
    };
});