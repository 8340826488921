'use strict';

angular.module('app').directive('datePicker', function () {

        return {
            restrict: 'A',
            controllerAs: '$ctrl',
            scope: {
                placeholder: '=',
                datePicker: '='
            },
            controller: function ($element) {

                this.ngModelCtrl = $element.controller('ngModel');

                this.foramt = 'shortDate';

                this.popup = {
                    opened: false
                };

                this.dateOptions = {
                    formatYear: 'yy',
                    startingDay: 1
                };

                this.open = () => {
                    this.popup.opened = true;
                };
            },
            template:
                '<div class="input-group">' +
                '<input type="text"' +
                    'placeholder="{{placeholder}}"' +
                    'class="form-control"' +
                    'uib-datepicker-popup="{{$ctrl.format}}"' +
                    'datepicker-append-to-body="true"' +
                    'popup-placement="auto bottom"' +
                    'ng-model="datePicker"' +
                    'is-open="$ctrl.popup.opened"' +
                    'datepicker-options="$ctrl.dateOptions"' +
                    'close-text="Close" />' +
                '<span class="input-group-btn">' +
                '<button type="button" class="btn btn-default" ng-click="$ctrl.open()">' +
                '<i class="glyphicon glyphicon-calendar"></i>' +
                '</button>' +
                '</span>' +
                '</div>'
        };
    }
);
