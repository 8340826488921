'use strict';

angular.module('app')

.config(function (editorGatesProvider) {
   editorGatesProvider.setGates('image-marker');
})

.directive('imageMarker', imageMarker);

function imageMarker (Droppable, $timeout, editorControls, nudgeable)
{
    return {
        restrict: 'A',
        require: ['^component, ^componentList'],
        template: `<div class="marker"></div>`,
        controllerAs: '$ctrl',
        controller: function ($scope, $element)
        {
            let moveHandler;

            const componentCtrl = $element.controller('component');
            const componentListCtrl = $element.controller('componentList');
            const marker = componentCtrl.getElement();
            const container = componentListCtrl.getElement();

            $scope.getTrackables = getTrackables;

            const handlerOptions = {
                container: container,
                percentage: true,
                stop: function (event, left, top)
                {
                    // Set css to model
                    $scope.model.componentStyle.left = left;
                    $scope.model.componentStyle.top = top;

                    $timeout(function () {
                        // Re-focus on object and show options
                        componentCtrl.select();
                        editorControls.show();
                    });
                }
            };

            this.$onInit = function ()
            {
                // Set defaults if styles not set
                $scope.model.componentStyle = $scope.model.componentStyle || {};
                moveHandler = new Droppable(marker, handlerOptions);

                if (angular.equals($scope.model.componentStyle, {})) {
                    moveHandler.setToCenter();
                }

                componentCtrl.setClickCallback(function (value)
                {
                    if (value) {
                        nudgeable.select(marker, container, update);
                    } else {
                        nudgeable.deselect();
                    }
                });

                // Override default method
                $scope.pickup = function ($event)
                {
                    moveHandler.startMove($event);
                    editorControls.hide();
                };
            };

            function update (option)
            {
                $scope.model.componentStyle.left = option.left;
                $scope.model.componentStyle.top = option.top;
            }

            function getTrackables ()
            {
                return {
                    'componentStyle.left': {
                        deepWatch: false,
                    },
                    'componentStyle.top': {
                        deepWatch: false,
                    },
                };
            }
        }
    };
}

