'use strict';

angular.module('email')

.controller('EmailAccountEditCtrl', function ($scope, model)
{
    $scope.model = model;
    $scope.auth_methods = [];

    $scope.saveModel = function ()
    {
        $scope.model.DSCreate().then(function ()
        {
            $scope.$close(model);
        });
    };

    $scope.$on('$destroy', function ()
    {
        delete $scope.model.password;
    });
});
