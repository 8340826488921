'use strict';

angular.module('app').controller('AnalyticsController', function (
    $rootScope,
    $scope,
    model,
    $state,
    $http,
    Notification,
    $httpParamSerializer,
    $uibModal,
    analyticsData,
    $location
) {

    this.list = {
        selected: {},
        all: false,
        exclude: false,
    };

    this.pagination = {total: 0};
    this.page = 1;

    this.modelNameField = 'agent';
    this.searchVal = '';

    this.showLimit = false;

    this.params = {
        limit: 100,
        where: {[this.modelNameField]: {likei: ''}}
    };

    this.sortReversed = false;

    this.selectedSort = {sort: 'start', label: 'Date'};
    this.initalLoaded = false;

    this.sorts = [
        {sort: 'start', label: 'Date'},
        {sort: 'duration', label: 'Duration'}
    ];

    this.unselected = {};

    this.paging = {
        prev:  () => {
            if (this.pagination.prev_page_url)  {
                let pageUrl = this.pagination.prev_page_url;
                this.page = pageUrl.match(/page=([0-9]+)/)[1];

                $location.search('page', this.page);
            }
        },
        next: () => {
            if (this.pagination.next_page_url)  {
                let pageUrl = this.pagination.next_page_url;
                this.page = pageUrl.match(/page=([0-9]+)/)[1];

                $location.search('page', this.page);
            }
        },

        getLastPage: () => {
            return this.pagination.total;
        },
    };

    this.options = {
        limitOptions: [100]
    };

    this.order = {
        selected: 'order',
        orderOptions: {
            order: 'Chapter and Slide',
            avg_duration_s: 'Duration',
            views: 'Views',
            events: 'Events',

        },
        orderDirections: {
            order: '',
            avg_duration_s: '-',
            views: '-',
            events: '-',
        },
    };

    this.$onInit = () =>
    {
        this.data = analyticsData;
        this.model = model;

        this.page = $location.search().page ? $location.search().page : 1;
        let sort = $location.search().sortBy;

        if (sort) {
            let currentSort = this.sorts.find(item => item.label === sort);

            if (currentSort) {
                this.selectedSort = currentSort;
            }
        }



        this.toggleAllSelected();
    };

    $scope.$watchGroup([
        '$ctrl.list.exclude',
        '$ctrl.page',
        '$ctrl.selectedSort',
    ], (oldVal, newVal) => {
        if (oldVal !== newVal) {

            this.list.selected = [];
            // this.list.all = false;
            this.reloadData();
        }
    });

    this.allSelected = () => {

        let one = Object.values(this.list.selected).filter(item => item).length;
        let two = this.data.sessions.length;

        return one === two;
    };

    this.searchChanged = (search) => {
        delete this.params.where[this.modelNameField];

        if (search.value) {
            this.params.where[this.modelNameField] = {};
            this.params.where[this.modelNameField].likei = search.value;
            this.searchVal = {[this.modelNameField]: this.params.where[this.modelNameField].likei};
            $location.search(this.modelNameField, this.params.where[this.modelNameField].likei);

        } else {
            this.searchVal = '';
            $location.search(this.modelNameField, null);
        }
    };

    this.setLimit = (option) => {
        this.params.limit = option;
        $location.search('limit', option);
    };

    this.toggleSortDirection = () => {
        this.sortReversed = !this.sortReversed;

        $location.search('sortBy', this.sortReversed ? 'desc' : 'asc');
    };

    this.setSort = (sort) => {
        this.selectedSort = this.sorts.find(item => item.sort === sort);
        $location.search('sortBy', this.selectedSort.label);
    };

    this.copied = () =>
    {
        Notification.success('The link has been copied to your clipboard!');
    };

    this.info = () =>
    {
        Notification.success('INFO ???');
    };

    this.selectedChanged = (id) =>
    {
        if (!this.unselected[id] && typeof id !== 'undefined') {
            this.unselected[id] = true;
        } else {
            delete this.unselected[id];
        }

        this.list.all = this.allSelected();

        this.reloadData();
    };

    this.toggleAllSelected = () => {

        if (this.allSelected()) {
            this.list.selected = {};
            this.data.sessions.forEach((value) => {
                this.unselected[value.id] = true;
            });
        } else {
            this.data.sessions.forEach((value) => {
                this.list.selected[value.id] = true;
                this.unselected = {};
            });
        }

        this.selectedChanged();
    };

    this.goToBreadcrumb = (id, isFolder) => {
        if (isFolder) {
            $state.go('admin.presentations', {folder_id: id, offset: null, name: null});
        } else {

            $state.go('admin.presentations.share', {
                id: id
            });
        }
    };

    this.getSessionIdsParam = () =>
    {
        let ids = [];

        Object.keys(this.list.selected).forEach((key) => {
            if (this.list.selected[key]) {
                ids.push(key);
            }
        });

        return $httpParamSerializer({
            'session_ids[]': ids,
            'exclude': this.list.exclude ? 1 : 0,
            'unselected': Object.keys(this.unselected)
        });
    };

    this.reloadData = () =>
    {
        let data = {
            'exclude': this.list.exclude ? 1 : 0,
            'unselected': Object.keys(this.unselected)
        };

        let params = `?page=${this.page}&perPage=${this.params.limit}&sortBy=${this.selectedSort.sort}`;

        return $http.post(`/api/a/${model.id}${params}`, data).then(res => {
            this.list.selected = {};
            this.data = res.data;

            this.reselect( );

            this.pagination = res.data.meta.pagination;
            this.resourceInfo = `${this.pagination.from} - ${this.pagination.to} of ${this.pagination.total}`;
        });
    };

    this.reselect = () => {

        this.data.sessions.forEach((value) => {
            if (!this.unselected[value.id]) {
                this.list.selected[value.id] = true;
            }
        });

        this.list.all = Object.keys(this.list.selected).length === this.data.sessions.length;
    };

    this.openSlideviewModal = (row) => {

        let params = {
            'session_ids[]': [],
            'exclude': this.list.exclude ? 1 : 0,
            'unselected': Object.keys(this.unselected),
            'link_id': this.model.id
        };

        $uibModal.open({
            templateUrl: 'app/analytics/analyticsSlideModal.html',
            controller: 'AnalyticsSlideModalCtrl',
            controllerAs: '$ctrl',
            windowClass: 'analytics-slide-modal',
            size: 'md',
            bindToController: true,
            resolve: {
                data: () => {

                    // let params = this.getSessionIdsParam();

                    return $http.post('/api/a/slide/' + row.id, params).then((response) => {
                        return response.data;
                    });
                }
            }
        });
    };

});
