'use strict';

angular.module('app')

.factory('qHttp', function ($q, $http) {

    var queue = $q.when();

    return function queuedHttp(httpConf)
    {
        var f = function () {
            return $http(httpConf);
        };

        queue = queue.then(f, f);

        return queue;
    };
});

