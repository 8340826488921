'use strict';

angular.module('app')

.factory('TaskNotification', function (Notification, $window, DS)
{
    return function TaskNotification(task)
    {
        this.task = task;
        this.scope = null;

        this.notification = Notification({
            delay: false,
            closeOnClick: false,
            templateUrl: 'app/task/notification.html',
        });

        this.notification.then((scope) =>
        {
            scope.task = task;
            this.scope = scope;

            this.scope._templateElement.addClass('clickable');
            this.scope._templateElement.bind('click', (e) =>
            {
                e = e.originalEvent || e;
                if (e.type === 'click' || e.propertyName === 'opacity' && e.elapsedTime >= 1){
                    this.clicked(task);
                }
            });

            return scope;
        });

        this.clicked = () =>
        {
            if (['success', 'failed'].includes(this.task.status)) {
                if (this.task.data && this.task.data.url) {
                    $window.open(this.task.data.url, '_blank');
                }
            }

            let id = this.task.id;

            this.task.DSDestroy();

            DS.eject('Task', id);
        };

        this.kill = () =>
        {
            this.scope.kill(true);
        };
    };
});
