'use strict';

angular.module('app')

.config(function (resourceStateProvider)
{
    resourceStateProvider
    .state('admin.audit-logs', {
        url: 'audit-logs',
        model: 'Metric',
        viewOptions: 'auditLogViewOptions',
        title: 'Audit Logs',
        breadcrumbs: false,
        modelNameField: 'data',
        editable: function ()
        {
            return false;
        },
        show: function () {
            return false;
        }
    });
});
