'use strict';

angular.module('app')

.factory('previewAction', function ($state, $window)
{
    return {
        name: 'preview',
        tooltip: 'Present',
        buttonClass: 'btn btn-default',
        template: '<i class="material-icon material-icon-ondemand-video"></i> Preview',
        act: function (models)
        {
            var url = $state.href('admin.editor.presentation.chapter.slide', {
                presentationMode: 'slideshow',
                id: models[0].id
            });

            $window.open(url, '_blank');
        },
        show: function (model)
        {
            return model.type !== 'folder';
        }
    };
});
