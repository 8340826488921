'use strict';

/*
* importerCtrl.js
*
* This controller simply takes an input from
* a content-editable area and attempts to parse
* a HTML table, which would be compatible with
* UI Grid.
*/

angular.module('datasheet.admin')

.controller('SpreadsheetEditorCtrl', function (
    $scope,
    $q,
    $log,
    Datasheet,
    datasheet,
    notify,
    uiGridConstants,
    DatasetUtil
) {
    var init = function ()
    {
        $scope.datasheet.fetchData().then(function ()
        {
            setData();
        });
    };

    $scope.DataResource = datasheet.getDataResource();
    $scope.DataResource.ejectAll();

    $scope.datasheet = datasheet;
    $scope.data = [];

    $scope.responses = [];
    $scope.paste = {
        content: ''
    };

    var idColumn = {
        name: 'id',
        width: 70,
    };

    $scope.gridOptions = {
        data: [],
        columnDefs: [idColumn],
        enableSorting: true,
        enableCellEditOnFocus: true,
        flatEntityAccess: true,
        minRowsToShow: 15,
        // fastWatch: true,
    };

    function setData ()
    {
        $scope.DataResource.bindAll({}, $scope, 'gridOptions.data');
        $scope.gridOptions.enableColumnMenus = false;
        $scope.gridOptions.columnDefs = DatasetUtil.guessColumnDefs(
            $scope.DataResource.filter({limit: 1}),
            idColumn,
            {
                width: 200,
                enableCellEdit: true
            },
            datasheet
        );
    }

    // Attempt to take the first
    // row from the table body and
    // assign these as column names
    var parseColumns = function (tableElem)
    {
        var rows = tableElem.find('tr');
        var cols = [idColumn];

        angular.forEach(rows[0].children, function (cell, idx) {

            idx++; // because of the id column
            cols.push({
                name: cell.innerText,
                width: 200,
                enableCellEdit: true,
            });
        });

        $scope.gridOptions.columnDefs = cols;
    };

    // Attempt to cycle through all table rows (apart from
    // the first) and add it to the grid data
    var parseRows = function (tableElem)
    {
        var lastRowNum;
        var rows = tableElem.find('tr');

        angular.forEach(rows, function (node, rowNum) {

            // Skip the header row
            if (rowNum === 0) {
                return;
            }

            var obj;
            var push = true;

            if ($scope.gridOptions.data[rowNum - 1]) {
                // We already have a row we can use
                push = false;
                obj = $scope.gridOptions.data[rowNum - 1];

                // Destroy existing properties except its ID
                angular.forEach(obj, function (value, key) {
                    if (key !== idColumn.name) {
                        delete obj[key];
                    }
                });
            } else {
                obj = $scope.DataResource.createInstance();
            }

            angular.forEach(node.children, function (innerNode, colNum) {
                var fieldName = $scope.gridOptions.columnDefs[colNum + 1].name;
                obj[fieldName] = innerNode.innerText;
            });

            lastRowNum = rowNum;
            obj.DSCreate();
        });

        // Stop if no data was changed
        if (!lastRowNum) {
            return;
        }

        // Are there extra rows?
        var numExtraRows = $scope.gridOptions.data.length - lastRowNum;
        if (numExtraRows <= 0) {
            return;
        }

        // Delete extra data after confirm
        notify.confirm('The pasted data contains less rows than before, destroy [' + numExtraRows + '] extra rows?').result.then(function ()
        {
            while ($scope.gridOptions.data[lastRowNum]) {
                $scope.removeRow(lastRowNum);
                lastRowNum++;
            }
        });
    };

    $scope.removeRow = function (rowNum)
    {
        $scope.gridOptions.data[rowNum].DSDestroy().then(function ()
        {
            delete $scope.gridOptions.data[rowNum];
        });
    };

    // First function to call when
    // beginning the processing of
    // HTMl table data
    var parseTableData = function (tableElem)
    {
        parseColumns(tableElem);
        parseRows(tableElem);
    };

    // Fired by ng-change on the
    // HTML contenteditable area.
    $scope.changed = function ()
    {
        $scope.responses = [];

        try {
            if (!$scope.paste.content || $scope.paste.content === '') {
                return;
            }

            if (!/<table/i.test($scope.paste.content)) {
                $scope.addError('No table in pasted content.');
                return;
            }

            var cleanUpToFirstTag = $scope.paste.content.replace(/^[^<]*</, '<');
            var element = angular.element(cleanUpToFirstTag);

            if (!element.find('tr')) {
                $scope.addError('No rows in pasted content.');
                return;
            }

            parseTableData(angular.element(cleanUpToFirstTag));
            $scope.paste.content = null;
        }
        catch (err) {
            $scope.addError('There was an error parsing your table.');
            $log.error(err);
        }
    };

    $scope.addError = function (msg)
    {
        $scope.responses.push({
            class: 'bg-danger',
            text: msg
        });
    };

    $scope.saveData = function ()
    {
        var promises = [];

        // Save the datasheet header
        if ($scope.datasheet.DSHasChanges($scope.datasheet.id)) {
            promises.push($scope.datasheet.DSUpdate());
        }

        // Save all the data rows
        angular.forEach($scope.gridOptions.data, function (item) {

            // If the item already existed and hasn't been changed, skip it
            if (item[idColumn.name] && !item.DSHasChanges(item[idColumn.name])) {
                return;
            }

            promises.push(item.DSCreate());
        });

        $scope.responses = [];
        $q.all(promises).then(function ()
        {
            var successMsg = {
                class: 'bg-success',
                text: promises.length ? 'Save successful [' + promises.length + '].' : 'No changes were made'
            };

            $scope.responses.push(successMsg);
        }, $log.error);
    };

    init();
});
