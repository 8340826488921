'use strict';

angular.module('presentation.theme')

.directive('themeSectionNav', function ($uibModal, $document, Notification, $rootScope)
{
    return {
        restrict: "E",
        templateUrl: 'app/presentation/theme/theme.builder/section-nav/section-nav.html',
        scope: {
            title: '@',
            steps: '=',
            model: '=',
        },
        controller: function ($scope, ThemeValidator)
        {
            $scope.nextIndex = 0;
            $scope.prevIndex = 0;

            $scope.save = save;

            this.$onInit = function ()
            {
                for (let i = 0; i < $scope.steps.length; i++) {
                    if ($scope.steps[i].title === $scope.title) {
                        $scope.nextIndex = i + 1;
                        $scope.prevIndex = i - 1;
                    }
                }
            };

            function save ()
            {
                const errors = ThemeValidator.run($scope.model);

                if (errors) {
                    return Notification.error('There are missing styles in the theme!');
                }

                $uibModal.open({
                    animation: true,
                    templateUrl: 'app/presentation/theme/theme.builder/section-nav/theme.builder.save.modal.html',
                    windowClass: 'theme-builder-modal',
                    resolve: {
                        theme: function () {
                            return $scope.model;
                        },
                    },
                    controller: function ($scope, theme, $uibModalInstance, DS) {
                        $scope.save = function () {
                            theme.DSSave()
                            .then(function () {
                                DS.emit('saved');
                                $uibModalInstance.close(theme);
                                refreshUserThemes();
                            });
                        };
                    },
                });
            }

            function refreshUserThemes ()
            {
                const folder = $rootScope.config.build === 'dist' ? 'dist' : 'dev';
                const stylesheet = angular.element($document[0].querySelector("[href*=user-themes]"));
                stylesheet.attr('href', folder + '/user-themes.css?' + Date.now());
            }
        },
    };
});
