'use strict';

angular.module('app')

.service('handleSlideVideosService', function($timeout, $document) {
    this.videos = [];
    this.initialVideoState = [];


    this.preventAutoPlay = (slide) => {
        if (Number(slide) === 1) {
            this.videos = $document[0].getElementsByTagName('video');

            if (this.videos && this.videos.length > 0) {

                for(var i = 0; i < this.videos.length; i++) {
                    this.initialVideoState[i] = this.videos[i].autoplay;
                    this.videos[i].pause();
                }
            }
        }
    };

    this.reEnableAutoPlay = (slide) => {
        if (Number(slide) === 1) {
            if (this.videos && this.videos.length > 0) {
                for(var i = 0; i < this.videos.length; i++) {
                    if (this.initialVideoState[i]) {
                        this.videos[i].autoplay = true;
                        this.videos[i].play();
                    }
                }
            }
        }
    };

    this.endVideoPlay = () => {
        // if (Number(slide) === 1) {
            this.videos = $document[0].getElementsByTagName('video');

            if (this.videos && this.videos.length > 0) {
                for(var i = 0; i < this.videos.length; i++) {
                    // this.videos[i].autoplay = true;
                    this.videos[i].pause();
                    this.videos[i].currentTime = 0;
                }
            }
        // }
    };

    this.removePresenteeControls = () => {
        this.controls = $document[0].getElementsByClassName('video-controls')[0];

        if (this.controls) {
            angular.element(this.controls).remove();
        }
    };
});
