'use strict';

angular.module('app')

.factory('gradientConfigurator', function ()
{
    return {

        toggleUnit: function (unit, config)
        {
            config.unit = unit === 'px' ? '%' : 'px';
            this.applyChanges(config);
        },

        applyChanges: function (config)
        {
            if (!config) {
                return;
            }

            config.style = {};

            if (config.position === 'left' || config.position === 'right') {
                config.style.width = config.span + config.unit;
            } else {
                config.style.height = config.span + config.unit;
            }
        }
    };
});
