'use strict';

angular.module('app')

.config(function ($stateProvider, $urlRouterProvider)
{
    $urlRouterProvider.otherwise('/');

    $stateProvider
    .state('presentation', {
        url: '/',
        onEnter: function ($rootScope, $state, $timeout, auth, homeState) {

            if ($rootScope.jump_to_presentation) {
                var mode = 'slideshow';

                if ($rootScope.offline && $rootScope.offline_edit) {
                    mode = 'editor';
                }

                $timeout(()=>{
                    $state.go('admin.editor.presentation.chapter.slide', {
                        id: $rootScope.jump_to_presentation,
                        presentationMode: mode
                    });
                });

            } else {
                if (!auth.isLoggedIn() || auth.isGuest()) {
                    $state.go('guest.login');
                } else {
                    $state.go(homeState());
                }
            }
        },
        data:{
            guestAccess: true
        }
    });

    $stateProvider
    .state('guest', {
        abstract: true,
        views: {
            main: {
                template: '<div ui-view></div>'
            }
        }
    });

    $stateProvider
    .state('guest.404', {
        url: '/404',
        templateUrl: 'views/errors/404.html'
    });

    $stateProvider
    .state('guest.login', {
        url: '/login/:username?',
        controller: 'LoginCtrl',
        templateUrl: 'views/login.html',
        data:{
            guestAccess: true,
        },
        onEnter: function ($rootScope, $state, $stateParams) {
            if ($rootScope.config && $rootScope.config.sso_login_force && !$stateParams.username) {
                $state.go('guest.login-sso');
            }
        }
    });

    $stateProvider
    .state('guest.login-sso', {
        url: '/login-sso',
        controller: ['$scope', '$rootScope', '$http', '$state', '$document', ($scope, $rootScope, $http, $state, $document) =>
        {
            $scope.loginStyle = {
                backgroundImage: `url(${$rootScope.config.links.login_image})`
            };

            $scope.appName = $rootScope.config.app_name;
            $scope.url = $rootScope.config.saml.url;

            $scope.user = {
                username: ''
            };

            $scope.validated = null;
            $scope.errorMessage = null;

            $scope.loginDifferent = () => {
                $scope.validated = null;
                $scope.user.username = '';
            };

            $scope.continue = () =>
            {
                $http.post('auth/saml/validate', $scope.user).then((response) =>
                {
                    $scope.validated = response.data;

                    $scope.url = $scope.url + '?email=' + $scope.validated.email;

                    if ($scope.validated.sso_login && !$scope.validated.pswd_login) {
                        $document[0].location.href = $scope.url;
                    }

                    if ($scope.validated.pswd_login && !$scope.validated.sso_login) {
                        $state.go('guest.login', {'username': $scope.user.username});
                    }

                    $scope.errorMessage = null;

                }).catch(() =>
                {
                    $scope.errorMessage = 'User could not be validated!';
                });
            };
        }],
        templateUrl: 'views/login.sso.html',
        data:{
            guestAccess: true
        }
    });

    $stateProvider
    .state('guest.forbidden', {
        url: '/403-forbidden',
        templateUrl: 'views/errors/403-forbidden.html'
    });

    $stateProvider
    .state('guest.not-supported', {
        url: '/403-not-supported',
        templateUrl: 'views/errors/403-not-supported.html'
    });

    $stateProvider
    .state('auth', {
        abstract: true,
        views: {
            header: {
                templateUrl: 'views/header.html',
                controller: 'HeaderCtrl'
            },
            main: {
                template: '<div class="animated-view" ui-view></div>'
            }
        }
    });

    $stateProvider
    .state('admin', {
        url: '/admin',
        abstract: true,
        resolve: {
            tasks: ['Task', function (Task)
            {
                return Task.findAll(null, {bypassCache: false});
            }]
        },
        views: {
            header: {
                templateUrl: 'views/header.html',
                controller: 'HeaderCtrl'
            },
            main: {
                template: '<div ui-view></div>',
                controller: function ($scope)
                {
                    $scope.stickyOffset = 100;
                }
            },
            // footer: {
            //     templateUrl: 'views/footer.html'
            // }
        }
    });
});
