'use strict';

angular.module('presentation.theme')

.factory('themeConfigBrandColor', function ()
{
    return {
        enabled: false,
        defaultColor: null
    };
});
