'use strict';

angular.module('presentation.theme')

.config(function (ThemeValidatorProvider)
{
    ThemeValidatorProvider.add(function(config) {
        const markers = config.images.markers;

        const colourFields = [
            {key: 'active'},
            {key: 'borderColor'},
            {key: 'color'},
        ];

        return [{
            section: 'images',
            fields: colourFields,
            validKeys: 'colour',
            config: markers
        }];
    });
});
