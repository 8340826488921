'use strict';

angular.module('presentation.admin')

.factory('shareAction', function ($state)
{
    return {
        name: 'share',
        tooltip: 'Share',
        buttonClass: 'btn btn-default',
        template: '<i class="material-icon material-icon-share"></i> Share',
        act: function (models)
        {
            $state.go('.share', {
                id: models[0].id
            });
        },
        show: function (model)
        {
            return model.type !== 'folder';
        }
    };
});
