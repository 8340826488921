'use strict';

angular.module('presentation.theme')

.config(function (ThemeValidatorProvider)
{
    ThemeValidatorProvider.add(function(config) {
        const shapeStyles = config.shapes.shapeStyles;

        const colorFields = [
            {key: 'borderColor'},
            {key: 'fillColor'},
        ];

        return [{
            section: 'shapes',
            fields: colorFields,
            validKeys: 'colour',
            config: shapeStyles
        }];
    });
});
