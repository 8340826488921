'use strict';

angular.module('presentation.font')

.controller('FontEditCtrl', function (
    $scope,
    $state,
    model,
    FileUploader,
    baseApiUrl,
    ajaxHeaders,
    $cookies,
    $http
)
{
    $scope.model = model;

    $scope.uploader = new FileUploader({
        url: baseApiUrl + 'fonts/upload',
        headers: angular.extend({
            'X-XSRF-TOKEN': $cookies.get('XSRF-TOKEN')
        }, ajaxHeaders),
        autoUpload: true,
        onBeforeUploadItem: function (item) {
            item.formData = [{id: $scope.model.id}];
        },
        onErrorItem: function (item, response) {
            item.returnedErrors = response.file.join('\n');
        }
    });

    $scope.remove = function (fileItem)
    {
        fileItem.remove();
    };

    $scope.deleteFile = function (fileItem)
    {
        $http.post('/api/fonts/deleteFile',
            {fontId: model.id, fileId: fileItem.id}).then(function ()
        {
            var index = $scope.model.files.indexOf(fileItem);
            $scope.model.files.splice(index, 1);
        });
    };

    $scope.saveModel = function ()
    {
        $scope.model.DSCreate().then($scope.$close);
    };
});
