'use strict';

angular.module('app')

.run(function (Metric) {}) // jshint ignore:line

.factory('Metric', function (DS)
{
    return DS.defineResource({
        basePath: '/api/',
        name: 'Metric',
        endpoint: 'metrics',
    });
});
