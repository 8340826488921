'use strict';

angular.module('app')

/**
 * Store and retrieve js-data filter params
 * http://www.js-data.io/v1.8.0/docs/query-syntax
 */
.factory('CountryPopupDataRepo', function ($q)
{
    return function (countryData, countryName)
    {
        /**
         * retrive the full data of a specific country from the
         *
         * @param  {array} dataArr  Array of data object.
         *                          e.g. {id: ..., country: ..., property1: ..., property2: ...}
         * @param  {string} country Name of the country.
         * @param  {string} labelField country label field name
         * @return {object}         filtered data object
         */
        function getDataForCountry (dataArr, country, labelField)
        {
            if (!country) {
                return dataArr[0];
            }

            country = country.toLowerCase();

            for (var i = 0; i < dataArr.length; i++) {
                var itemCountry = dataArr[i][labelField] || '';
                if (itemCountry.toLowerCase() === country) {
                    return dataArr[i];
                }
            }

            // by default (no data found), return the first item of the dataArr
            return dataArr[0];
        }

        return {
            countryDataset: countryData,
            countryName: countryName,

            /**
             * ...
             *
             * @return {Promise}
             */
            loadAllDatasheets: function ()
            {
                var deferred = $q.defer();
                deferred.resolve(this.countryDataset);
                return deferred.promise;
            },
            /**
             *  ...
             *
             * @param  {array} data                     Full data array of the map-component
             * @param  {array} data[0].data             Filtered data(rows) from the original datasheet
             * @param  {array} data[0].filterQuery      Filter query.
             *                                          e.g. { where: { field: { in: [..., ...] } } }
             * @return {Promise}
             */
            loadDatasetByFilter: function (data)
            {
                var countryData, filterArr, arr, filteredData = [];

                for (var i = 0; i < data.length; i++) {
                    countryData = getDataForCountry(data[i].data, this.countryName, data[i].labelField);

                    filterArr = data[i].filterQuery.where.field['in'];
                    arr = [];

                    for (var j = 0; j < filterArr.length; j++) {
                        arr.push({
                            name: filterArr[j],
                            value: countryData[filterArr[j]]
                        });
                    }

                    filteredData.push(arr);
                }

                var deferred = $q.defer();
                deferred.resolve(filteredData);
                return deferred.promise;
            }
        };

    };
});
