'use strict';

angular.module('presentation.theme')

.controller('ThemeBuilderCtrl', function (
    $scope,
    $rootScope,
    $document,
    model,
    themeConfigMarginals,
    ThemeValidator,
    defaultThemeConfig,
    objectDefaults
) {
    // Set id of theme we're editing
    $scope.themeId = model.id;
    $scope.model = model;
    $scope.fonts = model.fonts;
    $scope.fontFamilies = model.font_families;

    this.$onInit = function ()
    {
        $scope.model.config = objectDefaults($scope.model.config, defaultThemeConfig());
        $scope.model.setDefaultFonts();

        if ($scope.model.config.mapsStyleToggle) {
            $scope.model.config.maps.enabled = true;
            delete $scope.model.config.mapsStyleToggle;
        }

        if ($scope.model.config.chartsStyleToggle) {
            $scope.model.config.charts.enabled = true;
            delete $scope.model.config.chartsStyleToggle;
        }

        if ($scope.model.config.marginals.customCss) {
            $scope.model.config.custom.css += '.marginals { \n';
            $scope.model.config.custom.css += $scope.model.config.marginals.customCss + ' \n';
            $scope.model.config.custom.css += '} \n';
            delete $scope.model.config.marginals.customCss;
        }

        if ($scope.model.config.tabs.customCss) {
            $scope.model.config.custom.css += '.tabs-component { \n';
            $scope.model.config.custom.css += $scope.model.config.tabs.customCss + ' \n';
            $scope.model.config.custom.css += '} \n';
            delete $scope.model.config.tabs.customCss;
        }

        ThemeValidator.run($scope.model);
    };

    // If user changes the theme id in the URL make sure navigation's theme id is updated
    const unregisterListener = $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams)
    {
        if (toState.name.includes('admin.theme-build')) {
            $scope.themeId = toParams.id;
            $document[0].body.scrollTop = $document[0].documentElement.scrollTop = 0;
        }
    });

    $scope.$on('$destroy', () => unregisterListener());
});
