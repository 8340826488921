'use strict';

angular.module('app')

.factory('highMapService', function (extendDeep, mapSeriesConfigPresets)
{
    return {
        name: 'map',
        label: 'Map',

        /**
         * Apply series data, configuration for series, to the current map config
         *
         * @param  {Object} mapConfig       Current map config.
         * @param  {Object} seriesConfig    Config for map series
         * @param  {Array} data             Data of map series.
         * @param  {Array} dataSeriesConfig Data & extras of map series.
         * @return {[type]}
         */
        apply: function (mapConfig, seriesConfig, data, dataSeriesConfig, mapTheme)
        {
            var renderedData = this.renderData(data, dataSeriesConfig, mapTheme);
            var seriesData = renderedData.seriesData;
            // apply series config and data to map options
            seriesData = [extendDeep({}, mapSeriesConfigPresets, {
                name: 'series',
                countries: renderedData.categories.join(', '),
                data: seriesData,
                color: mapTheme.color,
                nullColor: mapTheme.nullColor,
                borderWidth: mapTheme.borderWidth,
                borderColor: mapTheme.borderColor,
            }, seriesConfig)];

            // only need to show *allAreas* of the first set of data in series
            if (seriesConfig.showAllAreas) {
                seriesData[0].allAreas = true;
            }

            extendDeep(mapConfig, {
                /**
                 * @type {Array} series
                 * @type {Array} series[0].name
                 * @type {Array} series[0].countries
                 * @type {Array} series[0].data             Data obtained from this.renderData()
                 * @type {Array} series[0].data[0].name
                 * @type {Array} series[0].data[0].value
                 */
                series: seriesData
            });
        },

        /**
         * parse the data array to chart-understandable data format
         *
         * @param  {Array}  data                Array of data objects filtered from original datasheet, before passing into chart
         * @param  {Array}  data[0].data        Data objects(rows) from datasheet
         * @param  {Object} data[0].filterQuery {
         *                                         where: {
         *                                             property1: {
         *                                                 in: [key1, key2]
         *                                             },
         *                                             property2: {
         *                                                 in: [key11]
         *                                             },
         *                                             ...
         *                                         }
         *                                      }
         * @param  {String} data[0].labelField  Selected field to act as label
         * @param  {String} data[0].valueField  Selected field to act as value
         * @return {Object} Object.categories   Array of strings
         *                  Object.seriesData   Array of data to be feed into highmap
         *
         */
        renderData: function (data, dataSeriesConfig, mapTheme)
        {
            var categories = [];
            var processedData = {};
            var countryColors = {};

            angular.forEach(data, function (dataItem, key) {
                angular.forEach(dataItem.data, function (row) {
                    var countryName = row[dataItem.labelField];

                    if (!processedData[countryName]) {
                        categories.push(countryName);
                    }

                    processedData[countryName] = processedData[countryName] || '';
                    processedData[countryName] += dataItem.valueField + ': ' + row[dataItem.valueField] + '; ';
                    countryColors[countryName] = dataSeriesConfig[key] && dataSeriesConfig[key].color ?
                                                 dataSeriesConfig[key].color : mapTheme.color;
                });
            });

            var seriesData = [];
            angular.forEach(categories, function (country) {

                seriesData.push({
                    color: countryColors[country],
                    name:  country.replace(/^\s+|\s+$/, ''),
                    value: processedData[country]
                });
            });

            return {
                categories: categories,
                /**
                 * chart friendly data
                 *
                 * @type {Array}    seriesData
                 * @type {Object}   seriesData[0].name  Name/Title(country or region) of each data object
                 * @type {Object}   seriesData[0].value Value of each data Object. e.g. "key1: value1"
                 */
                seriesData: seriesData
            };
        },

        /**
         * Get country region list from high map object
         *
         * @param  {Object} highMap       Current highMap object.
         * @return {Object}
         */
        getCountryRegionList: function (highMap)
        {
            var countryList = [];
            var regionList = [];

            var o = {};
            angular.forEach(highMap.options.plotOptions.map.mapData.features, function (feature) {
                countryList.push(feature.properties.name);

                if (!o[feature.properties['region-un']]) {
                    regionList.push(feature.properties['region-un']);
                    o[feature.properties['region-un']] = true;
                }
                if (!o[feature.properties['region-wb']]) {
                    regionList.push(feature.properties['region-wb']);
                    o[feature.properties['region-wb']] = true;
                }
            });

            return {
                countryList: countryList,
                regionList: regionList
            };
        },

        undo: function (config)
        {
            delete config.series;
        },
    };
});
