'use strict';

angular.module('presentation.theme')

.directive('themeTableBorderStyles', function ()
{
    return {
        restrict: "E",
        templateUrl: 'app/presentation/theme/theme.builder/tables/table-border-styles/table-border-styles.html',
        scope: {
            borderStylesConfig: "=",
            palette: "="
        },
        controller: function ($scope)
        {
            var getStylesCount = function ()
            {
                return Object.keys($scope.borderStylesConfig).length + 1;
            };

            $scope.addBorderStyle = function()
            {
                var stylesCount = getStylesCount();
                var defaultStyleName = 'solid-' + stylesCount;

                var defaultBorderStyle = {
                    width: 2,
                    style: 'solid',
                    name: defaultStyleName,
                    color: '#000000'
                };

                // Add a new object with the default border style values to the array of border styles
                $scope.borderStylesConfig.push(defaultBorderStyle);
            };

            $scope.updateStyleName = function (borderStyle)
            {
                // Border style to be "solid-1", "dashed-2"
                var stylesCount = getStylesCount();
                borderStyle.name = borderStyle.style + '-' + stylesCount;
            };

            // Remove a border style at a specific position
            $scope.deleteBorderStyle = function(index)
            {
                $scope.borderStylesConfig.splice(index, 1);
            };
        }
    };
});
