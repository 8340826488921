'use strict';

angular.module('presentation.model')

.run(function (Share) {}) // jshint ignore:line

.factory('Share', function (DS)
{
    return DS.defineResource({
        basePath: '/api/',
        name: 'Share',
        endpoint: 'links',
        upsert: true,
        relations: {
            belongsTo: {
                Presentation: {
                    localField: 'presentations',
                    localKey: 'presentation_id'
                },
                Client: {
                    localField: 'client',
                    localKey: 'client_id'
                }
            }
        }
    });
});
