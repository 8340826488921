'use strict';

angular.module('presentation.frontend')

.directive('slideSidebarOptions', function() {
    return {
        restrict: 'EA',
        templateUrl: 'app/presentation/frontend/slide-sidebar-options/slideSidebarOptions.html',
        controller: 'SlideOptionsCtrl',
    };
});
