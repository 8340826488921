'use strict';

angular.module('presentation.model')

.run(function (Presentation) {}) // jshint ignore:line

.factory('Presentation', function (
    DS,
    $state,
    $rootScope,
    $window,
    toJson,
    $http,
    PreviousState,
    Notification,
    $injector,
    appUuid,
    $q,
    kdAuth,
    md5
) {
    return DS.defineResource({
        basePath: '/api/',
        name: 'Presentation',
        endpoint: 'presentations',
        upsert: true,
        defaultValues: {
            type: 'presentation',
            action_id: 0,
        },
        methods: {
            ejectDataStore: function ()
            {
                DS.ejectAll('Chapter');
                DS.ejectAll('ChapterSlide');
                DS.ejectAll('Slide');
            },
            goInto: function ()
            {
                if (this.type === 'folder') {
                    $state.go('admin.presentations', {folder_id: this.id, offset: null, name: null});
                } else {
                    $state.go('admin.editor.presentation.chapter.slide', {
                        presentationMode: 'editor',
                        id: this.id
                    });
                }
            },
            getChapterSlides: function ()
            {
                const chapters = this.chapters.sort((a, b) => {
                    return parseInt(a.display_order) - parseInt(b.display_order);
                });

                return chapters.reduce((collection, chapter) => {
                    const sortedChapterSlides = chapter.chapter_slides.sort((a, b) => {
                        return parseInt(a.display_order) - parseInt(b.display_order);
                    });
                    return collection.concat(sortedChapterSlides);
                }, []);
            },
            saveSlides: function ()
            {
                if ($rootScope.offline) {
                    return this.saveOffline().then(function (data) {
                        $rootScope.$broadcast('slide:saved');
                        return data;
                    });
                }

                return this.saveOnline();
            },
            saveOffline: function ()
            {
                const self = this;

                if ($injector.has('localData')) {
                    const localData = $injector.get('localData');
                    return localData.storage.save('presentations', toJson.offlineJson(this)).then(function ()
                    {
                        return self;
                    });
                }

                return $q.resolve(this);
            },
            saveOnline: function (action)
            {
                action = action || 'update';

                const self = this;

                const data = {
                    action: action,
                    saved_at: this.saved_at || this.updated_at,
                    data: toJson.presentation(this, action === 'update')
                };

                return $http.post('/api/presentations/sync', data).then(function (data)
                {
                    self.ejectDataStore();

                    const press = $injector.get('Presentation').inject(data.data);

                    if (action !== 'update') {
                        Notification.success(`New presentation "${press.name}" has been created`);
                    }

                    if ($rootScope.offline) {

                        if (action === 'update') {
                            let user = kdAuth.user;
                            delete user.token;

                            kdAuth.setUser(user);
                            Notification.success('Presentation synced.');
                        }

                        //If offline we need to save to a local storage as well.
                        return press.saveOffline().then(function ()
                        {
                            if (action === 'update') {
                                $rootScope.$broadcast('slide:saved');
                            }

                            return press;
                        },
                        function (error)
                        {
                            $rootScope.$broadcast('slide:save-failed', error);
                            return $q.reject(error);
                        });
                    }

                    if (action === 'update') {
                        $rootScope.$broadcast('slide:saved');
                    } else {
                        PreviousState.skip();
                    }

                    return press;
                },
                function (error)
                {
                    $rootScope.$broadcast('slide:save-failed', error);
                    return $q.reject(error);
                });
            },
            getChecksum: function ()
            {
                let str = '';

                angular.forEach(this.chapters, function (chapter)
                {
                    str += chapter.name.id + chapter.name + chapter.display_order;

                    angular.forEach(chapter.chapter_slides, function (chapter_slide)
                    {
                        str += chapter_slide.id + chapter_slide.display_order;
                    });
                });

                return md5.createHash(str);
            },
            deleteChecksum: function ()
            {
                if (this.checksum) {
                    delete this.checksum;
                }

                angular.forEach(this.getChapterSlides(), function (chapterSlide)
                {
                    chapterSlide.slide.deleteChecksum();
                });
            },
            snapshotChecksum: function ()
            {
                this.checksum = this.getChecksum();
            },
            hasContentChanged: function ()
            {
                if (!this.checksum) {
                    return false;
                }

                if (this.checksum !== this.getChecksum()) {
                    return true;
                }

                const chapterSlides = this.getChapterSlides();

                for (let i = 0; i < chapterSlides.length; i++) {
                    if (chapterSlides[i].slide.hasContentChanged()) {
                        return true;
                    }
                }

                return false;
            },
            forceChaptersDisplayOrder: function ()
            {
                const chapters = this.chapters;

                chapters.sort(function (a, b)
                {
                    return parseInt(a.display_order) - parseInt(b.display_order);
                });

                angular.forEach(chapters, function(chapter, key)
                {
                    chapter.display_order = key + 1;
                });
            },
            lockForOthers: function ()
            {
                return $http.post('/api/presentations/lock', {id: this.id});
            },
            unlock: function ()
            {
                let data = JSON.stringify({
                    id: this.id,
                    app_uuid: appUuid()
                });

                try {
                    $window.navigator.sendBeacon('/api/presentations/unlock', data);
                }
                catch(err) {
                    $http.post('/api/presentations/unlock', data);
                }
            },
            getClientsBrandColor: function ()
            {
                if (!this.theme) {
                    return null;
                }

                if (!this.theme.isBrandColorEnabled()) {
                    return null;
                }

                if (this.client && this.client.brand_color) {
                    return this.client.brand_color;
                }

                return this.theme.getDefaultClientBrandColor();
            }
        },
        relations: {
            belongsTo: {
                Client: {
                    localField: 'client',
                    localKey: 'client_id'
                },
                User: {
                    localField: 'user',
                    localKey: 'user_id'
                },
                Theme: {
                    localKey: 'theme_id',
                    localField: 'theme'
                }
            },
            hasMany: {
                Chapter: {
                    localField: 'chapters',
                    foreignKey: 'presentation_id'
                },
                Share: {
                    localField: 'shares',
                    foreignKey: 'presentation_id'
                }
            }
        },
        beforeCreateInstance: function (resource, data)
        {
            if (data.type === 'presentation') {
                data.theme_id = data.theme_id || 0;
            }
            return data;
        },
        afterDestroy: function (resourceName, attrs, cb)
        {
            $rootScope.$broadcast('destroy', resourceName, attrs);

            DS.emit('destroy');
            cb(null, attrs);
        }
    });
});
