'use strict';

angular.module('app')

.factory('restoreAction', function ($window, $uibModal, kdGate)
{
    return {
        name: 'restore',
        tooltip: 'Restore',
        buttonClass: 'btn btn-default',
        template: '<i class="material-icon material-icon-restore"></i> Restore',
        act: function (models) {

            $uibModal.open({
                templateUrl: 'views/modals/presentationRestoreModal.html',
                controller: 'restorePresentationCtrl',
                size: 'md',
                resolve: {
                    presentation_id: function () {
                        return models[0].metricable_id;
                    }
                }
            });
        },
        show: function (model) {

            if (kdGate.denies('presentation.restore')) {
                return false;
            }

            if (model.action !== 'Deleted' || model.metricable_type !== 'presentations') {
                return false;
            }

            return model.data.restored === false;
        }
    };
});
