'use strict';

angular.module('app')

.directive('dropdownMultiselect', function ()
{
    var controller = function ($scope)
    {
        $scope.selectAll = function ()
        {
            $scope.deselectAll();

            for (var i = 0; i < $scope.options.length; i++) {
                $scope.model.push($scope.options[i]);
            }
        };

        $scope.deselectAll = function ()
        {
            for (var i = $scope.model.length; i >= 0; i--) {
                $scope.model.splice(i, 1);
            }
        };

        $scope.setSelectedItem = function ()
        {
            $scope.open = false;

            if ($scope.model.indexOf(this.option) > -1) {
                $scope.model.splice($scope.model.indexOf(this.option), 1);
            } else {
                $scope.model.push(this.option);
            }
        };

        $scope.isChecked = function (id)
        {
            return $scope.model.indexOf(id) > -1 ? 'active' : false;
        };
    };

    var link = function (scope, elem)
    {
        elem.addClass('dropdown-multiselect');
    };

    return {
        restrict: 'E',
        scope:{
            model: '=',
            options: '=',
        },
        link: link,
        controller: controller,
        template: '<div uib-dropdown class="dropdown btn-group btn-group-xs btn-block">' +
                     '<button uib-dropdown-toggle class="btn btn-default btn-block">' +
                         'Select <span class="caret"></span>' +
                     '</button>' +
                     '<div uib-dropdown-menu class="dropdown-menu">' +
                         '<div class="dropdown-item">' +
                             "<button class='btn btn-default btn-block btn-xs' type='button' ng-click='selectAll()'>" +
                                     "<i class='material-icon material-icon-check'></i><span> Check all</span></button>" +
                         '</div>' +
                         '<div class="dropdown-item">' +
                             '<button class="btn btn-default btn-block btn-xs" type="button" ng-click="deselectAll()">' +
                                     '<i class="material-icon material-icon-clear"></i><span> Uncheck all</span></button>' +
                         '</div>' +
                         '<div class="divider"></div>' +
                         '<div class="dropdown-item" ng-repeat="option in options">' +
                             "<button class='btn btn-default btn-block btn-xs'" +
                                     "type='button' ng-click='setSelectedItem()'" +
                                     "ng-class='isChecked(option)'>{{option}}</button>" +
                         '</div>' +
                     '</div>' +
                 '</div>'
    };
});
