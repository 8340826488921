'use strict';

angular.module('app')

.factory('SwatchColorsConfig', function($rootScope, themeInjector)
{

    var theme = themeInjector.get($rootScope.theme);

    var options  = {
        default: '#002B59',
        colors: {
            '#FFFFFF': 'White',
            '#002B59': 'Blue',
            '#EB2617': 'Red',
            '#77BF23': 'Green',
            '#00AEA7': 'Light Green',
            '#F7610B': 'Orange',
            '#FF9F00': 'Light Orange',
            '#3EB6E0': 'Light blue',
            '#853F97': 'Purple',
            '#D3F207': 'Lemon',
        }};

    // Override options based on application theme
    theme.swatchColorsConfig(options);

    return options;
});
