'use strict';

angular.module('app').directive('analyticsVideoPlayerLogs', function ()
{
    return {
        restrict: 'EA',
        controllerAs: '$ctrl',
        bindToController: true,
        templateUrl: 'app/analytics/video-player/video-player.html',
        scope: {
            logs: '=',
        },
        controller: function ()
        {
            this.$onInit = () =>
            {
                this.grouped = this.getGroupedData();
                this.data = [];

                Object.keys(this.grouped).forEach((id) => {

                    let list = this.grouped[id];
                    let duration = null;
                    let name = null;
                    let alt_name = null;
                    let sum = 0;

                    list.forEach((log) => {

                        if (log.subject) {
                            duration = log.subject.duration;
                            name = log.subject.name;
                        }

                        alt_name = log.subject_name;

                        sum = sum + log.seconds;
                    });

                    let tmp = {
                        name: name || alt_name,
                        duration: duration,
                        clicks: list.length,
                        avg: list.length ? Math.round(sum / list.length) : 0,
                    };

                    this.data.push(tmp);
                });
            };

            this.getGroupedData = () =>
            {
                let grouped = {};

                this.logs.forEach((item) => {
                    grouped[item.subject_id] = grouped[item.subject_id] || [];
                    grouped[item.subject_id].push(item);
                });

                return grouped;
            };
        }
    };
});
