'use strict';

angular.module('app')

.service('defaultModalThemeConfig', function ($rootScope, $injector, defaultConfigCache)
{
    const options = () =>
    {
        return {
            default: 'md',
            sizes: {
                xl: {
                    label: 'X-Large',
                    class: 'popup-xl',
                },
                lg: {
                    label: 'Large',
                    class: 'popup-lg',
                },
                md: {
                    label: 'Medium',
                    class: 'popup-md',
                },
                sm: {
                    label: 'Small',
                    class: 'popup-sm',
                },
                suite: {
                    label: 'Suite',
                    class: 'popup-suite',
                },
            },
        };
    };

    this.get = function () {
        return defaultConfigCache('modalThemeConfig', options());
    };
});
