'use strict';

angular.module('editorComponents.timeline')

.controller('timelineCtrl', function (
    $scope,
    TimelineDefaults,
    timelineModal
) {

    this.$onInit = function ()
    {
        // Set defaults
        $scope.model.data = Object.keys($scope.model.data).length > 0 ? $scope.model.data : new TimelineDefaults();
        $scope.model.componentStyle = {backgroundColor: '#FFFFFF'};
    };

    /*
     * Public functions
     */
    $scope.configureTimeline = function ()
    {
        timelineModal.open({
            events: $scope.model.data.events,
            showAllMonths: $scope.model.data.showAllMonths,
            startDate: $scope.model.data.startDate
        })
        .result.then(function (options)
        {
            if (!options) {
                return;
            }

            $scope.model.data.showAllMonths = options.showAllMonths;
            $scope.model.data.events = options.events;
            $scope.model.data.startDate = options.startDate;

            // Force a $onChanges event to be triggered
            $scope.model.data = angular.copy($scope.model.data);
        });
    };
});
