'use strict';

angular.module('app').factory('arrayGet', function () {

    return (input, key, defaultValue = null) => {

        if (!key) {
            return input;
        }

        let s = key.split('.');
        let o = input;

        for (let x = 0; x < s.length; x++) {
            if (null !== o && o.hasOwnProperty(s[x])) {
                o = o[s[x]];
            } else {
                return defaultValue;
            }
        }

        return o;
    };
});
