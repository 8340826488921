'use strict';

angular.module('app')

/**
 * Handles changing the active stylesheet
 */
.service('themeChanger', function ($document, $rootScope)
{
    this.themeSuffix = '';
    this.bodyElement = angular.element($document[0].documentElement);

    /**
     *
     * @param {int} themeId
     *
     * @return {Promise}
     */
    this.setTheme = function (theme)
    {
        if (theme) {
            $rootScope.theme = theme.name;
            this.themeSuffix = theme.system ? theme.name : theme.id;
        } else {
            $rootScope.theme = 'bylder';
            this.themeSuffix = $rootScope.theme;
        }

        // Set themeClass to body
        this.setThemeClass();
    };

    this.setThemeClass = function ()
    {
        const themeClass = "theme-" + this.themeSuffix;
        this.bodyElement.addClass(themeClass);
    };

    this.removeThemeClass = function ()
    {
        const themeClass = "theme-" + this.themeSuffix;
        this.bodyElement.removeClass(themeClass);
    };
});
