'use strict';

angular.module('app')

.config(function (editorGatesProvider) {
   editorGatesProvider.setGates('chapter-header');
})

.directive('chapterHeader', function ()
{
    var componentName = 'chapterHeader';
    var fileLocation = 'scripts/editorComponents/' + componentName + '/';

    var link = function ($scope, element, attributes, controllers)
    {
        var componentCtrl = controllers[0];

        // Set chapter order value coming from rootScoop
        $scope.chapterOrder = $scope.$root.chapterOrder || 1;

        componentCtrl.setFocusRequestHandler(function ()
        {
            element[0].focus();
        });

        if ($scope.data.text === undefined) {
            $scope.data.text = 'New Chapter Heading';
        }
    };

    return {
        restrict: 'A',
        require: ['^component', '^editor'],
        templateUrl: fileLocation + componentName + '.html',
        link: link
    };
});
