'use strict';

angular.module('app')

.config(function ($stateProvider) {
    $stateProvider
        .state('admin.analytics', {
            url: '/analytics/{id:[0-9\w-]+}',
            resolve: {
                model: ['Share', '$stateParams', function (Share, $stateParams) {
                    return Share.find($stateParams.id);
                }],
                analyticsData: ['model', '$http', function (model, $http) {
                    return $http.get('/api/a/' + model.id).then(res => {
                        return res.data;
                    });
                }],
                access: ['$rootScope', '$location', '$q', function($rootScope, $location, $q) {
                    if (!$rootScope.config.analytics_session_enabled) {
                        $location.path('/403-forbidden');
                        return $q.reject(); // Reject the promise
                    }
                }],
            },
            views: {
                '@admin': {
                    controller: 'AnalyticsController',
                    controllerAs: '$ctrl',
                    bindToController: true,
                    templateUrl: 'app/analytics/analytics.html'
                }
            }
        });
});
