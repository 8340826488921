'use strict';

angular.module('app')

.controller('PresentationEditCtrl',
    function (
        $scope,
        $state,
        $http,
        DS,
        baseApiUrl,
        Theme,
        model,
        themes,
        Presentation,
        $rootScope,
        defaultTheme,
        slideTransitionConfig
    ) {
        $scope.cloning = $state.current.name === 'admin.presentations.clone';

        $scope.transitions = slideTransitionConfig.options;
        $scope.themes = themes;
        $scope.transitions = slideTransitionConfig.options;

        //Set default type
        $scope.model = model;
        $scope.selectedTemplate = null;

        if ($scope.cloning) {
            $scope.model = angular.copy(model);
            $scope.model.name = "Copy " + model.name;
        }

        $scope.setSelectedTheme = function (id) {
            let selectedTheme = Theme.filter({id: id});
            $scope.selectedTheme = selectedTheme.length > 0 ? selectedTheme[0] : defaultTheme();
            $scope.model.theme_id = $scope.selectedTheme.id;
        };

        // Set selected theme object
        $scope.setSelectedTheme($scope.model.theme_id);

        // Ensure the slide config is an object
        if (!$scope.model.config || angular.isArray($scope.model.config)) {
            $scope.model.config = {};
        }

        $scope.model.config.transition_id = $scope.model.config.transition_id || 0;
        $scope.model.config.aspectRatioCanvas = $scope.model.config.aspectRatioCanvas || false;
        $scope.model.config.sidebarOptions = $scope.model.config.sidebarOptions || $rootScope.config.presentation.editor.sidebar_options_default;
        $scope.model.config.rtl = $scope.model.config.rtl || false;
        $scope.model.config.intro = $scope.model.config.intro || false;
        $scope.model.config.playOnMobile = $scope.model.config.playOnMobile || false;

        if (!$scope.model.id || !$scope.model.toggle_canvas) {
            $scope.model.config.aspectRatioCanvas = true;
        }

        // If we are creating, get the folder id from state params
        if ($scope.model.folder_id === undefined) {
            $scope.model.folder_id = $state.params.folder_id || 0;
        }

        //Getting master presentations
        if (!$scope.model.id) {
            Presentation.findAll({template: 1});
            Presentation.bindAll({template: 1}, $scope, 'templates');
        }

        $scope.saveModel = () => {
            if (!$scope.cloning) {
                return model.DSCreate($scope.model).then(() => {
                    $scope.$close($scope.model);
                });
            }

            return $http.post(`${baseApiUrl}${Presentation.endpoint}/copy`, $scope.model).then(data => {
                Presentation.inject(data.data);
                $scope.$close($scope.model);
                DS.emit('create');
            }).catch(error => {
                DS.emit('error', 'create', 'presentation', error.data);
            });
        };

        $scope.selectTemplate = function (template) {
            $scope.model.template_id = template.id;
            $scope.selectedTemplate = template;
        };

    });
