'use strict';

angular.module('presentation.theme')

.factory('defaultThemeConfig', function (
    themeConfigTypography,
    themeConfigColors,
    themeConfigMarginals,
    themeConfigTables,
    themeConfigShapes,
    themeConfigCharts,
    themeConfigMaps,
    themeConfigImages,
    themeConfigTabs,
    themeConfigIframes,
    themeConfigCustom,
    themeConfigPopups,
    themeConfigBrandColor
) {
    return function ()
    {
        return {
            colors: themeConfigColors,
            typography: themeConfigTypography.typography,
            marginals: themeConfigMarginals,
            tables: themeConfigTables,
            shapes: themeConfigShapes,
            charts: themeConfigCharts,
            maps: themeConfigMaps,
            images: themeConfigImages,
            tabs: themeConfigTabs,
            iframes: themeConfigIframes,
            brandColor: themeConfigBrandColor,
            custom: themeConfigCustom,
            popups: themeConfigPopups,
            gradient: {
                color: 'dark'
            },
        };
    };
});
