'use strict';

angular.module('editorComponents.timeline')

.controller('timelineViewportCtrl', function ($scope, $element, $window, $timeout, tweenMax, createTable)
{
    this.gradientOffset = 5;
    this.events = [];
    this.container = null;
    this.isLastPosition = false;
    this.disableFirstPosition = false;
    this.currentIndex = 0;
    this.currentYear = null;

    this.attachEvent = function (event, element)
    {
        this.events.push({event:event, element:element});

        if (event.date === this.startDate) {
            this.currentIndex = this.events.length -1;

            // Timeout to ensure events are
            // rendered before checking offset
            $timeout(function () {
                this.move(0);
            }.bind(this), 300);
        }
    };

    this.attachContainer = function (element)
    {
        this.container = element;
    };

    this.move = function (move)
    {
        var index = this.currentIndex + move;
        var event = this.events[index].event;

        if (!this.container || this.events.length === 0) {
            return;
        }

        if (event && event.type === 'year') {
            index = index + move;
            event = this.events[index].event;
        }

        var element = this.events[index].element;
        var maxOffset = this.container[0].clientWidth - $element[0].clientWidth;
        var elementOffset = event ? element[0].offsetLeft : 0;
        var offset = this.checkOffset(elementOffset, maxOffset + this.gradientOffset);

        this.currentIndex = index;
        this.currentYear = index > 0 ? this.events[index -1].event.year : event.year;

        tweenMax.to(this.container, 0.5, {
            left: -offset + this.gradientOffset // Additional 5px offset to account for the gradient
        });
    };

    this.lastPosition = function ()
    {
        return this.isLastPosition;
    };

    this.firstPosition = function ()
    {
        return this.disableFirstPosition || this.currentIndex < 1;
    };

    this.checkOffset = function (offset, maxOffset)
    {
        if (maxOffset < 0) {
            this.isLastPosition = this.disableFirstPosition = true;
            return 0;
        } else {
            this.disableFirstPosition = false;
        }

        if (offset < maxOffset || maxOffset < 0 && maxOffset) {
            this.isLastPosition = false;
            return offset;
        } else {
            this.isLastPosition = true;
        }

        return maxOffset;

    };

    this.$onChanges = function (changes)
    {
        var events = changes.data.currentValue.events;
        var showAllMonths = changes.data.currentValue.showAllMonths;

        // Reset events and index counter ready for rebuild
        this.events = [];
        this.currentIndex = 0;

        if (events.length === 0) {
            this.table = {};
            return;
        }

        this.startDate = changes.data.currentValue.startDate;
        this.table = createTable(events, showAllMonths);
    };

    this.resize = function ()
    {
        this.move(0);
    };

    $scope.$on('$destroy', function ()
    {
        angular.element($window).off('resize', this.resize.bind(this));
    }.bind(this));

    angular.element($window).on('resize', this.resize.bind(this));
});
