'use strict';

angular.module('datasheet.frontend')

/**
 * Dataseries selection popup modal. Used to filter/select data for chart-component and highMap-component.
 */
.factory('DataSeriesModal', function ($uibModal)
{
    return function DataSeriesModal (datasheets, dataseries, typeConfig, brandColorEnabled)
    {
        return $uibModal.open({
            size: 'lg',
            templateUrl: 'app/datasheet/frontend/dataSeriesModal/dataSeriesModal.html',
            controller: 'DataSeriesModalCtrl',
            windowClass: 'data-series-config-modal',
            resolve: {
                /**
                 * list of datasheets available from backend/db
                 */
                datasheets: function () {
                    return datasheets;
                },

                /**
                 * list of dataseries previously saved
                 */
                dataseries: function () {
                    return dataseries;
                },

                /**
                 * type of occasion where this Modal is triggered
                 *
                 * @return {Object}                             For chart (default)
                 * @return {Object} typeConfig.isForMap         For map (includes countryList, regionList for
                 *                                              validation checks)
                 * @return {Object} typeConfig.isForChartOnMap  For chart on/inside CountryDetailModal
                 */
                typeConfig: function () {
                    return typeConfig;
                } ,

                /**
                 * Is brand color enabled.
                 * @returns {*}
                 */
                brandColorEnabled: function () {
                    return brandColorEnabled;
                }
            }
        });
    };
});
