'use strict';

angular.module('presentation.theme')

.directive('themeMarginSize', function ()
{
    return {
        scope: {},
        controllerAs: '$ctrl',
        bindToController: {
            config: '='
        },
        templateUrl: 'app/presentation/theme/theme.builder/slide-setup/margin-size/margin-size.html',
        controller: function ()
        {
            this.margins = [];
            
            this.$onInit = function ()
            {
                angular.forEach(this.config, (marginSide) => {
                    const key = Object.keys(marginSide);
                    const label = key[0];
                    this.margins.push(splitValues(label, marginSide[label]));
                });
            };

            this.updateMargins = function ()
            {
                for (let i = 0; i < this.config.length; i++) {
                    const margin = this.margins[i];
                    const value = margin.value ? margin.value : 0;
                    this.config[i][margin.label] = `${value}${value !== 0 ? margin.unit : '' }`;
                }
            };

            this.toggleUnit = function (marginSide)
            {
                marginSide.unit = marginSide.unit === '%' ? 'px' : '%';
                this.updateMargins();
            };

            function splitValues (label, marginSide) {
                let parts = marginSide.match(/(\d*\.?\d*)(.*)/);
                return {
                    label: label,
                    value: Number(parts[1]), 
                    unit: parts[2]
                };
            }

        }
    };
});