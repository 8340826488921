'use strict';

angular.module('datasheet.model')

.run(function (Datasheet) {}) // jshint ignore:line

.factory('Datasheet', function (DS, $state, dataResourceFactory)
{
    return DS.defineResource({
        basePath: '/api/',
        name: 'Datasheet',
        endpoint: 'datasheets',
        upsert: true,
        defaultValues: {
            action_id: 0,
        },
        methods: {
            goInto: function ()
            {
                if (this.type === 'folder') {
                    $state.go('admin.datasheets', {folder_id: this.id, name: null, offset: null});
                } else {
                    $state.go('admin.datasheets.editor', {id: this.id});
                }
            },
            getDataResource: function ()
            {
                return dataResourceFactory.getResource(this);
            },
            fetchData: function (params, options)
            {
                params = params || {};
                options = options || {};
                options.bypassCache = options.bypassCache || false;

                return this.getDataResource().findAll(params, options);
            },
            getDataOrder: function ()
            {
                var resource = this.getDataResource();
                return resource.meta.columns ? resource.meta.columns : null;
            }
        }
    });
});
