'use strict';

angular.module('editorComponents.timeline')

.factory('TimelineDefaults', function (moment)
{
    function createFullYear ()
    {
        var list = [];
        var months = moment.monthsShort();
        var year = new Date().getFullYear();
        var event = {
            data: {},
            style: {}
        };

        for (var idx = 0; months.length > idx; idx++) {
            var month = months[idx];
            var eventInstance = angular.copy(event);

            eventInstance.date = new Date(month + ' 1, ' + year);

            list.push(eventInstance);
        }

        return list;
    }

    return function () {
        this.applyStyleToParent = true;
        this.showAllMonths = true;
        this.events = createFullYear();
        this.startDate = this.events[0].date;
    };
});
