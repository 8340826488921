'use strict';

angular.module('presentation.theme')

.config(function (resourceStateProvider, $stateProvider)
{
    resourceStateProvider.state('admin.themes', {
        url: 'themes',
        model: 'Theme',
        viewOptions: 'themeViewOptions',
        title: 'Themes',
        edit: {
            templateUrl: 'app/presentation/theme/theme.edit/theme.edit.html',
            controller: 'ThemeEditCtrl',
            resolve: {
                model: ['Theme', 'modalState', function (Theme, modalState)
                {
                    if (!parseInt(modalState.stateParams.id)) {
                        modalState.model = Theme.createInstance({action_id: 0});
                        return modalState.model;
                    }

                    return Theme.find(modalState.stateParams.id).then(function (data)
                    {
                        modalState.model = data;

                        return modalState.model;
                    });
                }],
            }
        },
        root: {
            resolve: {
                actions: ['Action', function (Action)
                {
                    return Action.findAll();
                }],
            }
        },
        deleteCheckDependencies: true
    });

    $stateProvider
    .state('admin.theme-build', {
        abstract: true,
        url: '/build/{id:[0-9\w-]+}',
        templateUrl: 'app/presentation/theme/theme.builder/theme.builder.html',
        controller: 'ThemeBuilderCtrl',
        resolve: {
            model: ['Theme', '$stateParams', function (Theme, $stateParams)
            {
                return Theme.findAll({
                    where: {id: $stateParams.id},
                    with: 'fonts'
                }).then(function (themes)
                {
                    return themes[0] ? themes[0] : null;
                });
            }]
        },
    });

    $stateProvider
    .state('admin.theme-build.introduction', {
        url: '/introduction',
        templateUrl: 'app/presentation/theme/theme.builder/introduction/theme.builder.introduction.html'
    });

    $stateProvider
    .state('admin.theme-build.colours', {
        url: '/colours',
        templateUrl: 'app/presentation/theme/theme.builder/colours/theme.builder.colours.html'
    });

    $stateProvider
        .state('admin.theme-build.typefaces', {
            url: '/typefaces',
            templateUrl: 'app/presentation/theme/theme.builder/typefaces/theme.builder.typefaces.html'
        });

    $stateProvider
    .state('admin.theme-build.typography', {
        url: '/typography',
        templateUrl: 'app/presentation/theme/theme.builder/typography/theme.builder.typography.html'
    });
    $stateProvider
    .state('admin.theme-build.presentation-intro', {
        url: '/presentation-intro',
        templateUrl: 'app/presentation/theme/theme.builder/presentation-intro/theme.builder.presentation-intro.html'
    });

    $stateProvider
    .state('admin.theme-build.slide-setup', {
        url: '/slide-information',
        templateUrl: 'app/presentation/theme/theme.builder/slide-setup/theme.builder.slide-setup.html'
    });

    $stateProvider
        .state('admin.theme-build.images', {
            url: '/images',
            templateUrl: 'app/presentation/theme/theme.builder/images/theme.builder.images.html'
        });

    $stateProvider
    .state('admin.theme-build.tables', {
        url: '/tables',
        templateUrl: 'app/presentation/theme/theme.builder/tables/theme.builder.tables.html'
    });

    $stateProvider
    .state('admin.theme-build.shapes', {
        url: '/shapes',
        templateUrl: 'app/presentation/theme/theme.builder/shapes/theme.builder.shapes.html'
    });

    $stateProvider
    .state('admin.theme-build.charts', {
        url: '/charts',
        templateUrl: 'app/presentation/theme/theme.builder/charts/theme.builder.Charts.html'
    });

    // $stateProvider
    // .state('admin.theme-build.maps', {
    //     url: '/maps',
    //     templateUrl: 'app/presentation/theme/theme.builder/maps/theme.builder.maps.html'
    // });

    $stateProvider
    .state('admin.theme-build.tabs', {
        url: '/tabs',
        templateUrl: 'app/presentation/theme/theme.builder/tabs/theme.builder.tabs.html'
    });

    $stateProvider
    .state('admin.theme-build.popups', {
        url: '/popups',
        templateUrl: 'app/presentation/theme/theme.builder/popups/theme.builder.popups.html'
    });

    $stateProvider
    .state('admin.theme-build.iframes', {
        url: '/iframes',
        templateUrl: 'app/presentation/theme/theme.builder/iframes/theme.builder.iframes.html'
    });

    $stateProvider
    .state('admin.theme-build.brand-color', {
        url: '/brand-color',
        templateUrl: 'app/presentation/theme/theme.builder/brand-color/theme.builder.brand-color.html'
    });

    $stateProvider
    .state('admin.theme-build.custom', {
        url: '/custom',
        templateUrl: 'app/presentation/theme/theme.builder/custom/theme.builder.custom.html'
    });
});
