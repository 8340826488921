'use strict';

angular.module('komondor-editor')

.directive('sidebarMapFormatting', function ()
{
    return {
        replace: false,
        restrict: 'A',
        require: '^editor',
        templateUrl: 'scripts/editorComponents/highMap/map-formatting/sidebar-map-formatting.html',
        controllerAs: '$mapFormatCtrl',
        controller: function ($scope, $element, editorState)
        {
            this.comScope = editorState.componentScope;

            this.$onInit = () =>
            {
               // console.log('sidebarMapFormatting');
            };
        }
    };
});
