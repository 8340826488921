'use strict';

angular.module('app')
    .controller('StorageSettingsCtrl', function ($uibModalInstance, $http, $state, notify) {

        var self = this;  // Bind this to self

        self.data = {
            criteria: 'all',
            date: 'today',
        };

        self.errorMessage = '';
        self.isLoading = false;

        self.deleteUnusedAssets = function(form) {
            if (form.$valid) {
                notify.confirm('Are you sure you want to delete unused assets?', { title: 'Confirm Deletion' })
                    .result.then(function () {
                    self.isLoading = true;

                    // Send the request to delete unused assets
                    $http.post('/api/assets/delete-unused', self.data)
                        .then(function() {
                            self.isLoading = false;
                            // Reload the current state to refresh the media library
                            $state.reload();
                            $uibModalInstance.close();
                        })
                        .catch(function(errorResponse) {
                            self.isLoading = false;
                            self.errorMessage = errorResponse.data.message || errorResponse.data;
                        });
                })
                    .catch(function() {
                        // If the user cancels, do nothing
                    });
            } else {
                form.$setSubmitted();
            }
        };
    });
