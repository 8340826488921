'use strict';

angular.module('app')

.factory('masterAction', function ($state, $rootScope, kdGate, kdAuth)
{
    return {
        name: 'master',
        tooltip: 'Master',
        buttonClass: 'btn btn-default',
        template: '<span>Master</span>',
        act: function (models)
        {
            $state.go('.master', {
                id: models[0].id
            });
        },
        show: function (model)
        {
            if (!kdGate.allows('slide.edit-master') || !kdGate.allows('slide.create')) {
                return false;
            }

            if (model.type === 'folder') {
                return false;
            }

            //Slide is already a master
            if (model.type === 'master') {
                return false;
            }

            //only show "Master" button if slide is editable.
            if (model.editable) {

                //If editable slide is mine, I can always make master of it.
                if (model.user_id === kdAuth.user.id) {
                    return true;
                }

                //If not, I need to have corresponding permission.
                return kdGate.allows('slide.edit-colleagues-master');
            }

            return false;
        }
    };
});
