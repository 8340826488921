'use strict';

angular.module('presentation.theme')

.directive('themePalette', function ()
{
    return {
        restrict: "E",
        templateUrl: 'src/app/presentation/theme/components/theme.palette/theme.palette.html',
        controllerAs: '$ctrl',
        bindToController: {
            model: '='
        },
        controller: function ()
        {
            var self = this;

            this.model = this.model || [];

            this.addColorRow = function ()
            {
                self.model.push({
                    name: '',
                    code: '#FFFFFF'
                });
            };

            this.removeColorRow = function (item)
            {
                var index = self.model.indexOf(item);

                if (index > -1) {
                    self.model.splice(index, 1);
                }
            };
        }
    };
});
