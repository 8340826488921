'use strict';

angular.module('komondor.multitenancy')

.factory('settingsAction', function ($state)
{
    return {
        name: 'settings',
        tooltip: 'Settings',
        buttonClass: 'btn btn-default',
        template: '<i class="material-icon material-icon-lock"></i> Settings',
        act: function (models)
        {
            $state.go('.settings', {
                id: models[0].id
            });
        },
        show: function ()
        {
            return true;
        }
    };
});
