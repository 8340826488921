'use strict';

angular.module('presentation.theme')

.component('themeComponentList', function ()
{
    return {
        templateUrl: 'app/presentation/theme/components/theme.component-list/theme.component-list.html',
    };
});
