'use strict';

angular.module('app')

.factory('usersExportAction', function ($window)
{
    return {
        name: 'export',
        tooltip: 'Export users CSV',
        buttonClass: 'btn btn-primary',
        template: '<span><i class="material-icon material-icon-import-export"></i> Export CSV</span>',
        act: function ()
        {
            $window.open('/api/export-users');
        },
        show: function ()
        {
            return true;
        }
    };
});
