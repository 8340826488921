'use strict';

angular.module('app')

.controller('ClientPresentationsCtrl', function (
    $scope,
    Presentation,
    listNameTemplate,
    model,
    $uibModalStack,
    $state
) {

    $scope.presentations = model;
    $scope.listNameTemplate = listNameTemplate;


    this.goToModel = ($event, mdl) =>
    {

        $state.go('admin.editor.presentation.chapter.slide',{
            chapterOrder: 1,
            slideOrder: 1,
            id: mdl.id,
            presentationMode: "editor",
        }).then(()=> {
            // Close all the modals
            $uibModalStack.dismissAll();
        });

    };

    this.goToFolder = ($event) =>
    {
        $state.go('admin.presentations',{folder_id: $event})
            .then(()=> {
                // Close all the modals
                $uibModalStack.dismissAll();
            });
    };


});
