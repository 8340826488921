'use strict';

angular.module('app')

.controller('MissingClientTokensCtrl', function (
    $scope,
    client,
    missingTokens,
    $uibModalInstance
) {
    $scope.client = client;
    $scope.data = [];

    angular.forEach(missingTokens, function (token)
    {
        $scope.data.push({
            key:  token,
            value:  ''
        });
    });

    $scope.save = function ()
    {
        angular.forEach($scope.data, function (newToken)
        {
            client.data.push(newToken);

        });

        client.DSSave().then(function (modal)
        {
            $uibModalInstance.close(modal);
        });
    };
});
