'use strict';

angular.module('app')

.factory('capital-one-cup', function (ThemeConfig)
{
    var theme = new ThemeConfig();

    /**
     * Modify the editor options to suit this theme
     *
     */
    theme.editorOptions = function (options)
    {
        delete options.nestableComponents.column.nested.tabs;

        options.componentClasses['comp-panel'] = {
            default: 'corner-braces',
            classes: {
                'corner-braces': 'Corner braces',
                'presentation-cover': 'Presentation cover',
                shaded: 'Shaded'
            }
        };

        options.componentOptions['comp-panel'].background = {
            default: 'transparent',
            colors: {
                transparent: 'None',
                '#000000': 'Black',
                '#FFFFFF': 'White',
                '#002B59': 'Blue',
                '#EB2617': 'Red',
                '#77BF23': 'Green',
                '#00AEA7': 'Light Green',
                '#F7610B': 'Orange',
                '#FF9F00': 'Light Orange',
                '#3EB6E0': 'Light blue',
                '#853F97': 'Purple'
            }
        };

        options.componentOptions['shape-line'].defaultColor = '#3EB6E0';

        options.componentOptions.textblock.tags = {
            h1: 'Hero Heading',
            h2: 'Heading',
            h3: 'Sub-heading',
            //h4: 'Sub-heading (Medium)',
            //h5: 'Sub-heading (Small)',
            h6: 'Stand-first',
            p: 'Paragraph',
            blockquote : 'Quote',
            cite: 'Cite'
        };

        options.componentOptions.textblock.allowedTags = {
            b: 'no attributes',
            u: 'no attributes',
            sup: 'no attributes',
            sub: 'no attributes',
            font: true,
            a: true,
        };

        options.textColours = {
            Black: '#4b4b4d',
            White: '#ffffed',
            Green: '#77BF23',
            Red: '#EB2617',
            'Light Green': '#00AEA7',
            Orange: '#F7610B',
            'Light Orange': '#FF9F00',
            Blue: '#002B59',
            'Light blue': '#3EB6E0',
            Purple: '#853F97'
        };

        options.backgroundColors = {
            '#000000': 'Black',
            '#FFFFFF': 'White',
            '#002B59': 'Blue',
            '#EB2617': 'Red',
            '#77BF23': 'Green',
            '#00AEA7': 'Light Green',
            '#F7610B': 'Orange',
            '#FF9F00': 'Light Orange',
            '#3EB6E0': 'Light blue',
            '#853F97': 'Purple'
        };

    };

    return theme;
});
