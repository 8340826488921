'use strict';

angular.module('komondor.multitenancy')

.factory('Domain', function (DS)
{
    // noinspection JSAnnotator
    return DS.defineResource({
        name: 'Domain',
        endpoint: 'domains',
        upsert: true,
        defaultValues: {
            logo_asset_id: 0,
            login_asset_id: 0,
            favicon_asset_id: 0,
        },
        relations: {
            belongsTo: {
                Action: {
                    localField: 'action',
                    localKey: 'action_id'
                },
            }
        }
    });
});
