'use strict';

angular.module('app')
.controller('TargetedMessageCtrl', function ($scope, message, $uibModalInstance)
{
    $scope.message = angular.copy(message);

    if (angular.isArray($scope.message)) {
        $scope.message = {html: '', className: ''};
    }

    $scope.saveData = function ()
    {
        Object.keys($scope.message).forEach(function(key) {
            message[key] = $scope.message[key];
        });

        $uibModalInstance.close($scope.message);
    };

    $scope.cancelModal = function ()
    {
        $uibModalInstance.dismiss();
    };
});
