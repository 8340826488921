'use strict';

angular.module('app')

.service('defaultSlideConfig', function (defaultConfigCache, extendDeep)
{
    const options = () => {
        return {
            background: {
                color: '#FFFFFF'
            },
            showInfo: true,
            slidePosition: 'top',
            text: {
                color: 'txt-dark'
            },
            fadeFrontLayer: {
                enabled: false,
                delay: 2,
            },
            rtl: false,
            showBreadcrumbs: true,
        };
    };

    this.get = () =>
    {
        return defaultConfigCache('slideConfig', options());
    };

    this.ensure = (slide) =>
    {
        if (!slide) {
            return;
        }

        const slideConfig = this.get();

        let defaultGradientConfig = {
            visible: false,
            span: 100,
            unit: '%',
            position: 'left',
            style: {}
        };

        // Ensure the slide config is an object
        if (!slide.config  || angular.isArray(slide.config)) {
            slide.config = {};
        }
        // Default the slide configuration
        slide.config = extendDeep({}, slideConfig, slide.config);
        slide.config.gradientConfig = slide.config.gradientConfig || angular.copy(defaultGradientConfig);
        slide.config.rtl = angular.isNumber(slide.id) ? slide.config.rtl : false;

        // Backwards compatibility to turn empty string background to white
        if (/^\s*$/.test(slide.config.background.color)) {
            slide.config.background.color = slideConfig.background.color;
        }

        return slide;
    };
});
