'use strict';

angular.module('presentation.frontend')

.run(function ($rootScope, targetedImageCache)
{
    $rootScope.$on('targeted-imagery:saved', function ($event, data)
    {
        targetedImageCache.clear(data.id);
    });
})

.service('targetedImageCache', function ($http, $rootScope, $q, Asset, md5)
{
    this.cached = {};
    this.queue = [];
    this.current = null;

    this.cache = function (backgroundAssetId, config)
    {
        var cacheKey = md5.createHash(angular.toJson(config));

        if (!this.cached[backgroundAssetId]) {
            this.cached[backgroundAssetId] = {};
        }

        if (!this.cached[backgroundAssetId][cacheKey]) {

            var defer = $q.defer();

            this.make(defer, backgroundAssetId, config);

            this.cached[backgroundAssetId][cacheKey] = defer.promise;
        }

        return this.cached[backgroundAssetId][cacheKey];
    };

    this.make = function (defer, backgroundAssetId, config)
    {
        if ($rootScope.offline) {
            return $q.when(Asset.get(backgroundAssetId));
        }

        this.queue.push(function ()
        {
            return this.makeTargetedMessage(config).then(function (messageAsset)
            {
                if (messageAsset) {
                    config.assets.message = messageAsset.id;
                }

                return $http.post('/api/targeted-imagery/make', {
                    background_id: backgroundAssetId,
                    config: config,
                }).then(function (response) {
                    var model = Asset.inject(response.data);
                    defer.resolve(model);
                }, defer.reject);

            }, defer.reject);

        }.bind(this));

        if (!this.current) {
            this.next();
        }
    };

    this.makeTargetedMessage = function (config)
    {
        if (!config.targetedMessage || !config.targetedMessage.html) {
            return $q.when(0);
        }

        return $http.post('/api/targeted-message/make', config.targetedMessage).then(function (response)
        {
            if (response.data) {
                return Asset.inject(response.data);
            } else {
                return null;
            }
        });
    };

    this.next = function ()
    {
        this.current = this.queue.shift();

        if (this.current) {
            var promise = this.current();
            promise.then(this.next.bind(this));
        }
    };

    this.put = function (backgroundAssetId, config, generatedAssetId)
    {
        var cacheKey = md5.createHash(angular.toJson(config));

        if (!this.cached[backgroundAssetId]) {
            this.cached[backgroundAssetId] = {};
        }

        this.cached[backgroundAssetId][cacheKey] = Asset.find(generatedAssetId);
    };

    this.clear = function (backgroundAssetId)
    {
        if (backgroundAssetId) {
            if (this.cached[backgroundAssetId]) {
                delete this.cached[backgroundAssetId];
            }
        } else {
            this.cached = [];
        }
    };
});
