'use strict';

angular.module('app')

.factory('deleteSingleItemAction', function (deleteAction)
{
    return angular.extend({}, deleteAction, {
        name: 'deleteSingleItem',
        show: function (model) {
            return !model.deleted_at && model.editable !== false && !model.locked_by;
        }
    });
});
