'use strict';

angular.module('app')

.service('defaultChartThemeConfig', function (defaultConfigCache)
{
    const options = {
        themes: {
            light: {
                colors: {
                    series: [
                        '#00B8E6',
                        '#FFFFFF',
                        '#C2D440',
                        '#F59638',
                        '#FF00FF'
                    ],
                    highlight: '#00B8E6',
                    // You can set a single colour
                    // as a String if they are all
                    // the same
                    arrows:    {
                        increased: 'green',
                        decreased: 'red',
                        equal: 'blue'
                    },
                    percentage: '#FFFFFF',
                    legend:   '#FFFFFF',
                    plotOptions: {
                        series: {
                            dataLabels: '#FFFFFF'
                        },
                        pie: {
                            dataLabels: '#FFFFFF'
                        }
                    },
                    xAxis: {
                        label: '#FFFFFF',
                        line:   '#FFFFFF',
                        lineWidth: 1
                    },
                    yAxis: {
                        label: '#FFFFFF',
                        line:   '#FFFFFF',
                        lineWidth: 1
                    }
                }
            },
            dark: {
                colors: {
                    series: [
                        '#00B8E6',
                        '#999999',
                        '#C2D440',
                        '#F59638',
                        '#FF00FF'
                    ],
                    highlight: '#999999',
                    arrows:    {
                        increased: 'green',
                        decreased: 'red',
                        equal: 'blue'
                    },
                    percentage: '#373737',
                    legend:    '#333333',
                    plotOptions: {
                        series: {
                            dataLabels: '#333333'
                        },
                        pie: {
                            dataLabels: '#333333'
                        }
                    },
                    xAxis: {
                        label: '#333333',
                        line:   '#333333',
                        lineWidth: 1
                    },
                    yAxis: {
                        label: '#333333',
                        line:   '#333333',
                        lineWidth: 1
                    }
                }
            }
        }
    };

    this.get = function () {
        return defaultConfigCache('chartThemeConfig', options);
    };
});
