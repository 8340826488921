'use strict';

angular.module('app')

.directive('keepAspectRatio', function (
    $rootScope,
    tweenMax,
    $window,
    debounce,
    isPhantom,
    presentationState
) {
    return {
        restrict: 'A',
        scope: {
            aspectRatio: '=keepAspectRatio',
            enable: '=keepAspectRatioEnable',
            minWidth: '=?',
        },
        controller: function ($scope, $element)
        {
            if (!$scope.enable) {
                return;
            }

            const config = presentationState.presentation ? presentationState.presentation.config : null;
            const top = isPhantom ? 0 : 45;
            const left = isPhantom ? 0 : 100;
            const bottom = isPhantom ? 0 : 100;

            const aspectRatios = {
                '16:9': {width: 1440, height: 810},
                '16:10': {width: 1440, height: 900}
            };
            const element = $element[0];
            const parent = $element.parent()[0];

            let baseCanvasSize = aspectRatios[$scope.aspectRatio];
            let topOffset = 0;
            let leftOffset = 0;
            let rightOffset = 300;
            let bottomOffset = 0;

            this.$onInit = function ()
            {
                $scope.aspectRatio = $scope.aspectRatio || '16:9';
                $scope.minWidth = $scope.minWidth || '0';

                setCanvasSize();
            };

            function checkMargins ()
            {
                topOffset = 0;
                leftOffset = 0;
                bottomOffset = 0;

                if (windowOrientation() >= 1 && $window.innerWidth <= 1024)
                {
                    bottomOffset = bottom;

                    if (presentationState.editMode) {
                        presentationState.goToPreview();
                    }
                }
                else if (windowOrientation() < 1 && $window.innerWidth <= 1024)
                {
                    leftOffset = config && config.sidebarMobileNavigationOff ? 0 : left;

                    if (presentationState.editMode) {
                        presentationState.goToPreview();
                    }
                }
                else
                {
                    topOffset = presentationState.editMode ? top : 0;
                }
            }

            function setCanvasSize ()
            {
                checkMargins();

                var prop = {
                    width: baseCanvasSize.width,
                    height: baseCanvasSize.height,
                    marginLeft: -(baseCanvasSize.width - leftOffset) / 2,
                    paddingRight: rightOffset,
                    marginTop: -(baseCanvasSize.height - topOffset + bottomOffset) / 2,
                    top: "50%",
                    left: "50%"
                };

                if (presentationState.scale) {
                    prop.scale = presentationState.scale;
                }

                tweenMax.set(element, prop);

                scaleCanvas();
            }

            function windowOrientation ()
            {
                const height = parent.clientHeight - topOffset - bottomOffset;
                const width = parent.clientWidth - leftOffset;
                return height / width;
            }

            function smallestSide ()
            {
                var canvasRatio = baseCanvasSize.height / baseCanvasSize.width;
                var windowRatio = windowOrientation();

                return windowRatio > canvasRatio ? 'width' : 'height';
            }

            function getCanvasScale ()
            {
                var minWindowWidth = Math.max($scope.minWidth, parent.clientWidth);

                return {
                    height: (parent.clientHeight - topOffset - bottomOffset) / baseCanvasSize.height,
                    width: (minWindowWidth - leftOffset) / baseCanvasSize.width
                };
            }

            function scaleCanvas ()
            {
                debounce(function ()
                {
                    var side = smallestSide();
                    var scale = getCanvasScale();
                    var options = {
                        scale: scale[side],
                        ease: $window.Power2.easeOut,
                        immediateRender: true,
                        force3D: "auto"
                    };

                    if (isPhantom) {
                        tweenMax.set(element, options);
                    } else {
                        tweenMax.to(element, 0.2, options);
                    }

                    // Save for next slide
                    presentationState.scale = scale[side];

                }, isPhantom ? 0 : 300)();
            }

            angular.element($window).on('resize', setCanvasSize);
            const removeCanvasResizeListener = $rootScope.$on('canvas:resize', setCanvasSize);

            $scope.$on('$destroy', function ()
            {
                angular.element($window).off('resize', setCanvasSize);
                removeCanvasResizeListener();
            });
        }
    };
});
