'use strict';

angular.module('app')

.factory('loadPresentationResources', function (Presentation)
{
    return function (presentationId, additional)
    {
        additional = additional || [];

        var loadWith = ['chapters', 'chapters.chapterSlides', 'chapters.chapterSlides.slide'];

        angular.forEach(additional, function (add)
        {
            loadWith.push(add);
        });

        return Presentation.findAll({
            where: {id: presentationId},
            with: loadWith.join(',')
        }, {
            bypassCache: true
        }).then(function (presentations)
        {
            return presentations[0] ? presentations[0] : null;
        });
    };
});
