'use strict';

angular.module('app')

/**
 * This service adds an overlay element to the DOM that gets removed when a promise resolves
 * or you call the skip method
 */
.factory('presenterOverlay', function ($rootScope, $document, $compile, $q, $log, assetCache, $timeout)
{
    let deferred;
    let onStartPresenting;
    let scope = $rootScope.$new();

    /**
     * Remove the element if we ever change state
     */
   // $rootScope.$on('$stateChangeSuccess', removeElement);

    /**
     * Add a compiled preloader template to the DOM
     *
     * @param Promise a promise that if resolved will close the overlay
     *
     * @return Promise
     */
    function create (presentation, onStart)
    {
        let introConfig = presentation.theme.config.intro || {};
        let pressConfig = presentation.config || {};

        onStartPresenting = onStart;

        scope.startPresenting = startPresenting;

        // Change this to be theming variables

        scope.intro = {
            enabled: introConfig.enabled && (pressConfig.intro || introConfig.autoEnableAll),
            name: presentation.name
        };

        scope.backgroundUrl = '';
        scope.disableStartBtn = true;
        scope.viewers = [];

        //If intro page enabled check what text we need to show.
        if (scope.intro.enabled) {

            if (introConfig.displayImage) {

                if (introConfig.assetId) {
                    assetCache.get(introConfig.assetId).then(function (asset)
                    {
                        scope.backgroundUrl = asset.links.download;
                    });
                } else if (introConfig.assetUrl) {
                    scope.backgroundUrl = introConfig.assetUrl;
                }
            }
        }

        let template = '<div id="presenter-overlay" ng-include="\'views/editor/presenter.overlay.html\'"></div>';
        let element = $compile(template)(scope);

        angular.element($document[0].body).append(element);

        // The promise that will close the overlay
        deferred = $q.defer();

        // Remove overlay once loaded or skipped
        deferred.promise.then(removeElement);

        return deferred.promise;
    }

    /**
     * Remove the overlay from the DOM
     *
     * The compiled element could be stored as state on the preloader
     *
     * @return void
     */
    function removeElement ()
    {
        $timeout(function () {
            let element = $document[0].getElementById('presenter-overlay');
            angular.element(element).remove();
        });
    }

    /**
     * Skip the preloading
     *
     * @return void
     */
    function startPresenting ()
    {
        onStartPresenting();
    }

    function disableStart() {
        if (!scope.viewers) {
            scope.disableStartBtn = true;
        }

        let enabled = scope.viewers.filter(viewer => !viewer.status);


        if (enabled.length > 0) {
            scope.disableStartBtn = true;
        } else {
            scope.disableStartBtn = false;
        }
    }

    function getViewers(viewers)
    {
        if (viewers) {
            let ids = Object.keys(viewers);

            for (var id in ids) {
                let newViewer = {id: ids[id], status: viewers[ids[id]]};

                if (!scope.viewers) {
                    scope.viewers = [];
                }
                const temp = newViewer;
                let index = scope.viewers.findIndex(viewer => viewer.id === temp.id);

                if (index === -1) {
                    scope.viewers.push(newViewer);
                } else {
                    scope.viewers[index].status = newViewer.status;
                }
            }
        }
    }

    return {
        create: create,
        removeElement: removeElement,
        getViewers: getViewers,
        disableStart: disableStart
    };
});
