'use strict';

angular.module('presentation.font')

.run(function (Font) {}) // jshint ignore:line

.factory('Font', function (DS)
{
    return DS.defineResource({
        name: 'Font',
        endpoint: 'fonts',
        upsert: true,
        relations: {
            belongsTo: {
                Theme: {
                    localField: 'theme',
                    localKey: 'theme_id'
                }
            }
        }
    });
});
