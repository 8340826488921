'use strict';

angular.module('app')

.controller('SlideCtrl', function (
    $scope,
    $state,
    $timeout,
    $log,
    $uibModal,
    $element,
    assetCache,
    slide,
    defaultEditorOptions,
    editorState,
    presentationState,
    presentation,
    $rootScope,
    clientTokens,
    Slide,
    prepareLimitedEditorOptions,
    kdGate,
    Notification,
    DS,
    linkModal,
    extendDeep,
    defaultSlideConfig,
    prepareEditorRtlOptions,
    prepareEditorClientOptions,
    IsMobile,
    currentTheme,
    presentLink,
    presenteeLink,
    presenteeService
) {
    const editorOptions = defaultEditorOptions.get();
    let slideShowMode;

    $scope.presentation = presentation;
    $scope.enableMobileMenu = !presenteeLink;

    $scope.$watch('presentation.client', function(newClient, oldClient)
    {
        if (newClient !== oldClient) {
            $timeout(() =>
            {
                $state.reload('admin.editor.presentation.chapter');
            });
        }

    }.bind(this));

    this.$onInit = function () {
        ///////////////////
        // scope members //
        ///////////////////
        $scope.slide = defaultSlideConfig.ensure(slide);
        $scope.presentLink = presentLink;
        $scope.showInfo = true;

        $scope.presentationState = presentationState;
        $scope.editorState = editorState;
        $scope.editorState.printMode = false;
        $scope.aspectRatioEnabled = presentationState.isFixedCanvas();
        $scope.sidebarOptionsEnabled = presentationState.isSidebarOptions();

        if (presenteeLink) {
            $scope.presenteeState = presenteeService.state;
        } else {
            $scope.presenteeState = {
                allow_control: true
            };
        }

        // To print on header/footer marginals
        $scope.date = new Date();

        // If we leave/change slide, drop any picked up components
        $scope.$on('$destroy', function ()
        {
            if (editorState.pickup) {
                editorState.pickup.destroy();
            }
        });

        // Video on mobile
        if(IsMobile && presentation !== null && presentation.config.playOnMobile) {
          editorOptions.componentOptions['video-player'].forcePoster = false;
        }

        // Client logo
        $scope.clientLogo = '';

        if (presentation && presentation.client && presentation.client.asset) {
            $scope.clientLogo = presentation.client.asset.links.download;
        }

        if ($scope.slide && $scope.slide.editable) {

            editorOptions.controls = true;

            if ($scope.sidebarOptionsEnabled) {
                editorOptions.componentOptionsContainer = () => {
                    let node = $element[0].getElementsByClassName('slide-sidebar-controls');
                    return  angular.element(node);
                };
            } else {
                editorOptions.componentOptionsContainer = null;
            }

            $timeout(function ()
            {
                $scope.slide.snapshotContent();
            }, 500);
        } else {
            editorOptions.controls = false;
        }

        slideShowMode = presentationState.slideShowMode();

        if ($scope.slide && $scope.presentationState.editMode) {
            $scope.presentationState.setSidebarEditMode(!slideShowMode);
        }

        if (slideShowMode) {
            $scope.presentationState.setEditMode(false);
        } else {
            $scope.presentationState.setEditMode();
        }

        $scope.focusFootnote = false;
        $scope.clientTokens = clientTokens;

        // Update editor options if slide exists
        if (slide) {
            prepareEditorRtlOptions.update(editorOptions, slide.config.rtl);
        }

        $scope.editorOptions = angular.copy(editorOptions);
        $scope.editorOptions.onCreateLink = linkModal.open;
        $scope.editorOptions.onClickLink = linkModal.click;

        // Show extra options at bottom of modal
        const options = {
            'enablePopupsCreation': true
        };

        linkModal.setOptions(options);

        // Sharing one object with slide options
        // due to slide options changes may be resulted on a different object
        presentationState.setEditorOptions($scope.editorOptions);


        if (slide) {
            $scope.editorOptions.showBreadcrumbs = slide.config.showBreadcrumbs;

            if (slide.isLimited()) {
                $scope.editorOptions.limited = kdGate.denies('slide.edit-limited');
                prepareLimitedEditorOptions($scope.editorOptions);
            }
        }

        $scope.backLayerOptions = angular.copy($scope.editorOptions);
        $scope.backLayerOptions.move = false;

    };

    ////////////
    // events //
    ////////////
    $scope.$watch('editorState.editMode', function ()
    {
        $scope.$broadcast('editable:reformat');
    });

    $scope.$on('slide:save', function ()
    {
        var action = 'update';

        if (presentationState.saving) {
            return;
        }

        presentationState.saving = true;

        // Save presentation
        if (presentation) {

            presentation.saveSlides().then(function ()
            {
                presentation.snapshotChecksum();
                presentation.lockForOthers();
                DS.emit(action);
            },
            function (error)
            {
                //If conflict show resolve conflict model
                if (error.status === 403 || error.status === 404) {
                    resolveConflict(error.status);
                } else {
                    Notification.error('Unknown error!.');
                }
            });
        // Save slide
        } else {
            $scope.slide.DSSave().then(function ()
            {
                $rootScope.$broadcast('slide:saved');
                $timeout(function ()
                {
                    $scope.slide.snapshotContent(true);
                }, 500);
            },
            function (error)
            {
                DS.emit('error', action, 'slide', error);
            });
        }
    });

    function resetSavingState ()
    {
        presentationState.saving = false;
    }

    $scope.$on('slide:saved', resetSavingState);
    $scope.$on('slide:save-failed', resetSavingState);

    $scope.$on('toggle-editing-layer', function (e, data)
    {
        toggleEditingLayer(data);
    });

    $scope.$on('$stateChangeStart', function (event, toState, toParams)
    {
        var allowedState = presentation ? 'admin.editor.presentation.chapter.slide' : 'admin.editor.slide';

        if (toState.name === allowedState) {
            return;
        }

        var contentChanged = false;

        if (!presentation) {
            contentChanged = $scope.slide.hasContentChanged();
        } else {
            contentChanged = presentation.hasContentChanged();
        }

        if (contentChanged) {

            event.preventDefault();

            var modal = $uibModal.open({
                templateUrl: 'views/modals/unsavedPresentationConfirmation.html',
                controller: 'UnsavedPresentationConfirmationCtrl',
                backdrop: 'static',
                keyboard: false,
                size: 'md',
                resolve: {
                    presentation: function () {
                        return presentation;
                    },
                    slide: function () {
                        return $scope.slide;
                    }
                }
            });

            modal.result.then(function ()
            {
                $timeout(function () {
                    $state.go(toState.name, toParams);
                });
            });
        }
    });

    $scope.$watchGroup([
        'slide.config.footnote.visible',
        'editorState.editMode'
    ],
    function (newV, oldV)
    {
        var footnoteVisible = {
            new: newV[0],
            old: oldV[0]
        },
        editMode = {
            new: newV[1],
            old: oldV[1]
        };

        if (footnoteVisible.new !== footnoteVisible.old) {
            $scope.focusFootnote = $scope.slide.config.footnote.visible;
        }

        if (editMode.new !== editMode.old) {
            $scope.$broadcast('editable:reformat');
        }
    });

    ///////////////////////
    // Private functions //
    ///////////////////////
    function toggleEditingLayer (layerName)
    {
        $scope.currEditingLayer = presentationState.currentLayer = layerName;
        $scope.backLayerOptions.controls = $scope.currentLayer !== 'FrontLayer';

        $scope.$broadcast('component:de-select');
    }

    function resolveConflict (status)
    {
        var modal = $uibModal.open({
            templateUrl: 'views/modals/presentationConflict.html',
            controller: 'presentationConflictCtrl',
            backdrop: 'static',
            keyboard: false,
            size: 'md',
            resolve: {
                presentation: function () {
                    return presentation;
                },
                status: function () {
                    return status;
                }
            }
        });

        return modal.result;
    }

    $scope.slideBackground = function (value)
    {
        if (value === 'client_brad_color') {

            let theme = currentTheme();

            if (!theme) {
                return null;
            }

            let color =  theme.getDefaultClientBrandColor();

            if (presentation && !$scope.editorState.editMode) {
                color = presentation.getClientsBrandColor();
            }

            if (color) {
                return color;
            }
        }

        return value;
    };

});
