'use strict';

angular.module('app')

.filter('coloursFilter', function () {
    return function (colours, search) {
        var filteredColours = {};

        angular.forEach(colours, function (value, key) {
            if (key.toLowerCase() !== search.toLowerCase()) {
                filteredColours[key] = value;
            }
        });

        return filteredColours;
    };
});

