'use strict';

angular.module('presentation.frontend')

.factory('PresentationKeyBindings', function (
    presentationState,
    $rootScope,
    editorState,
    printAction
) {
    return function (presentation)
    {
        var self = this;

        var goToNext = function ()
        {
            if (presentation) {
                presentationState.changeSlide(1);
            }
        };

        var goToPrev = function ($event)
        {
            if (presentation) {
                presentationState.changeSlide(-1);
                $event.preventDefault();
            }
        };

        self.keybindings = {

            // Next slide (Up arrow, Space, Right Arrow, Page Down)
            40: goToNext,
            39: goToNext,
            34: goToNext,
            32: function ($event)
            {
                if (!editorState.editMode) {
                    goToNext($event);
                }
            },

            // Prev slide (Down arrow, Left Arrow, Page Up)
            37: goToPrev,
            38: goToPrev,
            33: goToPrev,

            // Fullscreen (F)
            70: function () {
                if (!editorState.selected && !editorState.editMode) {
                    presentationState.toggleFullscreen();
                }
            },

            // Print friendly view ('ctrl + P' or 'cmd + P' or 'alt + P')
            80: function ($event) {
                if ( ($event.metaKey || $event.altKey || $event.ctrlKey ) && !presentationState.slideShowMode() && presentation) {
                    printAction.act([presentationState.presentation]);
                }
            },

            // Save (ctrl + S)
            83: function ($event) {
                if ($event.ctrlKey && !presentationState.slideShowMode()) {
                    $rootScope.$broadcast('slide:save');
                }
            },
        };


        self.handler = function ($event)
        {
            // Skip if target element is an input
            var editable = false;

            if ($event.target) {

                if ($event.target.tagName === 'INPUT') {
                    editable = true;
                }

                if (angular.isFunction($event.target.getAttribute) && $event.target.getAttribute('bind-content-editable')) {
                    editable = true;
                }
            }

            if (editable) {
                return;
            }

            // Check if an component is selected before allowing keybindings
            if (self.keybindings[$event.keyCode] && !editorState.selected) {
                self.keybindings[$event.keyCode]($event);
            }
        };
    };
});
