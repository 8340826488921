'use strict';

angular.module('presentation.theme')

.directive('themeValidatorLabel', function (ThemeValidator) {
    return {
        restrict: 'A',
        controllerAs: '$ctrl',
        scope: {
            label: '@themeValidatorLabel'
        },
        link: function ($scope, element) {

            ThemeValidator.addLabel($scope.label, function (hasError) {
                if (hasError) {
                    element.addClass('error');
                } else {
                    element.removeClass('error');
                }
            });

            $scope.$on('$destroy', function() {
                ThemeValidator.removeLabel($scope.label);
            });
        }
    };
});