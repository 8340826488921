'use strict';

angular.module('app')

.factory('highmapMarkerFormatter', function (IconSearch)
{
    var self = this;

    self.iconsNotFound = {};

    /**
     * Get label & value tooltip html
     * @param {object} point
     * @returns {string}
     */
    var tooltipBody = function (point)
    {
        return '<div class="marker-content" ng-class="{\'ng-hide\': !data.showLabels && !data.showValues}">' +
               (self.data.showLabels ? point.name : '') +
               (self.data.showValues ? '<span class="value">' + point.value + '</span>' : '') +
               '</div>';
    };

    /**
     * Return tootlip css classes
     * @returns {string}
     */
    var markerClasses = function (type)
    {
        var classes = 'map-marker';
        classes += ' theme-' + self.data.themeName;
        classes += angular.isString(type) ? ' with-' + type : '';
        classes += self.data.showLabels || self.data.showValues ? ' with-label' : '';

        return classes;
    };

    /**
     * Get flag url by country | region name
     * @param {string} token
     * @returns {string}
     */
    var getFlagUrl = function (token)
    {
        var tokenStr = String(token);
        var tokenFound = IconSearch.search(tokenStr.toLowerCase());

        if (!tokenFound || !tokenFound.length) {

            if (!self.iconsNotFound[tokenStr]) {
                self.iconsNotFound[tokenStr] = true;
            }

            return;
        }

        return tokenFound[0].icon.asset.links.download;
    };

    /**
    * Return marker standard click event
    * @param point
    * @returns {string}
    */
    var markerClickEvent = function (point)
    {
        return 'ng-click="clickMarker(\'' + point.name + '\'); $event.stopPropagation();"';
    };

    var getMarker = function (point, type)
    {
        return point.value ?
            '<div ' + markerClickEvent(point) +
                'class="' + markerClasses(type) + '">' +
                ( type === 'flag' ? '<img src="' + getFlagUrl(point.name) + '" />' : '') +
                tooltipBody(point) +
            '</div>' : null;
    };

    return {

        /**
         * Set data
         * @param {object} data
         * @param {object} iconsNotFound
         */
        setData: function (data, iconsNotFound)
        {
            self.data = data;

            // Reference to an iconNotFound object
            self.iconsNotFound = iconsNotFound;
        },

        /**
         * Return formatter function by type
         * Note: return empty function if there is no formatter found
         * @param {string} type
         * @returns {function}
         */
        get: function (type)
        {
            return this.formatters[type] || function () {};
        },
        formatters: {

            /**
             * Pins marker html
             * @returns {string|null}
             */
            pins: function ()
            {
                return getMarker(this.point, 'pin');
            },

            /**
             * Flags marker html
             * @returns {string|null}
             */
            flags: function ()
            {
                return getMarker(this.point, 'flag');
            },

            /**
             * Empty marker html
             * @returns {string|null}
             */
            none: function ()
            {
                return getMarker(this.point, null);
            }
        }
    };

});
