'use strict';

angular.module('app')
.component('sortToggle', {
    bindings: {
        name: '@',
        sort: '='
    },
    template: `<span class="material-icon material-icon-sort-toggle cursor-pointer"
                     ng-click="$ctrl.changeSort()"></span>`,
    controller: function ()
    {
        this.isActive = () =>
        {
            return this.sort.indexOf(this.name) > -1;
        };

        this.isAscending = () =>
        {
            return this.sort.indexOf('-') > -1;
        };

        this.getDirection = () =>
        {
            let direction = '+';

            if (this.isActive()) {
                direction = this.isAscending() ? '+' : '-';
            }

            return direction;
        };

        this.changeSort = () =>
        {
            this.sort = this.getDirection() + this.name;
        };
    }
});