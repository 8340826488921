'use strict';

angular.module('appAccess', [
    'ngKomondor'
])

.config(function (usersStateOptionsProvider)
{
    usersStateOptionsProvider.config.edit.templateUrl = 'app/user/user.edit.html';
});
