'use strict';

angular.module('presentation.admin')

.directive('linksTable', function($uibModal, Share, Notification, $rootScope, $state) {
    return {
        restrict: 'E',
        controllerAs: '$ctrl',
        bindToController: true,
        templateUrl: 'app/presentation/admin/share/linksTable.html',
        scope: {
            list: '=',
            model: '=',
        },
        controller: function()
        {
            let clipboardEnabled = true;

            this.config = $rootScope.config;

            this.openStats = (model) =>
            {
                if (model.analytics_v2) {
                    $state.go('admin.analytics', {id: model.id});
                } else {
                    $uibModal.open({
                        templateUrl: `app/presentation/admin/share/share-analytics.html`,
                        windowClass: 'share-analytics',
                        controllerAs: '$ctrl',
                        controller: 'ShareAnalyticsCtrl',
                        resolve: {
                            model: () =>
                            {
                                return model;
                            }
                        }
                    });
                }
            };

            this.displaySessions = (stats) =>
            {
                if (!stats || angular.isArray(stats)) {
                    stats = {};
                }

                if (stats && stats.sessions && stats.sessions > 0) {
                    return stats.sessions;
                }
                else if (stats && stats.timeOnChapterSlides && stats.timeOnChapterSlides.length)
                {
                    return 1;
                }
                else
                {
                    return 0;
                }
            };

            this.expireLink = (id) =>
            {
                $uibModal.open({
                    templateUrl: `app/presentation/admin/share/expireLinkModal.html`,
                    controllerAs: '$ctrl',
                    controller: 'ExpireLinkModalCtrl',
                    resolve: {
                        share_id: () => {
                            return id;
                        }
                    }
                });
            };

            this.releaseLink = (id) =>
            {
                $uibModal.open({
                    templateUrl: `app/presentation/admin/share/releaseLinkModal.html`,
                    controllerAs: '$ctrl',
                    controller: 'ReleaseLinkModalCtrl',
                    resolve: {
                        share_id: () => {
                            return id;
                        }
                    }
                });
            };

            this.deleteLink = (id) =>
            {
                $uibModal.open({
                    templateUrl: `app/presentation/admin/share/deleteLinkModal.html`,
                    controllerAs: '$ctrl',
                    controller: 'DeleteLinkModalCtrl',
                    resolve: {
                        share_id: () => {
                            return id;
                        }
                    }
                });
            };

            this.copied = () =>
            {
                Notification.success('The link has been copied to your clipboard!');
            };

            this.onCopyError = (event) =>
            {
                clipboardEnabled = event.name !== 'flash-disabled';
            };

            this.checkClipboardEnabled = (event) =>
            {
                if (!clipboardEnabled) {
                    event.preventDefault();
                    Notification.warning('Link NOT copied. Please enable Flash on your browser.');
                }
            };
        }
    };
});
