'use strict';

angular.module('app')

.config(function ($stateProvider)
{
    $stateProvider
    .state('admin.error', {
        url: '/error',
        views: {
            'header@': {
                template: '',
            },
            'sidemenu@': {
                template: ''
            },
            '@admin': {
                template: ''
            }
        },
        data:{
            guestAccess: true
        }
    });

    $stateProvider
    .state('admin.error.offline-editing-not-supported', {
        url: '/offline-editing-not-supported',
        onEnter: function ($rootScope, $state, homeState) {

            if (!$rootScope.offline) {
                $state.go(homeState());
            }
        },
        views: {
            'header@': {
                templateUrl: 'views/errors/offline.editing.not.supported.html',
            },
        },
        data:{
            guestAccess: true
        }
    });
});
