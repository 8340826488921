'use strict';

angular.module('editorComponents.timeline')

.controller('timelineModalCtrl', function (
    Options,
    lodash,
    moment,
    $uibModalInstance,
    $scope
) {
    this.monthMap = moment.months();
    this.events = [];
    this.oldEvents = [];

    this.showAllMonths = Options.showAllMonths;

    this.format = 'MMM-yyyy';
    this.dt = new Date();
    this.popups = {
        newEvents: false
    };

    this.dateOptions =  {
        minMode: 'month',
        placement: 'auto right'
    };

    var checkDates = function (evts)
    {
        var events = angular.copy(evts);

        for (var idx in events)
        {
            var isDate = events[idx].date instanceof Date;
            events[idx].date = isDate ? events[idx].date : new Date(events[idx].date);
        }

        return events;
    };

    var groupEventsByYearMonth = function (events)
    {
        var groupedYears = lodash.groupBy(events, function (d) {
            return moment(d.date).format('YYYY');
        });

        for (var year in groupedYears) {
            groupedYears[year] = groupMonths(groupedYears[year]);
        }

        function groupMonths (year) {
            return lodash.groupBy(year, function (d) {
                return new Date(d.date).getMonth();
            });
        }

        return groupedYears;
    };

    var buildEventGroups = function ()
    {
        this.eventsGrouped = groupEventsByYearMonth(this.events);
    };

    this.$onInit = function ()
    {
        this.events = checkDates(Options.events);
        this.startDate = Options.startDate || this.events[0].date;
    };

    this.isSelected = function (date)
    {
        return Date.parse(date) === Date.parse(this.startDate);
    };

    this.open = function (popupName)
    {
        this.popups[popupName] = true;
    };

    this.add = function (date)
    {
        this.events.push({
            date: date,
            data: {},
            style: {}
        });

        if (this.events.length <= 1) {
            this.startDate = this.events[0].date;
        }
    };

    this.remove = function (event)
    {
        for (var idx = 0; idx < this.events.length; idx++) {

            if (this.events[idx] === event) {

                var removedEvent = this.events.splice(idx, 1);

                if (this.isSelected(removedEvent[0].date) && this.events.length) {
                    var index = idx ? idx -1 : 0;
                    this.startDate = this.events[index].date;
                }

                break;
            }
        }
    };

    this.setStartDate = function (date)
    {
        this.startDate = date;
    };

    this.save = function ()
    {
        $uibModalInstance.close({
            events: this.events,
            showAllMonths: this.showAllMonths,
            startDate: this.startDate
        });
    };

    $scope.$watch(function (evt) {

        if (angular.equals(evt.$ctrl.events, evt.$ctrl.oldEvents)) {
            return false;
        } else {
            evt.$ctrl.oldEvents = angular.copy(evt.$ctrl.events);
            return true;
        }

    }, buildEventGroups.bind(this));

});
